import { Injectable } from "@angular/core";



import { Observable } from "rxjs";
import { Org } from "../../models/org";
import { environment } from "../../../environments/environment";
import { ACCESS_TOKEN } from '../../storage/constants.lib';
import { HttpClient, HttpHeaders } from "@angular/common/http";



@Injectable({
  providedIn: "root"
})
export class PictureService {



  public baseURL = environment.baseURL;



  constructor(
    private http: HttpClient,
  ) {

  }


  getSites = (): Observable<Org[]> => {
    const token = localStorage.getItem(ACCESS_TOKEN)
    let headers = new HttpHeaders();
    //headers.append("Content-Type", 'application/json');
    headers = headers.append("Authorization", 'Bearer ' + token);

    const httpOptions = {
      headers: headers
    };
    return this.http
      .get<Org[]>(`${this.baseURL}/api/org/getall`, httpOptions)
      .catch(this.handleError);

  }




  deleteSite(siteId: string): Observable<Org[]> {
    const token = localStorage.getItem(ACCESS_TOKEN)
    let headers = new HttpHeaders();
    //headers.append("Content-Type", 'application/json');
    headers = headers.append("Authorization", 'Bearer ' + token);
    const httpOptions = {
      headers: headers
    };
    return this.http
      .get<Org[]>(`${this.baseURL}/api/org/delete/${siteId}`, httpOptions)
      .catch(this.handleError);

  }

  disableSite(siteId: string): Observable<Org[]> {
    const token = localStorage.getItem(ACCESS_TOKEN)
    let headers = new HttpHeaders();
    //headers.append("Content-Type", 'application/json');
    headers = headers.append("Authorization", 'Bearer ' + token);
    const httpOptions = {
      headers: headers
    };
    return this.http
      .get<Org[]>(`${this.baseURL}/api/org/disable/${siteId}`, httpOptions)
      .catch(this.handleError);

  }

  setReadonly(siteId: string, readonly: number): Observable<Org[]> {
    const token = localStorage.getItem(ACCESS_TOKEN)
    let headers = new HttpHeaders();
    //headers.append("Content-Type", 'application/json');
    headers = headers.append("Authorization", 'Bearer ' + token);
    const httpOptions = {
      headers: headers
    };
    return this.http
      .get<Org[]>(`${this.baseURL}/api/org/readonly/${siteId}/${readonly}`, httpOptions)
      .catch(this.handleError);

  }

  changePaymentStatus(siteId: string, paymentStatus: number): Observable<Org[]> {
    const token = localStorage.getItem(ACCESS_TOKEN)
    let headers = new HttpHeaders();
    //headers.append("Content-Type", 'application/json');
    headers = headers.append("Authorization", 'Bearer ' + token);
    const httpOptions = {
      headers: headers
    };
    return this.http
      .get<Org[]>(`${this.baseURL}/api/org/payment/${siteId}/${paymentStatus}`, httpOptions)
      .catch(this.handleError);

  }


  enableSite(siteId: string): Observable<Org[]> {
    const token = localStorage.getItem(ACCESS_TOKEN)
    let headers = new HttpHeaders();
    //headers.append("Content-Type", 'application/json');
    headers = headers.append("Authorization", 'Bearer ' + token);
    const httpOptions = {
      headers: headers
    };
    return this.http
      .get<Org[]>(`${this.baseURL}/api/org/enable/${siteId}`, httpOptions)
      .catch(this.handleError);

  }










  switchSite(siteId: string): Observable<any> {
    const token = localStorage.getItem('access_token')
    let headers = new HttpHeaders();
    //headers.append("Content-Type", 'application/json');
    headers = headers.append("Authorization", 'Bearer ' + token);
    const httpOptions = {
      headers: headers
    };
    return this.http
      .get(`${this.baseURL}/api/user/switch/${siteId}`, httpOptions)

      .catch(this.handleError);
  }



  addDataset(strData: string): Observable<any> {
    const token = localStorage.getItem(ACCESS_TOKEN);
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    headers = headers.append("Authorization", "Bearer " + token);
    const httpOptions = {
      headers: headers
    };
    return this.http.post(this.baseURL + "/api/datasets/add/", { Name: strData, ID: "", user: "" }, httpOptions).catch(this.handleError);
  }





  private handleError(error) {


    if (error == "Unauthorized") {
      return Observable.throw("Unauthorized");
    }

    return Observable.throwError("Server error");
    // do any other checking for statuses here
  }
}
