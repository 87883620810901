import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SettingsService } from '../../../services/settings/settings.service';
import { CalculationsService } from '../../../services/calculations/calculations.service';
import { HccSummaryCalculations } from '../../../models/hcc-summary-calculations';
import { PermissionMap, PermissionService } from '../../../services/settings/permission.service';
import { Permission } from '../../../models/permission';
import { ReportNames } from '../../../models/report-names';

@Component({
  selector: 'app-opportunity-submission-summary',
  templateUrl: './opportunity-submission-summary.component.html',
  styleUrls: ['./opportunity-submission-summary.component.scss']
})
export class OpportunitySubmissionSummaryComponent implements OnInit, OnDestroy {

  @Input() queryData = false;

  opportunityDxSwitch = false;
  reportingEnabled = false;
  progressBarValue = 0;
  serviceYear = -1;
  data: HccSummaryCalculations = new HccSummaryCalculations();
  userPermissions: Permission[] = [];
  hccValue = 0;
  dxValue = 0;
  hccPercent = 0;
  dxPercent = 0;

  SUBMISSION_OPPTY_TREND_REPORT_NAME = ReportNames.SUBMISSION_OPPTY_TREND_NAME;

  subs: Subscription[] = [];

  constructor(
    private settingsService: SettingsService,
    private calculationsService: CalculationsService,
    private permissionService: PermissionService
  ) { 
    //TODO
  }

  ngOnInit(): void {

    this.subs.push(this.settingsService.serviceYear.subscribe(result => {
      if(result > -1) {
        this.serviceYear = result;

        if(this.queryData) {
          this.calculationsService.getHccSummaryCalculations(this.serviceYear);
        }
      }
    }));

    this.subs.push(this.permissionService.activeUserPermissions.subscribe(result => {
      this.userPermissions = result;
      this.permissionsCheck();
    }));

    this.subs.push(this.calculationsService.hccSummaryCalculations.subscribe(result => {
      this.data = result;
      this.getValues();
      this.onDxSwitch();
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  getValues() {
    this.hccValue = this.data.HCCSubmissionOpp;
    this.dxValue = this.data.DXSubmissionOpp;
    this.hccPercent = this.data.HCCSubmissionOppPercent / 100;
    this.dxPercent = this.data.DXSubmissionOppPercent / 100;
  }

  onDxSwitch() {
    const value = (this.opportunityDxSwitch ? this.data.HCCSubmissionOppProgress : this.data.DXSubmissionOppProgress);
    this.progressBarValue = value > 0 ? value / 100 : 0;
  }

  permissionsCheck() {
    this.userPermissions.forEach(p => {
      if(p.PermissionID == PermissionMap.GenerateReportsOpportunities) {
        this.reportingEnabled = true;
      }
    })
  }

}
