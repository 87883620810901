<div class="flex basis-[5%] flex-row">
    <div class="flex flex-auto justify-start items-center content-center">
        <h2>Favorite Reports</h2>
    </div>
</div>

<div class="pr-[20%]">
    <div class="app-table-list flex flex-col">

        <div class="header-row flex flex-auto flex-row">
            <div class="flex basis-[5%]"></div>
            <div class="col-padding flex flex-auto justify-start items-center content-center">
                <h3>Category</h3>
            </div>
            <div class="col-padding flex flex-auto justify-start items-center content-center">
                <h3>Report Name</h3>
            </div>
        </div>

        <div class="row-item flex flex-auto flex-row" *ngIf="reports.length == 0">
            <div class="flex basis-[5%]"></div>
            <div class="col-padding flex flex-auto justify-start items-center content-center">
                <h3>No Favorites Selected</h3>
            </div>
        </div>

        <div class="row-item flex flex-auto flex-row" [ngClass]="!item.Active ? 'row-item-disabled' : ''" *ngFor="let item of displayedReports | async">
            <div class="col-border-right flex basis-[5%] justify-center items-center content-center" (click)="onFavoriteClick(item)">
                <mat-icon [color]="'primary'" *ngIf="item.Favorited"
                [matTooltip]="'Deselect as Favorite'"  
                [matTooltipClass]="'app-tooltip'">grade</mat-icon>
                <mat-icon [color]="'primary'" fontSet="material-icons-outlined" *ngIf="!item.Favorited">grade</mat-icon>
            </div>
            <div class="col-padding col-border-right flex flex-auto justify-start items-center content-center">
                <h3 
                [matTooltip]="item.Category.Description || ''"
                [matTooltipClass]="'app-tooltip'">{{item.Category.Name}}</h3>
            </div>
            <div class="col-padding flex flex-auto justify-start items-center content-center">
                <h3>{{item.Name}}</h3>
            </div>
        </div>

    </div>
</div>
