import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReportItem } from '../../../models/report-item';
import { ReportsService } from '../../../services/user/reports.service';
import { ReplaySubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-favorite-reports',
  templateUrl: './favorite-reports.component.html',
  styleUrls: ['./favorite-reports.component.scss']
})
export class FavoriteReportsComponent implements OnInit, OnDestroy {

  displayedReports: ReplaySubject<ReportItem[]> = new ReplaySubject<ReportItem[]>(1);
  reports: ReportItem[] = [];
  activeReports: ReportItem[] = [];

  displayAll = false;

  subs: Subscription[] = [];

  constructor(
    private reportsService: ReportsService) { 
    //TODO
  }

  ngOnInit(): void {
    this.getReportItems();

    this.subs.push(this.reportsService.displayAllReportItems.subscribe(result => {
      this.displayAll = result;
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  getReportItems() {
    this.reportsService.getFavoritedReportItems().subscribe(result => {
      this.reports = result;
      this.activeReports = result.filter(i => i.Active);
      this.displayAll ? this.displayedReports.next(this.reports) : this.displayedReports.next(this.activeReports);
    });
  }

  onFavoriteClick(item: any) {
    this.reportsService.updateFavoriteReportItem(item.ID, false).subscribe(() => {
      this.getReportItems();
    });
  }

}
