import { formatNumber } from '@angular/common';
import { Component, Injectable, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { IStatusPanelParams } from 'ag-grid-enterprise';
import { Subject } from 'rxjs';
import { ɵ$localize } from '@angular/localize';

export interface IAgPaginator {
  setPageSize(pageSize: number);
  goToCurrentPage();
  resetPaginator();
}

@Injectable()
export class AgPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();

  // For internationalization, the `$localize` function from
  // the `@angular/localize` package can be used.
  firstPageLabel = ɵ$localize`First page`;
  itemsPerPageLabel = ɵ$localize`Items per page:`;
  lastPageLabel = ɵ$localize`Last page`;

  // You can set labels to an arbitrary string too, or dynamically compute
  // it through other third-party internationalization libraries.
  nextPageLabel = 'Next page';
  previousPageLabel = 'Previous page';

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return ɵ$localize`Page 1 of 1`;
    }

    return ɵ$localize`${formatNumber(page * pageSize, 'en')} - ${formatNumber((page * pageSize) + pageSize, 'en')} of ${formatNumber(length, 'en')}`;
  }
}

@Component({
  selector: 'app-ag-paginator',
  templateUrl: './ag-paginator.component.html',
  styleUrls: ['./ag-paginator.component.scss']
})
export class AgPaginatorComponent implements IStatusPanelAngularComp {

  params: IStatusPanelParams<any, any>;
  isClientSide = false;
  length = 0;
  pageSize = 25;
  pageSizeOptions: number[] = [25, 50, 100, 150, 300];
  currentIndex = 0;

  pageEvent: PageEvent;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  agInit(params: IStatusPanelParams<any, any>): void {
    this.params = params;
    this.isClientSide = params['isClientSide'];
    this.params.api.setGridOption('paginationPageSize', this.pageSize);

    if(!this.isClientSide) {
      this.params.api.setGridOption('cacheBlockSize', this.pageSize);
    }

    this.params.api.addEventListener('modelUpdated', () => {
      this.length = this.params.api.paginationGetRowCount();
      this.pageSize = this.params.api.paginationGetPageSize();
    });
  }
  
  onPage(pageEvent: PageEvent) {

    if(pageEvent.pageSize != this.pageSize) {
      this.pageSize = pageEvent.pageSize;
      this.params.api.setGridOption('paginationPageSize', this.pageSize);

      if(!this.isClientSide) {
        this.params.api.setGridOption('cacheBlockSize', this.pageSize);
      }
      
      this.params.context.componentParent.onPaginatorPageSizeChange(this.pageSize);
    }

    if(pageEvent.pageIndex != this.currentIndex) {
      this.currentIndex = pageEvent.pageIndex;
      this.params.api.paginationGoToPage(pageEvent.pageIndex);
    }
  }

  setPageSize(pageSize: number) {
    this.onPage({pageSize: pageSize, pageIndex: 0, previousPageIndex: 0, length: 0});
  }

  goToCurrentPage() {
    this.params.api.paginationGoToPage(this.currentIndex);
  }

  resetPaginator() {
    this.currentIndex = 0;
    this.paginator.firstPage();
  }

}
