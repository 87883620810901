<mat-form-field [appearance]="appearance" [floatLabel]="floatLabel">
    <mat-label *ngIf="label">{{label}}</mat-label>
    <input matInput
    [formControl]="control"
    [placeholder]="placeholder"
    [required]="required">

    <span matPrefix>$&nbsp;</span>

    <mat-error *ngIf="control.hasError('required')">Value is <strong>required</strong></mat-error>
    <mat-error *ngIf="control.hasError('pattern')">Value must be valid currency format</mat-error>
</mat-form-field>
