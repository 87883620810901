<mat-form-field color="primary" [appearance]="'outline'" floatLabel="always">
    <mat-label>Date of Service</mat-label>
    <mat-date-range-input 
    [formGroup]="rangeGroup" 
    [rangePicker]="drPicker" 
    (dateChange)="onSelectionChange()">
        <input matStartDate formControlName="startDate" placeholder="MM/DD/YYYY" [required]="required">
        <input matEndDate formControlName="endDate" placeholder="MM/DD/YYYY" [required]="required">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="drPicker">
        <mat-icon matDatepickerToggleIcon [svgIcon]="isDrToggled ? 'vc-calendar-icon-active' :'vc-calendar-icon-default'"></mat-icon>
    </mat-datepicker-toggle>
    <mat-date-range-picker #drPicker (opened)="isDrToggled = true" (closed)="isDrToggled = false"></mat-date-range-picker>
    <mat-error *ngIf="startControl.hasError('required') || endControl.hasError('required')">DOS start/end is <strong>required</strong></mat-error>
</mat-form-field>
