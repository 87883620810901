import { Injectable } from '@angular/core';
import { ServiceFormatter } from '../../helpers/service-formatter';
import { HttpClient } from '@angular/common/http';
import { AuditLogQuery } from '../../models/grid-query';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  private endpoint = ServiceFormatter.endpoint;

  constructor(private http: HttpClient) { 
    //TODO
  }

  logAudit(actionID: number, detail: string, detailJson?: string) {
    return this.http.post<any>(this.endpoint + "log/audit", {
      ActionID: actionID,
      Detail: detail,
      DetailJSON: detailJson
    }, ServiceFormatter.getHttpOptions());
  }

  getOrgAuditLogs(request: AuditLogQuery) {
    return this.http.post<any>(this.endpoint + "log/audit-query", request, ServiceFormatter.getHttpOptions());
  }
}
