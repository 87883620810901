<div class="page-container">
  <div class="page-content-container flex flex-col gap-5">

    <div class="flex basis-[7%] flex-row">
      <div class="flex basis-3/4 justify-start items-center content-center">
          <h1>Organizations</h1>
      </div>

      <div class="flex basis-1/4 flex-row justify-end items-center content-center">
        <button
          mat-flat-button
          class="primary-button"
          [matTooltip]="'Add Org'"
          [matTooltipClass]="'app-tooltip'"
          (click)="AddEditOrg(null, 'add')">
            <mat-icon class="add-user-icon">person_add</mat-icon>&nbsp;Add Org
        </button>
      </div>
    </div>

    <div class="filter-section grid grid-cols-5 gap-3">

      <div class="flex">
        <mat-form-field class="flex flex-auto" appearance="outline" floatLabel="always">
          <mat-label>Name</mat-label>
          <input matInput [formControl]="nameControl" inputAutofocus autocomplete="name" (keypress)="onKeyPress($event)">
        </mat-form-field>
      </div>

      <div class="flex col-start-5 justify-end pt-3">
        <button mat-flat-button class="primary-button"
        [matTooltip]="'Search Orgs'"
        [matTooltipClass]="'app-tooltip'"
        (click)="LoadOrgs()">Search</button>
      </div>

    </div>

    <div class="grid-section flex flex-[2_1_0]">
      <!-- AG Grid Angular Component -->
      <ag-grid-angular
      class="ag-theme-balham w-full h-full"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowModelType]="'serverSide'"
      [getRowId]="getRowId"
      [pagination]="true"
      [tooltipShowDelay]="0"
      [components]="frameworkComps"
      [statusBar]="statusBar"
      [noRowsOverlayComponent]="noRowsOverlayComponent"
      [noRowsOverlayComponentParams]="noRowsOverlayComponentParams"
      [context]="gridContext"
      [getContextMenuItems]="onGridGetContextMenu"
      [suppressCopyRowsToClipboard]="true"
      [suppressPaginationPanel]="true"
      [loadingOverlayComponent]="loadingOverlayComponent"
      (gridReady)="onGridReady()"
      (cellKeyDown)="onCellKeyDown($event)"></ag-grid-angular>
    </div>

  </div>
</div>