<div [ngClass]="mode == 'oppty-list' ? 'oppty-list-page-container' : 'dx-list-page-container'">

    <div [ngClass]="sideMenuCollapsed ? 'allocation-side-menu-collapsed' : 'allocation-side-menu'" *ngIf="mode == 'oppty-list'">
        <div class="allocation-side-header flex" *ngIf="!sideMenuCollapsed">
            <div class="allocation-side-title grow justify-start align-middle">
                <h2>Progress</h2>
            </div>
        </div>

        <mat-selection-list [multiple]="false">
            <mat-list-option *ngFor="let option of menuOptions" [value]="option" [selected]="selectedMenuOption.ID == option.ID" (click)="onSelectedMenuOption(option)">
                <div class="flex">
                    <div
                    class="flex align-middle items-center"
                    [ngClass]="sideMenuCollapsed ? 'basis-full justify-center' : 'basis-[15%] justify-start'"
                    [matTooltip]="sideMenuCollapsed ? option.Name : ''"
                    [matTooltipClass]="'app-tooltip'"
                    [matTooltipPosition]="'right'"
                    *ngIf="option.Icon">
                        <mat-icon *ngIf="!option.IconOutlined">{{option.Icon}}</mat-icon>
                        <mat-icon fontSet="material-icons-outlined" *ngIf="option.IconOutlined">{{option.Icon}}</mat-icon>
                    </div>

                    <div class="grow justify-start align-middle pt-[2px] pl-[5px]" *ngIf="!sideMenuCollapsed">
                        {{option.Name}}
                    </div>
                </div>
            </mat-list-option>
        </mat-selection-list>
    </div>

    <div class="allocation-side-menu-collapsed-content-container" *ngIf="mode == 'oppty-list' && sideMenuCollapsed">
        <div class="allocation-side-menu-collapsed-content-header flex-auto justify-start align-middle">
            <h3>{{selectedMenuOption.Name}}</h3>
        </div>
        <div class="allocation-side-menu-collapsed-content flex-[1_2_auto]">
            <app-progress-allocation-totals
            class="flex-auto"
            *ngIf="selectedMenuOption.ID == 1"></app-progress-allocation-totals>

            <app-progress-allocation-user
            class="flex-auto"
            [expanded]="true"
            [iconDiameter]="38"
            *ngIf="selectedMenuOption.ID == 2"></app-progress-allocation-user>
    
            <app-progress-allocation-preferred-action
            class="flex-auto"
            [expanded]="true"
            *ngIf="selectedMenuOption.ID == 3"></app-progress-allocation-preferred-action>
    
            <app-progress-allocation-payer
            class="flex-auto"
            [expanded]="true"
            *ngIf="selectedMenuOption.ID == 4"></app-progress-allocation-payer>
        </div>
        <div class="flex-[50_1_auto]"></div>
    </div>

    <div class="flex flex-col" [ngClass]="{'oppty-list-container': mode == 'oppty-list', 
        'oppty-list-container-collapsed': mode == 'oppty-list' && sideMenuCollapsed,
        'dx-list-container': mode == 'dx-list'}">

        <div class="oppty-list-container-header flex flex-row basis-[7%]">
            <div class="oppty-list-title flex basis-[40%] justify-start items-center content-center">
                <h1 *ngIf="mode == 'oppty-list'">Opportunity List</h1>
                <h1 *ngIf="mode == 'dx-list'">Diagnosis Events</h1>
            </div>

            <div class="oppty-list-chips flex basis-[60%] justify-end items-center content-center">
                <mat-chip-list #chipFilters>
                    <mat-chip *ngFor="let chip of (mode == 'oppty-list' ? opptyFilterChips : dxFilterChips)"
                    [value]="chip.Name" 
                    [selected]="chip.Selected" 
                    [class.mat-chip-selected]="chip.Selected"
                    (click)="onChipFilterChange(chip)">
                        {{chip.DisplayName}}
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
        
        <div class="filter-section mat-elevation-z3 grid grid-cols-5 gap-3 p-3">

            <div>
                <app-preferred-action-select
                [form]="form"
                [typeIds]="paTypeIDs"
                (ready)="isPreferredActionReady = true; filtersReadyCheck()"
                (valueChanged)="onQueryFilterChange($event); checkEnableTemplateFieldStatus(true)"></app-preferred-action-select>
            </div>

            <div>
                <app-payer-select
                [form]="form"
                [queryOptions]="true"
                (ready)="isPayerReady = true; filtersReadyCheck()"
                (valueChanged)="onQueryFilterChange($event); checkEnableTemplateFieldStatus(true)"></app-payer-select>
            </div>

            <div>
                <app-status-state-select
                [form]="form"
                [required]="true"
                (ready)="onStatusStateReady($event)"
                (valueChanged)="onStatusStatesSelected($event); onQueryFilterChange($event)"></app-status-state-select>
            </div>

            <div>
                <app-status-select
                [form]="form"
                [required]="true"
                [origin]="mode"
                (ready)="onStatusReady($event)"
                (valueChanged)="onStatusSelected($event); onQueryFilterChange($event)"></app-status-select>
            </div>

            <div>
                <app-root-cause-select
                [form]="form"
                (ready)="isRootCauseReady = true; filtersReadyCheck()"
                (valueChanged)="onQueryFilterChange($event)"></app-root-cause-select>
            </div>

            <div>
                <app-user-search-by-permission
                #assignOpptyList
                [form]="form"
                [label]="'Assigned'"
                [queryOptions]="true"
                (ready)="isAssignedReady = true; filtersReadyCheck()"
                (valueChanged)="onQueryFilterChange($event)"></app-user-search-by-permission>
            </div>

            <div>
                <mat-form-field color="primary" [appearance]="'outline'" floatLabel="always" class="flex-auto">
                    <mat-label>EMR PID</mat-label>
                    <input matInput minlength="3" [formControl]="emrControl" (change)="onQueryFilterChange($event)" (focus)="$event.target.select()">
                    <mat-error *ngIf="emrControl.hasError('minlength')">Requires 3 characters or more</mat-error>
                </mat-form-field>
            </div>

            <div>
                <mat-form-field color="primary" [appearance]="'outline'" floatLabel="always" class="flex-auto">
                    <mat-label>ICD 10</mat-label>
                    <input matInput minlength="3" [formControl]="icd10Control" (change)="onQueryFilterChange($event)" (focus)="$event.target.select()">
                    <mat-error *ngIf="icd10Control.hasError('minlength')">Requires 3 characters or more</mat-error>
                </mat-form-field>
            </div>

            <div>
                <app-dos-select
                [form]="form"
                (ready)="isDosReady = true; filtersReadyCheck();"
                (valueChanged)="onQueryFilterChange($event)"></app-dos-select>
            </div>

            <div>
                <app-filter-field-search-select
                #exportTemplate
                [form]="form"
                [label]="'Export Template'"
                [noneEnabled]="true"
                [disabled]="exportTemplateDisabled"></app-filter-field-search-select>
            </div>

            <div class="col-start-5 justify-self-end">
                <button mat-flat-button class="primary-button"
                [disabled]="!formValid"
                [matTooltip]="'Generate Opportunities'"
                [matTooltipClass]="'app-tooltip'"
                (click)="onGenerateGridData()">Generate</button>
            </div>

        </div>

        <div class="grid-section flex-auto">
            <!-- AG Grid Angular Component -->
            <ag-grid-angular
            style="width: 100%; height: 100%"
            class="ag-theme-balham"
            [columnDefs]="mode == 'oppty-list' ? opptyColumnDefs : dxColumnDefs"
            [defaultColDef]="defaultColDef"
            [rowSelection]="'multiple'"
            [rowModelType]="'serverSide'"
            [maxBlocksInCache]="1"
            [getRowId]="getRowId"
            [rowMultiSelectWithClick]="true"
            [pagination]="true"
            [tooltipShowDelay]="0"
            [components]="frameworkComps"
            [sideBar]="sideBar"
            [statusBar]="statusBar"
            [noRowsOverlayComponent]="noRowsOverlayComponent"
            [noRowsOverlayComponentParams]="noRowsOverlayComponentParams"
            [context]="gridContext"
            [getContextMenuItems]="onGridGetContextMenu"
            [suppressCopyRowsToClipboard]="true"
            [suppressPaginationPanel]="true"
            (gridReady)="onGridReady()"
            (columnVisible)="onGridColumnSelect($event)"
            (columnMoved)="onGridColumnSelect($event)"
            (rowSelected)="onGridRowSelected($event)"
            (cellKeyDown)="onCellKeyDown($event)"
            (toolPanelVisibleChanged)="agGrid.api.sizeColumnsToFit()"
            (selectionChanged)="onSelectionChanged($event)"></ag-grid-angular>
        </div>

    </div>

</div>
