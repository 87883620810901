import { Organization, NewOrg } from '../../models/organization.model';
import { Observable, from } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { mergeMap } from 'rxjs/operators';
import { ClientStorage } from '../../storage/client-storage.lib';
import { ACCESS_TOKEN } from '../../storage/constants.lib';
import { OrgQuery } from '../../models/org-query';
import { OrgQueryResult } from '../../models/org-query-result';
import { ServiceFormatter } from '../../helpers/service-formatter';
import { Org } from '../../models/org';

@Injectable({
    providedIn: "root"
})
export class OrganizationLib {

    private baseURL = "";


    constructor(
        private http: HttpClient,
        private storage: ClientStorage
    ) {
        this.baseURL = `${environment.baseURL}/api/org`;
    }

    public getOrg(): Observable<Organization> {
        const value = this.storage.getItem(ACCESS_TOKEN).then((token) => {

            let headers = new HttpHeaders();
            headers = headers.append("Authorization", "Bearer " + token);

            const httpOptions = {
                headers: headers

            };

            return this.http
                .get<Organization>(`${this.baseURL}`, httpOptions);
        })


        return from(value).pipe(mergeMap(value => { return value }));


    }

    public addUpdateOrg(org: Org, sectionID: number) {
        const params = new HttpParams().set("sectionID", sectionID.toString());
        return this.http.post(`${this.baseURL}/update`, org, ServiceFormatter.getHttpOptions(params));
    }

    public updateAccountType(org: Organization): Observable<any> {
        const value = this.storage.getItem(ACCESS_TOKEN).then((token) => {

            let headers = new HttpHeaders();
            headers = headers.append("Authorization", "Bearer " + token);

            const httpOptions = {
                headers: headers

            };

            return this.http
                .post(`${this.baseURL}/updateaccounttype`, org, httpOptions);
        })


        return from(value).pipe(mergeMap(value => { return value }));



    }

    public addOrgSelfService(org: NewOrg): Observable<Organization> {
        const value = this.storage.getItem(ACCESS_TOKEN).then((token) => {

            let headers = new HttpHeaders();
            headers = headers.append("Authorization", "Bearer " + token);

            const httpOptions = {
                headers: headers

            };

            return this.http
                .post<Organization>(`${this.baseURL}/addself`, org, httpOptions);
        })

        return from(value).pipe(mergeMap(value => { return value }));

    }

    getOrgsList(query: OrgQuery) {
        return this.http.post<OrgQueryResult>(`${this.baseURL}/query`, query, ServiceFormatter.getHttpOptions());
    }

    switchOrg(orgID: string): Observable<any> {
        const value = this.storage.getItem(ACCESS_TOKEN).then((token) => {

            let headers = new HttpHeaders();
            headers = headers.append("Authorization", "Bearer " + token);
            headers = headers.append("Content-Type", "application/x-www-form-urlencoded");
            const httpOptions = {
                headers: headers
            };


            return this.http
                .get(`${environment.baseURL}/api/user/switch/${orgID}`, httpOptions)
        });

        return from(value).pipe(mergeMap(value => { return value }));
    }

}

