import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { FilterSelectItem } from '../../../models/filter-select-item';
import { PreferredActionType } from '../../../models/preferred-action-type';
import { FieldFilterService } from '../../../services/filters/field-filter.service';

@Component({
  selector: 'app-preferred-action-select',
  templateUrl: './preferred-action-select.component.html',
  styleUrls: ['./preferred-action-select.component.scss']
})
export class PreferredActionSelectComponent implements OnInit {

  private _disabled;
  @Input() set disabled(value: boolean) {
    this._disabled = value;
    this._disabled ? this.control?.disable() : this.control?.enable();
  }

  get disabled(): boolean {
    return this._disabled;
  }


  @Input() form: FormGroup;
  @Input() color = 'primary';
  @Input() appearance = 'outline';
  @Input() floatLabel = 'always';
  @Input() multiple = true;
  @Input() controlName = 'preferred-action';
  @Input() label = "Preferred Action";
  @Input() placeholder = "";
  @Input() toolTipPosition = 'above';
  @Input() selectAllEnabled = true;
  @Input() noneEnabled = false;
  @Input() required = false;
  @Input() typeIds = [PreferredActionType.Opportunity.valueOf()];
  @Input() onlyShowOptions: string[] = [];

  options: FilterSelectItem[] = [];
  allOption: FilterSelectItem = {ID: 0, Description: 'All', LinkAssociations: [], Selected: false};
  noneOption: FilterSelectItem = {ID: -1, Description: 'None', LinkAssociations: [], Selected: false};
  control = new FormControl(null);

  @Output() ready = new EventEmitter();
  @Output() valueChanged = new EventEmitter();

  @ViewChild(MatSelect) select: MatSelect;

  constructor(private filterService: FieldFilterService) { 
    //TODO
  }

  ngOnInit(): void {

    if(this.required) {
      this.control.setValidators([Validators.required]);
    }

    if(this.form) {
      this.form.addControl(this.controlName, this.control);
    }

    this.getOptionData();
  }

  getOptionData() {

    this.filterService.getPreferredActionOptions(this.typeIds.toString()).subscribe(result => {
      const items = result?.sort((a, b) => {
        return a.Description.toLowerCase().localeCompare(b.Description.toLowerCase());
      });

      this.options = this.onlyShowOptions.length > 0 ? items.filter(i => this.onlyShowOptions.includes(i.Description)) : items;

      this.ready.emit(this.options);
    });

  }

  setValues(values: FilterSelectItem[]) {
    if(!values) {
      return;
    }

    if(values.length == 0) {
      this.control.setValue([this.allOption]);
      this.onToggleItem(this.select.options.get(0));
      return;
    }

    this.allOption.Selected = false;
    this.control.setValue(values);
  }

  setSingleModeValue(value: FilterSelectItem) {
    if(!value) {
      return;
    }

    const item = this.options.find(i => i.ID == value.ID);

    this.control.setValue(item);
  }

  getValues() {
    if(this.allOption.Selected) {
      return [this.allOption];
    }

    return this.control?.value;
  }

  onToggleItem(item: MatOption) {
    if(!this.multiple) {
      return;
    }

    if(item.value == this.allOption) {
      if(item.selected) {
        this.allOption.Selected = true;
        this.select.options.forEach(o => o.select());
        return;
      }

      this.allOption.Selected = false;
      this.select.options.forEach(o => o.deselect());

    } else {
      
      const allItem = this.select.options.get(0);

      if(!item.selected && allItem.selected) {
        this.allOption.Selected = false;
        allItem.deselect();
        return;
      }

      if(item.selected && !allItem.selected) {
        const check = this.checkOptionAllStatus();
        if(check) {
          this.allOption.Selected = true;
          allItem.select();
        }
      }
    }
  }

  onSelectionChange() {
    this.valueChanged.emit(this.control?.value);
  }

  checkOptionAllStatus() {
    let result = true;
    this.select.options.forEach(o => {
      if(o.value != this.allOption && !o.selected) {
        result = false;
      }
    });

    return result;
  }

  getTooltipString() {
    let result = '';
    const options = this.allOption.Selected ? this.control?.value.slice(1) : this.control?.value;

    options.forEach((o, index) => {
      result += o.Description;

      if(index < options.length - 1) {
        result += ', ';
      }
    })
    return result;
  }

  getSelectCount() {
    return (this.control.value?.length <= this.options.length) 
      ? this.control.value?.length 
      : this.control.value?.length - 1;
  }

}
