import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { ClientStorage } from '../../storage/client-storage.lib';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../../storage/constants.lib';
import { LoginResult } from '../../models/auth.model';

@Injectable({
    providedIn: "root"
})
export class AuthLib {
    private readonly apiUrl: string = `${environment.baseURL}/api/account`;



    constructor(
        private http: HttpClient,
        private storage: ClientStorage
    ) { }

    login(username: string, password: string): Observable<any> {
        return this.http.post<LoginResult>(`${this.apiUrl}/login`, { username, password });
    }

    submitNewPassword(key: string, password: string) {
        return this.http.post<any>(`${this.apiUrl}/newPW`, { key, password });
    }



    forgotPassword = (userId: string): Observable<any> => {
        return this.http.get(`${this.apiUrl}/forgotpassword/${userId}`)
    };

   

    logout(): Observable<any> {
        const value = this.storage.getItem(ACCESS_TOKEN).then((token) => {

            let headers = new HttpHeaders();
            headers = headers.append("Authorization", "Bearer " + token);


            const httpOptions = {
                headers: headers,
            };

            return this.http.post<any>(`${this.apiUrl}/logout`, {}, httpOptions);

        });

        return from(value).pipe(mergeMap(value => { return value }));
    }

    refreshToken(refreshToken: string): Observable<LoginResult> {
        const value = this.storage.getItem(ACCESS_TOKEN).then((access_token) => {
            let headers = new HttpHeaders();
            headers = headers.append("Authorization", "Bearer " + access_token);


            const httpOptions = {
                headers: headers,
            };
            return this.http
                .post<LoginResult>(`${this.apiUrl}/refresh-token`, { refreshToken }, httpOptions);

        });

        return from(value).pipe(mergeMap(value => { return value }));

    }


    switchSite(siteId: string): Observable<any> {
        const value = this.storage.getItem(ACCESS_TOKEN).then((token) => {

            let headers = new HttpHeaders();
            headers = headers.append("Authorization", "Bearer " + token);


            const httpOptions = {
                headers: headers,
            };
            return this.http
                .get<LoginResult>(`${this.apiUrl}/switch/${siteId}`, httpOptions);

        });

        return from(value).pipe(mergeMap(value => { return value }));
    }

    getUser(): Observable<LoginResult> {
        const value = this.storage.getItem(ACCESS_TOKEN).then((token) => {

            let headers = new HttpHeaders();
            headers = headers.append("Authorization", "Bearer " + token);


            const httpOptions = {
                headers: headers,
            };

            return this.http.get<LoginResult>(`${this.apiUrl}/user`, httpOptions);

        });

        return from(value).pipe(mergeMap(value => { return value }));
    }
}
