export class QueryMetadata {
    PageSize: number;
    PageNumber: number;
    Sort: QuerySort;
}

export class QuerySort {
    Sort: string;
    ColID: string;
}
