<div class="flex flex-row flex-wrap gap-0 pt-3">

    <div class="flex basis-full justify-start items-center content-center">
        <div class="filter-options">
            <mat-form-field appearance="outline" color="primary" floatLabel="always">
                <mat-label>{{filterLabel}}</mat-label>
                <mat-select [formControl]="filterControl" multiple="true" [placeholder]="'Make a Selection'" (selectionChange)="onFilterChange()">
                    <mat-option>
                        <ngx-mat-select-search
                        placeholderLabel="search..."
                        noEntriesFoundLabel="No Options Found"
                        [id]="'filter-search'"
                        [formControl]="filterSearchControl"
                        [showToggleAllCheckbox]="true"
                        [toggleAllCheckboxChecked]="allToggled"
                        [toggleAllCheckboxIndeterminate]="indeterminateToggle"
                        (toggleAll)="filterAllOptionClicked($event)"></ngx-mat-select-search>
                    </mat-option>

                    <mat-option *ngFor="let option of filterOptionsBySearch | async" [value]="option">
                        {{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <mat-card class="vc-card vc-card-charts mat-elevation-z5 w-[600px]" *ngFor="let graph of graphOptionsList">
        <mat-card-header>
            <div class="title-container flex flex-row">
              <h3 class="flex basis-[80%]">{{graph.title.text}}</h3>
              <div class="flex flex-auto justify-end items-center content-center">
                  <span class="material-symbols-outlined download-icon"
                  [matTooltip]="'Download'"
                  [matTooltipClass]="'app-tooltip'"
                  [matTooltipPosition]="'above'"
                  (click)="onDownload(chart.chart)">download</span>
              </div>
            </div>
          </mat-card-header>
    
          <mat-card-content>
    
              <ag-charts-angular
              #chart
              class="h-full"
              [options]="graph"></ag-charts-angular>
            
          </mat-card-content>
    </mat-card>
</div>
