import { HttpHeaders, HttpParams } from "@angular/common/http";
import { ACCESS_TOKEN } from "../storage/constants.lib";
import { environment } from "../../environments/environment";

export class ServiceFormatter {

    public static endpoint = `${environment.baseURL}/api/`;

    public static getHttpOptions(params?: HttpParams) {
        const token = localStorage.getItem(ACCESS_TOKEN);
        let headers = new HttpHeaders();
        headers = headers.append("Authorization", "Bearer " + token);
        const httpOptions = {
          headers: headers,
          params: params
        };
    
        return httpOptions;
    }
}
