import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { BehaviorSubject, from, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { map, tap, delay, mergeMap } from 'rxjs/operators';
import { environment } from "../../../labeler-shared/environments/environment";
import { ApplicationUser } from '../../models/user.model';
import { ACCESS_TOKEN, CURRENT_DATASET, DATASETS, LOGIN_EVENT, LOGOUT_EVENT, ORGANIZATION, ORGANIZATIONADDRESS, REFRESH_TOKEN } from '../../storage/constants.lib';
import { ClientToastController } from '../../toasts/client-toast.lib';
import { AuthLib } from '../libs/auth-lib.service';
import { ClientStorage } from '../../storage/client-storage.lib';
import { Router } from '@angular/router';
import { LAST_USER, LAST_PW } from '../libs/storageConsts';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
//import { NotificationsService } from 'src/app/services/notifications/notifications.service';

interface LoginResult {
  UserName: string;
  Role: string;
  OrgName: string;
  UserID: number;
  AccessToken: string;
  RefreshToken: string;
  Authenticated: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {


  private readonly apiUrl = `${environment.baseURL}/api/account`;
  private subject = new Subject<any>();
  private timer: Subscription;
  public orgName = "";
  public _user = new BehaviorSubject<ApplicationUser>(null);
  user$: Observable<ApplicationUser> = this._user.asObservable();

  private storageEventListener(event: StorageEvent) {
    if (event.storageArea === localStorage) { //this local_storage might be fine
      if (event.key === LOGOUT_EVENT) {
        this.stopTokenTimer();
        this._user.next(null);
      }
      if (event.key === LOGIN_EVENT) {

        this.stopTokenTimer();

        this.authLib.getUser().subscribe((x) => {
          this.orgName = x.OrgName;
          this._user.next({
            UserName: x.UserName,
            Role: x.Role,
            OrgName: x.OrgName,
            UserID: x.UserID,
            OrgID: x.OrgID
          });
        });
      }
    }
  }

  constructor(
    private authLib: AuthLib,
    private toastController: ClientToastController,
    private storage: ClientStorage,
    private router: Router,
    private zone: NgZone,
    private dialog: MatDialog
    //private notificationService: NotificationsService
  ) {
    // window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  public setOrgName(inName: string) {
    //used on page refresh
    this.orgName = inName;
  }
  public onLoggedIn(): Observable<any> {
    return this.subject.asObservable();
  }

  ngOnDestroy(): void {
    //window.removeEventListener('storage', this.storageEventListener.bind(this));
  }

  login(username: string, password: string, webSite = false): Observable<any> {
    return from(
      this.authLib.login(username, password).toPromise()
      .catch((err) => {
        if (err.status == 401) {
          throw(err);
        }
      })
      .then((x) => {
        if(x.ResetPassword) {
          return x;
        }
        else if (x.Disabled == "1" && x.IsSystemAdmin == false) {
          throw ("Disabled Account");
        }
        else if (x.Reason == "blocked") {
          throw ("Blocked Account");
        }
        else if (x.Reason == "invalid") {
          throw ("Invalid Credentials");
        }
        else {
          return this.setLocalStorage(x).then(() => {
            this.startTokenTimer();
            //this.notificationService.updateToken();
            this.orgName = x.OrgName;
            this._user.next({
              UserName: x.UserName,
              Role: x.Role,
              OrgName: x.OrgName,
              UserID: x.UserID,
              OrgID: x.OrgID
            });


            this.subject.next({ loggedIn: true });
            return x;
          })
        }

      }));
  }



  async offLineLogin(username: string, password: string, webSite = false) {

    const [lastPW, lastUser, cachedUserString] = await Promise.all([
      this.getLastPassword(),
      this.getLastUser(),
      this.getLastLoggedInUser()
    ]);



    const cachedUser = JSON.parse(cachedUserString);
    if (lastPW == password && lastUser == username) {

      if (cachedUser.Disabled == "1" && cachedUser.IsSystemAdmin == false) {
        throw ("Disabled Account");
      }
      else if (cachedUser.MobileOnly == true && webSite == true) {
        throw ("Mobile Only");
      }
      else {
        this.startTokenTimer();
        //this.notificationService.updateToken();
        this.orgName = cachedUser.OrgName;
        this._user.next({
          UserName: cachedUser.UserName,
          Role: cachedUser.Role,
          OrgName: cachedUser.OrgName,
          UserID: cachedUser.UserID,
          OrgID: cachedUser.OrgID
        });


        this.subject.next({ loggedIn: true });
        return cachedUser;
      }

    }

  }

  submitNewPassword(key: string, password: string) {
    return this.authLib.submitNewPassword(key, password).pipe(
      map((x) => {
        return x;
      })
    );
  }


  private async getLastUser() {
    return this.storage.getItem(LAST_USER);
  }

  private async getLastPassword() {
    return this.storage.getItem(LAST_PW);
  }

  private async getLastLoggedInUser() {
    return this.storage.getItem("LabelAI_CacheLastUserLoggedIn");
  }


  public forgotPassword = (userId: string): Observable<any> => {
    return this.authLib.forgotPassword(userId).pipe(
      map((x) => {
        return x;
      })
    );


  };



  /**
   * 
   */
  logout(): Promise<any> {
    console.log("logout")
    return this.authLib.logout().toPromise().finally(async () => {
      await this.clearLocalStorage();
      this._user.next(null);
      this.dialog.closeAll();
      this.stopTokenTimer();
      //
      this.zone.run(() => {
        this.router.navigate(['login']);
      });
    });




  }

  refreshToken(): Observable<LoginResult> {
    console.log("in refresh token");
    return from(this.storage.getItem(REFRESH_TOKEN).then((refreshToken) => {
      if (refreshToken == null) {
        this.clearLocalStorage();
        return of(null);
      } else {
        return from(this.authLib.refreshToken(refreshToken).toPromise().then((x) => {
          return this.setLocalStorage(x).then(() => {
            this.startTokenTimer();

            this.orgName = x.OrgName;
            this._user.next({
              UserName: x.UserName,
              Role: x.Role,
              OrgName: x.OrgName,
              UserID: x.UserID,
              OrgID: x.OrgID
            });

            return x;
          })
        }))
      }
    })).pipe(mergeMap(x => x))
  }


  switchSite(siteId: string): Observable<any> {

    return from(this.authLib.switchSite(siteId).toPromise().then((x) => {
      this.clearAccountBasedLocalStorage().then(() => {
        return this.setLocalStorage(x).then(() => {
          this.startTokenTimer();

          this.orgName = x.OrgName;
          this._user.next({
            UserName: x.UserName,
            Role: x.Role,
            OrgName: x.OrgName,
            UserID: x.UserID,
            OrgID: x.OrgID
          });


          this.subject.next({ loggedIn: true });
          return x;
        });
      });
    }));

  }

  setLocalStorage(x: LoginResult) {
    return this.storage.setItem("LabelAI_CacheLastUserLoggedIn", JSON.stringify(x)).then(() => {
      return this.storage.setItem(ACCESS_TOKEN, x.AccessToken).then(() => {
        return this.storage.setItem(REFRESH_TOKEN, x.RefreshToken).then(() => {
          return this.storage.setItem(LOGIN_EVENT, 'login' + Math.random());
        });
      });
    });
  }

  clearLocalStorage() {
    return this.storage.removeItem(ACCESS_TOKEN).then(() => {
      return this.storage.removeItem(REFRESH_TOKEN).then(() => {
        return this.storage.setItem(LOGOUT_EVENT, 'logout' + Math.random());
      });
    });
  }

  clearAccountBasedLocalStorage() {

    return this.storage.removeItem(CURRENT_DATASET).then(() => {

      return this.storage.removeItem(DATASETS).then(() => {
        return this.storage.removeItem(ORGANIZATION).then(() => {
          return this.storage.removeItem(ORGANIZATIONADDRESS).then(() => {

            return this.storage.removeItem(ACCESS_TOKEN).then(() => {
              return this.storage.removeItem(REFRESH_TOKEN).then(() => {
                return this.storage.setItem(LOGOUT_EVENT, 'logout' + Math.random());
              });
            });
          });
        });
      });
    });
  }

  private getTokenRemainingTime(): Promise<number> {
    return this.storage.getItem(ACCESS_TOKEN).then((accessToken) => {
      if (accessToken == null) {
        return 0
      } else {
        const jwtToken = JSON.parse(atob(accessToken.split('.')[1]));
        const expires = new Date(jwtToken.exp * 1000);
        return expires.getTime() - Date.now();
      }
    });
  }



  public startTokenTimer() {
    this.getTokenRemainingTime().then((timeout) => {

      this.timer = of(true)
        .pipe(
          delay(timeout),
          tap(() => this.refreshToken().subscribe())
        )
        .subscribe();
    })

  }

  private stopTokenTimer() {
    this.timer?.unsubscribe();
  }

  public isAuthenticated() {
    if (this._user.getValue() == null) {
      return false;
    }
    else {
      return true;
    }
  }


  private _getProfile(authResult) {
    /*   // Use access token to retrieve user's profile and set session
      this.auth0.client.userInfo(authResult.accessToken, (err, profile) =>
      {
        this.setSession(authResult);
        this.userProfile = profile;
      }); */
  }

  public changePassword(email: string): void {


    this.forgotPassword(email)
      // tslint:disable-next-line:max-line-length
      .subscribe(data => {
        this.toastController.displayToast('We just sent a password reset email to the address with a verify email link! If there is no email in a few minutes please check your junk mail folder.', 3000)
      }
        , error => this.toastController.displayToast('Error sending verification email!', 3000)
      );
  }


}//end class