import { ComponentType } from '@angular/cdk/portal';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class OkDialogModel
{
  readonly component: ComponentType<any> = OkDialogComponent;
  panelClass?: string | string[] = 'dialog-container';
  autoFocus?: boolean = false;
  data?: any;
  width?: string = '25dvw';

  constructor(public title: string, public message: string[]) {
    this.data = {title, message};
  }
}

@Component({
  selector: 'app-ok-dialog',
  templateUrl: './ok-dialog.component.html',
  styleUrls: ['./ok-dialog.component.css']
})
export class OkDialogComponent implements OnInit
{
  title: string;
  message: string[];

  constructor(public dialogRef: MatDialogRef<OkDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: OkDialogModel) 
  {
    this.title = data.title;
    this.message = data.message;
  }

  ngOnInit()
  {
    //TODO
  }

  onDismiss(): void
  {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

}
