
import { Observable, from } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { User, jsonToUser } from '../../models/user.model';
import { ClientStorage } from '../../storage/client-storage.lib';
import { ACCESS_TOKEN } from '../../storage/constants.lib';
import { HccSummaryConfigItem } from '../../models/hcc-summary-config-item';

@Injectable({
    providedIn: "root"
})
export class UserLib {
    private baseURL = "";

    constructor(
        private http: HttpClient,
        private storage: ClientStorage,
    ) {
        this.baseURL = environment.baseURL;
    }

    public getCurrentUser(): Observable<User> {
        const value = this.storage.getItem(ACCESS_TOKEN).then((token) => {
            let headers = new HttpHeaders();
            headers = headers.append("Authorization", "Bearer " + token);

            const httpOptions = {
                headers: headers

            };
            return this.http
                .get<User>(`${this.baseURL}/api/user/getUser/`, httpOptions).pipe(map(jsonToUser));
        })


        return from(value).pipe(mergeMap(value => { return value }));


    }

    public getUsers(): Observable<User[]> {
        const value = this.storage.getItem(ACCESS_TOKEN).then((token) => {
            let headers = new HttpHeaders();
            headers = headers.append("Authorization", "Bearer " + token);

            const httpOptions = {
                headers: headers

            };
            return this.http
                .get<User[]>(`${this.baseURL}/api/user/getall/`, httpOptions);
        })

        return from(value).pipe(mergeMap(value => { return value }));
    }

    public getUserList(): Observable<User[]> {
        const value = this.storage.getItem(ACCESS_TOKEN).then((token) => {
            let headers = new HttpHeaders();
            headers = headers.append("Authorization", "Bearer " + token);

            const httpOptions = {
                headers: headers

            };
            return this.http
                .get<User[]>(`${this.baseURL}/api/user/getlist/`, httpOptions);
        })

        return from(value).pipe(mergeMap(value => { return value }));
    }

    public getUserListByPermission(permissionID: number): Observable<User[]> {
        const value = this.storage.getItem(ACCESS_TOKEN).then((token) => {
            let headers = new HttpHeaders();
            headers = headers.append("Authorization", "Bearer " + token);

            const httpOptions = {
                headers: headers,
                params: new HttpParams().set('permissionID', permissionID ? permissionID.toString() : 0)
            };
            return this.http
                .get<User[]>(`${this.baseURL}/api/user/users-by-permission`, httpOptions);
        })

        return from(value).pipe(mergeMap(value => { return value }));
    }

    public updateSettings(settings: any) {
        const value = this.storage.getItem(ACCESS_TOKEN).then((token) => {
            let headers = new HttpHeaders();
            headers = headers.append("Authorization", "Bearer " + token);

            const httpOptions = {
                headers: headers

            };
            return this.http
                .post<any>(`${this.baseURL}/api/user/UpdateKeySettings`, settings, httpOptions);
        });

        return from(value).pipe(mergeMap(value => { return value }));

    }

    public getInsightsConfig(): Observable<HccSummaryConfigItem[]>{
        const value = this.storage.getItem(ACCESS_TOKEN).then((token) => {
            let headers = new HttpHeaders();
            headers = headers.append("Authorization", "Bearer " + token);

            const httpOptions = {
                headers: headers
                
            };
            return this.http
                .get<HccSummaryConfigItem[]>(`${this.baseURL}/api/user/insights-config`, httpOptions);
        })

        return from(value).pipe(mergeMap(value => { return value }));
    }

    public updateInsightsConfig(settings: any) {
        const value = this.storage.getItem(ACCESS_TOKEN).then((token) => {
            let headers = new HttpHeaders();
            headers = headers.append("Authorization", "Bearer " + token);

            const httpOptions = {
                headers: headers

            };
            return this.http
                .put<any>(`${this.baseURL}/api/user/insights-config`, settings, httpOptions);
        });

        return from(value).pipe(mergeMap(value => { return value }));

    }

}

