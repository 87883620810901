import { ComponentType } from '@angular/cdk/portal';
import { Component, Inject, OnInit } from '@angular/core';
import { DataRefreshErrorDetailItem } from '../../models/data-refresh-error-detail-item';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SchedulesService } from '../../services/settings/schedules.service';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

export class DataRefreshErrorDetailsDialogModel {
  readonly component: ComponentType<any> = DataRefreshErrorDetailsDialogComponent;
  panelClass?: string | string[] = 'dialog-container';
  autoFocus?: boolean = false;
  width?: string = '35dvw';
  maxHeight?: string = '70dvh';
  data?: any;

  constructor(data: any) {
    this.data = data;
  }
}

@Component({
  selector: 'app-data-refresh-error-details-dialog',
  templateUrl: './data-refresh-error-details-dialog.component.html',
  styleUrls: ['./data-refresh-error-details-dialog.component.scss']
})
export class DataRefreshErrorDetailsDialogComponent implements OnInit {

  items: DataRefreshErrorDetailItem[] = [];

  loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loading: Observable<boolean>;

  constructor
  (
    public dialogRef: MatDialogRef<DataRefreshErrorDetailsDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private scheduleService: SchedulesService
  ) { 
    this.loading = this.loadingSubject.asObservable();
  }

  ngOnInit(): void {

    this.loadingSubject.next(true);

    this.scheduleService.getRefreshBatchItemErrorDetails(this.data.RefreshBatchID).subscribe(result => {
      this.items = result;
      this.loadingSubject.next(false);
    });
  }

}
