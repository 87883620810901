export class OpptyHccValuationReportItem {
    PreferredActionID: number;
    PreferredAction: string;
    StateID: number;
    StatusID: number;
    Status: string;
    TotalCount: number;
    HCCCount: number;
    Valuation: number;
}
