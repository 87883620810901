<mat-card class="vc-card mat-elevation-z5">
    <mat-card-header>
        <div class="title-container flex">
            <h2 class="basis-4/5">Opportunity</h2>
            <div class="flex grow justify-end align-middle items-center">
                <span class="material-symbols-outlined report-icon" *ngIf="reportingEnabled" 
                [routerLink]="['/reporting']" [queryParams]="{report: SUBMISSION_OPPTY_TREND_REPORT_NAME, year: serviceYear}"
                [matTooltip]="'Generate Report'"
                [matTooltipClass]="'app-tooltip'"
                [matTooltipPosition]="'above'">export_notes</span>

                <span class="material-symbols-outlined report-icon" *ngIf="!reportingEnabled" aria-disabled="true"
                [matTooltip]="'Contact Admin for Access to Generate Report'"
                [matTooltipClass]="'app-tooltip'"
                [matTooltipPosition]="'above'">export_notes</span>
            </div>
        </div>
    </mat-card-header>

    <mat-card-content class="vc-card-summary-grid">
        <mat-grid-list cols="6" rowHeight="40px" gutterSize="0">

            <mat-grid-tile class="header-tile" [colspan]="4">
                <div class="toggle-container grow">
                    <mat-slide-toggle color="accent" [(ngModel)]="opportunityDxSwitch">
                        <span>DX View</span>
                    </mat-slide-toggle>
                </div>
            </mat-grid-tile>

            <mat-grid-tile class="header-tile" [colspan]="1">
                <div class="flex grow justify-end align-middle">
                    <h2 *ngIf="!opportunityDxSwitch">HCCs</h2>
                    <h2 *ngIf="opportunityDxSwitch">DXs</h2>
                </div>
            </mat-grid-tile>

            <mat-grid-tile class="header-tile">
                <div class="flex grow justify-end align-middle">
                    <h2>%</h2>
                </div>
            </mat-grid-tile>

            <!--Submission-->
            <mat-grid-tile [colspan]="4">
                <div class="flex grow justify-start align-middle">
                    <h3><a [routerLink]="['/opportunity-list', {qfilter: 'Master'}]">Submission Opportunity</a></h3>
                </div>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1">
                <div class="flex grow justify-end align-middle">
                    <h3 *ngIf="!opportunityDxSwitch">{{hccValue | number}}</h3>
                    <h3 *ngIf="opportunityDxSwitch">{{dxValue | number}}</h3>
                </div>
            </mat-grid-tile>

            <mat-grid-tile>
                <div class="flex grow justify-end align-middle">
                    <h3 *ngIf="!opportunityDxSwitch">{{hccPercent | percent}}</h3>
                    <h3 *ngIf="opportunityDxSwitch">{{dxPercent | percent}}</h3>
                </div>
            </mat-grid-tile>

            <!--Progress-->
            <mat-grid-tile [colspan]="3">
                <div class="flex grow justify-start align-middle">
                    <h3>Submission Progress</h3>
                </div>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="3">
                <div class="flex grow justify-end align-middle" class="p-r-10">
                    <h3 *ngIf="!opportunityDxSwitch">{{progressBarValue | percent}}</h3>
                    <h3 *ngIf="opportunityDxSwitch">{{progressBarValue | percent}}</h3>
                </div>
                <mat-progress-bar mode="determinate" [value]="progressBarValue * 100"></mat-progress-bar>
            </mat-grid-tile>
        </mat-grid-list>

        <label class="data-refresh-label" *ngIf="data.DateUpdated">Data Refreshed {{data.DateUpdated | date: 'MM/dd/YYYY'}}</label>
    </mat-card-content>
</mat-card>