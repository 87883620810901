import { Timezone } from "./timezone";

export class Org
{
    OrgID: number;
    OrgName: string;
    Notes: string;
    Disabled: boolean;
    Timezone: Timezone;
    UserResetPasswordInterval: number;
    ExportPasswordExpirationInterval: number;
}