import { DiagnosisEventQuery } from "./diagnosis-event-query";

export class DiagnosisEventEditRequest {
    EventIDs: string;
    StatusID: number;
    RootCauseID: number;
    RootCauseReason: string;
    Note: string;
    NoteUserID: number;
    BatchCount?: number;
    VisitID?: string;
    DXCode?: string;
    Query?: DiagnosisEventQuery;
}
