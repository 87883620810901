import { Routes } from "@angular/router";
import { FullComponent } from "./layouts/full/full.component";
import { LoginComponent } from "./components/route-components/login/login.component";
import { UsersComponent } from "./components/route-components/users/users.component";
import { HomeComponent } from "./components/route-components/home/home.component";
import { OrgsComponent } from "./components/route-components/orgs/orgs.component";
import { AuthGuard } from "./services/authentication/guards/auth-guard.service";
import { SystemAdminGuard } from "./services/authentication/guards/system-admin-guard.service";
import { AdminGuard } from "./services/authentication/guards/admin-guard.service";
import { OpportunityListPageComponent } from "./components/route-components/opportunity-list-page/opportunity-list-page.component";
import { ConfigPageComponent } from "./components/route-components/config-page/config-page.component";
import { ReportingPageComponent } from "./components/route-components/reporting-page/reporting-page.component";


export const AppRoutes: Routes = [
  {
    path: "",
    component: FullComponent,
    children: [
      {
        path: "",
        redirectTo: "insights",
        pathMatch: "full",
        //canActivate: [AuthGuard]
      },
      {
        path: "insights",
        component: HomeComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "diagnosis-event",
        component: OpportunityListPageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "opportunity-list",
        component: OpportunityListPageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "reporting",
        component: ReportingPageComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "users",
        component: UsersComponent,
        canActivate: [AdminGuard],
      },
      {
        path: "orgs",
        component: OrgsComponent,
        canActivate: [SystemAdminGuard],
      },
      {
        path: "forgotpassword",
        component: LoginComponent,
      },
      {
        path: "login",
        component: LoginComponent
      },
      {
        path: "configuration",
        component: ConfigPageComponent,
        canActivate: [AuthGuard]
      },

      { path: "**", component: HomeComponent },
    ],
  },
];
