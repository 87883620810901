import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { HccSummaryCalculations } from '../../../../../models/hcc-summary-calculations';
import { ColDef, ColGroupDef, ValueFormatterParams } from 'ag-grid-enterprise';
import { formatNumber } from '@angular/common';
import { CalculationsService } from '../../../../../services/calculations/calculations.service';
import { Subscription } from 'rxjs';
import { AgGridAngular } from 'ag-grid-angular';
import { AgNoRowsOverlayComponent } from '../../../../ag-grid-components/ag-no-rows-overlay/ag-no-rows-overlay.component';
import { DxsrExcelStyling } from '../dxsr-excel-styling';
import { AuditAction } from '../../../../../models/audit-action';
import { LoggingService } from '../../../../../services/api/logging.service';
import { RootCauseSummaryCalculation } from '../../../../../models/root-cause-summary-calculation';
import { AgLoadingOverlayComponent } from '../../../../ag-grid-components/ag-loading-overlay/ag-loading-overlay.component';

@Component({
  selector: 'app-diagnosis-events-summary-trend-report',
  templateUrl: './diagnosis-events-summary-trend-report.component.html',
  styleUrls: ['./diagnosis-events-summary-trend-report.component.scss']
})
export class DiagnosisEventsSummaryTrendReportComponent implements OnInit, OnDestroy {

  data: HccSummaryCalculations[] = [];
  numberRowWidth = 90;

  dxEventsRowName = 'DocumentedDiagnosisEvents';
  row2Name = 'PostedViaClaimOrSuppFeed';
  row2Sub1Name = 'PostedOnClaim';
  row2Sub2Name = 'PostedOnSuppFeed';
  row3Name = 'NonActionable';
  row3SubName = "NonActionableSub";
  row3SubBottomName = 'NonActionableSubBottom';
  row4Name = 'OnRapsOrMOR';
  row4Sub1Name = 'OnRAPS';
  row4Sub2Name = 'OnMOR';
  row5Name = 'PostedAndOnRapsOrMOR';
  row5Sub1Name = 'PostedNotOnRapsOrMOR';
  row5Sub2Name = 'NotPostedButOnRapsOrMOR';

  columnDefs: (ColDef | ColGroupDef)[] = [];

  defaultColDef: ColDef = {
    menuTabs: [],
    minWidth: 100,
    maxWidth: 100,
    autoHeight: true
  };

  noRowsOverlayComponent: any = AgNoRowsOverlayComponent;
  noRowsOverlayComponentParams: any = {
    noRowsMessageFunc: () => 'No results returned',
  };

  loadingOverlayComponent: any = AgLoadingOverlayComponent;

  excelStyles = DxsrExcelStyling.excelStylesTrends;

  gridContext: any;

  rowItems: any[] = [];
  gridReady = false;

  dxSwitch = false;

  currentStartYear = 0;
  currentEndYear = 0;

  currentRowHeight = 140;
  currentGridWidth = 282;

  subs: Subscription[] = [];

  @Output() ready: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() setExportEnable: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('grid') agGrid!: AgGridAngular;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setGridColumnsToFit();
  }

  constructor(
    private calculationsService: CalculationsService,
    private loggingService: LoggingService) {
    this.gridContext = { componentParent: this };
  }

  ngOnInit(): void {
    //TODO
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  onGridReady(event) {
    this.ready.emit(true);

    this.subs.push(this.calculationsService.hccSummaryCalculationsRange.subscribe(result => {
      if(!result) {
        return;
      }

      if(result.length == 0) {
        return;
      }

      this.data = result;
      const nonActionableCount = this.data[0].NonActionables.length;
      this.currentRowHeight = 412 + (nonActionableCount * 29);
      this.currentGridWidth = 282 + (this.data ? this.data.length * 200 : 0);
      //this.setGridsRowData(this.data);
      this.setRowItems();
      this.agGrid.api.setGridOption('rowData', this.rowItems);
      this.agGrid.api.setGridOption('columnDefs', this.getColDef());
      this.onDxToggleChange();
      this.hideGridsLoadingOverlay();
      this.setExportEnable.emit(true);

      setTimeout(() => {
        this.setGridColumnsToFit();
      }, 500);
    }));
  }

  updateServiceYearRange(startYear: number, endYear: number) {
    this.currentStartYear = startYear;
    this.currentEndYear = endYear;
    this.calculationsService.getHccSummaryCalculationsByRange(startYear, endYear);
    this.showGridsLoadingOverlay();
  }

  setRowItems() {
    this.rowItems = [];

    this.rowItems.push(this.addNewCategory(this.data, '', 'DocumentedDiagnosisEvents', 'Documented Dx Events'));
    this.rowItems.push(this.addNewCategory(this.data, 'Posted Via Claim or Supp Feed', 'PostedViaClaimOrSuppFeed', 'Posted Via Claim or Supp Feed'));
    this.rowItems.push(this.addNewCategory(this.data, 'Posted Via Claim or Supp Feed', 'PostedOnClaim', 'Billing Charge Posted on Claim'));
    this.rowItems.push(this.addNewCategory(this.data, 'Posted Via Claim or Supp Feed', 'PostedOnSuppFeed', 'Billing Charge Posted on Supp Feed'));

    this.rowItems.push(this.addNewCategory(this.data, 'Non Actionable', 'NonActionable', 'Non Actionable'));
    this.addNonActionablesCategories(this.data, 'Non Actionable');

    this.rowItems.push(this.addNewCategory(this.data, 'On RAPs/MAO or MOR', 'OnRapsOrMOR', 'On RAPs/MAO or MOR'));
    this.rowItems.push(this.addNewCategory(this.data, 'On RAPs/MAO or MOR', 'OnRAPS', 'RAPs/MAO'));
    this.rowItems.push(this.addNewCategory(this.data, 'On RAPs/MAO or MOR', 'OnMOR', 'MOR'));

    this.rowItems.push(this.addNewCategory(this.data, 'Posted and on RAPs/MAO or MOR', 'PostedAndOnRapsOrMOR', 'Posted and on RAPs/MAO or MOR'));
    this.rowItems.push(this.addNewCategory(this.data, 'Posted and on RAPs/MAO or MOR', 'PostedNotOnRapsOrMOR', 'Posted: Not on RAPs/MAO or MOR'));
    this.rowItems.push(this.addNewCategory(this.data, 'Posted and on RAPs/MAO or MOR', 'NotPostedButOnRapsOrMOR', 'Not Posted: On RAPs/MAO or MOR'));
  }

  addNewCategory(dataItems: HccSummaryCalculations[], parentName: string, propertyName: string, displayName: string) {

    if(!dataItems || dataItems.length == 0) {
      return;
    }

    let rowItem = {};
    rowItem["DisplayName"] = displayName;
    rowItem["PropertyName"] = propertyName;
    rowItem["Parent"] = parentName;

    const hccPrefix = 'HCC';
    const dxPrefix = 'Dx';

    dataItems.forEach(i => {
      rowItem = this.addNewRowItemColumns(i, rowItem, propertyName, hccPrefix, dxPrefix);
    });

    return rowItem;
  }

  addNonActionablesCategories(dataItems: HccSummaryCalculations[], parentName: string) {
    if(!dataItems || dataItems.length == 0) {
      return;
    }

    const categoryData = dataItems[0].NonActionables;

    categoryData.forEach((i, index) => {
      const rowItem = {};
      const propName = index == categoryData.length - 1 ? this.row3SubBottomName : this.row3SubName;
      rowItem["DisplayName"] = i.RootCauseDescription;
      rowItem["PropertyName"] = propName;
      rowItem["Parent"] = parentName;

      this.rowItems.push(rowItem);
    });

    dataItems.forEach(i => {
      i.NonActionables.forEach((o, index) => {
        let item = this.rowItems[this.rowItems.findIndex(i => i.DisplayName == o.RootCauseDescription)]
        item = this.addNonActionableRowItemColumns(o, item);
      });
    });
  }

  addNewRowItemColumns(dataItem: HccSummaryCalculations, rowItem: any, propertyName: string, hccPrefix:string, dxPrefix: string) {
    const serviceYear = dataItem.ServiceYear;

    rowItem[`${serviceYear}HCCs`] = dataItem[hccPrefix + propertyName];
    rowItem[`${serviceYear}HCCPercent`] = dataItem[hccPrefix + propertyName + 'Percent'] || 0;
    rowItem[`${serviceYear}DX`] = dataItem[dxPrefix + propertyName];
    rowItem[`${serviceYear}DXPercent`] = dataItem[dxPrefix + propertyName + 'Percent'];

    return rowItem;
  }

  addNonActionableRowItemColumns(item: RootCauseSummaryCalculation, rowItem: any) {
    const serviceYear = item.ServiceYear;

    rowItem[`${serviceYear}HCCs`] = item.HCCCount ? item.HCCCount.toString() : 0;
    rowItem[`${serviceYear}HCCPercent`] = item.HCCPercent ? item.HCCPercent.toString() : 0;
    rowItem[`${serviceYear}DX`] = item.DXCount ? item.DXCount.toString() : 0;
    rowItem[`${serviceYear}DXPercent`] = item.DXPercent ? item.DXPercent.toString() : 0;

    return rowItem;
  }

  getColDef(): (ColDef | ColGroupDef)[] {

    const result: any = [
      { field: 'DisplayName', headerName: '',
        tooltipField: 'DisplayName',
        minWidth: 280,
        maxWidth: 600,
        cellClassRules: {
          'dxsr-display-name-1': (params) => {
            return params.data.PropertyName === this.dxEventsRowName;
          },
          'dxsr-display-name-2': (params) => {
            return  params.data.PropertyName === this.row2Name ||
                    params.data.PropertyName === this.row3Name ||
                    params.data.PropertyName === this.row4Name ||
                    params.data.PropertyName === this.row5Name;
          },
          'dxsr-display-name-3': (params) => {
            return  params.data.PropertyName === this.row2Sub2Name ||
                    params.data.PropertyName === this.row3SubBottomName ||
                    params.data.PropertyName === this.row4Sub2Name;
          },
          'dxsr-display-name-4': (params) => {
            return  params.data.PropertyName === this.row2Sub1Name ||
                    params.data.PropertyName === this.row3SubName ||
                    params.data.PropertyName === this.row4Sub1Name ||
                    params.data.PropertyName === this.row5Sub1Name;
          },
          'dxsr-display-name-5': (params) => {
            return params.data.PropertyName === this.row5Sub2Name;
          }
        }
      },
    ];

    this.data.forEach(i => {
      const serviceYear = i.ServiceYear;
      const newYear = {
        headerName: `${serviceYear} Service Year`,
        children: [
          { field: `${serviceYear}HCCs`, headerName: 'HCCs', type: 'numericColumn', 
            valueFormatter: this.getNumberValue,
            cellClassRules: {
              'dxsr-values-1': (params) => {
                return params.data.PropertyName === this.dxEventsRowName;
              },
              'dxsr-values-2': (params) => {
                return  params.data.PropertyName === this.row2Name ||
                        params.data.PropertyName === this.row3Name ||
                        params.data.PropertyName === this.row4Name ||
                        params.data.PropertyName === this.row5Name;
              },
              'dxsr-values-3': (params) => {
                return  params.data.PropertyName === this.row2Sub2Name ||
                        params.data.PropertyName === this.row3SubBottomName ||
                        params.data.PropertyName === this.row4Sub2Name;
              },
              'dxsr-values-4': (params) => {
                return  params.data.PropertyName === this.row2Sub1Name ||
                        params.data.PropertyName === this.row3SubName ||
                        params.data.PropertyName === this.row4Sub1Name ||
                        params.data.PropertyName === this.row5Sub1Name;
              },
              'dxsr-values-5': (params) => {
                return params.data.PropertyName === this.row5Sub2Name;
              }
            }
          },
          { field: `${serviceYear}HCCPercent`, headerName: 'HCC %', type: 'numericColumn', 
            valueFormatter: this.getPercentValue,
            cellClassRules: {
              'dxsr-percent-1': (params) => {
                return params.data.PropertyName === this.dxEventsRowName;
              },
              'dxsr-percent-2': (params) => {
                return  params.data.PropertyName === this.row2Name ||
                        params.data.PropertyName === this.row3Name ||
                        params.data.PropertyName === this.row4Name ||
                        params.data.PropertyName === this.row5Name;
              },
              'dxsr-percent-3': (params) => {
                return  params.data.PropertyName === this.row2Sub2Name ||
                        params.data.PropertyName === this.row3SubBottomName ||
                        params.data.PropertyName === this.row4Sub2Name;
              },
              'dxsr-percent-4': (params) => {
                return  params.data.PropertyName === this.row2Sub1Name ||
                        params.data.PropertyName === this.row3SubName ||
                        params.data.PropertyName === this.row4Sub1Name ||
                        params.data.PropertyName === this.row5Sub1Name;
              },
              'dxsr-percent-5': (params) => {
                return params.data.PropertyName === this.row5Sub2Name;
              }
            }
          },
          { field: `${serviceYear}DX`, headerName: 'DX', type: 'numericColumn', 
            valueFormatter: this.getNumberValue,
            cellClassRules: {
              'dxsr-values-1': (params) => {
                return params.data.PropertyName === this.dxEventsRowName;
              },
              'dxsr-values-2': (params) => {
                return  params.data.PropertyName === this.row2Name ||
                        params.data.PropertyName === this.row3Name ||
                        params.data.PropertyName === this.row4Name ||
                        params.data.PropertyName === this.row5Name;
              },
              'dxsr-values-3': (params) => {
                return  params.data.PropertyName === this.row2Sub2Name ||
                        params.data.PropertyName === this.row3SubBottomName ||
                        params.data.PropertyName === this.row4Sub2Name;
              },
              'dxsr-values-4': (params) => {
                return  params.data.PropertyName === this.row2Sub1Name ||
                        params.data.PropertyName === this.row3SubName ||
                        params.data.PropertyName === this.row4Sub1Name ||
                        params.data.PropertyName === this.row5Sub1Name;
              },
              'dxsr-values-5': (params) => {
                return params.data.PropertyName === this.row5Sub2Name;
              }
            }
          },
          { field: `${serviceYear}DXPercent`, headerName: 'DX %', type: 'numericColumn', 
            valueFormatter: this.getPercentValue,
            cellClassRules: {
              'dxsr-percent-1': (params) => {
                return params.data.PropertyName === this.dxEventsRowName;
              },
              'dxsr-percent-2': (params) => {
                return  params.data.PropertyName === this.row2Name ||
                        params.data.PropertyName === this.row3Name ||
                        params.data.PropertyName === this.row4Name ||
                        params.data.PropertyName === this.row5Name;
              },
              'dxsr-percent-3': (params) => {
                return  params.data.PropertyName === this.row2Sub2Name ||
                        params.data.PropertyName === this.row3SubBottomName ||
                        params.data.PropertyName === this.row4Sub2Name;
              },
              'dxsr-percent-4': (params) => {
                return  params.data.PropertyName === this.row2Sub1Name ||
                        params.data.PropertyName === this.row3SubName ||
                        params.data.PropertyName === this.row4Sub1Name ||
                        params.data.PropertyName === this.row5Sub1Name;
              },
              'dxsr-percent-5': (params) => {
                return params.data.PropertyName === this.row5Sub2Name;
              }
            }
          }
        ]
      }
      
      result.push(newYear);
    });

    return result;
  }

  onDxToggleChange() {

    this.data.forEach(i => {
      const serviceYear = i.ServiceYear;

      this.agGrid.api.applyColumnState({
        state: [
          {colId: `${serviceYear}HCCs`, hide: this.dxSwitch},
          {colId: `${serviceYear}HCCPercent`, hide: this.dxSwitch},
          {colId: `${serviceYear}DX`, hide: !this.dxSwitch},
          {colId: `${serviceYear}DXPercent`, hide: !this.dxSwitch}
        ]
      });
    });

    this.setGridColumnsToFit();
  }

  setGridsRowData(data: HccSummaryCalculations[]) {
    this.agGrid.api.setRowData(data);
  }

  setGridColumnsToFit() {
    if(!document.getElementById('grid')) {
      return;
    }

    this.agGrid.api.sizeColumnsToFit();
  }

  showGridsLoadingOverlay() {
    this.agGrid.api.showLoadingOverlay();
  }

  hideGridsLoadingOverlay() {
    this.agGrid.api.hideOverlay();
  }

  onExport() {
    const sheets = [
      this.agGrid.api.getSheetDataForExcel({sheetName: "Documented Diagnosis Events"}),
    ];

    const filename =`DiagnosisEventsTrendExport_${this.currentStartYear}-${this.currentEndYear}`;

    this.agGrid.api.exportMultipleSheetsAsExcel({
      data: sheets,
      fileName: filename
    });

    const reportJSON = JSON.stringify({ReportName: 'DX Events Trend', Filename: filename});
    this.loggingService.logAudit(AuditAction.ReportExported, `Report Name: DX Events Trend, Filename: ${filename}`, reportJSON).subscribe(() => {
      //TODO
    });
  }

  getNumberValue(item: any) {
    if(!item.value) {
      return '0';
    }

    return formatNumber(item.value, 'en').toString();
  }

  getPercentValue(item: any) {
    if(item.data.PropertyName == item.context.componentParent.dxEventsRowName) {
      return '';
    }

    if(!item.value) {
      return '0%';
    }

    return item.value + "%";
  }

  getNonActionableValue(params: ValueFormatterParams, nonActionableIndex: number, name: string) {
    const value = params.data.NonActionables[nonActionableIndex][name];
    if(!value) {
      return '0';
    }

    return formatNumber(value, 'en').toString();
  }

  getNonActionablePercent(params: ValueFormatterParams, nonActionableIndex: number, name) {
    const value = params.data.NonActionables[nonActionableIndex][name];
    if(!value) {
      return '0%';
    }

    return value + "%";
  }

}
