<div class="spinner-container" [ngStyle]="{'width': diameter + 'px', 'height': diameter + 'px'}">
    <mat-progress-spinner
        class="custom-progress-bar-track"
        [color]="color" 
        [diameter]="diameter" 
        [mode]="'determinate'" 
        [strokeWidth]="strokeWidth" 
        [value]="100">
    </mat-progress-spinner>
    <mat-progress-spinner
        class="custom-progress-bar"
        [color]="color" 
        [diameter]="diameter" 
        [mode]="mode" 
        [strokeWidth]="strokeWidth" 
        [value]="value">
    </mat-progress-spinner>

    <div class="spinner-background">
        <div class="flex flex-1 flex-row justify-center items-center content-center">{{display}}</div>
    </div>
</div>