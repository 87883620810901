<div mat-dialog-title>
    <div class="flex flex-row">
        <div class="flex basis-3/4">
            <h1>HCC / ICD10 Model Version {{data.mode == 'add' ? 'Add' : 'Edit'}} Entry</h1>
        </div>
    
        <div class="flex basis-1/4 justify-end items-center content-center">
            <mat-icon (click)="onCloseClick()">close</mat-icon>
        </div>
    </div>
</div>

<div mat-dialog-content>
    <div class="content-container flex flex-col">
        <div class="flex basis-[5%] flex-row">
            <div class="flex basis-[35%] justify-start items-center content-center">
                <h3>Service Year *</h3>
            </div>

            <div class="flex basis-[60%]">
                <app-payment-year-select
                #serviceYear
                [form]="form"
                [controlName]="serviceYearControlName"
                [label]="null"
                [placeholder]="'Make a Selection'"
                [required]="true"
                [minYear]="2021"
                [maxYearAddition]="2"
                [omitValues]="invalidServiceYears"
                [disabled]="mode == 'edit'"></app-payment-year-select>
            </div>
        </div>

        <div class="flex basis-[5%] flex-row">
            <div class="flex basis-[35%] justify-start items-center content-center">
                <h3>Model Category *</h3>
            </div>

            <div class="flex basis-[60%]">
                <app-hcc-model-category-select
                [form]="form"
                [controlName]="categoryControlName"
                [label]="null"
                [queryOnInit]="true"
                [required]="true"
                [multiple]="false"
                [placeholder]="'Make a Selection'"
                (valueChanged)="onCategoryChanged($event)"
                (ready)="onCategoryReady()"></app-hcc-model-category-select>
            </div>
        </div>

        <div class="flex basis-[5%] flex-row">
            <div class="flex basis-[35%] justify-start items-center content-center">
                <h3>Version *</h3>
            </div>

            <div class="flex basis-[60%]">
                <app-hcc-model-version-select
                [form]="form"
                [controlName]="versionControlName"
                [label]="null"
                [placeholder]="'Make a Selection'"
                [disabled]="true"
                [required]="true"
                [multiple]="false"
                (ready)="onVersionReady()"
                (valueChanged)="onVersionChanged($event)"></app-hcc-model-version-select>
            </div>
        </div>
        
    </div>
</div>

<mat-dialog-actions align="end">
    <button  
        mat-flat-button 
        class="primary-button" 
        [disabled]="form.invalid"
        (click)="onSaveClick()">
        Save
    </button>
</mat-dialog-actions>
