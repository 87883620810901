import { SimpleUserOption } from "./simple-user-option";

export class OrgHccModelVersionItem {
    ID: number; 
    OrgID: number;
    ServiceYear: number;
    ModelCategoryID: number;
    ModelCategoryName: string;
    VersionID: number;
    VersionName: string;
    LastModifiedDateTime: string;
    LastModifiedBy: SimpleUserOption;
    IsEdit: boolean;
}
