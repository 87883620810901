import { QueryMetadata } from "./query-metadata";

export class GridQuery {
    ServiceYear: number;
    Metadata: QueryMetadata;
}

export class UserAllocationQuery extends GridQuery {
    AssignedToIDs: string;
}

export class AuditLogQuery extends GridQuery {
    StartDate: string;
    EndDate: string;
    ActionIDs: string;
}
