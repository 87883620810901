<div class="timeline-container flex flex-auto flex-col">
  <div class="flex flex-auto flex-row">
    <label class="current-date-label flex flex-auto justify-center items-center content-center" *ngIf="serviceYear < 0">
        Today - {{currentDate | date: 'shortDate'}}
    </label>
  </div>
  
  <div class="flex flex-auto flex-row">
    <div
    [matTooltip]="simpleDisplay ? getTooltip(node1) : ''"
    [matTooltipClass]="'multi-line-tooltip'">

      <button mat-mini-fab color="primary" 
      [disabled]="!node1.DeadlineForSubmission">
          <mat-icon *ngIf="node1.DeadlineForSubmission">done</mat-icon>
          <mat-icon *ngIf="!node1.DeadlineForSubmission">schedule</mat-icon>
      </button>

    </div>
  
    <mat-progress-bar [value]="bar1Value"></mat-progress-bar>
  
    <div
    [matTooltip]="simpleDisplay ? getTooltip(node2) : ''"
    [matTooltipClass]="'multi-line-tooltip'">

      <button mat-mini-fab color="primary" [disabled]="bar1Value < 100">
        <mat-icon *ngIf="bar1Value == 100">done</mat-icon>
        <mat-icon *ngIf="bar1Value == 0">schedule</mat-icon>
        <mat-icon *ngIf="bar1Value > 0 && bar1Value < 100">timelapse</mat-icon>
      </button>

    </div>
  
    <mat-progress-bar [value]="bar2Value"></mat-progress-bar>

    <div
    [matTooltip]="simpleDisplay ? getTooltip(node3) : ''"
    [matTooltipClass]="'multi-line-tooltip'">

      <button mat-mini-fab color="primary" [disabled]="bar2Value < 100">
        <mat-icon *ngIf="bar2Value == 100">done</mat-icon>
        <mat-icon *ngIf="bar2Value == 0">schedule</mat-icon>
        <mat-icon *ngIf="bar2Value > 0 && bar2Value < 100">timelapse</mat-icon>
      </button>

    </div>
  
    <mat-progress-bar [value]="bar3Value"></mat-progress-bar>

    <div
    [matTooltip]="simpleDisplay ? getTooltip(node4) : ''"
    [matTooltipClass]="'multi-line-tooltip'">

    <button mat-mini-fab color="primary" [disabled]="bar3Value < 100">
      <mat-icon *ngIf="bar3Value == 100">done</mat-icon>
      <mat-icon *ngIf="bar3Value == 0">schedule</mat-icon>
      <mat-icon *ngIf="bar3Value > 0 && bar3Value < 100">timelapse</mat-icon>
    </button>

    </div>
  </div>
  
  <div class="flex flex-auto flex-row">
    <div class="flex basis-[17%] flex-col justify-start items-center content-center" *ngIf="node1.DeadlineForSubmission">
        <span><strong>{{node1.DeadlineForSubmission}}</strong></span>
        <span *ngIf="!simpleDisplay">{{node1.RiskScoreRunYear}} {{node1.RiskScoreRunTypeName}}</span>
        <span class="dos-range-label" *ngIf="!simpleDisplay">{{node1.DatesOfServiceStart}} - {{node1.DatesOfServiceEnd}}</span>
    </div>
    <div class="flex basis-[17%] flex-col justify-start items-center content-center" *ngIf="!node1.DeadlineForSubmission">
      <span><strong>No Data</strong></span>
    </div>

    <div class="flex flex-auto flex-col justify-center items-center content-center" *ngIf="node2.DeadlineForSubmission">
      <span><strong>{{node2.DeadlineForSubmission}}</strong></span>
      <span *ngIf="!simpleDisplay">{{node2.RiskScoreRunYear}} {{node2.RiskScoreRunTypeName}}</span>
      <span class="dos-range-label" *ngIf="!simpleDisplay">{{node2.DatesOfServiceStart}} - {{node2.DatesOfServiceEnd}}</span>
    </div>
    <div class="flex flex-auto flex-col justify-center items-center content-center" *ngIf="!node2.DeadlineForSubmission">
      <span><strong>No Data</strong></span>
    </div>

    <div class="flex flex-auto flex-col justify-center items-center content-center" *ngIf="node3.DeadlineForSubmission">
      <span><strong>{{node3.DeadlineForSubmission}}</strong></span>
      <span *ngIf="!simpleDisplay">{{node3.RiskScoreRunYear}} {{node3.RiskScoreRunTypeName}}</span>
      <span class="dos-range-label" *ngIf="!simpleDisplay">{{node3.DatesOfServiceStart}} - {{node3.DatesOfServiceEnd}}</span>
    </div>
    <div class="flex flex-auto flex-col justify-center items-center content-center" *ngIf="!node3.DeadlineForSubmission">
      <span><strong>No Data</strong></span>
    </div>

    <div class="flex basis-[17%] flex-col justify-end items-center content-center" *ngIf="node4.DeadlineForSubmission">
      <span><strong>{{node4.DeadlineForSubmission}}</strong></span>
      <span *ngIf="!simpleDisplay">{{node4.RiskScoreRunYear}} {{node4.RiskScoreRunTypeName}}</span>
      <span class="dos-range-label" *ngIf="!simpleDisplay">{{node4.DatesOfServiceStart}} - {{node4.DatesOfServiceEnd}}</span>
    </div>
    <div class="flex basis-[17%] flex-col justify-end items-center content-center" *ngIf="!node4.DeadlineForSubmission">
      <span><strong>No Data</strong></span>
    </div>
  </div>
</div>
