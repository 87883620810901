import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import { DragDropModule } from "@angular/cdk/drag-drop";
import {
  BrowserModule,
  HammerModule,
} from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { AppRoutes } from "./app.routing";
import { AppComponent } from "./app.component";

import { FullComponent } from "./layouts/full/full.component";
import { AppHeaderComponent } from "./layouts/full/header/header.component";
import {
  AppSidebarComponent,
} from "./layouts/full/sidebar/sidebar.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DemoMaterialModule } from "./demo-material-module";
import { MatDialogModule } from "@angular/material/dialog";
import { AngularResizeEventModule } from "angular-resize-event";
import { APIService } from "./services/api/api.service";
import { LoginComponent } from "./components/route-components/login/login.component";
import {
  UsersComponent,
  DialogOverviewExampleDialogComponent,
  DialogAddUserComponent,
} from "./components/route-components/users/users.component";
import { CallbackComponent } from "./components/route-components/callback/callback.component";

import { HomeComponent } from "./components/route-components/home/home.component";
import { ConfirmDialogComponent } from "./dialogs/confirm-dialog/confirm-dialog.component";

import { OrgsComponent } from "./components/route-components/orgs/orgs.component";

import { OkDialogComponent } from "./dialogs/ok-dialog/ok-dialog.component";

import { LoadingDialogComponent } from "./dialogs/loading-dialog/loading-dialog.component";
import { UnauthorizedInterceptor } from "./authGuard/interceptor";
import { WebForgotPasswordComponent } from "./components/route-components/web-forgot-password/web-forgot-password.component";
import { ClientStorage } from "./storage/client-storage.lib";
import { ClientToastController } from "./toasts/client-toast.lib";
import { AuthService } from "./services/authentication/auth.service";
import { appInitializer } from "./services/authentication/app-initializer";
import { WebClientStorage } from "../libs/storage/web-client-storage.lib";
import { WebClientToast } from "../libs/toast/web-client-toast.lib";
import { AuthGuard } from "./services/authentication/guards/auth-guard.service";

import { AgGridModule } from "ag-grid-angular";
import 'ag-grid-enterprise';
import { AgChartsAngularModule } from 'ag-charts-angular';

import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DeterminateSpinnerComponent } from "./components/sub-components/determinate-spinner/determinate-spinner.component";
import { LoadSpinnerComponent } from "./components/sub-components/load-spinner/load-spinner.component";
import { OverlayLoadingDirective } from './directives/overlay-loading.directive';
import { AutoLogoutService } from "./services/autologout/auto-logout.service";
import { HccSummaryInfoComponent } from "./components/sub-components/hcc-summary-info/hcc-summary-info.component";
import { OpportunityListPageComponent } from "./components/route-components/opportunity-list-page/opportunity-list-page.component";
import { OpportunitySubmissionSummaryComponent } from "./components/sub-components/opportunity-submission-summary/opportunity-submission-summary.component";
import { ValidationSummaryComponent } from "./components/sub-components/validation-summary/validation-summary.component";
import { AutofocusDirective } from './directives/auto-focus.directive';
import { ProgressAllocationUserComponent } from "./components/sub-components/progress-allocation-user/progress-allocation-user.component";
import { ProgressAllocationPreferredActionComponent } from "./components/sub-components/progress-allocation-preferred-action/progress-allocation-preferred-action.component";
import { ProgressAllocationPayerComponent } from "./components/sub-components/progress-allocation-payer/progress-allocation-payer.component";

import { PreferredActionSelectComponent } from "./components/field-components/preferred-action-select/preferred-action-select.component";
import { PayerSelectComponent } from "./components/field-components/payer-select/payer-select.component";
import { StatusStateSelectComponent } from "./components/field-components/status-state-select/status-state-select.component";
import { StatusSelectComponent } from "./components/field-components/status-select/status-select.component";
import { RootCauseSelectComponent } from "./components/field-components/root-cause-select/root-cause-select.component";
import { DateRangePickerComponent } from "./components/field-components/static/date-range-picker/date-range-picker.component";
import { PaymentYearSelectComponent } from "./components/field-components/payment-year-select/payment-year-select.component";
import { UserSearchByPermissionComponent } from "./components/field-components/user-search-by-permission/user-search-by-permission.component";
import { CheckboxRendererComponent } from "./components/ag-grid-components/checkbox-renderer/checkbox-renderer.component";
import { EditIconRendererComponent } from "./components/ag-grid-components/edit-icon-renderer/edit-icon-renderer.component";
import { AssignOpptyDialogComponent } from './dialogs/assign-oppty-dialog/assign-oppty-dialog.component';
import { ServiceYearIconComponent } from './components/sub-components/service-year-icon/service-year-icon.component';
import { DosSelectComponent } from './components/field-components/dos-select/dos-select.component';
import { EditOpptyDialogComponent } from './dialogs/edit-oppty-dialog/edit-oppty-dialog.component';
import { NotesRendererComponent } from './components/ag-grid-components/notes-renderer/notes-renderer.component';
import { DiagnosisEventNotesDialogComponent } from './dialogs/diagnosis-event-notes-dialog/diagnosis-event-notes-dialog.component';
import { ProgressAllocationTotalsComponent } from './components/sub-components/progress-allocation-totals/progress-allocation-totals.component';
import { AgPaginatorComponent, AgPaginatorIntl } from './components/ag-grid-components/ag-paginator/ag-paginator.component';
import { AgAssignButtonComponent } from './components/ag-grid-components/ag-assign-button/ag-assign-button.component';
import { AgExportButtonComponent } from './components/ag-grid-components/ag-export-button/ag-export-button.component';
import { ConfigPageComponent } from './components/route-components/config-page/config-page.component';
import { DataRefreshConfigComponent } from './components/sub-components/data-refresh-config/data-refresh-config.component';
import { CmsScheduleConfigComponent } from './components/sub-components/cms-schedule-config/cms-schedule-config.component';
import { PayerConfigComponent } from './components/sub-components/payer-config/payer-config.component';
import { BooleanSelectComponent } from './components/field-components/static/boolean-select/boolean-select.component';
import { AddUpdatePayerDialogComponent } from './dialogs/add-update-payer-dialog/add-update-payer-dialog.component';
import { PayerAliasSelectComponent } from './components/field-components/payer-alias-select/payer-alias-select.component';
import { PayerConfigEditRendererComponent } from './components/ag-grid-components/payer-config-edit-renderer/payer-config-edit-renderer.component';
import { CMSTimelineComponent } from "./components/sub-components/cms-timeline/cms-timeline.component";
import { AddUpdateCmsScheduleDialogComponent } from './dialogs/add-update-cms-schedule-dialog/add-update-cms-schedule-dialog.component';
import { RiskScoreRunTypeSelectComponent } from './components/field-components/risk-score-run-type-select/risk-score-run-type-select.component';
import { MonthYearSelectComponent } from './components/field-components/month-year-select/month-year-select.component';
import { DocumentedDxEventGraphComponent } from './components/sub-components/documented-dx-event-graph/documented-dx-event-graph.component';
import { AgNoRowsOverlayComponent } from './components/ag-grid-components/ag-no-rows-overlay/ag-no-rows-overlay.component';
import { UserListActionIconRendererComponent } from './components/ag-grid-components/user-list-action-icon-renderer/user-list-action-icon-renderer.component';
import { UserRoleSelectComponent } from './components/field-components/user-role-select/user-role-select.component';
import { ResetPasswordDialogComponent } from './dialogs/reset-password-dialog/reset-password-dialog.component';
import { AgRowSelectedCountComponent } from './components/ag-grid-components/ag-row-selected-count/ag-row-selected-count.component';
import { AgOpptyEditButtonComponent } from './components/ag-grid-components/ag-oppty-edit-button/ag-oppty-edit-button.component';
import { AgHeaderCheckboxComponent } from './components/ag-grid-components/ag-header-checkbox/ag-header-checkbox.component';
import { ReportingPageComponent } from './components/route-components/reporting-page/reporting-page.component';
import { AllReportsComponent } from './components/sub-components/all-reports/all-reports.component';
import { FavoriteReportsComponent } from './components/sub-components/favorite-reports/favorite-reports.component';
import { ReportsGenerationComponent } from './components/sub-components/reporting/reports-generation/reports-generation.component';
import { DiagnosisEventsSummaryReportComponent } from './components/sub-components/reporting/reports/diagnosis-events-summary-report/diagnosis-events-summary-report.component';
import { AgDxSummaryDetailRendererComponent } from './components/ag-grid-components/ag-dx-summary-detail-renderer/ag-dx-summary-detail-renderer.component';
import { DiagnosisEventsSummaryTrendReportComponent } from './components/sub-components/reporting/reports/diagnosis-events-summary-trend-report/diagnosis-events-summary-trend-report.component';
import { DiagnosisEventsSummaryTrendGraphsComponent } from './components/sub-components/reporting/graphs/diagnosis-events-summary-trend-graphs/diagnosis-events-summary-trend-graphs.component';
import { MatPaginatorIntl } from "@angular/material/paginator";
import { OrgListActionIconRendererComponent } from "./components/ag-grid-components/org-list-action-icon-renderer/org-list-action-icon-renderer.component";
import { TimezoneSelectComponent } from "./components/field-components/timezone-select/timezone-select.component";
import { AddUpdateOrgDialogComponent } from "./dialogs/add-update-org-dialog/add-update-org-dialog.component";
import { AgDatePickerCellEditorComponent } from './components/ag-grid-components/ag-date-picker-cell-editor/ag-date-picker-cell-editor.component';
import { DataRefreshErrorDetailsDialogComponent } from './dialogs/data-refresh-error-details-dialog/data-refresh-error-details-dialog.component';
import { SubmissionOpportunityTrendReportComponent } from './components/sub-components/reporting/reports/submission-opportunity-trend-report/submission-opportunity-trend-report.component';
import { PayerValidationTrendReportComponent } from './components/sub-components/reporting/reports/payer-validation-trend-report/payer-validation-trend-report.component';
import { SubmissionOpportunityTrendGraphsComponent } from './components/sub-components/reporting/graphs/submission-opportunity-trend-graphs/submission-opportunity-trend-graphs.component';
import { PayerValidationTrendGraphsComponent } from './components/sub-components/reporting/graphs/payer-validation-trend-graphs/payer-validation-trend-graphs.component';
import { AddUpdateRiskPolicyDialogComponent } from './dialogs/add-update-risk-policy-dialog/add-update-risk-policy-dialog.component';
import { HccPatientTrendGraphComponent } from './components/sub-components/reporting/graphs/hcc-patient-trend-graph/hcc-patient-trend-graph.component';
import { SubmissionOpptyUserProgressReportComponent } from './components/sub-components/reporting/reports/submission-oppty-user-progress-report/submission-oppty-user-progress-report.component';
import { ProgressCellRendererComponent } from './components/ag-grid-components/progress-cell-renderer/progress-cell-renderer.component';
import { SubmissionOpptyPayerProgressReportComponent } from './components/sub-components/reporting/reports/submission-oppty-payer-progress-report/submission-oppty-payer-progress-report.component';
import { SubmissionOpptyPreferredActionProgressReportComponent } from './components/sub-components/reporting/reports/submission-oppty-preferred-action-progress-report/submission-oppty-preferred-action-progress-report.component';
import { HccConfigComponent } from './components/sub-components/hcc-config/hcc-config.component';
import { AddUpdateHccValuationDialogComponent } from './dialogs/add-update-hcc-valuation-dialog/add-update-hcc-valuation-dialog.component';
import { CurrencyInputComponent } from './components/field-components/currency-input/currency-input.component';
import { AgEditIconRendererComponent } from './components/ag-grid-components/ag-edit-icon-renderer/ag-edit-icon-renderer.component';
import { RootCauseTrendReportComponent } from './components/sub-components/reporting/reports/root-cause-trend-report/root-cause-trend-report.component';
import { OpptyHccValuationReportComponent } from './components/sub-components/reporting/reports/oppty-hcc-valuation-report/oppty-hcc-valuation-report.component';
import { HccPatientDistributionReportComponent } from './components/sub-components/reporting/reports/hcc-patient-distribution-report/hcc-patient-distribution-report.component';
import { AgSummationStatusPanelComponent } from './components/ag-grid-components/ag-summation-status-panel/ag-summation-status-panel.component';
import { AgLabelStatusPanelComponent } from './components/ag-grid-components/ag-label-status-panel/ag-label-status-panel.component';
import { ProgressAllocationSnapshotReportComponent } from './components/sub-components/reporting/reports/progress-allocation-snapshot-report/progress-allocation-snapshot-report.component';
import { DateRangeComponent } from './components/field-components/date-range/date-range.component';
import { ProgressAllocationSnapshotGraphsComponent } from './components/sub-components/reporting/graphs/progress-allocation-snapshot-graphs/progress-allocation-snapshot-graphs.component';
import { TreeMenuComponent } from './components/sub-components/tree-menu/tree-menu.component';
import { HccModelVersionConfigComponent } from './components/sub-components/hcc-model-version-config/hcc-model-version-config.component';
import { AddUpdateHccModelVersionDialogComponent } from './dialogs/add-update-hcc-model-version-dialog/add-update-hcc-model-version-dialog.component';
import { HccModelCategorySelectComponent } from './components/field-components/hcc-model-category-select/hcc-model-category-select.component';
import { HccModelVersionSelectComponent } from './components/field-components/hcc-model-version-select/hcc-model-version-select.component';
import { OrgAuditLogReportComponent } from './components/sub-components/reporting/reports/org-audit-log-report/org-audit-log-report.component';
import { AdminAuditActionSelectComponent } from './components/field-components/admin-audit-action-select/admin-audit-action-select.component';
import { OpptyHccValuationGraphsComponent } from './components/sub-components/reporting/graphs/oppty-hcc-valuation-graphs/oppty-hcc-valuation-graphs.component';
import { OpptyDataIngestionReportComponent } from './components/sub-components/reporting/reports/oppty-data-ingestion-report/oppty-data-ingestion-report.component';
import { AverageHccPerPatientTrendReportComponent } from './components/sub-components/reporting/reports/average-hcc-per-patient-trend-report/average-hcc-per-patient-trend-report.component';
import { AverageHccPerPatientTrendGraphsComponent } from './components/sub-components/reporting/graphs/average-hcc-per-patient-trend-graphs/average-hcc-per-patient-trend-graphs.component';
import { TemplateConfigComponent } from './components/sub-components/template-config/template-config.component';
import { AgIconRendererComponent } from './components/ag-grid-components/ag-icon-renderer/ag-icon-renderer.component';
import { AddUpdateTemplateConfigDialogComponent } from './dialogs/add-update-template-config-dialog/add-update-template-config-dialog.component';
import { FilterSelectComponent } from './components/field-components/filter-select/filter-select.component';
import { AgHeaderInfoIconRendererComponent } from './components/ag-grid-components/ag-header-info-icon-renderer/ag-header-info-icon-renderer.component';
import { AgLoadingOverlayComponent } from './components/ag-grid-components/ag-loading-overlay/ag-loading-overlay.component';
import { FilterFieldSearchSelectComponent } from './components/field-components/filter-field-search-select/filter-field-search-select.component';

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    AppHeaderComponent,
    AppSidebarComponent,
    UsersComponent,
    LoginComponent,
    DialogOverviewExampleDialogComponent,
    DialogAddUserComponent,
    CallbackComponent,
    HomeComponent,
    ConfirmDialogComponent,
    OrgsComponent,
    OkDialogComponent,
    WebForgotPasswordComponent,
    LoadingDialogComponent,
    DeterminateSpinnerComponent,
    LoadSpinnerComponent,
    OverlayLoadingDirective,
    HccSummaryInfoComponent,
    OpportunityListPageComponent,
    OpportunitySubmissionSummaryComponent,
    ValidationSummaryComponent,
    AutofocusDirective,
    ProgressAllocationUserComponent,
    ProgressAllocationPreferredActionComponent,
    ProgressAllocationPayerComponent,
    PreferredActionSelectComponent,
    PayerSelectComponent,
    StatusStateSelectComponent,
    StatusSelectComponent,
    RootCauseSelectComponent,
    DateRangePickerComponent,
    PaymentYearSelectComponent,
    UserSearchByPermissionComponent,
    CheckboxRendererComponent,
    EditIconRendererComponent,
    AssignOpptyDialogComponent,
    ServiceYearIconComponent,
    DosSelectComponent,
    EditOpptyDialogComponent,
    NotesRendererComponent,
    DiagnosisEventNotesDialogComponent,
    ProgressAllocationTotalsComponent,
    AgPaginatorComponent,
    AgAssignButtonComponent,
    AgExportButtonComponent,
    ConfigPageComponent,
    DataRefreshConfigComponent,
    CmsScheduleConfigComponent,
    PayerConfigComponent,
    BooleanSelectComponent,
    AddUpdatePayerDialogComponent,
    PayerAliasSelectComponent,
    PayerConfigEditRendererComponent,
    CMSTimelineComponent,
    AddUpdateCmsScheduleDialogComponent,
    RiskScoreRunTypeSelectComponent,
    MonthYearSelectComponent,
    DocumentedDxEventGraphComponent,
    AgNoRowsOverlayComponent,
    UserListActionIconRendererComponent,
    UserRoleSelectComponent,
    ResetPasswordDialogComponent,
    AgRowSelectedCountComponent,
    AgOpptyEditButtonComponent,
    AgHeaderCheckboxComponent,
    ReportingPageComponent,
    AllReportsComponent,
    FavoriteReportsComponent,
    ReportsGenerationComponent,
    DiagnosisEventsSummaryReportComponent,
    AgDxSummaryDetailRendererComponent,
    DiagnosisEventsSummaryTrendReportComponent,
    DiagnosisEventsSummaryTrendGraphsComponent,
    OrgListActionIconRendererComponent,
    TimezoneSelectComponent,
    AddUpdateOrgDialogComponent,
    AgDatePickerCellEditorComponent,
    DataRefreshErrorDetailsDialogComponent,
    SubmissionOpportunityTrendReportComponent,
    PayerValidationTrendReportComponent,
    SubmissionOpportunityTrendGraphsComponent,
    PayerValidationTrendGraphsComponent,
    AddUpdateRiskPolicyDialogComponent,
    HccPatientTrendGraphComponent,
    SubmissionOpptyUserProgressReportComponent,
    ProgressCellRendererComponent,
    SubmissionOpptyPayerProgressReportComponent,
    SubmissionOpptyPreferredActionProgressReportComponent,
    HccConfigComponent,
    AddUpdateHccValuationDialogComponent,
    CurrencyInputComponent,
    AgEditIconRendererComponent,
    RootCauseTrendReportComponent,
    OpptyHccValuationReportComponent,
    HccPatientDistributionReportComponent,
    AgSummationStatusPanelComponent,
    AgLabelStatusPanelComponent,
    ProgressAllocationSnapshotReportComponent,
    DateRangeComponent,
    ProgressAllocationSnapshotGraphsComponent,
    TreeMenuComponent,
    HccModelVersionConfigComponent,
    AddUpdateHccModelVersionDialogComponent,
    HccModelCategorySelectComponent,
    HccModelVersionSelectComponent,
    OrgAuditLogReportComponent,
    AdminAuditActionSelectComponent,
    OpptyHccValuationGraphsComponent,
    OpptyDataIngestionReportComponent,
    AverageHccPerPatientTrendReportComponent,
    AverageHccPerPatientTrendGraphsComponent,
    TemplateConfigComponent,
    AgIconRendererComponent,
    AddUpdateTemplateConfigDialogComponent,
    FilterSelectComponent,
    AgHeaderInfoIconRendererComponent,
    AgLoadingOverlayComponent,
    FilterFieldSearchSelectComponent
  ],
  imports: [
    BrowserModule,
    DragDropModule,
    AngularResizeEventModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDialogModule,
    RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' }),
    HammerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatFormFieldModule,
    NgxMatTimepickerModule,
    AgGridModule,
    NgxMatSelectSearchModule,
    AgChartsAngularModule
  ],
  exports: [
    DragDropModule,
  ],
  providers: [AuthService, APIService, AuthGuard, AutoLogoutService,
    {
      provide: ClientStorage,
      useClass: WebClientStorage
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
    {
      provide: ClientToastController,
      useClass: WebClientToast,
    },
    {
      provide: MatPaginatorIntl, 
      useClass: AgPaginatorIntl
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
