import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { FilterSelectItem } from '../../../models/filter-select-item';
import { MatSelect } from '@angular/material/select';
import { FieldFilterService } from '../../../services/filters/field-filter.service';
import { MatOption } from '@angular/material/core';
import { takeUntil } from 'rxjs/operators';
import { MatSelectSearchComponent } from 'ngx-mat-select-search';

@Component({
  selector: 'app-admin-audit-action-select',
  templateUrl: './admin-audit-action-select.component.html',
  styleUrls: ['./admin-audit-action-select.component.scss']
})
export class AdminAuditActionSelectComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() color = 'primary';
  @Input() appearance = 'outline';
  @Input() label = 'Action Performed';
  @Input() placeholder = '';
  @Input() floatLabel = 'always';
  @Input() multiple = true;
  @Input() controlName = 'audit-action';
  @Input() toolTipPosition = 'above';
  @Input() required = false;
  @Input() queryOnInit = false;
  @Input() disabled = false;
  @Input() defaultValue = null;

  options: FilterSelectItem[] = [];
  filteredOptions: ReplaySubject<FilterSelectItem[]> = new ReplaySubject<FilterSelectItem[]>(1);
  allOption = {ID: 0, Description: 'All', LinkAssociations: [], Selected: false};
  control: FormControl<FilterSelectItem[] | FilterSelectItem>;
  filterControl = new FormControl<string>('');

  allToggled = false;
  indeterminateToggle = false;

  subs: Subscription[] = [];

  protected _onDestroy = new Subject<void>();

  @Output() ready = new EventEmitter();
  @Output() valueChanged = new EventEmitter();

  @ViewChild(MatSelect) select: MatSelect;
  @ViewChild(MatSelectSearchComponent) searchFilter: MatSelectSearchComponent;

  constructor(private filterService: FieldFilterService) { }

  ngOnInit(): void {
    const validators = this.required ? [Validators.required] : [];
    this.control = this.multiple ? new FormControl<FilterSelectItem[]>(this.defaultValue, validators) : new FormControl<FilterSelectItem>(this.defaultValue, validators);
    this.form.addControl(this.controlName, this.control);

    this.filterControl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterMulti();

      if(this.multiple) {
        this.setAllToggleState();
      }
    });

    if(this.multiple) {
      this.control.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.setAllToggleState();
      });
    }

    if(this.disabled) {
      this.control.disable();
    }

    if(this.queryOnInit) {
      this.getOptionData();
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getOptionData() {
    this.subs.push(this.filterService.getActiveAdminAuditActions().subscribe(result => {
      this.options = result;
      this.filteredOptions.next(this.options);
      this.ready.emit(this.options);
    }));
  }

  setSelectedValues(values: FilterSelectItem[]) {

    if(!values) {
      this.control?.reset();
      this.control?.setValue([]);
      this.searchFilter._reset();
      return;
    }

    if(values.length == 0) {
      this.allOptionClicked(true);
      return;
    }

    values.forEach(o => {
      const found = this.select.options.find((i, index) => {
        if(index == 0) {
          return;
        }

        return i.value.ID == o.ID;
      });

      if(found) {
        found.select();
      }
    });
  }

  getValues() {
    const values = this.control?.value as FilterSelectItem[] || [];

    if(this.allToggled && this.multiple) {
      return [{ID: 0}];
    }
    
    return this.control?.value ? values.map(i => i) : [];
  }

  allOptionClicked(isChecked: boolean) {
    this.select.options.forEach((o, index) => {
      if(index == 0) {
        return;
      }

      isChecked ? o.select() : o.deselect();
    });

    this.options.forEach(o => {
      o.Selected = isChecked;
    });

  }

  toggleSelection(option: MatOption, item: FilterSelectItem) {
    item.Selected = option.selected;
  }

  checkAllOptions() {
    let result = true;
    this.options.forEach(o => {
      if(!o.Selected) {
        result = false;
      }
    });

    return result;
  }

  onSelectionChange() {
    const values = this.control?.value as FilterSelectItem[];
    const options = this.allOption.Selected ? values.slice(1) : values;
    this.valueChanged.emit(options);
  }

  getTooltipString() {
    if(!this.multiple) {
      return '';
    }

    let result = '';

    const values = this.control?.value as FilterSelectItem[];
    const options = this.allOption.Selected ? values.slice(1) : values;

    options?.forEach((o, index) => {
      result += o.Description;

      if(index < options.length - 1) {
        result += ', ';
      }
    })
    return result;
  }

  getSelectCount() {
    const values = this.control.value as FilterSelectItem[];

    return (values?.length <= this.options.length) 
      ? values?.length 
      : values?.length - 1;
  }

  filterMulti() {
    if (!this.options) {
      return;
    }

    let search = this.filterControl?.value;

    if (!search) {
      this.filteredOptions.next(this.options.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredOptions.next(
      this.options.filter(o => (o.Description.toLowerCase()).indexOf(search.toLowerCase()) > -1)
    );
  }

  setAllToggleState() {
    let filteredLength = 0;
    const value = this.control?.value as FilterSelectItem[] || [];

    if(value.length == 0) {
      return;
    }

    this.options.forEach(o => {
      if(value.indexOf(o) > -1) {
        filteredLength++;
      }
    });

    this.indeterminateToggle = filteredLength > 0 && filteredLength < this.options.length;
    this.allToggled = filteredLength > 0 && filteredLength === this.options.length;
  }

}
