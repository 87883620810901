import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() color = 'primary';
  @Input() appearance = 'outline';
  @Input() floatLabel = 'always';
  @Input() controlName = 'date-range';
  @Input() required = false;

  isDrToggled = false;

  rangeGroup: FormGroup;
  startControl = new FormControl<Date | null>(null);
  endControl = new FormControl<Date | null>(null);

  @Output() ready = new EventEmitter();
  @Output() valueChanged = new EventEmitter();

  constructor(private fb: FormBuilder) { 
    this.rangeGroup = this.fb.group({
      startDate: this.startControl,
      endDate: this.endControl
    });

    if(this.required) {
      this.rangeGroup.setValidators([Validators.required]);
    }
  }

  ngOnInit(): void {
    if(this.form) {
      this.form.addControl(this.controlName, this.rangeGroup);
    }

    this.ready.emit();
  }

  onSelectionChange() {
    this.valueChanged.emit(this.rangeGroup?.value);
  }

}
