import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, ColGroupDef, GetRowIdFunc, GetRowIdParams, IServerSideDatasource, StatusPanelDef } from 'ag-grid-enterprise';
import { Subscription } from 'rxjs';
import { AgNoRowsOverlayComponent } from '../../../../ag-grid-components/ag-no-rows-overlay/ag-no-rows-overlay.component';
import { AgPaginatorComponent, IAgPaginator } from '../../../../ag-grid-components/ag-paginator/ag-paginator.component';
import { GridQuery } from '../../../../../models/grid-query';
import { QueryMetadata, QuerySort } from '../../../../../models/query-metadata';
import { ProgressAllocationService } from '../../../../../services/progress/progress-allocation.service';
import { SettingsKeys, SettingsService } from '../../../../../services/settings/settings.service';
import { AgGridFunctions } from '../../../../../helpers/ag-grid-functions';
import { ProgressCellRendererComponent } from '../../../../ag-grid-components/progress-cell-renderer/progress-cell-renderer.component';
import { DxsrExcelStyling } from '../dxsr-excel-styling';
import { AgLabelStatusPanelComponent } from '../../../../ag-grid-components/ag-label-status-panel/ag-label-status-panel.component';
import { AgSummationStatusPanelComponent, IAgSummationPanel } from '../../../../ag-grid-components/ag-summation-status-panel/ag-summation-status-panel.component';
import { ProgressAllocationPayerItem } from '../../../../../models/progress-allocation-payer-item';
import { AuditAction } from '../../../../../models/audit-action';
import { LoggingService } from '../../../../../services/api/logging.service';
import { AgLoadingOverlayComponent } from '../../../../ag-grid-components/ag-loading-overlay/ag-loading-overlay.component';

@Component({
  selector: 'app-submission-oppty-payer-progress-report',
  templateUrl: './submission-oppty-payer-progress-report.component.html',
  styleUrls: ['./submission-oppty-payer-progress-report.component.scss']
})
export class SubmissionOpptyPayerProgressReportComponent implements OnInit {

  isGridReady = false;

  formValid = false;
  isFirstGenerate = true;
  currentListSize = 0;

  statusBar: {
    statusPanels: StatusPanelDef[];
  } = {
    statusPanels: [
      {
        statusPanel: AgLabelStatusPanelComponent,
        key: 'lable-panel',
        statusPanelParams: {
          label: 'Totals',
        },
        align: 'left'
      },
      {
        statusPanel: AgSummationStatusPanelComponent,
        key: 'closed-panel',
        statusPanelParams: {
          label: '',
          col: 'Closed',
          width: 150
        }
      },
      {
        statusPanel: AgSummationStatusPanelComponent,
        key: 'open-panel',
        statusPanelParams: {
          label: '',
          col: 'Open',
          width: 150
        }
      },
      {
        statusPanel: AgSummationStatusPanelComponent,
        key: 'total-panel',
        statusPanelParams: {
          label: '',
          col: 'Total',
          width: 150
        }
      },
    ]
  };

  frameworkComps = {
    progress: ProgressCellRendererComponent
  }

  noRowsOverlayComponent: any = AgNoRowsOverlayComponent;
  noRowsOverlayComponentParams: any = {
    noRowsMessageFunc: () => 'No results returned',
  };

  loadingOverlayComponent: any = AgLoadingOverlayComponent;

  columnDefs: ColDef[] = [];

  defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    menuTabs: [],
    wrapHeaderText: true,
    autoHeaderHeight: true
  };

  excelStyles = DxsrExcelStyling.excelStyles2;

  gridContext: any;
  query: GridQuery = new GridQuery();

  data: ProgressAllocationPayerItem[] = [];

  currentRowHeight = 140;
  currentGridWidth = 282;

  subs: Subscription[] = [];

  @Output() ready: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() setExportEnable: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.agGrid.api.sizeColumnsToFit();
    const cellWidth = this.agGrid.api.getColumn('Closed').getActualWidth();
    this.updateTotalPanelWidths(cellWidth);
  }

  constructor(
    private allocationService: ProgressAllocationService,
    private settingsService: SettingsService,
    private loggingService: LoggingService
  ) { 
    this.gridContext = { componentParent: this };
    this.query.Metadata = new QueryMetadata();
  }

  ngOnInit(): void {
    //TODO
  }

  onGridReady() {

    this.isGridReady = true;

    this.agGrid.api.sizeColumnsToFit();

    // this.subs.push(this.settingsService.getPaginatorPageSize(SettingsKeys.OPPTY_SUBMISSION_PAYER_ALLOCATION_PAGE_SIZE).subscribe(result => {
    //   if(result == 0) {
    //     return;
    //   }

    //   this.agGrid.api.getStatusPanel<IAgPaginator>('ag-paginator').setPageSize(result);
    // }));

    this.ready.emit(true);
  }

  //#region AG Grid Functions

  getColDef(): (ColDef | ColGroupDef)[] {

    const result: any = [
      {
        headerName: `Service Year ${this.query.ServiceYear}`,
        children: [
          { field: 'Payer', headerName: 'Payer', valueFormatter: AgGridFunctions.payerValue, tooltipValueGetter: AgGridFunctions.payerTooltip},
          { field: 'Progress', headerName: 'Progress', type: 'numericColumn', valueFormatter: (params) => AgGridFunctions.getPercentValue(params, 1), cellClass: 'numberType', maxWidth: 150},
          { field: 'Closed', headerName: 'Closed', type: 'numericColumn', valueFormatter: AgGridFunctions.getNumberValue, cellClass: 'numberType', maxWidth: 150},
          { field: 'Open', headerName: 'Open', type: 'numericColumn', valueFormatter: AgGridFunctions.getNumberValue, cellClass: 'numberType', maxWidth: 150},
          { field: 'Total', headerName: 'Total', type: 'numericColumn', valueFormatter: AgGridFunctions.getNumberValue, cellClass: 'numberType', maxWidth: 150},
        ]
      }
    ];

    return result;
  }

  getServerSideDatasource(): IServerSideDatasource {
    return {
      getRows: (params) => {
        this.query.Metadata.PageSize = params.api.paginationGetPageSize();
        this.query.Metadata.PageNumber = params.api.paginationGetCurrentPage();

        this.allocationService.getPayerAllocationByQuery(this.query).subscribe(result => {
          this.currentListSize = result.ListSize;

          params.success({
            rowData: result.Items,
            rowCount: result.ListSize,
          });

          if(this.currentListSize == 0) {
            params.api.showNoRowsOverlay();
          }

          params.api.sizeColumnsToFit();
          this.setExportEnable.emit(true);
        },
        error => {
          params.fail();
        });

      },
    };
  }

  getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.Payer.ID;
  };

  onGenerateGridData(serviceYear: number) {
    
    this.query.ServiceYear = serviceYear;

    this.query.Metadata.PageSize = 0;
    this.query.Metadata.PageNumber = 0;

    this.allocationService.getPayerAllocationByQuery(this.query).subscribe(result => {
      this.currentListSize = result.ListSize;
      const items = result.Items || [];
      const height = this.currentListSize == 0 ? 300 : 107 + (result.Items.length * 28);
      const width = 552;

      this.agGrid.api.setGridOption('columnDefs', this.getColDef());

      this.updateGrid(items, width, height);

      this.setExportEnable.emit(true);
    });
  }

  updateGrid(data: ProgressAllocationPayerItem[], width: number, height: number) {
    this.data = data;

    this.currentRowHeight = height;
    this.currentGridWidth = width;
    this.agGrid.api.setGridOption('rowData', data);

    const cellWidth = this.agGrid.api.getColumn('Closed').getActualWidth();
    this.updateTotalPanelWidths(cellWidth);

    this.updateTotals();

    setTimeout(() => {this.agGrid.api.sizeColumnsToFit();}, 200);
  }

  updateTotals() {
    this.agGrid.api.getStatusPanel<IAgSummationPanel>('closed-panel').updateSum();
    this.agGrid.api.getStatusPanel<IAgSummationPanel>('open-panel').updateSum();
    this.agGrid.api.getStatusPanel<IAgSummationPanel>('total-panel').updateSum();
  }

  updateTotalPanelWidths(width: number) {
    this.agGrid.api.getStatusPanel<IAgSummationPanel>('closed-panel').updatePanelWidth(width);
    this.agGrid.api.getStatusPanel<IAgSummationPanel>('open-panel').updatePanelWidth(width);
    this.agGrid.api.getStatusPanel<IAgSummationPanel>('total-panel').updatePanelWidth(width);
  }

  onExport() {
    const filename =`Payer_Progress_Export_${this.query.ServiceYear}`;
    const total = this.agGrid.api.getStatusPanel<IAgSummationPanel>('total-panel').getSum();
    const openTotal = this.agGrid.api.getStatusPanel<IAgSummationPanel>('open-panel').getSum();
    const closedTotal = this.agGrid.api.getStatusPanel<IAgSummationPanel>('closed-panel').getSum();

    this.agGrid.api.exportDataAsExcel({
      fileName: filename,
      processCellCallback(params) {
        if(params.column.getColId() == 'Payer') {
          return AgGridFunctions.payerValue(params);
        }

        if(params.column.getColId() == 'Progress') {
          return params.value * 100;
        }

        return params.value;
      },
      processHeaderCallback(params) {
        if(params.column.getColId() == 'Progress') {
          return 'Progress(%)';
        }

        return params.column.getColDef().headerName;
      },
      appendContent: [
        {
          cells: [
            {
              data: {
                value: 'Totals',
                type: 'String'
              },
              styleId: 'status-label'
            },
            {
              data: {
                value: '',
                type: 'String'
              }
            },
            {
              data: {
                value: closedTotal.toString(),
                type: 'Number'
              },
              styleId: 'summation'
            },
            {
              data: {
                value: openTotal.toString(),
                type: 'Number'
              },
              styleId: 'summation'
            },
            {
              data: {
                value: total.toString(),
                type: 'Number'
              },
              styleId: 'summation'
            }
          ]
        }
      ]
    });

    const reportJSON = JSON.stringify({ReportName: 'Payer Progress Export', Filename: filename});
    this.loggingService.logAudit(AuditAction.ReportExported, `Report Name: Payer Progress Export, Filename: ${filename}`, reportJSON).subscribe(() => {
      //TODO
    });
  }

  onPaginatorPageSizeChange(pageSize: number) {
    this.settingsService.updatePaginatorPageSize(pageSize, SettingsKeys.OPPTY_SUBMISSION_PAYER_ALLOCATION_PAGE_SIZE).subscribe(() => {
      //TODO
    });
  }
}
