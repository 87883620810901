import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { random } from 'lodash';
import { Subscription } from 'rxjs';
import { ProgressAllocationUserListItem } from '../../../models/progress-allocation-user-list-item';
import { User } from '../../../models/user.model';
import { UserService } from '../../../services/user/user.service';
import { ProgressAllocationService } from '../../../services/progress/progress-allocation.service';
import { SimpleUserOption } from '../../../models/simple-user-option';
import { SettingsService } from '../../../services/settings/settings.service';
import { UserAllocationTotal } from '../../../models/user-allocation-total';
import { PermissionMap, PermissionService } from '../../../services/settings/permission.service';
import { Permission } from '../../../models/permission';

@Component({
  selector: 'app-progress-allocation-user',
  templateUrl: './progress-allocation-user.component.html',
  styleUrls: ['./progress-allocation-user.component.scss']
})
export class ProgressAllocationUserComponent implements OnInit, OnDestroy {

  @Input() expanded = false;
  @Input() iconDiameter = 48;

  serviceYear = 0;
  currentUser: User;
  userAllocationList: ProgressAllocationUserListItem[] = [];
  total: UserAllocationTotal = new UserAllocationTotal();
  userPermissions: Permission[] = [];

  closedTotal = 0;
  openTotal = 0;
  grandTotal = 0;

  subs: Subscription[] = [];

  constructor(
    private allocationService: ProgressAllocationService,
    private settingsService: SettingsService,
    private permissionService: PermissionService,
    private userService: UserService) {
    //TODO
  }

  ngOnInit(): void {
    this.subs.push(this.userService.currentUser$.subscribe(result => {
      this.currentUser = result;
    }));

    this.subs.push(this.permissionService.activeUserPermissions.subscribe(result => {
      this.userPermissions = result;

      this.subs.push(this.allocationService.userAllocation.subscribe(result => {
        if(!result) {
          return;
        }

        this.userAllocationList = [];
  
        const viewEditPerm = this.userPermissions.find(p => p.PermissionID == PermissionMap.ViewAndEditAllDxEvents);
        const acceptsAssignmentPerm = this.userPermissions.find(p => p.PermissionID == PermissionMap.AcceptsOpportunityAssignment);
        const assignPerm = this.userPermissions.find(p => p.PermissionID == PermissionMap.AssignOpportunities);
  
        if(viewEditPerm || assignPerm) {
  
          this.userAllocationList = result;
  
        } else if(acceptsAssignmentPerm) {
  
          const userFound = result.find(u => u.User.UserID == parseInt(this.currentUser.uid));
  
          if(userFound)
            this.userAllocationList.push(userFound);
          
        }

        this.getTotals();
      }));
    }));

    this.subs.push(this.settingsService.serviceYear.subscribe(result => {
      if(!result) {
        return;
      }

      this.serviceYear = result;
      this.updateData();
    }));

    // this.subs.push(this.allocationService.userAllocationTotals.subscribe(result => {
    //   if(!result) {
    //     return;
    //   }

    //   this.total = result;
    // }));
  }

  ngOnDestroy(): void {
    this.userAllocationList = [];
    this.subs.forEach(s => s.unsubscribe());
  }

  updateData() {
    //this.allocationService.getAllocationTotals(this.serviceYear);
    this.allocationService.getUserAllocation(this.serviceYear);
  }

  getUserName(item: SimpleUserOption) {
    if(!item) {
      return '';
    }

    return item.FirstName + ' ' + item.LastName;
  }

  getUserInitials(user: SimpleUserOption) {
    if(!user) {
      return "";
    }

    return user.FirstName[0] + user.LastName[0];
  }

  getTotals() {
    this.closedTotal = 0;
    this.openTotal = 0;
    this.grandTotal = 0;

    this.userAllocationList.forEach(i => {
      this.closedTotal = this.closedTotal += i.Closed;
      this.openTotal = this.openTotal += i.Open;
      this.grandTotal = this.grandTotal += i.Assigned;
    });
  }

}
