<h1 mat-dialog-title *ngIf="data.parent == 'oppty-list'">Edit Opportunity</h1>
<h1 mat-dialog-title *ngIf="data.parent == 'dx-list'">Edit Diagnosis Event</h1>

<div mat-dialog-content>
    <div class="content-container flex flex-col">

        <div class="grid grid-cols-2 gap-3">
            <div class="batch-limit-warning flex flex-auto justify-center items-center content-center col-start-1 col-span-2" *ngIf="data.items.length > batchLimit">
                <label>
                    Batch edit is limted to <strong>{{batchLimit}}</strong> items. There are too many items selected.
                </label>
            </div>
    
            <div class="batch-mode-stats flex flex-auto flex-col col-start-1 col-span-2 justify-start items-start content-start" *ngIf="data.mode == 'batch'">
                <label>Edit <strong *ngIf="!data.allEnabled">{{data.items.length}}</strong> selected Opportunities</label>
                <label *ngIf="!data.allEnabled" style="padding: 0 0 0 15px">Current Status Count: {{batchStatusStats}}</label>
                <label *ngIf="!data.allEnabled" style="padding: 0 0 0 15px">Current Preferred Action Count: {{batchPreferredActionStats}}</label>
            </div>
    
            <div class="flex flex-auto col-start-1" *ngIf="data.allEnabled">
                <mat-radio-group [formControl]="batchTypeControl" class="flex flex-auto flex-col gap-[5px]">
                    <mat-radio-button [value]="'default'"><strong>{{data.items.length | number}}</strong> records from the page</mat-radio-button>
                    <mat-radio-button [value]="'all'"><strong>{{data.queryResultCount | number}}</strong> records from results</mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="col-start-1">
                <h3>Status *</h3>
            </div>

            <div class="col-start-2">
                <app-status-select
                #editStatusSelect
                [form]="editDialogForm"
                [label]="''"
                [placeholder]="'Select Status'"
                [multiple]="false"
                [required]="true"
                (ready)="onStatusReady($event)"
                (valueChanged)="onStatusChanged($event)"></app-status-select>
            </div>

            <div class="col-start-1">
                <h3>Root Cause</h3>
            </div>

            <div class="col-start-2">
                <app-root-cause-select
                #editRootCauseSelect
                [form]="editDialogForm"
                [label]="''"
                [placeholder]="'Select Root Cause'"
                [multiple]="false"
                [clearEnabled]="true"
                (ready)="onRootCauseReady()"
                (valueChanged)="onRootCauseChanged($event)"></app-root-cause-select>
            </div>

            <div class="col-start-1" *ngIf="showRcReason">
                <h3>Root Cause Reason*</h3>
            </div>

            <div class="col-start-2" *ngIf="showRcReason">
                <mat-form-field appearance="outline" color="primary">
                    <input matInput 
                    [formControl]="rcReasonControl"
                    [placeholder]="'Type reason here...'">
                </mat-form-field>
            </div>

            <div class="col-start-1 col-span-2">
                <mat-form-field appearance="outline" color="primary">
                    <mat-label>Notes</mat-label>
                    <textarea matInput [formControl]="notesControl" [rows]="3"></textarea>
                </mat-form-field>
            </div>

            <div class="col-start-1 col-span-2">
                <div class="note-history-container">
                    <div class="note-history-item" *ngFor="let note of notesHistory">
                        <p><span class="note-history-user">{{note.User.FirstName[0] + note.User.LastName[0]}} ({{note.Timestamp | date: 'MM/dd/yy'}})</span>
                            {{note.Message}}
                        </p>
                    </div>
                </div>
            </div>
    
            <div class="status-change-warning col-start-1 col-span-2 flex flex-auto justify-center items-center content-center" *ngIf="showStatusWarning">
                <label>
                    Status update will result in changing the opportunity from a <strong>CLOSED</strong> state to an <strong>OPEN</strong> state. This action may impact historical reporting.
                </label>
            </div>
        </div>

    </div>
</div>

<mat-dialog-actions align="end">

    <button mat-flat-button class="secondary-button" mat-dialog-close>Cancel</button>

    <button mat-flat-button 
    class="primary-button" 
    [disabled]="!formReady || editDialogForm.invalid || data.items.length > batchLimit"
    (click)="onSaveClick()">Save</button>

    <button 
    mat-flat-button 
    class="primary-button" 
    [disabled]="!formReady || editDialogForm.invalid || data.items.length > batchLimit"
    *ngIf="data.exportEnabled && batchTypeControl?.value == 'all'"
    (click)="onSaveClick(true)">Save & Export</button>

</mat-dialog-actions>
