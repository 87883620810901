<mat-form-field color="primary" [appearance]="'outline'" floatLabel="always">
    <mat-label *ngIf="label">{{label}}</mat-label>
    <mat-select 
    [formControl]="control"
    [placeholder]="placeholder"
    [required]="required"
    (selectionChange)="onSelectionChange()">

        <mat-option *ngIf="noneEnabled"
        [value]="-1">All</mat-option>

        <mat-option *ngFor="let option of options"
        [value]="option">
            {{option}}
        </mat-option>

    </mat-select>
    <mat-error *ngIf="control.hasError('required')">{{label}} is <strong>required</strong></mat-error>
    <mat-error *ngIf="control.hasError('markedDuplicate')">Make a different selection</mat-error>
</mat-form-field>
