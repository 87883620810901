<div class="side-menu-page-container">

    <div class="side-menu-page-menu-container flex-auto flex-col">
        <div class="side-menu-page-menu-header flex basis-[5%] flex-row">
            <div class="side-menu-page-menu-title flex justify-start items-center content-center">
                <h2>Categories</h2>
            </div>
        </div>

        <mat-selection-list [multiple]="false">
            <mat-list-option *ngFor="let option of menuOptions" [value]="option" [selected]="selectedOption.ID == option.ID" (click)="onSelectedConfigOption(option)">
                <div class="flex flex-row">
                    <div class="flex basis-[15%] justify-start items-center content-center" *ngIf="option.Icon">
                        <mat-icon *ngIf="!option.IconOutlined">{{option.Icon}}</mat-icon>
                        <mat-icon fontSet="material-icons-outlined" *ngIf="option.IconOutlined">{{option.Icon}}</mat-icon>
                    </div>

                    <div class="flex-auto justify-start items-center content-center pr-[5px]">
                        {{option.Name}}
                    </div>
                </div>
            </mat-list-option>
        </mat-selection-list>
    </div>

    <!--Report Lists-->
    <app-all-reports class="side-menu-page-content-container flex flex-col"
    [reportMenuOption]="selectedOption"
    (reportClicked)="onReportClicked($event)"
    *ngIf="!selectedReport"></app-all-reports>

    <!--Report Generator-->
    <app-reports-generation class="side-menu-page-content-container flex flex-col" [reportName]="selectedReport" *ngIf="selectedReport"></app-reports-generation>

</div>
