import { Injectable } from '@angular/core';

@Injectable({
    providedIn: "root"
})
export abstract class ClientStorage {
    abstract getItem(key: string): Promise<string>;
    abstract setItem(key: string, value: string): Promise<void>;
    abstract removeItem(key: string): Promise<void>;
    abstract clear(): Promise<void>;
}