import { User } from '../../../models/users';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PasswordValidation } from '../users/password-validation';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/authentication/auth.service';

@Component({
  selector: 'app-web-forgot-password',
  templateUrl: './web-forgot-password.component.html',
  styleUrls: ['./web-forgot-password.component.css']
})
export class WebForgotPasswordComponent implements OnInit {
  form: any;

  password = new UntypedFormControl('', [Validators.required]);
  confirmPassword = new UntypedFormControl('', [Validators.required]);
  constructor(private snackBar: MatSnackBar, private auth: AuthService, private router: Router) { }

  ngOnInit(): void {

    document.getElementById('securityprologo').style.display = 'flex';

  }
  getPasswordErrorMessage() {
    if (this.password.hasError('required')) {
      return 'You must enter a value';
    }

    if (this.password.value.length < 7) {
      return 'Must be at least 7 characters'
    }

  }

  getconfirmPasswordErrorMessage() {
    return this.confirmPassword.hasError('required') ? 'You must enter a value' :
      this.confirmPassword.hasError('MatchPassword') ? 'Passwords must match' :
        '';
  }

  goToLogin(): void {
    this.router.navigate(["/login"]);
  }

  resetPassword(): void {
    if (this.password.value !== this.confirmPassword.value) {
      this.snackBar.open('Passwords must match!', '', { duration: 3000 });
      return;
    }

    const newPW = this.password.value;
    const key = this.router.routerState.snapshot.root.queryParams["key"];

    this.auth.submitNewPassword(key, newPW)
      // tslint:disable-next-line:max-line-length
      .subscribe(data => {
        this.snackBar.open('Password Changed! ', '', { duration: 8000 });

      }
        , error => this.snackBar.open('Password change request failed!  Please try again!' + error, '', { duration: 6000 })
      );


  }

}
