<h1 mat-dialog-title *ngIf="actionType == 'add'">Add Payer</h1>
<h1 mat-dialog-title *ngIf="actionType == 'edit'">Edit Payer</h1>

<div mat-dialog-content>
    <div class="content-container flex flex-col">
        <div class="grid grid-cols-[1fr_1.3fr_1fr_1fr] gap-3">
            <div class="col-start-1">
                <h3>Payer Full Name *</h3>
            </div>

            <div class="col-start-2">
                <mat-form-field color="primary" appearance="outline">
                    <input matInput placeholder="Enter Payer Full Name" [formControl]="nameControl" [required]="true">
                    <mat-error *ngIf="nameControl.hasError('required')">Name is <strong>required</strong></mat-error>
                </mat-form-field>
            </div>

            <div class="col-start-1 row-start-2">
                <h3>Abbreviation *</h3>
            </div>

            <div class="col-start-2 row-start-2">
                <mat-form-field color="primary" appearance="outline">
                    <input matInput placeholder="Enter Abbreviation" [formControl]="abbreviationControl" [required]="true">
                    <mat-error *ngIf="abbreviationControl.hasError('required')">Abbreviation is <strong>required</strong></mat-error>
                </mat-form-field>
            </div>

            <div class="col-start-1 row-start-3">
                <h3>Payer Aliases *</h3>
            </div>

            <div class="col-start-2 row-start-3">
                <app-payer-alias-select
                [form]="payerForm"
                [label]="''"
                [showDisabledOptions]="actionType == 'edit'"
                [currentPayerID]="payerItem ? payerItem.PayerID : 0"
                [queryOptions]="true"
                [placeholder]="'Make a Selection'"
                [required]="true"
                (ready)="isPayerAliasReady = true; filterReadyCheck();"></app-payer-alias-select>
            </div>
        </div>

        <mat-divider class="divider-row"></mat-divider>

        <div class="flex basis-[5%] flex-row">
            <div class="flex basis-[40%] justify-start items-center content-center">
                <h3>Risk Adjustment Policy</h3>
            </div>

            <div class="flex flex-auto justify-end items-center content-center">
                <button 
                mat-flat-button 
                class="primary-button-longtext" 
                (click)="onAddPolicyClick()">
                Add Service Year Entry
            </button>
            </div>
        </div>

        <div class="flex flex-auto">
            <ag-grid-angular
            class="ag-theme-balham ag-grid-container w-full h-[30dvh]"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [pagination]="true"
            [tooltipShowDelay]="0"
            [noRowsOverlayComponent]="noRowsOverlayComponent"
            [noRowsOverlayComponentParams]="noRowsOverlayComponentParams"
            [rowClassRules]="rowClassRules"
            [context]="gridContext"
            [components]="frameworkComps"
            [suppressCopyRowsToClipboard]="true"
            [suppressPaginationPanel]="true"
            [getRowId]="getRowId"
            [loadingOverlayComponent]="loadingOverlayComponent"
            (gridReady)="onGridReady($event)"></ag-grid-angular>
        </div>
        
    </div>
</div>

<mat-dialog-actions align="end">
    <label class="policy-error-label" *ngIf="invalidServiceYear">Service Year range conflicts with existing entries.</label>
    <label class="policy-error-label" *ngIf="emptyPolicyFields">Required fields are empty.</label>
    <button mat-flat-button class="secondary-button" mat-dialog-close>Cancel</button>
    <button 
        *ngIf="actionType == 'edit'" 
        mat-flat-button 
        class="primary-button" 
        [disabled]="payerForm.invalid || invalidPolicyRows" 
        (click)="onSaveClick()">
        Save
    </button>

    <button 
        *ngIf="actionType == 'add'" 
        mat-flat-button 
        class="primary-button" 
        [disabled]="payerForm.invalid || invalidPolicyRows"
        (click)="onSaveClick()">
        Add
    </button>
</mat-dialog-actions>
