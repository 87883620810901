import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserLib } from '../libs/user-lib.service';
import { User } from '../../models/user.model';
import { AuthService } from '../authentication/auth.service';
import { HccSummaryConfigItem } from '../../models/hcc-summary-config-item';
import { PermissionMap } from '../settings/permission.service';


@Injectable({
    providedIn: 'root'
})
export class UserService {


    currentUser$: ReplaySubject<User> = new ReplaySubject<User>(1);
    userIsReadOnly$: Observable<boolean> = new BehaviorSubject<boolean>(true);
    users$: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);
    insightsConfig$: ReplaySubject<HccSummaryConfigItem[]> = new ReplaySubject<HccSummaryConfigItem[]>(1);
    usersByPermission: Map<number, ReplaySubject<User[]>> = new Map<number, ReplaySubject<User[]>>();

    constructor(
        private sharedUserService: UserLib,
        public auth: AuthService,
    ) {
        this.auth.user$.subscribe((user) => {
            if (user != null) {
                this.getCurrentUser();
            }
        });

        this.userIsReadOnly$ = this.currentUser$.pipe(map((user) => {
            if (user != null) {
                return user.app_metadata.role == 'readonlyuser'
            }
        }));

        this.usersByPermission.set(PermissionMap.AcceptsOpportunityAssignment, new ReplaySubject<User[]>(1));
        this.usersByPermission.get(PermissionMap.AcceptsOpportunityAssignment).next(null);
    }

    async getCurrentUser() {
        this.sharedUserService.getCurrentUser()
            .subscribe(user => {
                if (user != null) {

                    this.currentUser$.next(user);
                }

            }, (error) => {
                console.log(error);
            });
    }

    async getUsers() {
        this.sharedUserService.getUsers()
            .subscribe(users => {
                this.users$.next(users);

            }, (error) => {
                console.log(error);
            });
    }

    async getUserList() {
        this.sharedUserService.getUserList()
            .subscribe(users => {
                this.users$.next(users);

            }, (error) => {
                console.log(error);
            });
    }

    async getUserListByPermission(permissionID: number) {
        this.sharedUserService.getUserListByPermission(permissionID)
            .subscribe(users => {
                this.usersByPermission.get(permissionID).next(users);

            }, (error) => {
                console.log(error);
            });
    }

    async getInsightsConfig() {
        this.sharedUserService.getInsightsConfig().subscribe(result => {
            this.insightsConfig$.next(result);
        },
        error => {
            console.log(error);
        });
    }

    async updateHccSummaryConfig(configItems: HccSummaryConfigItem[]) {
        this.sharedUserService.updateInsightsConfig(configItems).subscribe(result => {
            //TODO
        },
        error => {
            console.log(error);
        })
    }

}
