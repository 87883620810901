import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { User } from '../../../models/users';

@Component({
  selector: 'app-user-list-action-icon-renderer',
  templateUrl: './user-list-action-icon-renderer.component.html',
  styleUrls: ['./user-list-action-icon-renderer.component.scss']
})
export class UserListActionIconRendererComponent implements ICellRendererAngularComp {

  params: ICellRendererParams<any, any>;
  item: User;
  currentUser: User = new User();

  constructor() {
    //TODO
  }

  agInit(params: ICellRendererParams<any, any>): void {
    this.params = params;
    this.item = params.data;
    this.currentUser = this.params.context.componentParent.currentUser;
  }
  
  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }

  onItemClick(action) {
    this.params.context.componentParent.onActionItemClick({item: this.params.data, action: action});
  }

}
