import { QueryDateRange } from "./diagnosis-event-query";
import { QueryMetadata } from "./query-metadata";

export class CmsScheduleQuery {
    RiskScoreRunYears: string;
    RiskScoreRunTypes: string;
    DatesOfServiceStart: QueryDateRange;
    DatesOfServiceEnd: QueryDateRange;
    DeadlineForSubmission: QueryDateRange;
    AnticipatedPaymentMonth: QueryDateRange;
    Metadata: QueryMetadata;
}
