import { Subscription } from 'rxjs';
import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import { SchedulesService } from '../../../services/settings/schedules.service';
import { CmsScheduleItem } from '../../../models/cms-schedule-item';

@Component({
  selector: 'app-cms-timeline',
  templateUrl: './cms-timeline.component.html',
  styleUrls: ['./cms-timeline.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'timeline',
  },
})
export class CMSTimelineComponent implements OnInit, OnDestroy {

  @Input() queryData = false;
  @Input() simpleDisplay = false;
  @Input() serviceYear = -1;

  currentDate: Date = new Date();

  node1: CmsScheduleItem = new CmsScheduleItem();
  node2: CmsScheduleItem = new CmsScheduleItem();
  node3: CmsScheduleItem = new CmsScheduleItem();
  node4: CmsScheduleItem = new CmsScheduleItem();

  blankNode: CmsScheduleItem = new CmsScheduleItem();

  bar1Value = 0;
  bar2Value = 0;
  bar3Value = 0;

  caseSub: Subscription;
  progressOnTimeLine: number;

  cmsItems: CmsScheduleItem[] = [];

  subs: Subscription[] = [];

  constructor(private schedulesService: SchedulesService) {}

  ngOnInit(): void {

    this.subs.push(this.schedulesService.cmsTimelineItems.subscribe(result => {
      this.cmsItems = result;
      this.node1 = this.blankNode;
      this.node2 = this.blankNode;
      this.node3 = this.blankNode;
      this.node4 = this.blankNode;
      this.bar1Value = 0;
      this.bar2Value = 0;
      this.bar3Value = 0;

      this.cmsItems.forEach((item, index) => {
        if(index == 0) {
          this.node1 = item;
        }

        if(index == 1) {
          this.node2 = item;
        }

        if(index == 2) {
          this.node3 = item;
        }

        if(index == 3) {
          this.node4 = item;
        }

        this.setBarValues();
      })
    }));

    if(this.queryData) {
      this.getData(this.serviceYear);
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  getData(serviceYear: number) {
    this.serviceYear = serviceYear;
    this.schedulesService.getCMSTimelineItems(serviceYear);
  }

  setBarValues() {
    const node1Date = this.node1 ? new Date(this.node1.DeadlineForSubmission) : null;
    const node2Date = this.node2 ? new Date(this.node2.DeadlineForSubmission) : null;
    const node3Date = this.node3 ? new Date(this.node3.DeadlineForSubmission) : null;
    const node4Date = this.node4 ? new Date(this.node4.DeadlineForSubmission) : null;

    if(!node1Date) {
      return;
    }

    if(node1Date > this.currentDate) {
      return;
    }

    if(node2Date < this.currentDate) {
      this.bar1Value = 100;
    }

    if(node2Date > this.currentDate) {
      const bar1TotalDays = this.calculateDaysBetween(node1Date, node2Date);
      const bar1ToCurrentDays = this.calculateDaysBetween(this.currentDate, node2Date);
      this.bar1Value = (bar1TotalDays - bar1ToCurrentDays) / bar1TotalDays * 100;
    }

    if(node3Date < this.currentDate) {
      this.bar2Value = 100;
    }

    if(node3Date > this.currentDate && this.bar1Value == 100) {
      const bar2TotalDays = this.calculateDaysBetween(node2Date, node3Date);
      const bar2ToCurrentDays = this.calculateDaysBetween(this.currentDate, node3Date);
      this.bar2Value = (bar2TotalDays - bar2ToCurrentDays) / bar2TotalDays * 100;
    }

    if(node4Date < this.currentDate) {
      this.bar3Value = 100;
    }

    if(node4Date > this.currentDate && this.bar2Value == 100) {
      const bar3TotalDays = this.calculateDaysBetween(node3Date, node4Date);
      const bar3ToCurrentDays = this.calculateDaysBetween(this.currentDate, node4Date);
      this.bar3Value = (bar3TotalDays - bar3ToCurrentDays) / bar3TotalDays * 100;
    }
  }

  getTooltip(item: CmsScheduleItem) {
    if(!item.DeadlineForSubmission) {
      return '';
    }
    
    const result = 
    `Deadline: ${item.DeadlineForSubmission}\n${item.RiskScoreRunYear} ${item.RiskScoreRunTypeName}\nDOS: ${item.DatesOfServiceStart} - ${item.DatesOfServiceEnd}`;

    return result;
  }

  calculateDaysBetween(date1: Date, date2: Date) {
    const time = date2.getTime() - date1.getTime();
    const days = Math.floor(time / (1000 * 3600 * 24));

    return days;
  }
}
