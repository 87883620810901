import { Injectable } from '@angular/core';
import { ServiceFormatter } from '../../helpers/service-formatter';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ReportItem, ReportItemCategory } from '../../models/report-item';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  private endpoint = ServiceFormatter.endpoint;

  DISPLAY_ALL_REPORTS_KEY = "vc-display-all-reports";

  displayAllReportItems: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(private http: HttpClient) {
    this.displayAllReportItems.next(JSON.parse(sessionStorage.getItem(this.DISPLAY_ALL_REPORTS_KEY)));
  }

  getAllReportItems() {
    return this.http.get<ReportItem[]>(this.endpoint + "reports/get-all-items", ServiceFormatter.getHttpOptions());
  }

  getFavoritedReportItems() {
    return this.http.get<ReportItem[]>(this.endpoint + "reports/get-favorited-items", ServiceFormatter.getHttpOptions());
  }

  getCategories() {
    return this.http.get<ReportItemCategory[]>(this.endpoint + "reports/get-categories", ServiceFormatter.getHttpOptions());
  }

  updateFavoriteReportItem(reportItemID: number, favorited: boolean) {
    const params = new HttpParams()
    .set("reportItemID", reportItemID.toString())
    .set("favorited", favorited);
    return this.http.post<any>(this.endpoint + "reports/update-favorited-item", null, ServiceFormatter.getHttpOptions(params));
  }

  updateDisplayAllReportItems(value: boolean) {
    this.displayAllReportItems.next(value);
    sessionStorage.setItem(this.DISPLAY_ALL_REPORTS_KEY, JSON.stringify(value));
  }
}
