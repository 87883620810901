import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatSelectSearchComponent } from 'ngx-mat-select-search';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { PayerConfigService } from '../../../services/payer/payer-config.service';
import { PayerAliasItem } from '../../../models/payer-alias-item';
import { MatOption } from '@angular/material/core';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-payer-alias-select',
  templateUrl: './payer-alias-select.component.html',
  styleUrls: ['./payer-alias-select.component.scss']
})
export class PayerAliasSelectComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() color = 'primary';
  @Input() appearance = 'outline';
  @Input() floatLabel = 'always';
  @Input() multiple = true;
  @Input() controlName = 'payer-alias';
  @Input() label = 'Alias';
  @Input() placeholder = '';
  @Input() toolTipPosition = 'above';
  @Input() selectAllEnabled = true;
  @Input() required = false;
  @Input() queryOptions = false;
  @Input() showDisabledOptions = false;
  @Input() currentPayerID = 0;

  options: PayerAliasItem[] = [];
  initialOptions: PayerAliasItem[] = [];
  filteredOptions: ReplaySubject<PayerAliasItem[]> = new ReplaySubject<PayerAliasItem[]>(1);
  control = new FormControl(null);
  filterControl = new FormControl<string>('');
  allToggled = false;
  indeterminateToggle = false;

  subs: Subscription[] = [];

  protected _onDestroy = new Subject<void>();

  @Output() ready = new EventEmitter();
  @Output() valueChanged = new EventEmitter();

  @ViewChild(MatSelect) select: MatSelect;
  @ViewChild(MatSelectSearchComponent) searchFilter: MatSelectSearchComponent;

  constructor(private configService: PayerConfigService) { }

  ngOnInit(): void {

    if(this.required) {
      this.control.setValidators([Validators.required]);
    }

    if(this.form) {
      this.form.addControl(this.controlName, this.control);
    }

    this.filterControl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterMulti();

      if(this.multiple) {
        this.setAllToggleState();
      }
    });

    if(this.multiple) {
      this.control.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.setAllToggleState();
      });
    }

    if(this.queryOptions) {
      this.getOptionData();
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getOptionData() {
    this.configService.getOrgPayerAliases().subscribe(result => {
      this.options = [];
      
      if(this.showDisabledOptions) {
        this.options = result;
        this.initialOptions = result;
      } else {
        result.forEach(i => {

          if(i.PayerID) {
            return;
          }
          
          this.options.push(i);
          this.initialOptions.push(i);
        });
      }

      this.filteredOptions.next(this.options);
      this.ready.emit();
    })
  }

  setSelectedValues(values: PayerAliasItem[]) {

    if(!values) {
      this.control?.reset();
      this.control?.setValue([]);
      this.searchFilter._reset();
      return;
    }

    if(values.length == 0) {
      this.allOptionClicked(true);
      return;
    }

    const options = [];
    values.forEach(u => {
      options.push({user: u, selected: true});
    });

    this.control.setValue(options);
  }

  setSelectedValuesByName(values: string[]) {
    if(!values) {
      this.control?.reset();
      this.control?.setValue([]);
      this.searchFilter._reset();
      return;
    }

    if(values.length == 0) {
      this.allOptionClicked(true);
      return;
    }

    const options = [];
    values.forEach(name => {
      const item = this.options.find(i => i.Name == name);

      if(item) {
        item.Selected = true;
        options.push(item);
      }
    });

    this.control.setValue(options);
  }

  getValues() {

    if(this.allToggled && this.multiple) {
      return [{ID: 0}];
    }
    
    return this.control?.value ? this.control?.value.map(i => i) : [];
  }

  getAliasIDsForPayer() {
    return this.control?.value ? this.control?.value.map(a => a.ID) : [];
  }

  getAliasNamesForPayer() {
    return this.control?.value ? this.control?.value.map(a => a.Name) : [];
  }

  allOptionClicked(isChecked: boolean) {

    this.options.forEach(o => {
      if(o.PayerID == 0 || o.PayerID == this.currentPayerID) {
        const selectOption = this.select.options.find(i => i.value == o);
        isChecked ? selectOption.select() : selectOption.deselect();
        o.Selected = isChecked;
      }
    });

  }

  toggleSelection(option: MatOption, item: PayerAliasItem) {
    item.Selected = option.selected;
  }

  checkAllOptions() {
    let result = true;
    this.options.forEach(o => {
      if(!o.Selected && (o.PayerID == 0 || o.PayerID == this.currentPayerID)) {
        result = false;
      }
    });

    return result;
  }

  onSelectionChange() {
    this.valueChanged.emit(this.control?.value);
  }

  getTooltipString() {
    let result = '';
    const options = this.control?.value;

    options.forEach((o, index) => {
      result += o.Name;

      if(index < options.length - 1) {
        result += ', ';
      }
    })
    return result;
  }

  getOptionTooltip(item: PayerAliasItem) {
    if(item.PayerID > 0) {
      return `Associated to ${item.PayerName}`;
    }

    return '';
  }

  getSelectCount() {
    return (this.control.value?.length <= this.options.length) 
      ? this.control.value?.length 
      : this.control.value?.length - 1;
  }

  filterMulti() {
    if (!this.options) {
      return;
    }

    let search = this.filterControl?.value;

    if (!search) {
      this.filteredOptions.next(this.options.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredOptions.next(
      this.options.filter(o => (o.Name.toLowerCase()).indexOf(search.toLowerCase()) > -1)
    );
  }

  setAllToggleState() {
    let filteredLength = 0;
    let activeOptionLength = 0;
    if(this.control?.value) {
      this.options.forEach(o => {
        if(this.control?.value.indexOf(o) > -1) {
          filteredLength++;
        }

        if(o.PayerID == 0 || o.PayerID == this.currentPayerID) {
          activeOptionLength++;
        }
      });

      this.indeterminateToggle = filteredLength > 0 && filteredLength < activeOptionLength;
      this.allToggled = filteredLength > 0 && filteredLength === activeOptionLength;
    }
  }
}
