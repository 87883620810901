import { Component, OnDestroy, OnInit } from '@angular/core';
import { CalculationsService } from '../../../../../services/calculations/calculations.service';
import { LoggingService } from '../../../../../services/api/logging.service';
import { Subscription } from 'rxjs';
import { UtilityFunctions } from '../../../../../helpers/utility-functions';
import { formatCurrency, formatNumber } from '@angular/common';
import { AgChartInstance, AgChartOptions, AgCharts } from 'ag-charts-enterprise';
import { AuditAction } from '../../../../../models/audit-action';
import { FieldFilterService } from '../../../../../services/filters/field-filter.service';

@Component({
  selector: 'app-oppty-hcc-valuation-graphs',
  templateUrl: './oppty-hcc-valuation-graphs.component.html',
  styleUrls: ['./oppty-hcc-valuation-graphs.component.scss']
})
export class OpptyHccValuationGraphsComponent implements OnInit, OnDestroy {

  data: any[] = [];

  graphOptionsList: AgChartOptions[] = [];
  preferredActions = ["Grand Total All Actions"];
  totalSectionName = "Grand Total All Actions";

  parentDiv = document.getElementById('snav');
  chartsColorPrimary = '';
  chartsColor2 = '';
  chartsColor3 = '';
  chartsColor4 = '';
  chartsColor5 = '';
  chartsColor6 = '';
  chartsColor7 = '';
  accentColor = '';

  subs: Subscription[] = [];

  constructor(
    private calculationsService: CalculationsService,
    private filterService: FieldFilterService,
    private loggingService: LoggingService
  ) { 
    this.chartsColorPrimary = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-charts-1');
    this.chartsColor2 = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-charts-2');
    this.chartsColor3 = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-charts-3');
    this.chartsColor4 = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-charts-4');
    this.chartsColor5 = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-charts-5');
    this.chartsColor6 = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-charts-6');
    this.chartsColor7 = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-charts-7');
    this.accentColor = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-accent');
  }

  ngOnInit(): void {
    this.subs.push(this.filterService.opptyPreferredActions.subscribe(result => {
      this.preferredActions = [this.totalSectionName];

      result?.map(p => p.Description).forEach(i => {
        this.preferredActions.push(i);
      });

      this.setChartOptions();
    }));

    this.subs.push(this.calculationsService.hccValuation.subscribe(result => {
      this.data = result;

      this.setChartOptions();
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  setChartOptions() {
    this.graphOptionsList = [];

    this.preferredActions.forEach((v, index) => {
      const datasubset = index == 0 ? this.data : this.data.filter(d => d.PreferredAction == v);
      const items = index == 0 ? this.calculationsService.getHccValuationGrandTotalItems(datasubset) : this.calculationsService.getHccValuationRowItems(datasubset)
      const total = this.calculationsService.getHccValuationTotal(datasubset); //TODO Total

      const filteredItems = items.filter(i => i.DisplayName != 'Closed' && i.DisplayName != 'Open' && i.DisplayName != 'Total');

      this.graphOptionsList.push({
        autoSize: true,
        title: {text: `${v} Valuation`, fontSize: 16, enabled: true},
        //data: filteredItems,
        series: [
          {
            type: 'pie',
            title: {
              text: 'Status',
              showInLegend: false,
              enabled: false
            },
            data: filteredItems,
            calloutLabelKey: 'DisplayName',
            calloutLabel: {
              formatter(params) {
                return `${params.datum[params.calloutLabelKey]} - ${formatNumber(params.datum[params.angleKey], 'en')}`
              },
            },
            legendItemKey: 'DisplayName',
            angleKey: 'HCCCount',
            innerRadiusRatio: 0.7,
            outerRadiusRatio: 0.9,
            fills: [
              this.chartsColor2,
              this.chartsColor3,
              this.chartsColor4,
              this.chartsColor5,
              this.chartsColor6,
              this.chartsColor7
            ],
            innerLabels: [
              {
                text: 'Total Valuation',
                fontWeight: 'bold'
              },
              {
                text: formatCurrency(total, 'en', '$','USD', '1.0-2'),
                margin: 4,
                fontSize: 16,
                fontWeight: 'bold',
                color: this.chartsColorPrimary,
              },
            ],
            innerCircle: {
              fill: this.accentColor,
              fillOpacity: 0.4
            }
          }
        ],
        theme: {
          overrides: {
            pie: {
              series: {
                strokes: [this.chartsColorPrimary],
                highlightStyle: {
                  item: {
                    fill: this.accentColor,
                    stroke: this.chartsColorPrimary,
                    strokeWidth: 3
                  }
                },
                tooltip: {
                  renderer(params) {
                    return {content: `${formatNumber(params.datum[params.angleKey], 'en')} HCC, ${formatCurrency(params.datum["Valuation"], 'en', '$','USD', '1.0-2')}`};
                  },
                }
              }
            }
          }
        }
      })
    })
  }

  onDownload(instance: AgChartInstance) {
    const instanceName = instance.getOptions().title.text;
    const filename = `${instanceName}`;

    AgCharts.download(instance, {
      fileName: filename
    });

    const reportJSON = JSON.stringify({ReportName: 'HCC Valuation by Preferred Action', Filename: filename});
    this.loggingService.logAudit(AuditAction.ReportExported, `Report Name: HCC Valuation by Preferred Action, Filename: ${filename}`, reportJSON).subscribe(() => {
      //TODO
    });

  }

}
