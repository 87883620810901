import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SettingsService } from '../../../services/settings/settings.service';
import { CalculationsService } from '../../../services/calculations/calculations.service';
import { HccSummaryCalculations } from '../../../models/hcc-summary-calculations';
import { Permission } from '../../../models/permission';
import { ReportNames } from '../../../models/report-names';
import { PermissionMap, PermissionService } from '../../../services/settings/permission.service';

@Component({
  selector: 'app-validation-summary',
  templateUrl: './validation-summary.component.html',
  styleUrls: ['./validation-summary.component.scss']
})
export class ValidationSummaryComponent implements OnInit, OnDestroy {

  @Input() queryData = false;

  validationDxSwitch = false;
  reportingEnabled = false;
  serviceYear = -1;
  data: HccSummaryCalculations = new HccSummaryCalculations();
  userPermissions: Permission[] = [];
  hccValue = 0;
  dxValue = 0;
  hccPercent = 0;
  dxPercent = 0;

  subs: Subscription[] = [];

  PAYER_VALIDATION_TREND_REPORT_NAME = ReportNames.PAYER_VALIDATION_TREND_NAME;

  constructor(
    private settingsService: SettingsService,
    private calculationsService: CalculationsService,
    private permissionService: PermissionService
  ) { 
    //TODO
  }

  ngOnInit(): void {
    this.subs.push(this.settingsService.serviceYear.subscribe(result => {
      if(result > -1) {
        this.serviceYear = result;

        if(this.queryData) {
          this.calculationsService.getHccSummaryCalculations(this.serviceYear);
        }
      }
    }));

    this.subs.push(this.permissionService.activeUserPermissions.subscribe(result => {
      this.userPermissions = result;
      this.permissionsCheck();
    }));

    this.subs.push(this.calculationsService.hccSummaryCalculations.subscribe(result => {
      this.data = result;
      this.getValues();
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  getValues() {
    this.hccValue = this.data.HCCPayerValidation;
    this.hccPercent = this.data.HCCPayerValidationPercent / 100;
    this.dxValue = this.data.DxPayerValidation;
    this.dxPercent = this.data.DxPayerValidationPercent / 100;
  }

  permissionsCheck() {
    this.userPermissions.forEach(p => {
      if(p.PermissionID == PermissionMap.GenerateReportsDxEvents) {
        this.reportingEnabled = true;
      }
    })
  }

}
