<mat-form-field [color]="color" [appearance]="appearance" [floatLabel]="floatLabel">
    <mat-label *ngIf="label">{{label}}</mat-label>
    <mat-select 
    [multiple]="multiple" 
    [formControl]="control"
    [placeholder]="placeholder"
    [matTooltip]="(control.value?.length || 0) > 1 ? getTooltipString() : ''"
    [matTooltipClass]="'field-tooltip'"
    [matTooltipPosition]="toolTipPosition"
    [required]="required"
    (selectionChange)="onSelectionChange()">
        <mat-select-trigger>
            <span *ngIf="(control.value?.length || 0) === 1">
                {{control.value?.[0].Name || ''}}
            </span>
            <span *ngIf="(control.value?.length || 0) > 1 && (control.value?.length) == options.length">
                All
            </span>
            <span *ngIf="(control.value?.length || 0) > 1 && (control.value?.length) < options.length">
                Multiple
            </span>
            <span *ngIf="(control.value?.length || 0) > 1">
              ({{getSelectCount()}})
            </span>
        </mat-select-trigger>

        <mat-option>
            <ngx-mat-select-search
            placeholderLabel="search..."
            noEntriesFoundLabel="No Payers Found"
            [id]="'filter-' + controlName"
            [formControl]="filterControl"
            [showToggleAllCheckbox]="multiple"
            [toggleAllCheckboxChecked]="allToggled"
            [toggleAllCheckboxIndeterminate]="indeterminateToggle"
            (toggleAll)="allOptionClicked($event)"></ngx-mat-select-search>
        </mat-option>

        <mat-option 
        *ngFor="let option of filteredOptions | async"
        #item
        [value]="option"
        [disabled]="option.PayerID > 0 && option.PayerID != currentPayerID"
        [matTooltip]="getOptionTooltip(option)"
        [matTooltipClass]="'field-tooltip'"
        [matTooltipPosition]="'left'"
        (click)="toggleSelection(item, option)">
            <span *ngIf="option.PayerID == currentPayerID">
                <strong>{{option.Name}}</strong>
            </span>
            <span *ngIf="option.PayerID != currentPayerID">
                {{option.Name}}
            </span>
        </mat-option>
    </mat-select>

    <mat-error *ngIf="control.hasError('required')">Selection is <strong>required</strong></mat-error>
</mat-form-field>
