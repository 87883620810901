import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { environment } from "../../../environments/environment";
import { DiagnosisEventNote } from '../../models/diagnosis-event-note';
import { DiagnosisEventItem } from '../../models/diagnosis-event-item';
import { DiagnosisEventQuery } from '../../models/diagnosis-event-query';
import { ACCESS_TOKEN } from '../../storage/constants.lib';
import { DiagnosisEventQueryResult } from '../../models/diagnosis-event-query-result';
import { DiagnosisEventEditRequest } from '../../models/diagnosis-event-edit-request';
import { DiagnosisEventFilterValues } from '../../models/diagnosis-event-filter-values';
import { User } from '../../models/users';

@Injectable({
  providedIn: 'root'
})
export class DiagnosisEventService {

  private endpoint = `${environment.baseURL}/api/`;

  constructor(private http: HttpClient) { 
    //TODO
  }

  private getHttpOptions(params?: HttpParams) {
    const token = localStorage.getItem(ACCESS_TOKEN);
    let headers = new HttpHeaders();
    headers = headers.append("Authorization", "Bearer " + token);
    const httpOptions = {
      headers: headers,
      params: params
    };

    return httpOptions;
  }

  getDiagnosisEvents(query: DiagnosisEventQuery) {
    return this.http.post<DiagnosisEventQueryResult>(this.endpoint + "diagnosisevent/get-items", query, this.getHttpOptions());
  }

  //If query present overrides items list
  updateAssignedOpptyListItems(items: DiagnosisEventItem[], assignedToUser: User, batchCount: number, query?: DiagnosisEventQuery) {
    const ids = items.map(i => i.ID).toString();
    return this.http.post<any>(this.endpoint + "diagnosisevent/assigned-to", {
      EventIDs: ids,
      AssignedToID: assignedToUser.user_id,
      AssignedToName: assignedToUser.given_name + ' ' + assignedToUser.family_name,
      BatchCount: batchCount,
      VisitID: ids && items?.length == 1 ? items[0].VisitID : null,
      DXCode: ids && items?.length == 1 ? items[0].ICDCODE : null,
      Query: query
    }, this.getHttpOptions());
  }

  updateEditListItem(request: DiagnosisEventEditRequest) {
    return this.http.post<any>(this.endpoint + "diagnosisevent/edit", request, this.getHttpOptions());
  }

  getNotesByEventID(eventID: number) {
    const params = new HttpParams().set("eventID", eventID.toString());
    return this.http.get<DiagnosisEventNote[]>(this.endpoint + "diagnosisevent/notes", this.getHttpOptions(params));
  }
}
