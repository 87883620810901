<h1 mat-dialog-title>Reset Password</h1>

<div mat-dialog-content>
    <div class="content-container flex flex-col">

        <div class="grid grid-cols-2 gap-3">
            <div class="col-start-1 col-span-2">
                <h3>Enter a new password.</h3>
            </div>

            <div class="col-start-1 col-span-2">
                <label style="font-style: italic;">Must be 7 characters or more.</label>
            </div>

            <div class="error-box reset-error col-start-1 col-span-2 justify-center items-center content-center" *ngIf="hasError">
                <label>
                    {{errorMessage}}
                </label>
            </div>

            <div class="col-start-1">
                <h3>Password *</h3>
            </div>

            <div class="col-start-2">
                <mat-form-field appearance="outline" color="primary" floatLabel="always">
                    <input minlength="7" matInput maxlength="45" placeholder="Enter password" [formControl]="password" required>
                    <mat-error *ngIf="password.invalid">{{getPasswordErrorMessage()}}</mat-error>
                </mat-form-field>
            </div>

            <div class="col-start-1">
                <h3>Confirm Password *</h3>
            </div>

            <div class="col-start-2">
                <mat-form-field appearance="outline" color="primary" floatLabel="always">
                    <input minlength="7" matInput maxlength="45" placeholder="Confirm password"
                        [formControl]="confirmPassword" required>
                    <mat-error *ngIf="confirmPassword.invalid">{{getconfirmPasswordErrorMessage()}}</mat-error>
                </mat-form-field>
            </div>
        </div>

    </div>
</div>

<div class="dialog-bottom-section flex flex-row">
    <label class="flex basis-[70%] justify-start items-center content-center pr-[30px]" *ngIf="orgPolicyDays >= 0">Organization policy requires a password reset every {{orgPolicyDays}} days.</label>
    <div class="flex flex-auto justify-end items-end content-end">
        <button 
            mat-flat-button 
            class="primary-button" 
            [disabled]="form.invalid"
            (click)="resetPassword()">
            Save & Login
        </button>
    </div>
</div>
