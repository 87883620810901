import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-progress-cell-renderer',
  templateUrl: './progress-cell-renderer.component.html',
  styleUrls: ['./progress-cell-renderer.component.scss']
})
export class ProgressCellRendererComponent implements ICellRendererAngularComp {

  params: ICellRendererParams<any, any>;
  value = 0;

  agInit(params: ICellRendererParams<any, any, any>): void {
    this.params = params;
    this.value = params.data[params.column.getColId()];
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }

}
