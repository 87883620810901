import { Component, OnDestroy, OnInit } from '@angular/core';
import { HccService } from '../../../../../services/hcc/hcc.service';
import { AverageHCCPerPatientItem, HccPerPatientTrendItem } from '../../../../../models/hcc-per-patient-trend-item';
import { Subscription } from 'rxjs';
import { AgChartInstance, AgChartOptions, AgCharts } from 'ag-charts-enterprise';
import { UtilityFunctions } from '../../../../../helpers/utility-functions';
import { formatNumber, formatPercent } from '@angular/common';
import { AuditAction } from '../../../../../models/audit-action';
import { LoggingService } from '../../../../../services/api/logging.service';

@Component({
  selector: 'app-average-hcc-per-patient-trend-graphs',
  templateUrl: './average-hcc-per-patient-trend-graphs.component.html',
  styleUrls: ['./average-hcc-per-patient-trend-graphs.component.scss']
})
export class AverageHccPerPatientTrendGraphsComponent implements OnInit, OnDestroy {

  items: HccPerPatientTrendItem[] = [];
  averageItems: AverageHCCPerPatientItem[] = [];

  startYear = 0;
  endYear = 0;

  groupedItems: any;

  subs: Subscription[] = [];

  pieChartOptionsList: AgChartOptions[] = [];
  hccDistChartOptions: AgChartOptions = {};
  averageChartOptions: AgChartOptions = {};
  

  parentDiv = document.getElementById('snav');
  chartsColor1 = '';
  accentColor = '';

  constructor(private hccService: HccService, private loggingService: LoggingService) { 
    this.chartsColor1 = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-charts-1');
    this.accentColor = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-accent');
  }

  ngOnInit(): void {
    this.subs.push(this.hccService.hccPerPatientTrend.subscribe(result => {
      this.pieChartOptionsList = [];
      this.items = result;
      this.groupedItems = UtilityFunctions.groupBy(result, i => i.ServiceYear);
      this.setPieChartOptions();
      this.setDistChartOptions();
    }));

    this.subs.push(this.hccService.averageHccPerPatient.subscribe(result => {
      this.averageItems = result;
      this.setAverageChartOptions();
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  updateYearRange(startYear: number, endYear: number) {
    this.startYear = startYear;
    this.endYear = endYear;
  }

  setPieChartOptions() {
    for(const year in this.groupedItems) {
      const data = this.groupedItems[year];
      const total = data.map(i => i.PatientCount).reduce((partialSum, current) => partialSum + current, 0);

      this.pieChartOptionsList.push({
        autoSize: true,
        title: {text: `${year} HCCs`, fontSize: 16, enabled: true},
        data: data,
        series: [
          {
            type: 'pie',
            title: {
              text: 'Status',
              showInLegend: false,
              enabled: false
            },
            calloutLabelKey: 'HCCCount',
            calloutLabel: {
              formatter(params) {
                return `${params.datum[params.calloutLabelKey]}:${formatPercent(params.datum[params.angleKey]/total, 'en', '1.0-2')}`
              },
            },
            legendItemKey: 'HCCCount',
            angleKey: 'PatientCount',
          }
        ],
        legend: {
          enabled: false
        },
        theme: {
          overrides: {
            pie: {
              series: {
                highlightStyle: {
                  item: {
                    fill: this.accentColor,
                    stroke: this.chartsColor1,
                    strokeWidth: 3
                  }
                },
                tooltip: {
                  renderer: function(params) {
                    const yValue = parseFloat(params.datum[params.angleKey]);
                    const xValue = parseFloat(params.datum[params.calloutLabelKey]);
      
                    const contentValue = formatNumber(yValue, 'en', '1.0-2');
                    return {
                      content: contentValue,
                      title: `${year}: ${xValue} HCCs`
                    }
                  }
                }
              }
            }
          }
        }
      })
    }
  }

  setDistChartOptions() {
    const series = [];
    for(const year in this.groupedItems) {
      series.push(
        {
          type: 'scatter',
          data: this.groupedItems[year],
          xKey: 'HCCCount',
          yKey: 'PatientCount',
          yName: year,
          tooltip: {
            renderer: function(params) {
              const yValue = parseFloat(params.datum[params.yKey]);
              const xValue = parseFloat(params.datum[params.xKey]);

              const contentValue = formatNumber(yValue, 'en');
              return {
                content: contentValue,
                title: `${year}: ${xValue} HCCs`
              }
            }
          }
        }
      );
    }

    this.hccDistChartOptions = {
      autoSize: true,
      title: {
        text: 'HCC Distribution'
      },
      series: series,
      axes: [
        {
          type: 'number',
          position: 'bottom',
          title: {
            text: 'HCC Count'
          },
          tick: {
            interval: 1
          }
        },
        {
          type: 'number',
          position: 'left',
          title: {
            text: 'Patients'
          },
          label: {
            format: '~s',
            formatter: (params) => params.formatter(params.value).replace('0y', '0')
          },
          min: 0
        }
      ],
      legend: {
        enabled: true
      }
    };
  }


  setAverageChartOptions() {
    this.averageChartOptions = {
      autoSize: true,
      title: {
        text: 'Average HCCs Trend'
      },
      data: this.averageItems,
      series: [
        {
          type: 'line',
          xKey: 'ServiceYear',
          yKey: 'AverageHCCs',
          yName: 'HCC Count',
          tooltip: {
            renderer: function(params) {
              const yValue = parseFloat(params.datum[params.yKey]);
              const xValue = parseFloat(params.datum[params.xKey]);

              const contentValue = formatNumber(yValue, 'en', '1.0-2');
              return {
                content: contentValue,
                title: `${xValue} HCCs`
              }
            }
          }
        }
      ],
      axes: [
        {
          type: 'number',
          position: 'bottom',
          title: {
            text: 'ServiceYear'
          },
          tick: {
            interval: 1
          },
          min: this.startYear,
          max: this.endYear
        },
        {
          type: 'number',
          position: 'left',
          title: {
            text: 'HCC Count'
          },
          label: {
            format: '~s',
            formatter: (params) => params.formatter(params.value).replace('0y', '0')
          },
          min: 0
        }
      ],
      legend: {
        enabled: true
      }
    };
  }


  onDownload(instance: AgChartInstance) {
    const instanceName = instance.getOptions().title.text;
    const filename = `${instanceName}`;

    AgCharts.download(instance, {
      fileName: filename
    });

    const reportJSON = JSON.stringify({ReportName: 'HCC Per Patient Distribution Trend', Filename: filename});
    this.loggingService.logAudit(AuditAction.ReportExported, `Report Name: HCC Per Patient Distribution Trend, Filename: ${filename}`, reportJSON).subscribe(() => {
      //TODO
    });

  }

}