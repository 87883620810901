import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { Subscription } from 'rxjs';
import { FieldFilterService } from '../../../services/filters/field-filter.service';
import { Timezone } from '../../../models/timezone';

@Component({
  selector: 'app-timezone-select',
  templateUrl: './timezone-select.component.html',
  styleUrls: ['./timezone-select.component.scss']
})
export class TimezoneSelectComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() color = 'primary';
  @Input() appearance = 'outline';
  @Input() floatLabel = 'always';
  @Input() label = 'Time Zone';
  @Input() placeholder = 'Make a Selection';
  @Input() multiple = true;
  @Input() controlName = 'timezone';
  @Input() toolTipPosition = 'above';
  @Input() selectAllEnabled = true;
  @Input() required = false;

  serviceYear: number;
  options: Timezone[] = [];
  allOption = {Name: 'All', Selected: false};
  control = new FormControl<any>(null);

  subs: Subscription[] = []

  @Output() ready = new EventEmitter();
  @Output() valueChanged = new EventEmitter();

  @ViewChild(MatSelect) select: MatSelect;

  constructor(private filtersService: FieldFilterService, private cd: ChangeDetectorRef) { 
    //TODO
  }

  ngOnInit(): void {

    if(this.required) {
      this.control.setValidators([Validators.required]);
    }

    if(this.form) {
      this.form.addControl(this.controlName, this.control);
    }

  }

  ngAfterViewInit(): void {
    this.subs.push(this.filtersService.getTimezones().subscribe(result => {
      if(result) {
        this.options = result;

        this.select?.options.forEach(o => {
          result.includes(o.value.Name) ? o.select() : o.deselect();
        });

        const checkAll = this.checkOptionAllStatus();

        if(checkAll && this.selectAllEnabled) {
          const allItem = this.select.options.get(0);
          allItem.select();
          this.onToggleItem(allItem);
        }
        
        this.cd.detectChanges();
      }

      this.ready.emit();
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  setValues(values: Timezone[]) {
    if(!values) {
      return;
    }

    if(this.multiple && values.length == 0) {
      this.control.setValue([this.allOption]);

      setTimeout(() => {
        this.onToggleItem(this.select.options.get(0));
      }, 300);
      
      return;
    }

    this.control.setValue(values);
  }

  setSingleModeValue(value: Timezone) {
    const findValue = this.select.options.find(o => o.value.ID == value.ID);
    this.control.setValue(findValue ? findValue.value : null);
  }

  onToggleItem(item: MatOption) {

    if(item.value === this.allOption) {
      if(item.selected) {
        this.allOption.Selected = true;
        this.select.options.forEach(o => o.select());
        return;
      }

      this.allOption.Selected = false;
      this.select.options.forEach(o => o.deselect());

    } else {
      
      const allItem = this.select.options.get(0);

      if(!item.selected && allItem.selected) {
        this.allOption.Selected = false;
        allItem.deselect();
        return;
      }

      if(item.selected && !allItem.selected) {
        const check = this.checkOptionAllStatus();
        if(check) {
          this.allOption.Selected = true;
          allItem.select();
        }
      }
    }
  }

  onSelectionChange() {
    this.valueChanged.emit(this.getValues());
  }

  checkOptionAllStatus() {
    let result = true;
    this.select.options.forEach(o => {
      if(o.value != this.allOption && !o.selected) {
        result = false;
      }
    });

    return result;
  }

  getNameValues() {
    const values = this.multiple && this.allOption.Selected ? this.control?.value.slice(1) : this.control?.value;
    return values.map(i => i.DisplayName);
  }

  getValues(): Timezone[] | Timezone {
    return this.multiple && this.allOption.Selected ? this.control?.value.slice(1) : this.control?.value;
  }

  getTooltipString() {
    let result = '';
    const options = this.allOption.Selected ? this.control?.value.slice(1) : this.control?.value;

    options.forEach((o, index) => {
      result += o.Name;

      if(index < options.length - 1) {
        result += ', ';
      }
    })
    return result;
  }

  getSelectCount() {
    return (this.control.value?.length <= this.options.length) 
      ? this.control.value?.length 
      : this.control.value?.length - 1;
  }
}
