import { OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { LoadSpinnerComponent } from '../components/sub-components/load-spinner/load-spinner.component';
import { DynamicOverlay } from '../overlay/dynamic-overlay';

@Directive({
  selector: '[overlayLoading]'
})
export class OverlayLoadingDirective implements OnDestroy {

  @Input('overlayLoading') toggler: Observable<boolean>;

  private overlayRef: OverlayRef;

  subs: Subscription[] = []

  constructor(
    private host: ElementRef,
    private dynamicOverlay: DynamicOverlay
  ) {}

  ngOnInit() {
    this.overlayRef = this.dynamicOverlay.createWithDefaultConfig(
      this.host.nativeElement
    );

    this.subs.push(this.toggler.subscribe(result => {
      if (result) {
        this.overlayRef.attach(new ComponentPortal(LoadSpinnerComponent));
      } else {
        this.overlayRef.detach();
      }
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

}
