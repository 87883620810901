<div class="flex basis-[7%] flex-row">
    <div class="flex flex-auto justify-start items-center content-center">
        <h1>Data Refresh Log</h1>
    </div>
</div>

<div class="flex basis-[15%] flex-row">
    <div class="data-refresh-config-container mat-elevation-z3 flex basis-[35%] flex-col">
        <div class="flex flex-auto justify-start items-start content-start">
            <h2>Configuration</h2>
        </div>
        <div class="flex flex-auto flex-row">
            <span>Refresh Frequency: Automated Nightly</span>
        </div>
    </div>
</div>

<div class="grid-section flex flex-auto">
    <!-- AG Grid Angular Component -->
    <ag-grid-angular
    class="ag-theme-balham w-full h-full"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowModelType]="'serverSide'"
    [getRowId]="getRowId"
    [pagination]="true"
    [tooltipShowDelay]="0"
    [statusBar]="statusBar"
    [context]="gridContext"
    [noRowsOverlayComponent]="noRowsOverlayComponent"
    [noRowsOverlayComponentParams]="noRowsOverlayComponentParams"
    [getContextMenuItems]="onGridGetContextMenu"
    [suppressCopyRowsToClipboard]="true"
    [suppressPaginationPanel]="true"
    [loadingOverlayComponent]="loadingOverlayComponent"
    (gridReady)="onGridReady()"
    (cellKeyDown)="onCellKeyDown($event)"></ag-grid-angular>
</div>
