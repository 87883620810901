import { Injectable } from '@angular/core';
import { ServiceFormatter } from '../../helpers/service-formatter';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GridQuery } from '../../models/grid-query';
import { HccValuationItem } from '../../models/hcc-valuation-item';
import { OrgHccModelVersionItem } from '../../models/org-hcc-model-version-item';
import { ReplaySubject } from 'rxjs';
import { AverageHCCPerPatientItem, HccPerPatientTrendItem } from '../../models/hcc-per-patient-trend-item';

@Injectable({
  providedIn: 'root'
})
export class HccService {

  private endpoint = ServiceFormatter.endpoint;

  opptyDataIngestion: ReplaySubject<any[]>;
  hccPerPatientTrend: ReplaySubject<HccPerPatientTrendItem[]>;
  averageHccPerPatient: ReplaySubject<AverageHCCPerPatientItem[]>;

  constructor(private http: HttpClient) { 
    this.opptyDataIngestion = new ReplaySubject<any[]>(1);
    this.hccPerPatientTrend = new ReplaySubject<HccPerPatientTrendItem[]>(1);
    this.averageHccPerPatient = new ReplaySubject<AverageHCCPerPatientItem[]>(1);
  }

  getHccValuationItems(query: GridQuery) {
    return this.http.post<any>(this.endpoint + "hcc/estimated-valuation", query, ServiceFormatter.getHttpOptions());
  }

  addUpdateHccValuationItem(item: HccValuationItem) {
    return this.http.post<any>(this.endpoint + "hcc/update-estimated-valuation", item, ServiceFormatter.getHttpOptions());
  }

  getOrgHccModelVersionItems(query: GridQuery) {
    return this.http.post<any>(this.endpoint + "hcc/org-model-version", query, ServiceFormatter.getHttpOptions());
  }

  addUpdateOrgHccModelVersionItem(item: OrgHccModelVersionItem) {
    return this.http.post<any>(this.endpoint + "hcc/update-org-model-version", item, ServiceFormatter.getHttpOptions());
  }

  getOpptyDataIngestion(serviceYear: number) {
    const params = new HttpParams().set("serviceYear", serviceYear.toString());
    return this.http.get<any>(this.endpoint + "hcc/ingestion-breakdown", ServiceFormatter.getHttpOptions(params)).subscribe(result => {
      this.opptyDataIngestion.next(result);
    });
  }

  getHccPerPatientTrend(startYear: number, endYear: number) {
    const params = new HttpParams()
    .set("startYear", startYear.toString())
    .set("endYear", endYear.toString());

    return this.http.get<HccPerPatientTrendItem[]>(this.endpoint + "hcc/hcc-per-patient-trend", ServiceFormatter.getHttpOptions(params)).subscribe(result => {
      this.hccPerPatientTrend.next(result);
    });
  }

  getAverageHccPerPatientTrend(startYear: number, endYear: number) {
    const params = new HttpParams()
    .set("startYear", startYear.toString())
    .set("endYear", endYear.toString());

    return this.http.get<AverageHCCPerPatientItem[]>(this.endpoint + "hcc/average-hcc-per-patient-trend", ServiceFormatter.getHttpOptions(params)).subscribe(result => {
      this.averageHccPerPatient.next(result);
    });
  }
}
