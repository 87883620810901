import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';


@Component({
  selector: 'checkbox-renderer',
  template: `
    <input 
      type="checkbox" 
      (click)="checkedHandler($event)"
      [checked]="params.value"
    />
`,
})

export class CheckboxRendererComponent implements ICellRendererAngularComp {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  checkedHandler(event) {
    const checked = event.target.checked;
    const colId = this.params.column.colId;
    this.params.node.setDataValue(colId, checked);
  }

  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }
}
