import { QueryDateRange } from "./diagnosis-event-query";

export class DiagnosisEventFilterValues {
    State: string;
    Status: string;
    RootCause: string;
    PreferredAction: string;
    Payer: string;
    Assigned: string;
    EMRPID: string;
    ICD10: string;
    DateRanges: QueryDateRange[];
}
