import { Injectable, OnInit } from "@angular/core";
import { BehaviorSubject, ReplaySubject, Subject } from "rxjs";
import { OrganizationLib } from "../libs/organization-lib.service";
import { Organization } from "../../models/organization.model";
import { ApplicationUser } from "../../models/user.model";
import { ORGANIZATION } from "../../storage/constants.lib";
import { ClientStorage } from "../../storage/client-storage.lib";
import { AuthService } from "../authentication/auth.service";

@Injectable({
	providedIn: "root",
})
export class OrganizationService {
	organization$: BehaviorSubject<Organization> = new BehaviorSubject<Organization>(
		null
	);
	user: ApplicationUser = null;

	constructor(
		private sharedOrgService: OrganizationLib,
		private storage: ClientStorage,
		public auth: AuthService
	) {
		this.init();
	}

	init(): void {
		this.auth.user$.subscribe((user) => {
			if (user != null) {
				this.user = user;

				this.sharedOrgService.getOrg().subscribe(
					(org) => {
						//broadcast new org
						//store the org
						this.storage.setItem(
							ORGANIZATION + "_" + org.orgID,
							JSON.stringify(org)
						);

						console.log("constructor: getting org from api");
						this.organization$.next(org);
					},
					(error) => {
						this.storage
							.getItem(ORGANIZATION + "_" + user.OrgID)
							.then((data) => {
								//if there is an organization stored
								if (data != null) {
									const organization: Organization = JSON.parse(data);

									//broadcast stored values for organization and org
									console.log("constructor: setting org from storage");
									this.organization$.next(organization);
								}
							});
					}
				);
			}
		});
	}

	/**
	 * gets the org stored in storage
	 * also gets the animals categories the org can filter on
	 */
	async getStoredOrg(): Promise<any> {
		console.log("calling getStoredOrg");

		// Get detected labels form storage
		return this.storage
			.getItem(ORGANIZATION + "_" + this.user.OrgID)
			.then((data) => {
				//if there is an organization stored
				if (data != null) {
					const organization: Organization = JSON.parse(data);

					//broadcast stored values for organization and org
					this.organization$.next(organization);
				}
			});
	}

	/**
	 * goes and gets the org with http request
	 */
	getOrg() {
		console.log("calling getORg/API");
		this.sharedOrgService.getOrg().subscribe(
			(org) => {
				//store the org
				this.storage.setItem(
					ORGANIZATION + "_" + this.user.OrgID,
					JSON.stringify(org)
				);

				//broadcast new org
				this.organization$.next(org);
			},
			(error) => {
				console.log(error);
			}
		);
	}
}
