import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-determinate-spinner',
  templateUrl: './determinate-spinner.component.html',
  styleUrls: ['./determinate-spinner.component.scss']
})
export class DeterminateSpinnerComponent {
  @Input() color: ThemePalette = "primary";
  @Input() diameter = 100;
  @Input() display: string | number;
  @Input() mode: ProgressSpinnerMode = "determinate";
  @Input() strokeWidth = 1;
  @Input() value: number;
}
