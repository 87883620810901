<div class="tree-menu">

    <mat-selection-list [multiple]="false" (selectionChange)="onSelectedConfigOption($event)">
        <mat-tree [dataSource]="data" [treeControl]="treeControl">
            <!-- This is the tree node template for leaf nodes -->
            <mat-tree-node *matTreeNodeDef="let option" matTreeNodeToggle>
                <mat-list-option [value]="option" [selected]="selectedOption.ID == option.ID">
                    <div class="flex flex-auto">
                        <div class="tree-item-icon" *ngIf="option.Icon">
                            <mat-icon *ngIf="!option.IconOutlined">{{option.Icon}}</mat-icon>
                            <mat-icon fontSet="material-icons-outlined" *ngIf="option.IconOutlined">{{option.Icon}}</mat-icon>
                        </div>
            
                        <div class="flex flex-auto justify-start items-center content-center">
                            {{option.Name}}
                        </div>
                    </div>
                </mat-list-option>
            </mat-tree-node>
        
            <!-- This is the tree node template for expandable nodes -->
            <mat-nested-tree-node *matTreeNodeDef="let option; when: hasChild">
                <mat-list-option [ngClass]="option.Children ? 'parent-node' : ''" [value]="option" [selected]="selectedOption.ID == option.ID">
                    <div class="mat-tree-node" class="flex flex-auto" matTreeNodeToggle>
                        <div class="tree-item-icon">
                            <mat-icon *ngIf="!option.IconOutlined">{{option.Icon}}</mat-icon>
                            <mat-icon fontSet="material-icons-outlined" *ngIf="option.IconOutlined">{{option.Icon}}</mat-icon>
                        </div>
        
                        <div class="flex flex-auto justify-start items-center content-center">
                            {{option.Name}}
                        </div>
                    </div>
                </mat-list-option>
                <div *ngIf="treeControl.isExpanded(option)" role="group">
                    <ng-container matTreeNodeOutlet></ng-container>
                </div>
            </mat-nested-tree-node>
        </mat-tree>
    </mat-selection-list>

</div>
