import { Injectable } from '@angular/core';
import { ClientStorage } from '../../app/storage/client-storage.lib';

@Injectable({
    providedIn: "root"
})

export class WebClientStorage implements ClientStorage {
    public async getItem(key: string): Promise<string> {
        return localStorage.getItem(key);
    }

    public async setItem(key: string, value: string): Promise<void> {
        return localStorage.setItem(key, value);
    }

    public async removeItem(key: string): Promise<void> {
        return localStorage.removeItem(key);
    }

    public async clear(): Promise<void> {
        return localStorage.clear();
    }
}