import { Injectable } from "@angular/core";

// tslint:disable-next-line:import-blacklist
import { Observable, Observer, Subject } from "rxjs/Rx";
import { CreateUser } from "../../models/createUser";
import { SimpleUser, User } from "../../models/users";
import { environment } from "../../../environments/environment";
import { ACCESS_TOKEN } from '../../storage/constants.lib';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { UserQuery } from "../../models/user-query";
import { UserQueryResult } from "../../models/user-query-result";
import { ServiceFormatter } from "../../helpers/service-formatter";


@Injectable()
export class APIService {
  private endpoint = `${environment.baseURL}/api/`;

  constructor(private http: HttpClient) { }

  public getUsers = (): Observable<User[]> => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    let headers = new HttpHeaders();
    //headers.append("Content-Type", 'application/json');
    headers = headers.append("Authorization", "Bearer " + token);
    const httpOptions = {
      headers: headers
    };
    return this.http
      .get<User[]>(this.endpoint + "user/getall", httpOptions)
      .catch(this.handleError);
  };

  public getUsersByQuery = (query: UserQuery): Observable<UserQueryResult> => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    let headers = new HttpHeaders();
    //headers.append("Content-Type", 'application/json');
    headers = headers.append("Authorization", "Bearer " + token);
    const httpOptions = {
      headers: headers
    };
    return this.http
      .post<UserQueryResult>(this.endpoint + "user/query", query, httpOptions)
      .catch(this.handleError);
  };

  public getUserList = (): Observable<SimpleUser[]> => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    let headers = new HttpHeaders();
    //headers.append("Content-Type", 'application/json');
    headers = headers.append("Authorization", "Bearer " + token);
    const httpOptions = {
      headers: headers
    };
    return this.http
      .get<SimpleUser[]>(this.endpoint + "user/getlist", httpOptions)
      .catch(this.handleError);
  };

  public getUsersWithDisabled = (): Observable<User[]> => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    let headers = new HttpHeaders();
    //headers.append("Content-Type", 'application/json');
    headers = headers.append("Authorization", "Bearer " + token);
    const httpOptions = {
      headers: headers
    };
    return this.http
      .get<User[]>(this.endpoint + "user/getallwithdisabled", httpOptions)
      .catch(this.handleError);
  };

  public getCurrentUser = (): Observable<User> => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    let headers = new HttpHeaders();
    //headers.append("Content-Type", 'application/json');
    headers = headers.append("Authorization", "Bearer " + token);


    const httpOptions = {
      headers: headers
    };
    return this.http
      .get<User>(this.endpoint + "user/getUser", httpOptions)
      .catch(this.handleError);
  };

  public makeSiteAdmin = (userId: string): Observable<any> => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    let headers = new HttpHeaders();
    //headers.append("Content-Type", 'application/json');
    headers = headers.append("Authorization", "Bearer " + token);
    const httpOptions = {
      headers: headers
    };
    return this.http
      .get(this.endpoint + "user/makesiteadmin/" + userId, httpOptions)
      .catch(this.handleError);
  };

  public makePlainUser = (userId: string): Observable<any> => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    let headers = new HttpHeaders();
    //headers.append("Content-Type", 'application/json');
    headers = headers.append("Authorization", "Bearer " + token);
    const httpOptions = {
      headers: headers
    };
    return this.http
      .get(this.endpoint + "user/makeplainuser/" + userId, httpOptions)
      .catch(this.handleError);
  };



  public makeReadOnlyUser = (userId: string): Observable<any> => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    let headers = new HttpHeaders();
    //headers.append("Content-Type", 'application/json');
    headers = headers.append("Authorization", "Bearer " + token);
    const httpOptions = {
      headers: headers
    };
    return this.http
      .get(this.endpoint + "user/makereadonlyuser/" + userId, httpOptions)
      .catch(this.handleError);
  };



  public isAdmin = (): Observable<any> => {

    const token = localStorage.getItem(ACCESS_TOKEN);
    let headers = new HttpHeaders();
    //headers.append("Content-Type", 'application/json');
    headers = headers.append("Authorization", "Bearer " + token);
    const httpOptions = {
      headers: headers
    };
    return this.http
      .get(this.endpoint + "user/Admin", httpOptions)
      .catch(this.handleError);
  };




  public isSysAdmin = (): Observable<any> => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    let headers = new HttpHeaders();
    //headers.append("Content-Type", 'application/json');
    headers = headers.append("Authorization", "Bearer " + token);
    const httpOptions = {
      headers: headers
    };
    return this.http
      .get(this.endpoint + "user/sysAdmin", httpOptions)
      .catch(this.handleError);
  };



  public addUser = (inputs: CreateUser): Observable<any> => {
    if (
      inputs.email == null &&
      inputs.email === ""
    ) {
      return Observable.throw("You must complete email");
    }
    const token = localStorage.getItem(ACCESS_TOKEN);
    let headers = new HttpHeaders();

    //headers.append("Content-Type", 'application/json');
    headers = headers.append("Authorization", "Bearer " + token);
    const httpOptions = {
      headers: headers
    };
    return this.http
      .post<string>(this.endpoint + "user/Adduser2", inputs, httpOptions)
      .catch(this.handleError);
  };

  public editUser = (inputs: CreateUser): Observable<any> => {
    if (
      inputs.email == null &&
      inputs.email === ""
    ) {
      return Observable.throw("You must complete email");
    }


    const token = localStorage.getItem(ACCESS_TOKEN);
    let headers = new HttpHeaders();

    //headers.append("Content-Type", 'application/json');
    headers = headers.append("Authorization", "Bearer " + token);
    const httpOptions = {
      headers: headers
    };
    return this.http
      .post(this.endpoint + "user/EditUser", inputs, httpOptions)
      .catch(this.handleError);
  };

  deleteUser(userId: string): Observable<any> {
    const token = localStorage.getItem(ACCESS_TOKEN);
    let headers = new HttpHeaders();
    //headers.append("Content-Type", 'application/json');
    headers = headers.append("Authorization", "Bearer " + token);
    const httpOptions = {
      headers: headers
    };
    return this.http
      .get(this.endpoint + "user/delete/" + userId, httpOptions)
      .catch(this.handleError);
  }

  sendVerifyEmail(userId: string): Observable<any> {
    const token = localStorage.getItem(ACCESS_TOKEN);
    let headers = new HttpHeaders();
    //headers.append("Content-Type", 'application/json');
    headers = headers.append("Authorization", "Bearer " + token);
    const httpOptions = {
      headers: headers
    };
    return this.http
      .get(this.endpoint + "user/sendVerfiyEmail/" + userId, httpOptions)
      .catch(this.handleError);
  }

  unblockUser(email: string): Observable<any> {
    const token = localStorage.getItem(ACCESS_TOKEN);
    let headers = new HttpHeaders();
    //headers.append("Content-Type", 'application/json');
    headers = headers.append("Authorization", "Bearer " + token);
    const httpOptions = {
      headers: headers
    };
    return this.http
      .get(this.endpoint + "user/unblock/" + email, httpOptions)
      .catch(this.handleError);
  }

  sendResetPassword(email: string): Observable<any> {
    const token = localStorage.getItem(ACCESS_TOKEN);
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/x-www-form-urlencoded");
    headers = headers.append("Authorization", "Bearer " + token);
    const httpOptions = {
      headers: headers
    };
    return this.http
      .post(this.endpoint + "user/sendPasswordReset", "=" + email, httpOptions)
      .catch(this.handleError);
  }

  getExportPassword(userID: string) {
    const params = new HttpParams().set("userID", userID)
    return this.http
    .get<any>(this.endpoint + "user/export-pw", ServiceFormatter.getHttpOptions(params))
    .catch(this.handleError);
  }

  private handleError(error: Response) {
    console.error(error);

    if (error.status === 401) {
      return Observable.throwError("Unauthorized");
    }

    return Observable.throwError("Server error");
    // do any other checking for statuses here
  }
}
