import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ReportItem } from '../../../models/report-item';
import { ReportsService } from '../../../services/user/reports.service';
import { ReplaySubject, Subscription } from 'rxjs';
import { ListMenuOption } from '../../../models/list-menu-option';

@Component({
  selector: 'app-all-reports',
  templateUrl: './all-reports.component.html',
  styleUrls: ['./all-reports.component.scss']
})
export class AllReportsComponent implements OnInit, OnDestroy {

  private _reportMenuOption;
  @Input() set reportMenuOption(value: ListMenuOption) {
    this._reportMenuOption = value;
    this.setDisplayedReports(this.reportMenuOption.Name);
  }

  get reportMenuOption(): ListMenuOption {
    return this._reportMenuOption;
  }

  displayedReports: ReplaySubject<ReportItem[]> = new ReplaySubject<ReportItem[]>(1);
  allReports: ReportItem[] = [];
  activeReports: ReportItem[] = [];
  currentReportCount = 0;

  displayAll = false;
  displayAllTooltip = 
  `Displays all reports, including reports that may be disabled due to permissions. 
  If reporting category permission has not been granted, the report will be displayed but disabled.`;

  subs: Subscription[] = [];

  @Output() reportClicked: EventEmitter<ReportItem> = new EventEmitter<ReportItem>();

  constructor(
    private reportsService: ReportsService) { 
    this.displayedReports.next([]);
  }

  ngOnInit(): void {
    this.getItems();

    this.subs.push(this.reportsService.displayAllReportItems.subscribe(result => {
      this.displayAll = result;
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  getItems() {
    this.reportsService.getAllReportItems().subscribe(result => {
      this.allReports = result;
      this.setDisplayedReports(this.reportMenuOption.Name);
    });
  }

  onFavoriteClick(item: any) {

    let favoritedValue = false;

    if(this.reportMenuOption.Name != 'Favorites') {
      item.Favorited = !item.Favorited;
      favoritedValue = item.Favorited;
    }
    
    this.reportsService.updateFavoriteReportItem(item.ID, favoritedValue).subscribe(result => {
      if(this.reportMenuOption.Name == 'Favorites') {
        this.getItems();
      }
    });
  }

  onDisplayAllToggle() {
    this.displayAll = !this.displayAll;
    this.reportsService.updateDisplayAllReportItems(this.displayAll);
    this.setDisplayedReports('All');
  }

  /**
   * Sets the displayed reports.
   * 
   * @param categoryName The category name, 'All' sets all reports and
   * 'Favorties' sets favorited reports.
   * 
   */
  setDisplayedReports(categoryName: string) {
    let reports = [];

    if(categoryName == 'All') {

      reports = this.displayAll ? this.allReports : this.allReports.filter(r => r.Active);

    } else if(categoryName == 'Favorites') {

      reports = this.displayAll ? this.allReports.filter(r => r.Favorited) : this.allReports.filter(r => r.Favorited && r.Active);

    } else {

      reports = this.displayAll ? this.allReports.filter(r => r.Category.Name == categoryName) : this.allReports.filter(r => r.Category.Name == categoryName && r.Active);

    }

    this.currentReportCount = reports.length;

    this.displayedReports.next(reports);
  }

}
