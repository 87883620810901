<div class="allocation-grid" [ngStyle]="{'--row-width': currentGridWidth + 'px', '--row-height': '100%'}">
    <ag-grid-angular
    #grid
    class="ag-theme-balham w-full h-full"
    [context]="gridContext"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="rowItems"
    [groupDisplayType]="groupDisplayType"
    [groupDefaultExpanded]="'-1'"
    [autoGroupColumnDef]="autoGroupColumnDef"
    [excelStyles]="excelStyles"
    [noRowsOverlayComponent]="noRowsOverlayComponent"
    [noRowsOverlayComponentParams]="noRowsOverlayComponentParams"
    [suppressContextMenu]="true"
    [loadingOverlayComponent]="loadingOverlayComponent"
    (gridReady)="onGridReady($event)"></ag-grid-angular>
</div>
