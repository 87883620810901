import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-payer-config-edit-renderer',
  templateUrl: './payer-config-edit-renderer.component.html',
  styleUrls: ['./payer-config-edit-renderer.component.scss']
})
export class PayerConfigEditRendererComponent implements ICellRendererAngularComp {

  params: ICellRendererParams<any, any>;

  constructor() {
    //TODO
  }

  agInit(params: ICellRendererParams<any, any>): void {
    this.params = params;
  }
  
  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }

  onIconClick(event) {
    event.stopImmediatePropagation();
    this.params.context.componentParent.onEditPayerClick(this.params.data);
  }

}