export class UtilityFunctions {

    static distinct<T extends Record<K, string | number | boolean>,
        K extends keyof T>(arr: T[], ...keys: K[]): Pick<T, K>[] {
        const key = (obj: T) => JSON.stringify(keys.map(k => obj[k]));
        const val = (obj: T) => keys.reduce((a, k) => (a[k] = obj[k], a), {} as Pick<T, K>);
        const dict = arr.reduce((a, t) => (a[key(t)] = val(t), a), {} as { [k: string]: Pick<T, K> })
        return Object.values(dict);
    }


    static groupBy<T, K extends keyof any>(arr: T[], key: (i: T) => K) {
        return arr.reduce((groups, item) => {
            (groups[key(item)] ||= []).push(item);
            return groups;
        }, {} as Record<K, T[]>);
    }
    
}
