export class CreateUser {

  constructor(
    public userID: string,
    public email: string,
    public lastname: string,
    public firstname: string,
    public readOnly: boolean,
    public mobileOnly: boolean,
    public limitedUser: boolean,
    public expiresAfter: number,
  ) { }

}

export class DataSetPermission {
  public DatasetID: number;
}