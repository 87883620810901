import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { FilterSelectItem } from '../../../models/filter-select-item';
import { FieldFilterService } from '../../../services/filters/field-filter.service';

@Component({
  selector: 'app-root-cause-select',
  templateUrl: './root-cause-select.component.html',
  styleUrls: ['./root-cause-select.component.scss']
})
export class RootCauseSelectComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() color = 'primary';
  @Input() appearance = 'outline';
  @Input() floatLabel = 'always';
  @Input() label = 'Root Cause';
  @Input() placeholder = '';
  @Input() multiple = true;
  @Input() controlName = 'root-cause';
  @Input() toolTipPosition = 'above';
  @Input() selectAllEnabled = true;
  @Input() noneEnabled = false;
  @Input() required = false;
  @Input() clearEnabled = false;

  options: FilterSelectItem[] = [];
  filteredOptions: FilterSelectItem[] = [];
  statusOptionIds: number[] = [];
  allOption: FilterSelectItem = {ID: 0, Description: 'All', LinkAssociations: [], Selected: false};
  noneOption: FilterSelectItem = {ID: -1, Description: 'None', LinkAssociations: [], Selected: false};
  control = new FormControl<any>(null);

  @Output() ready = new EventEmitter();
  @Output() valueChanged = new EventEmitter();

  @ViewChild(MatSelect) select: MatSelect;

  constructor(private filterService: FieldFilterService) { 
    //TODO
  }

  ngOnInit(): void {

    if(this.required) {
      this.control.setValidators([Validators.required]);
    }

    if(this.form) {
      this.form.addControl(this.controlName, this.control);
    }
  }

  getOptionData(statusOptionIds: number[]) {

    this.statusOptionIds = statusOptionIds;
    this.filterService.getRootCauseOptions(statusOptionIds).subscribe(result => {
      const items = result?.sort((a, b) => {
        return a.Description.toLowerCase().localeCompare(b.Description.toLowerCase());
      });

      this.options = items;
      this.filteredOptions = items;

      this.ready.emit(this.options);
    });
  }

  setValues(values: FilterSelectItem[], currentStateIds?: number[]) {
    if(!values) {
      return;
    }

    this.filterOptionsFromStatusIds(currentStateIds);

    if(this.selectAllEnabled && values.length == 0 && this.filteredOptions?.length > 1) {
      this.control.setValue([this.allOption]);

      setTimeout(() => {
        this.onToggleItem(this.select.options.get(0));
      }, 300);

      return;
    }

    this.control.setValue(values);
  }

  setSingleModeValue(value: FilterSelectItem) {
    const foundValue = this.filteredOptions.find(i => i.ID == value.ID);

    if(foundValue) {
      this.control.setValue(foundValue);
    }
  }

  getValues() {
    if(this.allOption.Selected) {
      return [this.allOption];
    }

    return this.control?.value;
  }

  filterOptionsFromStatusIds(currentStateIds: number[]) {
    const stateIds = currentStateIds || this.statusOptionIds;

    if(stateIds.length > 0) {
      this.allOption.Selected = false;
      this.filteredOptions = [];

      this.filteredOptions = this.options?.filter(o => o.LinkAssociations.some(a => stateIds.some(b => a === b)));
    }
  }

  onToggleItem(item: MatOption) {

    if(!this.multiple) {
      return;
    }
    
    if(item.value == this.allOption) {
      if(item.selected) {
        this.allOption.Selected = true;
        this.select.options.forEach(o => o.select());
        return;
      }

      this.allOption.Selected = false;
      this.select.options.forEach(o => o.deselect());

    } else {
      
      const allItem = this.select.options.get(0);

      if(!item.selected && allItem.selected) {
        this.allOption.Selected = false;
        allItem.deselect();
        return;
      }

      if(item.selected && !allItem.selected) {
        const check = this.checkOptionAllStatus();
        if(check) {
          this.allOption.Selected = true;
          allItem.select();
        }
      }
    }
  }

  onSelectionChange() {
    this.valueChanged.emit(this.control?.value);
  }

  onClear() {
    this.control.reset();
    this.valueChanged.emit(null);
  }

  checkOptionAllStatus() {
    let result = true;
    this.select.options.forEach(o => {
      if(o.value != this.allOption && !o.selected) {
        result = false;
      }
    });

    return result;
  }

  getTooltipString() {
    let result = '';
    const options = this.allOption.Selected ? this.control?.value.slice(1) : this.control?.value;

    options.forEach((o, index) => {
      result += o.Description;

      if(index < options.length - 1) {
        result += ', ';
      }
    })
    return result;
  }

  getSelectCount() {
    return (this.control.value?.length <= this.options.length) 
      ? this.control.value?.length 
      : this.control.value?.length - 1;
  }
}
