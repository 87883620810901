import { User } from "../models/users";

export class StringFormatter {

    public static FilterObjectToString(field: string, items: any[]) {
        if(!items) {
            return '';
        }

        const filterObjectLabelFields = [
            'Description',
            'Name',
            'Abbreviation'
        ]

        if(filterObjectLabelFields.includes(field)) {
            return items.map(i => {
                if(i.ID == 0) {
                    return 'All'
                }

                return i[field];
            }).toString();
        }

        return items.map(i => i[field]).toString();
    }

    public static FilterObjectToStringWithSingleQuotes(field: string, items: any[]) {
        if(!items) {
            return '';
        }

        return items.map(i => `'${i[field]}'`).toString();
    }

    public static UsersFullNameString(users: User[]) {
        if(!users) {
            return '';
        }

        return users.map(u => {
            if(u.user_id == '0') {
                return 'All';
            }

            if(u.user_id == '-1') {
                return 'Unassigned'
            }

            return u.given_name + ' ' + u.family_name;
        }).toString();
    }
}
