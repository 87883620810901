import { QueryMetadata } from "./query-metadata";

export class PayerConfigQuery {
    PayerIDs: string;
    ConfigStatuses: string;
    RiskAdjustmentPolicy: string;
    OmitPayer: string;
    PreferredActionIDs: string;
    PolicyServiceYearStart: string;
    PolicyServiceYearEnd: string;
    Metadata: QueryMetadata;
}
