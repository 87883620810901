import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { HccSummaryCalculations } from '../../../../../models/hcc-summary-calculations';
import { ColDef, ColGroupDef } from 'ag-grid-enterprise';
import { formatNumber } from '@angular/common';
import { CalculationsService } from '../../../../../services/calculations/calculations.service';
import { Subscription } from 'rxjs';
import { AgGridAngular } from 'ag-grid-angular';
import { AgNoRowsOverlayComponent } from '../../../../ag-grid-components/ag-no-rows-overlay/ag-no-rows-overlay.component';
import { DxsrExcelStyling } from '../dxsr-excel-styling';
import { AuditAction } from '../../../../../models/audit-action';
import { LoggingService } from '../../../../../services/api/logging.service';
import { AgLoadingOverlayComponent } from '../../../../ag-grid-components/ag-loading-overlay/ag-loading-overlay.component';

@Component({
  selector: 'app-payer-validation-trend-report',
  templateUrl: './payer-validation-trend-report.component.html',
  styleUrls: ['./payer-validation-trend-report.component.scss']
})
export class PayerValidationTrendReportComponent implements OnInit {
  data: HccSummaryCalculations[] = [];

  public gridColDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'Payer Validation',
      headerTooltip: 'Payer Validation',
      children: [
        {
          field: 'ServiceYear', headerName: 'Service Year',
          cellClass: 'dxsr-trend-right-border',
        },
        { field: 'HCCPayerValidation', headerName: 'HCCs', type: 'numericColumn', 
          valueFormatter: this.getNumberValue, cellClass: 'numberType',
        },
        { field: 'HCCPayerValidationPercent', headerName: 'HCC %', type: 'numericColumn', 
          valueFormatter: this.getPercentValue, cellClass: 'numberType',
        },
        { field: 'DxPayerValidation', headerName: 'DX', type: 'numericColumn', 
          valueFormatter: this.getNumberValue, cellClass: 'numberType'
        },
        { field: 'DxPayerValidationPercent', headerName: 'DX %', type: 'numericColumn', 
          valueFormatter: this.getPercentValue, cellClass: 'numberType',
        },
      ],
    }
  ];

  defaultColDef: ColDef = {
    menuTabs: [],
    autoHeight: true,
    minWidth: 120
  };

  noRowsOverlayComponent: any = AgNoRowsOverlayComponent;
  noRowsOverlayComponentParams: any = {
    noRowsMessageFunc: () => 'No results returned',
  };

  loadingOverlayComponent: any = AgLoadingOverlayComponent;

  excelStyles = DxsrExcelStyling.excelStylesTrends;

  gridContext: any;

  gridReady = false;

  dxSwitch = false;

  currentStartYear = 0;
  currentEndYear = 0;

  currentRowHeight = 140;

  subs: Subscription[] = [];

  @Output() ready: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() setExportEnable: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('grid1') agGrid!: AgGridAngular;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setGridColumnsToFit();
  }

  constructor(
    private calculationsService: CalculationsService,
    private loggingService: LoggingService) {
    this.gridContext = { componentParent: this };
  }

  ngOnInit(): void {
    //TODO
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  onAllGridsReady() {
    if(!this.gridReady) {
      return;
    }

    this.ready.emit(true);

    this.subs.push(this.calculationsService.hccSummaryCalculationsRange.subscribe(result => {
      if(!result) {
        return;
      }

      if(result.length == 0) {
        return;
      }

      this.data = result;
      this.currentRowHeight = 84 + (this.data ? this.data.length * 28 : 0);
      this.setGridsRowData(this.data);
      this.hideGridsLoadingOverlay();
      this.setExportEnable.emit(true);

      setTimeout(() => {
        this.setGridColumnsToFit();
      }, 500);
    }));
  }

  updateServiceYearRange(startYear: number, endYear: number) {
    this.currentStartYear = startYear;
    this.currentEndYear = endYear;
    this.calculationsService.getHccSummaryCalculationsByRange(startYear, endYear);
    this.showGridsLoadingOverlay();
  }

  setGridsRowData(data: HccSummaryCalculations[]) {
    this.agGrid.api.setGridOption('rowData', data);
  }

  setGridColumnsToFit() {
    if(!document.getElementById('grid1')) {
      return;
    }

    this.agGrid.api.sizeColumnsToFit();
  }

  showGridsLoadingOverlay() {
    this.agGrid.api.showLoadingOverlay();
  }

  hideGridsLoadingOverlay() {
    this.agGrid.api.hideOverlay();
  }

  onExport() {
    const sheets = [
      this.agGrid.api.getSheetDataForExcel({sheetName: "Payer Validation Trend"}),
    ];

    const filename =`Payer_Validation_Trend_Export_${this.currentStartYear}-${this.currentEndYear}`;

    this.agGrid.api.exportMultipleSheetsAsExcel({
      data: sheets,
      fileName: filename
    });

    const reportJSON = JSON.stringify({ReportName: 'Payer Validation Trend', Filename: filename});
    this.loggingService.logAudit(AuditAction.ReportExported, `Report Name: Payer Validation Trend, Filename: ${filename}`, reportJSON).subscribe(() => {
      //TODO
    });
  }

  getNumberValue(item: any) {
    if(!item.value) {
      return '0';
    }

    return formatNumber(item.value, 'en').toString();
  }

  getPercentValue(item: any) {
    if(!item.value) {
      return '0%';
    }

    return item.value + "%";
  }
}
