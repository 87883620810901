import { Component, OnInit } from "@angular/core";
import { APIService } from "../../../services/api/api.service";
import { Router } from "@angular/router";
import { User } from "../../../models/users";
import { AuthService } from "../../../services/authentication/auth.service";
import { Organization } from "../../../models/organization.model";
import { Subscription } from "rxjs";
import { OrganizationService } from "../../../services/organization/organization.service";
import { PermissionService } from "../../../services/settings/permission.service";
import { MatDialog } from "@angular/material/dialog";
import { AddUpdateUserDialogModel, DialogAddUserComponent } from "../../../components/route-components/users/users.component";
import { SettingsService } from "../../../services/settings/settings.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})

export class AppHeaderComponent implements OnInit {
  public isAdmin = false;
  public isSystemAdmin = false;
  public isReadOnly = true;
  public limitedUser = false;

  profile: any;
  org: Organization;
  initials = "--";
  currentUser: User = new User();

  private subscriptions: Subscription[] = [];
  constructor(
    public auth: AuthService,
    private api: APIService,
    public router: Router,
    private orgService: OrganizationService,
    private permissionService: PermissionService,
    private settingsService: SettingsService,
    private dialog: MatDialog
  ) {

    this.api.getCurrentUser().subscribe((data: User) => {
      this.currentUser = data;
      const user = data;
      this.limitedUser = user.limitedUser;
      
      const firstInitial = user.given_name ? user.given_name[0].toUpperCase() : "-";
      const secondInitial = user.family_name ? user.family_name[0].toUpperCase() : "-";
      this.initials = firstInitial + secondInitial;

      if (user.app_metadata.role == 'admin') {
        this.isAdmin = true;
      }
      else {
        this.isAdmin = false;
      }

      if (user.app_metadata.role == 'readonlyuser') {
        this.isReadOnly = true;
      }
      else {
        this.isReadOnly = false;
      }


      if (user.app_metadata.role == "readonlyuser") {
        this.isReadOnly = true;
      } else {
        this.isReadOnly = false;
      }

      if (user.app_metadata.systemAdmin == "true") {
        this.isSystemAdmin = true;
      } else {
        this.isSystemAdmin = false;
      }

      this.permissionService.getPermissions();
      this.settingsService.getServiceYear();
    },
      (error) => console.log(error)
    );
  }

  ngOnInit() {
    this.subscriptions.push(
      this.orgService.organization$.subscribe((response) => {
        if (response) {
          this.org = response;
        }
      })
    );
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  goToUsers() {
    this.router.navigateByUrl("/users");
  }


  goToOrgs() {
    this.router.navigateByUrl("/orgs");
  }

  goToConfigPage() {
    this.router.navigateByUrl("/configuration");
  }

  openUserProfile() {
    const mode = this.currentUser.app_metadata.role == 'admin' || this.currentUser.app_metadata.systemAdmin == "true" ? "edit" : "readonly";
    const data = { mode: mode, readOnly: this.currentUser.app_metadata.role == 'readonlyuser', userData: this.currentUser };
    const dialogRef = this.dialog.open(DialogAddUserComponent, new AddUpdateUserDialogModel(data));
  }

}
