

export interface ApplicationUser {
    UserName: string;
    Role: string;
    OrgName: string;
}

export class Identity {
    access_token?: any;
    connection: string;
    isSocial: boolean;
    provider: string;
    user_id: string;
}

export class AppMetadata {
    siteID: string;
    role: string;
    systemAdmin: string;
}

export class User {
    created_at: Date;
    identities: Identity[];
    last_ip: string;
    last_login: Date;
    locale?: any;
    logins_count: string;
    phone_verified: boolean;
    updated_at: Date;
    user_id: string;
    blocked: boolean;
    multifactor?: any;
    nickname: string;
    given_name?: any;
    name: string;
    family_name?: any;
    picture: string;
    app_metadata: AppMetadata;
    email: string;
    email_verified: boolean;
    phone_number?: any;
    user_metadata?: any;
    username?: any;
    uid?: any;
    mobileOnly: boolean;
    limitedUser: boolean;
    failedLogin: number;
    enabled?: string;
}

export class SimpleUser {
    created_at: Date;

    user_id: string;

    nickname: string;
    given_name?: any;
    name: string;
    family_name?: any;
    picture: string;

    email: string;
    phone_number?: any;
    username?: any;
    uid?: any;
}



