<div class="dx-trend-row report-grid-alt w-full h-full">
    <ag-grid-angular
    class="ag-theme-balham w-full h-full"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [getRowId]="getRowId"
    [rowModelType]="'serverSide'"
    [maxBlocksInCache]="1"
    [tooltipShowDelay]="0"
    [statusBar]="statusBar"
    [noRowsOverlayComponent]="noRowsOverlayComponent"
    [noRowsOverlayComponentParams]="noRowsOverlayComponentParams"
    [context]="gridContext"
    [components]="frameworkComps"
    [statusBar]="statusBar"
    [pagination]="true"
    [excelStyles]="excelStyles"
    [suppressCopyRowsToClipboard]="true"
    [suppressPaginationPanel]="true"
    [loadingOverlayComponent]="loadingOverlayComponent"
    (gridReady)="onGridReady()"></ag-grid-angular>
</div>
