import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { BehaviorSubject, ReplaySubject, Subscription } from 'rxjs';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../../../storage/constants.lib';
import { AuthService } from '../../../services/authentication/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ResetPasswordDialogComponent, ResetPasswordDialogModel } from '../../../dialogs/reset-password-dialog/reset-password-dialog.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy,AfterViewInit {

  linkSent = false;
  busy: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  username = '';
  password = '';
  loginError = false;
  disabledAccount = false;
  mobileOnly = false;
  browser = '';
  userRequired = false;
  blocked = false;

  private subscriptions: Subscription[] = [];
  @ViewChild('loginButton') loginButton;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    public dialog: MatDialog,
  ) {
    this.route.queryParams.subscribe(params => {
      this.username = params['user'];
    });
  }

  ngAfterViewInit() {
    this.loginButton.nativeElement.focus();
  }

  ngOnInit(): void {

    
    document.getElementById('loginsplogo').style.display = 'block';
    document.getElementById('header').style.backgroundColor = '#003f9c';



    this.subscriptions.push(this.authService.user$.subscribe((x) => {
      if (this.route.snapshot.url[0].path === 'login') {

        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        let refreshToken = localStorage.getItem(REFRESH_TOKEN);
        if (refreshToken != null) {
          this.authService.refreshToken().subscribe(() => {
            if (x && accessToken && refreshToken) {
              refreshToken = localStorage.getItem(REFRESH_TOKEN);
              this.router.navigate(["/insights"]);
            }
          });

        }

      } // optional touch-up: if a tab shows login page, then refresh the page to reduce duplicate login
    }));

    if(this.router.url.includes('/forgotpassword')) {
      const dialogRef = this.dialog.open(ResetPasswordDialogComponent, new ResetPasswordDialogModel);
    }

  }

  sendForgotLink() {
    this.loginError = false;

    if (!this.username || this.username == '') {
      this.userRequired = true;
      return;
    }

    this.authService
      .forgotPassword(this.username)
      .pipe(finalize(() => (this.busy.next(false))))
      .subscribe(
        () => {
          this.loginError = false;
          this.linkSent = true;
          this.userRequired = false;
        },
        error => {
          this.loginError = true;
        },
        () => {
          this.linkSent = true;
          this.userRequired = false;
        }
      );
  }

  login() {
    this.loginError = false;
    this.linkSent = false;
    this.userRequired = false;
    this.blocked = false;

    if (!this.username || this.username == '') {
      this.userRequired = true;
      return;
    }

    if ( !this.password) {
      return;
    }
    this.busy.next(true);

    this.authService
      .login(this.username, this.password, true)
      .pipe(finalize(() => (this.busy.next(false))))
      .subscribe(
        (x) => {

          if(x.ResetPassword) {
            this.router.navigate(['/forgotpassword'], {queryParams: {key: x.Key, orgPolicyDays: x.OrgPolicyDays}});
          }
          else {
            this.router.navigate(["/insights"]);
          }

        },
        (x) => {
          const y = x;
          if (x == "Blocked Account")
          {
            this.blocked = true;
          }
          else if (x == "Invalid Credentials")
          {
            this.disabledAccount = false;
            this.mobileOnly = false;
            this.loginError = true;
            this.userRequired = false;
            this.blocked = false;
          }
          else if (x == "Disabled Account") {
            this.disabledAccount = true;
          }
          else if (x == "Mobile Only") {
            this.mobileOnly = true;
          }
          else {
            this.disabledAccount = false;
            this.mobileOnly = false;
            this.loginError = true;
            this.userRequired = false;
            this.blocked = false;
          }


        }
      );
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

 

}
