<mat-icon [matMenuTriggerFor]="actionItemMenu">more_horiz</mat-icon>

<mat-menu #actionItemMenu="matMenu">
    <button mat-menu-item
    (click)="onItemClick('Switch Org')">
        <span>Switch To Organization</span>
    </button>

    <button mat-menu-item
    (click)="onItemClick('Edit Org')">
        <span>Edit Organization</span>
    </button>
</mat-menu>
