<mat-form-field [color]="color" [appearance]="appearance" [floatLabel]="floatLabel">
    <mat-label *ngIf="label">{{label}}</mat-label>
    <mat-select 
    [multiple]="multiple"
    [placeholder]="placeholder"
    [formControl]="control"
    [matTooltip]="(multiple && (control.value?.length || 0) > 1) ? getTooltipString() : ''"
    [matTooltipClass]="'field-tooltip'"
    [matTooltipPosition]="toolTipPosition"
    [required]="required"
    (closed)="onSelectionChange()">
        <mat-select-trigger *ngIf="multiple">
            <span *ngIf="(control.value?.length || 0) === 1">
                {{control.value?.[0].Name || control.value?.[0]}}
            </span>
            <span *ngIf="(control.value?.length || 0) > 1 && (control.value?.length - 1) == options.length">
                All
            </span>
            <span *ngIf="(control.value?.length || 0) > 1 && (control.value?.length - 1) < options.length">
                Multiple
            </span>
            <span *ngIf="(control.value?.length || 0) > 1">
              ({{getSelectCount()}})
            </span>
        </mat-select-trigger>

        <mat-option *ngIf="selectAllEnabled"
        #allItem 
        [value]="allOption"
        (click)="onToggleItem(allItem)">All</mat-option>

        <mat-option *ngFor="let option of options"
        #item
        [value]="option"
        (click)="onToggleItem(item)">
            {{option.DisplayName}}
        </mat-option>
    </mat-select>
</mat-form-field>
