<div mat-dialog-title>
    <div class="flex flex-row">
        <div class="flex basis-3/4">
            <h1>{{data.mode == 'add' ? 'Add' : 'Edit'}} Organization</h1>
        </div>
    
        <div class="flex basis-1/4 justify-end items-center content-center">
            <mat-icon (click)="onCloseClick()">close</mat-icon>
        </div>
    </div>
</div>

<div class="flex flex-row">
    <div class="dialog-side-menu-container flex basis-[20%] flex-col">
        <mat-selection-list [multiple]="false" (selectionChange)="onSelectedSideMenuOption($event)">
            <mat-list-option *ngFor="let option of sideMenuOptions" [value]="option" [selected]="selectedOption.ID == option.ID" [disabled]="option.Disabled">
                <div class="flex flex-row">
                    <div class="flex basis-[20%] justify-start items-center content-center">
                        <mat-icon *ngIf="!option.IconOutlined">{{option.Icon}}</mat-icon>
                        <mat-icon fontSet="material-icons-outlined" *ngIf="option.IconOutlined">{{option.Icon}}</mat-icon>
                    </div>

                    <div class="flex flex-auto justify-start items-center content-center">
                        {{option.Name}}
                    </div>
                </div>
            </mat-list-option>
        </mat-selection-list>
    </div>

    <div mat-dialog-content class="flex flex-auto">
    
        <div class="sm-content-container flex flex-auto flex-col gap-[15px]" *ngIf="selectedOption.ID == 1">
            <div class="flex flex-auto">
                <h2>{{selectedOption.Name}}</h2>
            </div>
            <div class="flex flex-[2_1_auto]">
                <form [formGroup]="form" class="flex basis-[80%]" autocomplete="off">
                    <div class="flex flex-auto flex-col">
                        <div class="flex flex-row">
                            <div class="flex basis-[40%] justify-start items-center content-center">
                                <h3>Name:*</h3>
                            </div>
                
                            <div class="flex basis-[60%]">
                                <mat-form-field appearance="outline" color="primary" floatLabel="always">
                                    <input type="text" autocomplete="off" matInput placeholder="Enter Name"
                                        [formControl]="nameControl" required>
                                    <mat-error *ngIf="nameControl.hasError('required')">Org Name is <strong>required</strong></mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="flex flex-row">
                            <div class="flex basis-[40%] justify-start items-center content-center">
                                <h3>Time Zone:*</h3>
                            </div>
                
                            <div class="flex basis-[60%]">
                                <app-timezone-select
                                    [form]="form"
                                    [multiple]="false"
                                    [label]="''"
                                    [selectAllEnabled]="false"
                                    [required]="true"
                                    (ready)="onTimezoneReady()">
                                </app-timezone-select>
                            </div>
                        </div>

                        <div class="flex flex-row">
                            <div class="flex basis-[40%] justify-start items-center content-center">
                                <h3>Notes:</h3>
                            </div>
                
                            <div class="flex basis-[60%]">
                                <mat-form-field appearance="outline" color="primary" floatLabel="always">
                                    <textarea autocomplete="off" matInput placeholder="Enter Notes"
                                        [formControl]="notesControl" [rows]="3" required></textarea>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="flex flex-row">
                            <div class="flex basis-[40%] justify-start items-center content-center">
                                <h3>Status:*</h3>
                            </div>

                            <div class="flex basis-[60%]">
                                <app-boolean-select
                                #statusSelect
                                [form]="form"
                                [trueValue]="'Inactive'"
                                [falseValue]="'Active'"
                                [defaultValue]="'Active'"
                                [required]="true"></app-boolean-select>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
    
            <div class="flex flex-auto justify-end items-end content-end">
                <button [disabled]="form.pristine" mat-flat-button class="primary-button" *ngIf="data.mode == 'edit'" (click)="addEditUser()">
                    Save
                </button>
                <button [disabled]="form.invalid" mat-flat-button class="primary-button" *ngIf="data.mode != 'edit'" (click)="addEditUser()">
                    Add Org
                </button>
            </div>
        </div>
    
        <div class="sm-content-container flex flex-auto flex-col" *ngIf="selectedOption.ID == 2">
            <div class="flex flex-auto">
                <h2>{{selectedOption.Name}}</h2>
            </div>
            <div class="flex flex-[2_1_auto] flex-col">
                <form [formGroup]="authPolicyForm" class="permission-item flex flex-row gap-[10px]" autocomplete="off">
                    <div class="flex flex-auto flex-col">
                        <div class="flex flex-row">
                            <div class="flex basis-[40%] justify-start items-center content-center">
                                <h3>Prompt Users to Reset Password every:</h3>
                            </div>
                
                            <div class="flex basis-[20%]">
                                <mat-form-field appearance="outline" color="primary" floatLabel="always">
                                    <mat-select 
                                    [placeholder]="'Select Days'"
                                    [formControl]="authResetIntervalControl">
                                        <mat-select-trigger>
                                            <span>
                                                {{authResetIntervalControl?.value + ' Days'}}
                                            </span>
                                        </mat-select-trigger>
                                
                                        <mat-option *ngFor="let option of authResetIntervalOptions" [value]="option">
                                            {{option + ' Days'}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="flex flex-row">
                            <div class="flex basis-[40%] justify-start items-center content-center">
                                <h3>Export Password Expires every:</h3>
                            </div>
                
                            <div class="flex basis-[20%]">
                                <mat-form-field appearance="outline" color="primary" floatLabel="always">
                                    <mat-select 
                                    [placeholder]="'Select Days'"
                                    [formControl]="authExportExpirationControl">
                                        <mat-select-trigger>
                                            <span>
                                                {{authExportExpirationControl?.value + ' Days'}}
                                            </span>
                                        </mat-select-trigger>
                                
                                        <mat-option *ngFor="let option of authExportExpirationOptions" [value]="option">
                                            {{option + ' Days'}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="flex flex-row">
                            <button mat-flat-button class="primary-button-longtext" (click)="onManuallyResetExportPassword()">
                                Manually Reset Export Password
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="flex flex-auto justify-end items-end content-end">
                <button mat-flat-button class="primary-button" *ngIf="data.mode == 'edit'" (click)="addEditUser()">
                    Save
                </button>
            </div>
        </div>
    
    </div>
</div>