<mat-form-field [color]="color" [appearance]="appearance" [floatLabel]="floatLabel">
    <mat-label *ngIf="label">{{label}}</mat-label>
    <mat-select 
    [multiple]="multiple" 
    [formControl]="control"
    [placeholder]="placeholder"
    [matTooltip]="(control.value?.length || 0) > 1 ? getTooltipString() : ''"
    [matTooltipClass]="'field-tooltip'"
    [matTooltipPosition]="toolTipPosition"
    [required]="required"
    (selectionChange)="onSelectionChange()">
        <mat-select-trigger *ngIf="multiple">
            <span *ngIf="(control.value?.length || 0) === 1">
                {{control.value?.[0].Description || ''}}
            </span>
            <span *ngIf="(control.value?.length || 0) > 1 && (control.value?.length - additionalOptionCount) >= options.length">
                All
            </span>
            <span *ngIf="(control.value?.length || 0) > 1 && (control.value?.length - additionalOptionCount) < options.length">
                Multiple
            </span>
            <span *ngIf="(control.value?.length || 0) > 1">
              ({{getSelectCount()}})
            </span>
        </mat-select-trigger>

        <mat-option *ngIf="selectAllEnabled"
        #allItem 
        [value]="allOption"
        (click)="onToggleItem(allItem)">All</mat-option>

        <mat-option *ngFor="let option of options"
        #item
        [value]="option"
        (click)="onToggleItem(item)">
            {{option.Description}}
        </mat-option>

        <mat-option *ngIf="systemAdminEnabled"
        #sysAdminItem 
        [value]="systemAdminOption"
        (click)="onToggleItem(sysAdminItem)">{{systemAdminOption.Description}}</mat-option>

        <mat-option *ngIf="noneEnabled"
        #noneItem 
        [value]="noneOption"
        (click)="onToggleItem(noneItem)">None</mat-option>
    </mat-select>

    <mat-error *ngIf="control.hasError('required')">Selection is <strong>required</strong></mat-error>
</mat-form-field>
