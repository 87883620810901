import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ServiceFormatter } from '../../helpers/service-formatter';
import { HccModelVersion } from '../../models/hcc-model-version';
import { FilterSelectItem } from '../../models/filter-select-item';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FieldFilterService {

  private endpoint = `${environment.baseURL}/api/`;

  templateConfigTypes: ReplaySubject<FilterSelectItem[]>;
  opptyPreferredActions: ReplaySubject<FilterSelectItem[]>;

  constructor(private http: HttpClient) { 
    this.templateConfigTypes = new ReplaySubject<FilterSelectItem[]>(1);
    this.opptyPreferredActions = new ReplaySubject<FilterSelectItem[]>(1);
  }

  getStatusStateOptions() {
    return this.http.get<any>(this.endpoint + "filters/status-state", ServiceFormatter.getHttpOptions());
  }

  getStatusOptions(dxEventTypeIDs: string, stateIds: number[]) {
    const params = new HttpParams()
    .set('dxEventTypeIDs', dxEventTypeIDs)
    .set('stateIds', stateIds.toString());
    
    return this.http.get<any>(this.endpoint + "filters/status", ServiceFormatter.getHttpOptions(params));
  }

  getRootCauseOptions(statusIds: number[]) {
    const params = new HttpParams().set('statusIds', statusIds.toString());
    return this.http.get<any>(this.endpoint + "filters/root-cause", ServiceFormatter.getHttpOptions(params));
  }

  getPreferredActionOptions(typeIds: string) {
    const params = new HttpParams().set('typeIds', typeIds);
    return this.http.get<any>(this.endpoint + "filters/preferred-actions", ServiceFormatter.getHttpOptions(params));
  }

  getOpptyPreferredActionOptionsSubject() {
    const params = new HttpParams().set('typeIds', '1');
    return this.http.get<any>(this.endpoint + "filters/preferred-actions", ServiceFormatter.getHttpOptions(params)).subscribe(result => {
      this.opptyPreferredActions.next(result);
    });
  }

  getPayerOptions() {
    return this.http.get<any>(this.endpoint + "filters/payers", ServiceFormatter.getHttpOptions());
  }

  getRiskScoreRunTypeOptions() {
    return this.http.get<any>(this.endpoint + "filters/risk-score-run-types", ServiceFormatter.getHttpOptions());
  }

  getUserRoles() {
    return this.http.get<any>(this.endpoint + "filters/user-roles", ServiceFormatter.getHttpOptions());
  }

  getTimezones() {
    return this.http.get<any>(this.endpoint + "filters/time-zones", ServiceFormatter.getHttpOptions());
  }

  getHccModelCategories() {
    return this.http.get<any>(this.endpoint + "filters/hcc-model-categories", ServiceFormatter.getHttpOptions());
  }

  getHccModelVersionsByCategory(categoryIDs: number[]) {
    const params = new HttpParams().set('categoryIDs', categoryIDs.toString());
    return this.http.get<HccModelVersion[]>(this.endpoint + "filters/hcc-model-versions-category", ServiceFormatter.getHttpOptions(params));
  }

  getActiveAdminAuditActions() {
    return this.http.get<any>(this.endpoint + "filters/admin-audit-actions", ServiceFormatter.getHttpOptions());
  }

  getTemplateConfigTypes() {
    return this.http.get<any>(this.endpoint + "filters/export-template-types", ServiceFormatter.getHttpOptions()).subscribe(result => {
      this.templateConfigTypes.next(result);
    });
  }

  getExportTemplatesByTypeAndPayer(typeIDs: string, payerIDs?: string) {
    const params = new HttpParams()
    .set('typeIDs', typeIDs)
    .set('payerIDs', payerIDs || '');
    return this.http.get<any>(this.endpoint + "filters/export-templates", ServiceFormatter.getHttpOptions(params));
  }
}
