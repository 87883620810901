<ag-grid-angular
class="ag-theme-balham ag-theme-dx-event-summary-report w-[60%] max-[1415px]:w-full h-[732px]"
[context]="gridContext"
[columnDefs]="columnDefs"
[defaultColDef]="defaultColDef"
[rowData]="rowItems"
[rowStyle]="{background: 'white', border: 'none', 'margin-bottom': '0px'}"
[excelStyles]="excelStyles"
[suppressContextMenu]="true"
[loadingOverlayComponent]="loadingOverlayComponent"
(gridReady)="onGridReady($event)"></ag-grid-angular>
