import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AgChartInstance, AgChartOptions, AgCharts } from 'ag-charts-enterprise';
import { Subscription } from 'rxjs';
import { HccService } from '../../../../../services/hcc/hcc.service';
import { formatNumber } from '@angular/common';
import { AuditAction } from '../../../../../models/audit-action';
import { ReportNames } from '../../../../../models/report-names';
import { LoggingService } from '../../../../../services/api/logging.service';
import { AgChartsAngular } from 'ag-charts-angular';

@Component({
  selector: 'app-oppty-data-ingestion-report',
  templateUrl: './oppty-data-ingestion-report.component.html',
  styleUrls: ['./oppty-data-ingestion-report.component.scss']
})
export class OpptyDataIngestionReportComponent implements OnInit, OnDestroy {

  options: AgChartOptions = {};

  data: any[];
  serviceYear = 0;

  subs: Subscription[] = [];

  parentDiv = document.getElementById('snav');
  chartsColorPrimary = '';
  accentColor = '';
  baseColor = '';

  @Output() setExportEnable: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(AgChartsAngular) agCharts!: AgChartsAngular;

  constructor(
    private hccService: HccService,
    private loggingService: LoggingService
  ) { 
    this.chartsColorPrimary = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-charts-1');
    this.accentColor = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-accent');
    this.baseColor = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-secondary');
  }

  ngOnInit(): void {
    this.hccService.opptyDataIngestion.subscribe(result => {
      this.data = result;
      this.setChartOptions(result);
      this.setExportEnable.emit(true);
    })
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  getBreakdownData(serviceYear: number) {
    this.serviceYear = serviceYear;
    this.hccService.getOpptyDataIngestion(serviceYear);
  }

  setChartOptions(data: any[]) {
    this.options = {
      data: data,
      title: {
        text: `Service Year ${this.serviceYear} Breakdown`,
        fontWeight: 'bold'
      },
      series: [
        {
          type: 'heatmap',
          yKey: 'ServiceMonth',
          yName: 'Service Month',
          xKey: 'IngestionMonth',
          xName: 'Ingestion Month',
          colorKey: 'EventCount',
          colorName: 'Dx Event Count',
          colorRange: [this.baseColor, this.accentColor],
          label: {
            enabled: true,
            formatter: ({ datum, colorKey = '' }) => {
              const value = datum[colorKey];
              return `${formatNumber(value, 'en')}`;
            },
          },
        },
      ],
      gradientLegend: {
        enabled: false
      },
      axes: [
        {
          position: 'bottom',
          type: 'category',
          title: {
            text: 'Ingestion Month',
            fontWeight: 'bold'
          },
          // crossLines: [
          //   {
          //     type: 'range',
          //     range: ['Aug-2022', 'Dec-2022'],
          //     strokeWidth: 0,
          //     fill: this.accentColor,
          //     fillOpacity: 0.2,
          //     label: {
          //       text: 'Mid Yr Run Aug-Dec 2022 - {Total}',
          //       position: 'top'
          //     }
          //   },
          //   {
          //     type: 'range',
          //     range: ['Mar-2023', 'Mar-2023'],
          //     strokeWidth: 0,
          //     fill: this.baseColor,
          //     fillOpacity: 0.3,
          //     label: {
          //       text: 'Due',
          //       position: 'top'
          //     }
          //   },
          //   {
          //     type: 'range',
          //     range: ['Jul-2023', 'Jul-2023'],
          //     strokeWidth: 0,
          //     fill: 'green',
          //     fillOpacity: 0.2,
          //     label: {
          //       text: 'PAID - {Total}',
          //       position: 'top'
          //     }
          //   },
          // ],
        },
        {
          position: 'left',
          type: 'category',
          title: {
            text: 'Service Month',
            fontWeight: 'bold'
          },
        }
      ]
    }
  }

  onExport() {
    const instance = this.agCharts.chart;
    const instanceName = instance.getOptions().title.text;
    const filename = `${instanceName}`;

    AgCharts.download(instance, {
      fileName: filename
    });

    const reportJSON = JSON.stringify({ReportName: ReportNames.OPPORTUNITY_DATA_INGESTION, Filename: filename});
    this.loggingService.logAudit(AuditAction.ReportExported, `Report Name: ${ReportNames.OPPORTUNITY_DATA_INGESTION}, Filename: ${filename}`, reportJSON).subscribe(() => {
      //TODO
    });

  }

}
