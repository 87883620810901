import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-currency-input',
  templateUrl: './currency-input.component.html',
  styleUrls: ['./currency-input.component.scss']
})
export class CurrencyInputComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() color = 'primary';
  @Input() appearance = 'outline';
  @Input() floatLabel = 'always';
  @Input() controlName = 'currency';
  @Input() label = "Amount";
  @Input() placeholder = "Enter Amount";
  @Input() min = 0
  @Input() required = false;
  @Input() defaultValue = -1;
  
  control: FormControl;

  currencyRegex = /^-?\d+(,\d{3})*(\.\d{1,2})?$/;

  constructor() { 
    //TODO
  }

  ngOnInit(): void {
    const defaultValue = this.defaultValue >= 0 ? this.defaultValue : '';
    this.control = this.required ? new FormControl(defaultValue, [Validators.required, Validators.pattern(this.currencyRegex)]) : new FormControl(defaultValue, [Validators.pattern(this.currencyRegex)]);
    this.form.addControl(this.controlName, this.control);
  }

}
