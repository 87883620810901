<div class="service-year-container flex flex-row"
#serviceYearMenuTrigger="matMenuTrigger"
[matTooltip]="getTooltip()"
[matTooltipClass]="'multi-line-tooltip'"
[matMenuTriggerFor]="serviceYearMenu">

    <div class="lable-container flex flex-auto justify-end items-center content-center">
        <h3>Service Year: {{selectedYear}}</h3>
        <mat-icon *ngIf="!serviceYearMenuTrigger.menuOpen">expand_more</mat-icon>
        <mat-icon *ngIf="serviceYearMenuTrigger.menuOpen">expand_less</mat-icon>
    </div>

</div>

<mat-menu #serviceYearMenu="matMenu" class="service-year-menu-panel" xPosition="before">
    <div class="year-picker">
        <button mat-menu-item *ngFor="let year of years" (click)="onYearSelected(year)">
            {{year}}
        </button>
    </div>
</mat-menu>
