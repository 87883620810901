import { Component } from '@angular/core';
import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { IStatusPanelParams } from 'ag-grid-community';

export interface IAgOpptyEditButton {
  updateDisabled(value: boolean): void;
}

@Component({
  selector: 'app-ag-oppty-edit-button',
  templateUrl: './ag-oppty-edit-button.component.html',
  styleUrls: ['./ag-oppty-edit-button.component.scss']
})
export class AgOpptyEditButtonComponent implements IStatusPanelAngularComp {

  params: IStatusPanelParams<any, any>;

  disabled = true;

  mode = 'oppty-list';

  agInit(params: IStatusPanelParams<any, any>): void {
    this.params = params;
    this.mode = params.context.componentParent.mode;
  }

  updateDisabled(value: boolean) {
    this.disabled = value;
  }

  onEditButtonClick() {
    this.params.context.componentParent.onBatchEditClick();
  }
}
