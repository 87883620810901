import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ReplaySubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ACCESS_TOKEN } from '../../storage/constants.lib';
import { DiagnosisEventQuery } from '../../models/diagnosis-event-query';
import { DiagnosisEventFilterValues } from '../../models/diagnosis-event-filter-values';
import { PayerConfigQuery } from '../../models/payer-config-query';
import { PayerConfigFilterValues } from '../../models/payer-config-filter-values';
import { MatDialog } from '@angular/material/dialog';
import { ServiceFormatter } from '../../helpers/service-formatter';
import { OkDialogComponent, OkDialogModel } from '../../dialogs/ok-dialog/ok-dialog.component';
import { AuditLogQuery } from '../../models/grid-query';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  private endpoint = `${environment.baseURL}/api/`;

  exportInProgress: ReplaySubject<boolean>;

  constructor(private http: HttpClient, private dialog: MatDialog) { 
    this.exportInProgress = new ReplaySubject<boolean>(1);
    this.exportInProgress.next(false);
  }

  exportDiagnosisEvents(query: DiagnosisEventQuery, sourceComp: string, docType: string, templateID: number, filterValues: DiagnosisEventFilterValues) {

    return this.http.post<any>(this.endpoint + "diagnosisevent/export", {
      Query: query,
      Source: sourceComp,
      DocType: docType,
      FilterValues: filterValues,
      TemplateID: templateID
    }, ServiceFormatter.getHttpOptions())
    .subscribe(result => {
      const message = [
        `Requested records are being exported and will be delivered to the email address associated to your username. Dependent upon the data request, 
        it could take several minutes until the export email is available.`,

        `Please capture the following password to open any exports generated today.`,

        `Password: ${result.Result}`
      ];

      this.dialog.open(OkDialogComponent, new OkDialogModel(
        "Export Authentication",
        message
      ));
    });
  }

  exportAuditLogs(query: AuditLogQuery) {

    return this.http.post<any>(this.endpoint + "log/export", query, ServiceFormatter.getHttpOptions())
    .subscribe(result => {
      const message = [
        `Requested records are being exported and will be delivered to the email address associated to your username. Dependent upon the data request, 
        it could take several minutes until the export email is available.`,

        `Please capture the following password to open any exports generated today.`,

        `Password: ${result.Result}`
      ];

      this.dialog.open(OkDialogComponent, new OkDialogModel(
        "Export Authentication",
        message
      ));
    });
  }

  exportPayerConfig(query: PayerConfigQuery, filterValues: PayerConfigFilterValues) {

    this.exportInProgress.next(true);

    const token = localStorage.getItem(ACCESS_TOKEN);
    let headers = new HttpHeaders();
    headers = headers.append("Authorization", "Bearer " + token);

    return this.http.post<any>(this.endpoint + "payer/export", {
      Query: query,
      FilterValues: filterValues
    }, 
    {
      headers: headers
    }
    ).subscribe(result => {
      if(!result) {
        return;
      }

      const blob = new Blob([Uint8Array.from(atob(result.FileContents), c => c.charCodeAt(0))]);
      const a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.setAttribute("download", result.FileDownloadName);
      a.click();

    }, error => {
      this.exportInProgress.next(false);
    },
    () => {
      this.exportInProgress.next(false);
    });
  }
}
