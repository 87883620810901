import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FieldFilterService } from '../../../services/filters/field-filter.service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { HccModelVersion } from '../../../models/hcc-model-version';

@Component({
  selector: 'app-hcc-model-version-select',
  templateUrl: './hcc-model-version-select.component.html',
  styleUrls: ['./hcc-model-version-select.component.scss']
})
export class HccModelVersionSelectComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() color = 'primary';
  @Input() appearance = 'outline';
  @Input() label = 'Model Version';
  @Input() placeholder = '';
  @Input() floatLabel = 'always';
  @Input() multiple = true;
  @Input() controlName = 'model-version';
  @Input() toolTipPosition = 'above';
  @Input() required = false;
  @Input() queryOnInit = false;
  @Input() categoryIDs = [0];
  @Input() disabled = false;
  @Input() defaultValue = null;

  options: HccModelVersion[] = [];
  allOption = {ID: 0, ModelCategory: 0, Version: 'All', StartDate: '', EndDate: '', Selected: false};
  control: FormControl<HccModelVersion[] | HccModelVersion>;

  subs: Subscription[] = []

  @Output() ready = new EventEmitter();
  @Output() valueChanged = new EventEmitter();

  @ViewChild(MatSelect) select: MatSelect;

  constructor(private filterService: FieldFilterService) { }

  ngOnInit(): void {
    const validators = this.required ? [Validators.required] : [];
    this.control = this.multiple ? new FormControl<HccModelVersion[]>(this.defaultValue, validators) : new FormControl<HccModelVersion>(this.defaultValue, validators);
    this.form.addControl(this.controlName, this.control);

    if(this.disabled) {
      this.control.disable();
    }

    if(this.queryOnInit) {
      this.getOptionData(this.categoryIDs);
    }
  }

  getOptionData(categoryIDs: number[]) {
    this.subs.push(this.filterService.getHccModelVersionsByCategory(categoryIDs).subscribe(result => {
      this.options = result;
      this.ready.emit(this.options);
    }));
  }

  setValues(values: HccModelVersion[]) {
    if(!values) {
      return;
    }

    if(values.length == 0) {
      this.control.setValue([this.allOption]);
      this.onToggleItem(this.select.options.get(0));
      return;
    }

    this.control.setValue(values);
  }

  setValuesByIDs(ids: number[]) {
    if(ids.length == 0) {
      this.control.setValue([this.allOption]);
      this.onToggleItem(this.select.options.get(0));
      return;
    }

    this.control.setValue(this.options.filter(i => ids.includes(i.ID)));
  }

  setSingleModeValue(value: HccModelVersion) {
    this.control.setValue(value);
  }

  setSingleModeValueByID(id: number) {
    const value = this.options.find(i => i.ID == id);
    this.control.setValue(value);
    this.valueChanged.emit(value);
  }

  getValues() {
    if(this.allOption.Selected) {
      return [this.allOption];
    }

    return this.control?.value;
  }

  onToggleItem(item: MatOption) {
    if(item.value == this.allOption) {
      if(item.selected) {
        this.allOption.Selected = true;
        this.select.options.forEach(o => o.select());
        return;
      }

      this.allOption.Selected = false;
      this.select.options.forEach(o => o.deselect());

    } else {
      
      const allItem = this.select.options.get(0);

      if(!item.selected && allItem.selected) {
        this.allOption.Selected = false;
        allItem.deselect();
        return;
      }

      if(item.selected && !allItem.selected) {
        const check = this.checkOptionAllStatus();
        if(check) {
          this.allOption.Selected = true;
          allItem.select();
        }
      }
    }
  }

  onSelectionChange() {
    const values = this.control?.value as HccModelVersion[];
    const options = this.allOption.Selected ? values.slice(1) : values;
    this.valueChanged.emit(options);
  }

  checkOptionAllStatus() {
    let result = true;
    this.select.options.forEach(o => {
      if(o.value != this.allOption && !o.selected) {
        result = false;
      }
    });

    return result;
  }

  getTooltipString() {
    if(!this.multiple) {
      return '';
    }

    let result = '';

    const values = this.control?.value as HccModelVersion[];
    const options = this.allOption.Selected ? values.slice(1) : values;

    options.forEach((o, index) => {
      result += o.Version;

      if(index < options.length - 1) {
        result += ', ';
      }
    })
    return result;
  }

  getSelectCount() {
    const values = this.control.value as HccModelVersion[];

    return (values?.length <= this.options.length) 
      ? values?.length 
      : values?.length - 1;
  }
}
