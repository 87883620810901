import { Injectable } from '@angular/core';
import { ServiceFormatter } from '../../helpers/service-formatter';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class JobsService {
  private endpoint = ServiceFormatter.endpoint;

  constructor(private http: HttpClient) { 
    //TODO
  }

  setRecurringExportHashRefreshJob(orgID: string, interval: number) {
    const params = new HttpParams()
    .set("orgID", orgID)
    .set("interval", interval.toString());
    return this.http.post(this.endpoint + "jobs/export-hash-check", null, ServiceFormatter.getHttpOptions(params));
  }

  triggerExportHashRefresh(orgID: string, interval: number) {
    const params = new HttpParams()
    .set("orgID", orgID)
    .set("interval", interval.toString());

    return this.http.post(this.endpoint + "jobs/export-hash-refresh", null, ServiceFormatter.getHttpOptions(params));
  }
}
