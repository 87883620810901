<div class="flex flex-row gap-[3px]">
    <div class="infoIconHeaderLable flex justify-start items-center content-center">
        {{params.displayName}}
    </div>
    <div class="flex justify-center items-center content-center">
        <mat-icon
        style="font-size: 15px; height: auto;"
        [matTooltip]="iconTooltip"
        [matTooltipClass]="'app-tooltip'">info</mat-icon>
    </div>
</div>
