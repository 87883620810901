<mat-icon [matMenuTriggerFor]="actionItemMenu">more_horiz</mat-icon>

<mat-menu #actionItemMenu="matMenu">
    <button mat-menu-item
    *ngIf="currentUser.app_metadata.systemAdmin == 'true' || item.app_metadata.systemAdmin != 'true'"
    (click)="onItemClick('Change Password')">
        <span>Change Password</span>
    </button>

    <button mat-menu-item
    *ngIf="(currentUser.app_metadata.systemAdmin == 'true' && item.app_metadata.systemAdmin != 'true' && item.enabled == '1') || item.app_metadata.systemAdmin != 'true' && item.enabled == '1'"
    (click)="onItemClick('Set User to Inactive')">
        <span>Set User to Inactive</span>
    </button>

    <button mat-menu-item
    *ngIf="(currentUser.app_metadata.systemAdmin == 'true' && item.app_metadata.systemAdmin != 'true' && item.enabled == '0') || item.app_metadata.systemAdmin != 'true' && item.enabled == '0'"
    (click)="onItemClick('Set User to Active')">
        <span>Set User to Active</span>
    </button>

    <button mat-menu-item
    *ngIf="currentUser.app_metadata.systemAdmin == 'true' || item.app_metadata.systemAdmin != 'true'"
    (click)="onItemClick('Edit User')">
        <span>Edit User</span>
    </button>

    <button mat-menu-item
    *ngIf="item.app_metadata.role =='user' || item.app_metadata.role =='readonlyuser'"
    (click)="onItemClick('Make Site Admin')">
        <span>Make Site Admin</span>
    </button>

    <button mat-menu-item
    *ngIf="item.app_metadata.role =='admin' || item.app_metadata.role =='readonlyuser'"
    (click)="onItemClick('Make Standard User')">
        <span>Make Standard User</span>
    </button>

    <button mat-menu-item
    *ngIf="item.blocked == true"
    (click)="onItemClick('Unblock User')">
        <span>Unblock User</span>
    </button>
</mat-menu>
