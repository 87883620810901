import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { Org } from '../../../models/org';

@Component({
  selector: 'app-org-list-action-icon-renderer',
  templateUrl: './org-list-action-icon-renderer.component.html',
  styleUrls: ['./org-list-action-icon-renderer.component.scss']
})
export class OrgListActionIconRendererComponent implements ICellRendererAngularComp {

  params: ICellRendererParams<any, any>;
  item: Org;

  constructor() {
    //TODO
  }

  agInit(params: ICellRendererParams<any, any>): void {
    this.params = params;
    this.item = params.data;
  }
  
  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }

  onItemClick(action) {
    this.params.context.componentParent.onActionItemClick({item: this.params.data, action: action});
  }

}
