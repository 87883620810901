<div class="progress-allocation-user-container">

    <div class="pau-header-row">
        <div class="pau-header-item grid grid-cols-5">
            <div class="justify-start items-center content-center"></div>
    
            <div class="progress-cell flex flex-auto justify-end items-center content-center">
                <h3>Progress</h3>
            </div>

            <div class="closed-cell flex flex-auto justify-end items-center content-center" *ngIf="expanded">
                <h3>Closed</h3>
            </div>

            <div class="open-cell flex flex-auto justify-end items-center content-center" *ngIf="expanded">
                <h3>Open</h3>
            </div>
    
            <div class="assigned-cell flex flex-auto justify-end items-center content-center">
                <h3>Assigned</h3>
            </div>
        </div>
    </div>

    <div class="pau-list-item grid grid-cols-5" *ngFor="let item of userAllocationList">

        <div class="name-cell flex flex-auto justify-start items-center content-center" [routerLink]="'/opportunity-list'">
            <app-determinate-spinner
            [diameter]="iconDiameter"
            [strokeWidth]="5"
            [display]="getUserInitials(item.User)"
            [value]="item.Progress * 100"
            [matTooltip]="getUserName(item.User)"
            [matTooltipClass]="'app-tooltip'"
            [matTooltipPosition]="'above'"
            [routerLink]="['/opportunity-list']" 
            [queryParams]="{user: getUserName(item.User)}"></app-determinate-spinner>
        </div>

        <div class="progress-cell flex flex-auto justify-end items-center content-center">
            <h3>{{item.Progress | percent}}</h3>
        </div>

        <div class="closed-cell flex flex-auto justify-end items-center content-center" *ngIf="expanded">
            <h3>{{item.Closed | number}}</h3>
        </div>

        <div class="open-cell flex flex-auto justify-end items-center content-center" *ngIf="expanded">
            <h3>{{item.Open | number}}</h3>
        </div>

        <div class="assigned-cell flex flex-auto justify-end items-center content-center">
            <h3>{{item.Assigned | number}}</h3>
        </div>
    </div>

    <div class="pau-list-item flex-row" *ngIf="userAllocationList.length == 0">
        <div class="flex flex-auto justify-center items-center content-center">
            No Users to Display
        </div>
    </div>

    <div class="pau-footer-row grid grid-cols-5">
        <div class="name-cell flex-auto justify-start items-center content-center pl-2">
            <strong>Total</strong>
        </div>
    
        <div class="progress-cell flex flex-auto"></div>
    
        <div class="closed-cell flex flex-auto justify-end items-center content-center" *ngIf="expanded">
            <h3>{{closedTotal | number}}</h3>
        </div>
    
        <div class="open-cell flex flex-auto justify-end items-center content-center" *ngIf="expanded">
            <h3>{{openTotal | number}}</h3>
        </div>
    
        <div class="assigned-cell flex flex-auto justify-end items-center content-center">
            <h3>{{grandTotal | number}}</h3>
        </div>
    </div>
</div>
