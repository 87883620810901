<mat-card class="vc-card mat-elevation-z5">
    <mat-card-header>
        <div class="title-container flex">
            <h2 class="basis-4/5">Dx Events Summary</h2>
            <div class="flex grow justify-end align-middle items-center">
                <span class="material-symbols-outlined report-icon" *ngIf="reportingEnabled" 
                [routerLink]="['/reporting']" [queryParams]="{report: DIAGNOSIS_EVENTS_SUMMARY_NAME, year: serviceYear}"
                [matTooltip]="'Generate Report'"
                [matTooltipClass]="'app-tooltip'"
                [matTooltipPosition]="'above'">export_notes</span>

                <span class="material-symbols-outlined report-icon" *ngIf="!reportingEnabled" aria-disabled="true"
                [matTooltip]="'Contact Admin for Access to Generate Report'"
                [matTooltipClass]="'app-tooltip'"
                [matTooltipPosition]="'above'">export_notes</span>
            </div>
        </div>
    </mat-card-header>

    <mat-card-content class="vc-card-summary-grid">
        <mat-grid-list cols="6" rowHeight="40px" gutterSize="0">

            <mat-grid-tile class="header-tile" [colspan]="4">
                <div class="summary-expand-icon basis-[7%] md:basis-[10%] justify-start align-middle">
                    <mat-icon *ngIf="!expandInfoSwitch" (click)="expandInfoSwitch = true">expand_more</mat-icon>
                    <mat-icon *ngIf="expandInfoSwitch" (click)="expandInfoSwitch = false">expand_less</mat-icon>
                </div>

                <div class="toggle-container grow">
                    <mat-slide-toggle color="accent" [(ngModel)]="summaryDxSwitch">
                        <span>DX View</span>
                    </mat-slide-toggle>
                </div>
            </mat-grid-tile>

            <mat-grid-tile class="header-tile" [colspan]="1">
                <div class="flex grow justify-end align-middle">
                    <h2 *ngIf="!summaryDxSwitch">HCCs</h2>
                    <h2 *ngIf="summaryDxSwitch">DXs</h2>
                </div>
            </mat-grid-tile>

            <mat-grid-tile class="header-tile">
                <div class="flex grow justify-end align-middle">
                    <h2>%</h2>
                </div>
            </mat-grid-tile>

            <!--EMR Captured-->
            <mat-grid-tile [colspan]="3">
                <div class="flex grow justify-start align-middle">
                    <h3>Documented Dx Events</h3>
                </div>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="2">
                <div class="flex grow justify-end align-middle">
                    <h3 *ngIf="!summaryDxSwitch">{{data.HCCDocumentedDiagnosisEvents | number}}</h3>
                    <h3 *ngIf="summaryDxSwitch">{{data.DxDocumentedDiagnosisEvents | number}}</h3>
                </div>
            </mat-grid-tile>

            <mat-grid-tile></mat-grid-tile>

            <!--Post/Supp Feed-->
            <mat-grid-tile [colspan]="4">
                <div class="flex grow justify-start align-middle">
                    <h3>Posted Via Claim or Supp Feed</h3>
                </div>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1">
                <div class="flex grow justify-end align-middle">
                    <p *ngIf="!summaryDxSwitch" [ngClass]="expandInfoSwitch ? 'bold-tile': ''">{{data.HCCPostedViaClaimOrSuppFeed | number}}</p>
                    <p *ngIf="summaryDxSwitch" [ngClass]="expandInfoSwitch ? 'bold-tile': ''">{{data.DxPostedViaClaimOrSuppFeed | number}}</p>
                </div>
            </mat-grid-tile>

            <mat-grid-tile>
                <div class="flex grow justify-end align-middle">
                    <p *ngIf="!summaryDxSwitch" [ngClass]="expandInfoSwitch ? 'bold-tile': ''">{{getPercentValue(data.HCCPostedViaClaimOrSuppFeedPercent) | percent}}</p>
                    <p *ngIf="summaryDxSwitch" [ngClass]="expandInfoSwitch ? 'bold-tile': ''">{{getPercentValue(data.DxPostedViaClaimOrSuppFeedPercent) | percent}}</p>
                </div>
            </mat-grid-tile>

            <!--Post/Supp Feed Expanded-->
            <mat-grid-tile class="expanded-tile" [colspan]="4" *ngIf="expandInfoSwitch">
                <div class="flex grow justify-start align-middle">
                    <p>Billing Charge Posted on Claim</p>
                </div>
            </mat-grid-tile>

            <mat-grid-tile class="expanded-tile" [colspan]="1" *ngIf="expandInfoSwitch">
                <div class="flex grow justify-end align-middle">
                    <p *ngIf="!summaryDxSwitch">{{data.HCCPostedOnClaim | number}}</p>
                    <p *ngIf="summaryDxSwitch">{{data.DxPostedOnClaim | number}}</p>
                </div>
            </mat-grid-tile>

            <mat-grid-tile class="expanded-tile" *ngIf="expandInfoSwitch">
                <div class="flex grow justify-end align-middle">
                    <p *ngIf="!summaryDxSwitch">{{getPercentValue(data.HCCPostedOnClaimPercent) | percent}}</p>
                    <p *ngIf="summaryDxSwitch">{{getPercentValue(data.DxPostedOnClaimPercent) | percent}}</p>
                </div>
            </mat-grid-tile>

            <mat-grid-tile class="expanded-tile" [colspan]="4" *ngIf="expandInfoSwitch">
                <div class="flex grow justify-start align-middle">
                    <p>Billing Charge Posted on Supp Feed</p>
                </div>
            </mat-grid-tile>

            <mat-grid-tile class="expanded-tile" [colspan]="1" *ngIf="expandInfoSwitch">
                <div class="flex grow justify-end align-middle">
                    <p *ngIf="!summaryDxSwitch">{{data.HCCPostedOnSuppFeed | number}}</p>
                    <p *ngIf="summaryDxSwitch">{{data.DxPostedOnSuppFeed | number}}</p>
                </div>
            </mat-grid-tile>

            <mat-grid-tile class="expanded-tile" *ngIf="expandInfoSwitch">
                <div class="flex grow justify-end align-middle">
                    <p *ngIf="!summaryDxSwitch">{{getPercentValue(data.HCCPostedOnSuppFeedPercent) | percent}}</p>
                    <p *ngIf="summaryDxSwitch">{{getPercentValue(data.DxPostedOnSuppFeedPercent) | percent}}</p>
                </div>
            </mat-grid-tile>

            <!--Non-Actionable-->
            <mat-grid-tile [colspan]="4">
                <div class="flex grow justify-start align-middle">
                    <h3>NonActionable</h3>
                </div>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1">
                <div class="flex grow justify-end align-middle">
                    <p *ngIf="!summaryDxSwitch" [ngClass]="expandInfoSwitch ? 'bold-tile': ''">{{data.HCCNonActionable | number}}</p>
                    <p *ngIf="summaryDxSwitch" [ngClass]="expandInfoSwitch ? 'bold-tile': ''">{{data.DxNonActionable | number}}</p>
                </div>
            </mat-grid-tile>

            <mat-grid-tile>
                <div class="flex grow justify-end align-middle">
                    <p *ngIf="!summaryDxSwitch" [ngClass]="expandInfoSwitch ? 'bold-tile': ''">{{getPercentValue(data.HCCNonActionablePercent) | percent}}</p>
                    <p *ngIf="summaryDxSwitch" [ngClass]="expandInfoSwitch ? 'bold-tile': ''">{{getPercentValue(data.DxNonActionablePercent) | percent}}</p>
                </div>
            </mat-grid-tile>

            <!--Non-Actionable Expanded-->
            <div *ngIf="expandInfoSwitch">
                <div *ngFor="let item of data.NonActionables">
                    <mat-grid-tile class="expanded-tile"  [colspan]="4">
                        <div class="flex grow justify-start align-middle">
                            <p>{{item.RootCauseDescription}}</p>
                        </div>
                    </mat-grid-tile>
        
                    <mat-grid-tile class="expanded-tile" [colspan]="1">
                        <div class="flex grow justify-end align-middle">
                            <p *ngIf="!summaryDxSwitch">{{item.HCCCount | number}}</p>
                            <p *ngIf="summaryDxSwitch">{{item.DXCount | number}}</p>
                        </div>
                    </mat-grid-tile>
        
                    <mat-grid-tile class="expanded-tile">
                        <div class="flex grow justify-end align-middle">
                            <p *ngIf="!summaryDxSwitch">{{getPercentValue(item.HCCPercent) | percent}}</p>
                            <p *ngIf="summaryDxSwitch">{{getPercentValue(item.DXPercent) | percent}}</p>
                        </div>
                    </mat-grid-tile>
                </div>
            </div>

            <!--Raps/MAO or MOR-->
            <mat-grid-tile [colspan]="4">
                <div class="flex grow justify-start align-middle">
                    <h3>On RAPs/MAO or MOR</h3>
                </div>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1">
                <div class="flex grow justify-end align-middle">
                    <div class="flex grow justify-end align-middle">
                        <p *ngIf="!summaryDxSwitch" [ngClass]="expandInfoSwitch ? 'bold-tile': ''">{{data.HCCOnRapsOrMOR | number}}</p>
                        <p *ngIf="summaryDxSwitch" [ngClass]="expandInfoSwitch ? 'bold-tile': ''">{{data.DxOnRapsOrMOR | number}}</p>
                    </div>
                </div>
            </mat-grid-tile>

            <mat-grid-tile>
                <div class="flex grow justify-end align-middle">
                    <p *ngIf="!summaryDxSwitch" [ngClass]="expandInfoSwitch ? 'bold-tile': ''">{{getPercentValue(data.HCCOnRapsOrMORPercent) | percent}}</p>
                    <p *ngIf="summaryDxSwitch" [ngClass]="expandInfoSwitch ? 'bold-tile': ''">{{getPercentValue(data.DxOnRapsOrMORPercent) | percent}}</p>
                </div>
            </mat-grid-tile>

            <!--Raps/MAO or MOR Expanded-->
            <mat-grid-tile class="expanded-tile" [colspan]="4" *ngIf="expandInfoSwitch">
                <div class="flex grow justify-start align-middle">
                    <p>RAPs/MAO</p>
                </div>
            </mat-grid-tile>

            <mat-grid-tile class="expanded-tile" [colspan]="1" *ngIf="expandInfoSwitch">
                <div class="flex grow justify-end align-middle">
                    <p *ngIf="!summaryDxSwitch">{{data.HCCOnRAPS | number}}</p>
                    <p *ngIf="summaryDxSwitch">{{data.DxOnRAPS | number}}</p>
                </div>
            </mat-grid-tile>

            <mat-grid-tile class="expanded-tile" *ngIf="expandInfoSwitch">
                <div class="flex grow justify-end align-middle">
                    <p *ngIf="!summaryDxSwitch">{{getPercentValue(data.HCCOnRAPSPercent) | percent}}</p>
                    <p *ngIf="summaryDxSwitch">{{getPercentValue(data.DxOnRAPSPercent) | percent}}</p>
                </div>
            </mat-grid-tile>

            <mat-grid-tile class="expanded-tile" [colspan]="4" *ngIf="expandInfoSwitch">
                <div class="flex grow justify-start align-middle">
                    <p>MOR</p>
                </div>
            </mat-grid-tile>

            <mat-grid-tile class="expanded-tile" [colspan]="1" *ngIf="expandInfoSwitch">
                <div class="flex grow justify-end align-middle">
                    <p *ngIf="!summaryDxSwitch">{{data.HCCOnMOR | number}}</p>
                    <p *ngIf="summaryDxSwitch">{{data.DxOnMOR| number}}</p>
                </div>
            </mat-grid-tile>

            <mat-grid-tile class="expanded-tile" *ngIf="expandInfoSwitch">
                <div class="flex grow justify-end align-middle">
                    <p *ngIf="!summaryDxSwitch">{{getPercentValue(data.HCCOnMORPercent) | percent}}</p>
                    <p *ngIf="summaryDxSwitch">{{getPercentValue(data.DxOnMORPercent) | percent}}</p>
                </div>
            </mat-grid-tile>

            <!--Posted Raps/MAO or MOR-->
            <mat-grid-tile [colspan]="4">
                <div class="flex grow justify-start align-middle">
                    <h3>Posted and on RAPs/MAO or MOR</h3>
                </div>
            </mat-grid-tile>

            <mat-grid-tile [colspan]="1">
                <div class="flex grow justify-end align-middle">
                    <p *ngIf="!summaryDxSwitch" [ngClass]="expandInfoSwitch ? 'bold-tile': ''">{{data.HCCPostedAndOnRapsOrMOR | number}}</p>
                    <p *ngIf="summaryDxSwitch" [ngClass]="expandInfoSwitch ? 'bold-tile': ''">{{data.DxPostedAndOnRapsOrMOR | number}}</p>
                </div>
            </mat-grid-tile>

            <mat-grid-tile>
                <div class="flex grow justify-end align-middle">
                    <p *ngIf="!summaryDxSwitch" [ngClass]="expandInfoSwitch ? 'bold-tile': ''">{{getPercentValue(data.HCCPostedAndOnRapsOrMORPercent) | percent}}</p>
                    <p *ngIf="summaryDxSwitch" [ngClass]="expandInfoSwitch ? 'bold-tile': ''">{{getPercentValue(data.DxPostedAndOnRapsOrMORPercent) | percent}}</p>
                </div>
            </mat-grid-tile>

            <!--Posted Raps/MAO or MOR Expanded-->
            <mat-grid-tile class="expanded-tile" [colspan]="4" *ngIf="expandInfoSwitch">
                <div class="flex grow justify-start align-middle">
                    <p>Posted: Not on RAPs/MAO or MOR</p>
                </div>
            </mat-grid-tile>

            <mat-grid-tile class="expanded-tile" [colspan]="1" *ngIf="expandInfoSwitch">
                <div class="flex grow justify-end align-middle">
                    <p *ngIf="!summaryDxSwitch">{{data.HCCPostedNotOnRapsOrMOR | number}}</p>
                    <p *ngIf="summaryDxSwitch">{{data.DxPostedNotOnRapsOrMOR | number}}</p>
                </div>
            </mat-grid-tile>

            <mat-grid-tile class="expanded-tile" *ngIf="expandInfoSwitch">
                <div class="flex grow justify-end align-middle">
                    <p *ngIf="!summaryDxSwitch">{{getPercentValue(data.HCCPostedNotOnRapsOrMORPercent) | percent}}</p>
                    <p *ngIf="summaryDxSwitch">{{getPercentValue(data.DxPostedNotOnRapsOrMORPercent) | percent}}</p>
                </div>
            </mat-grid-tile>

            <mat-grid-tile class="expanded-tile" [colspan]="4" *ngIf="expandInfoSwitch">
                <div class="flex grow justify-start align-middle">
                    <p>Not Posted: On RAPs/MAO or MOR</p>
                </div>
            </mat-grid-tile>

            <mat-grid-tile class="expanded-tile" [colspan]="1" *ngIf="expandInfoSwitch">
                <div class="flex grow justify-end align-middle">
                    <p *ngIf="!summaryDxSwitch">{{data.HCCNotPostedButOnRapsOrMOR | number}}</p>
                    <p *ngIf="summaryDxSwitch">{{data.DxNotPostedButOnRapsOrMOR | number}}</p>
                </div>
            </mat-grid-tile>

            <mat-grid-tile class="expanded-tile" *ngIf="expandInfoSwitch">
                <div class="flex grow justify-end align-middle">
                    <p *ngIf="!summaryDxSwitch">{{getPercentValue(data.HCCNotPostedButOnRapsOrMORPercent) | percent}}</p>
                    <p *ngIf="summaryDxSwitch">{{getPercentValue(data.DxNotPostedButOnRapsOrMORPercent) | percent}}</p>
                </div>
            </mat-grid-tile>
        </mat-grid-list>

        <label class="data-refresh-label" *ngIf="data.DateUpdated">Data Refreshed {{data.DateUpdated | date: 'MM/dd/YYYY'}}</label>
    </mat-card-content>
</mat-card>
