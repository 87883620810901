import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/authentication/auth.service';
import { APIService } from '../../../services/api/api.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit, AfterViewInit {
  profile: any;
  constructor(public auth: AuthService, private api: APIService, public router: Router) { }

  ngOnInit() {
    console.log('Callback oninit');

  }

  ngAfterViewInit() {
    console.log('Callback after view');
  }


}
