import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ProgressAllocationPayerItem } from '../../../models/progress-allocation-payer-item';
import { ProgressAllocationService } from '../../../services/progress/progress-allocation.service';
import { SettingsService } from '../../../services/settings/settings.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-progress-allocation-payer',
  templateUrl: './progress-allocation-payer.component.html',
  styleUrls: ['./progress-allocation-payer.component.scss']
})
export class ProgressAllocationPayerComponent implements OnInit, OnDestroy {

  @Input() expanded = false;

  payerItems: ProgressAllocationPayerItem[];
  serviceYear = 0;

  closedTotal = 0;
  openTotal = 0;
  grandTotal = 0;

  subs: Subscription[] = [];

  constructor(
    private allocationService: ProgressAllocationService,
    private settingsService: SettingsService) { 
    //TODO
  }

  ngOnInit(): void {
    this.subs.push(this.settingsService.serviceYear.subscribe(result => {
      if(!result) {
        return;
      }

      this.serviceYear = result;
      this.updateData();
    }));

    this.subs.push(this.allocationService.payerAllocation.subscribe(result => {
      if(!result) {
        return;
      }

      this.payerItems = result;
      this.getTotals();
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  updateData() {
    this.allocationService.getPayerItems(this.serviceYear);
  }

  getTotals() {
    this.closedTotal = 0;
    this.openTotal = 0;
    this.grandTotal = 0;
    
    this.payerItems.forEach(i => {
      this.closedTotal = this.closedTotal += i.Closed;
      this.openTotal = this.openTotal += i.Open;
      this.grandTotal = this.grandTotal += i.Total;
    })
  }

}
