<h1 mat-dialog-title class="unselectable">
  {{ title }}
</h1>

<div mat-dialog-content class="unselectable">
  <p>{{ message }}</p>
  <p *ngFor="let m of additionalMessages">
    {{m}}
  </p>
</div>

<span class="unselectable" mat-dialog-content *ngIf="confirmKey != ''">Type "{{confirmKey}}" to confirm</span>
<mat-form-field *ngIf="confirmKey != ''">
  <input matInput autocomplete="off" *ngIf="confirmKey != ''" type="text" maxlength="45" [(ngModel)]="textData" />
</mat-form-field>

<div mat-dialog-actions class="unselectable" style="justify-content: center;">

  <button [disabled]="isConfirmDisabled()" mat-stroked-button color="warn" color="primary"
    (click)="onConfirm()">Yes</button>
  <button mat-stroked-button color="warn" (click)="onDismiss()">No</button>
</div>