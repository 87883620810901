import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

@Component({
  selector: 'app-ag-edit-icon-renderer',
  templateUrl: './ag-edit-icon-renderer.component.html',
  styleUrls: ['./ag-edit-icon-renderer.component.scss']
})
export class AgEditIconRendererComponent implements ICellRendererAngularComp {

  params: ICellRendererParams<any, any>;
  iconName = 'edit';

  agInit(params: ICellRendererParams<any, any> & {iconName: string}): void {
    this.params = params;
  }
  
  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }

  onIconClick(event) {
    event.stopImmediatePropagation();
    this.params.context.componentParent.onListItemEditClick(this.params.data);
  }

  setIcon(name: string) {
    this.iconName = name;
  }

}
