<div class="pat-container">

    <div class="pat-header-row grid grid-cols-3">

        <div class="assign-cell">
            <h3>Assigned</h3>
        </div>

        <div class="unassigned-cell">
            <h3>Unassigned</h3>
        </div>

        <div class="total-cell">
            <h3
            [matTooltip]="'Open Opportunities'"
            [matTooltipClass]="'app-tooltip'"
            [matTooltipPosition]="'above'">Total</h3>
        </div>
    </div>

    <mat-divider></mat-divider>

    <div class="pat-list-item grid grid-cols-3">

        <div class="assign-cell">
            <h3>{{totals.Assigned | number}}</h3>
        </div>

        <div class="unassigned-cell">
            <h3>{{totals.Total - totals.Assigned | number}}</h3>
        </div>

        <div class="total-cell">
            <h3
            [matTooltip]="'Open Opportunities'"
            [matTooltipClass]="'app-tooltip'"
            [matTooltipPosition]="'below'">{{totals.Total | number}}</h3>
        </div>
    </div>
</div>
