import { formatDate } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

@Component({
  selector: 'app-ag-date-picker-cell-editor',
  templateUrl: './ag-date-picker-cell-editor.component.html',
  styleUrls: ['./ag-date-picker-cell-editor.component.scss']
})
export class AgDatePickerCellEditorComponent implements ICellEditorAngularComp {

  control: FormControl<Date> = new FormControl<Date>(null, [Validators.required]);
  
  @ViewChild(MatInput) input: MatInput;
  @ViewChild(MatDatepicker) picker: MatDatepicker<any>;

  agInit(params: ICellEditorParams<any, any, any>): void {
    const initalDate = new Date(params.value);
    this.control.setValue(initalDate);
  }

  getGui() {
    return this.input;
  }

  afterGuiAttached(): void {
    this.input.focus();
    //this.picker.open();
  }

  getValue() {
    return this.control.value ? formatDate(this.control.value, "M/d/yyyy", "en") : null;
  }

}
