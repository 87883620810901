import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ColDef, ColGroupDef, IAggFunc, IAggFuncParams, RowClassRules, StatusPanelDef } from 'ag-grid-enterprise';
import { CalculationsService } from '../../../../../services/calculations/calculations.service';
import { Subscription } from 'rxjs';
import { AgGridAngular } from 'ag-grid-angular';
import { AgNoRowsOverlayComponent } from '../../../../ag-grid-components/ag-no-rows-overlay/ag-no-rows-overlay.component';
import { DxsrExcelStyling } from '../dxsr-excel-styling';
import { AuditAction } from '../../../../../models/audit-action';
import { LoggingService } from '../../../../../services/api/logging.service';
import { AgGridFunctions } from '../../../../../helpers/ag-grid-functions';
import { HccPatientDistributionReportItem } from '../../../../../models/hcc-patient-distribution-report-item';
import { AgLabelStatusPanelComponent, IAgLabelPanel } from '../../../../ag-grid-components/ag-label-status-panel/ag-label-status-panel.component';
import { formatNumber } from '@angular/common';
import { AgLoadingOverlayComponent } from '../../../../ag-grid-components/ag-loading-overlay/ag-loading-overlay.component';

@Component({
  selector: 'app-hcc-patient-distribution-report',
  templateUrl: './hcc-patient-distribution-report.component.html',
  styleUrls: ['./hcc-patient-distribution-report.component.scss']
})
export class HccPatientDistributionReportComponent implements OnInit, OnDestroy {

  data: HccPatientDistributionReportItem[] = [];

  columnDefs: (ColDef | ColGroupDef)[] = [];

  defaultColDef: ColDef = {
    menuTabs: [],
    minWidth: 150,
    maxWidth: 150
  };

  noRowsOverlayComponent: any = AgNoRowsOverlayComponent;
  noRowsOverlayComponentParams: any = {
    noRowsMessageFunc: () => 'No results returned',
  };

  loadingOverlayComponent: any = AgLoadingOverlayComponent;

  statusBar: {
    statusPanels: StatusPanelDef[];
  } = {
    statusPanels: [
      {
        statusPanel: AgLabelStatusPanelComponent,
        key: 'total-panel',
        statusPanelParams: {
          label: 'Total Patients'
        }
      },
    ],
  };

  excelStyles = DxsrExcelStyling.excelStyles2;

  gridContext: any;

  rowItems: any[] = [];
  gridReady = false;

  dxSwitch = false;

  currentYear = 0;
  totalPatients = 0;

  currentRowHeight = 140;
  currentGridWidth = 282;

  subs: Subscription[] = [];

  @Output() ready: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() setExportEnable: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('grid') agGrid!: AgGridAngular;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setGridColumnsToFit();
  }

  constructor(
    private calculationsService: CalculationsService,
    private loggingService: LoggingService) {
    this.gridContext = { componentParent: this };
  }

  ngOnInit(): void {
    //TODO
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  onGridReady(event) {
    this.ready.emit(true);
  }

  updateServiceYear(serviceYear: number) {
    this.currentYear = serviceYear;
    this.showGridsLoadingOverlay();

    this.subs.push(this.calculationsService.getHccPatientDistribution(serviceYear).subscribe(result => {

      this.data = result.Items;
      const total = formatNumber(result.TotalPatients, 'en');

      this.currentRowHeight = 66 + ((this.data.length + 2) * 29);
      this.currentGridWidth = 502;

      this.agGrid.api.setGridOption('rowData', this.data);
      this.agGrid.api.setGridOption('columnDefs', this.getColDef());
      this.agGrid.api.getStatusPanel<IAgLabelPanel>('total-panel').updateLabel(`Total Patients: ${total}`);
      this.hideGridsLoadingOverlay();
      this.setExportEnable.emit(true);

      setTimeout(() => {
        this.setGridColumnsToFit();
      }, 500);
    }));
  }

  getColDef(): (ColDef | ColGroupDef)[] {

    const result: any = [
      {
        headerName: `Service Year ${this.currentYear}`,
        children: [
          { field: 'HCCCode', headerName: 'HCC Code', type: 'numericColumn', 
          },
          { field: "PatientCount", headerName: 'PatientCount', type: 'numericColumn', valueFormatter: AgGridFunctions.getNumberValue,
            cellClass: 'numberType'
          },
          { field: "Progress", headerName: '% of Patient Population', type: 'numericColumn', valueFormatter: (params) => AgGridFunctions.getPercentValue(params, 1),
            cellClass: 'percent', maxWidth: 500
          }
        ]
      }
    ];

    return result;
  }

  setGridColumnsToFit() {
    if(!document.getElementById('grid')) {
      return;
    }

    this.agGrid.api.sizeColumnsToFit();
  }

  showGridsLoadingOverlay() {
    this.agGrid.api.showLoadingOverlay();
  }

  hideGridsLoadingOverlay() {
    this.agGrid.api.hideOverlay();
  }

  onExport() {
    const sheets = [
      this.agGrid.api.getSheetDataForExcel(
        {
          sheetName: "HCC Patient Distribution by Service Year",
          appendContent: [
            {
              cells: [
                {
                  data: {
                    value: 'Grand Total',
                    type: 'String'
                  },
                  styleId: 'summation'
                },
                {
                  data: {
                    value: this.totalPatients.toString(),
                    type: 'Number'
                  },
                  styleId: 'summation'
                }
              ]
            }
          ]
        }
      ),
    ];

    const filename =`HCC_Prevalence_within_Population_${this.currentYear}`;

    this.agGrid.api.exportMultipleSheetsAsExcel({
      data: sheets,
      fileName: filename,
    });

    const reportJSON = JSON.stringify({ReportName: 'HCC Prevalence within Population by Service Year', Filename: filename});
    this.loggingService.logAudit(AuditAction.ReportExported, `Report Name: HCC Prevalence within Population by Service Year, Filename: ${filename}`, reportJSON).subscribe(() => {
      //TODO
    });
  }


}
