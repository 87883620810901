import { Component, OnInit } from '@angular/core';
import { ListMenuOption } from '../../../models/list-menu-option';
import { MatListOption, MatSelectionListChange } from '@angular/material/list';
import { ReportsService } from '../../../services/user/reports.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportItem } from '../../../models/report-item';

@Component({
  selector: 'app-reporting-page',
  templateUrl: './reporting-page.component.html',
  styleUrls: ['./reporting-page.component.scss']
})
export class ReportingPageComponent implements OnInit {

  currentPageID = 1;
  pageIDConfigKey = "VC-Reporting-Current-Page";
  reportNameKey = "VC-Report-Current-Page";

  menuOptions: ListMenuOption[] = [
    {ID: 1, Name: "All", Icon: "", IconOutlined: false, Disabled: false},
    {ID: 2, Name: "Favorites", Icon: "", IconOutlined: false, Disabled: false}
  ];

  selectedOption: ListMenuOption = new ListMenuOption();
  selectedReport: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private reportsService: ReportsService) {

    if(!this.router.navigated) {
      this.currentPageID = JSON.parse(sessionStorage.getItem(this.pageIDConfigKey) || '1');
      this.selectedReport = sessionStorage.getItem(this.reportNameKey);
    }
  }

  ngOnInit(): void {
    this.reportsService.getCategories().subscribe(result => {
      result.forEach((r, index) => {
        this.menuOptions.push({
          ID: 3 + index,
          ItemApiID: r.ID,
          Name: r.Name,
          Icon: "",
          IconOutlined: false,
          Disabled: false
        });
      });

      this.selectedReport = this.selectedReport || this.route.snapshot.queryParamMap.get('report');

      this.selectedOption = this.menuOptions.find(o => o.ID == this.currentPageID);
    });
  }

  onSelectedConfigOption(option: ListMenuOption) {
    if(!option) {
      return;
    }

    this.selectedReport = '';
    sessionStorage.setItem(this.reportNameKey, '');

    this.selectedOption = option;

    sessionStorage.setItem(this.pageIDConfigKey, JSON.stringify(option.ID));
  }

  onReportClicked(report: ReportItem) {
    sessionStorage.setItem(this.reportNameKey, report.Name);
    this.selectedReport = report.Name;
    this.setSelectedCategoryByReport(report);
  }

  setSelectedCategoryByReport(report: ReportItem) {
    const value = this.menuOptions.find(o => o.Name == report.Category.Name);
    if(value) {
      this.selectedOption = value;
      sessionStorage.setItem(this.pageIDConfigKey, JSON.stringify(value.ID));
    }
  }
}
