export class ReportNames {
    public static readonly DIAGNOSIS_EVENTS_SUMMARY_NAME = 'Diagnosis Events Summary';
    public static readonly DIAGNOSIS_EVENTS_SUMMARY_TREND_NAME = 'Diagnosis Events Summary Trend';
    public static readonly SUBMISSION_OPPTY_TREND_NAME = 'Submission Opportunity Trend';
    public static readonly PAYER_VALIDATION_TREND_NAME = 'Payer Validation Trend';
    public static readonly SUBMISSION_OPPTY_USER_ALLOCATION_NAME = 'User Progress';
    public static readonly SUBMISSION_OPPTY_PAYER_ALLOCATION_NAME = 'Payer Progress';
    public static readonly SUBMISSION_OPPTY_PREFERRED_ACTIONS_ALLOCATION_NAME = 'Preferred Action Progress';
    public static readonly ROOT_CAUSE_TREND_YTD_NAME = 'Root Cause Trend YTD';
    public static readonly SUBMISSION_OPPTY_HCC_VALUATION_BY_STATUS_NAME = 'HCC Valuation by Preferred Action';
    public static readonly HCC_PATIENT_DISTRIBUTION_BY_SERVICE_YEAR_NAME = 'HCC Prevalence within Population by Service Year';
    public static readonly PREFERRED_ACTION_SNAPSHOT_TREND_NAME = 'Preferred Action Snapshot Trend';
    public static readonly USER_PROGRESS_SNAPSHOT_TREND_NAME = 'User Progress Snapshot Trend';
    public static readonly PAYER_PROGRESS_SNAPSHOT_TREND_NAME = 'Payer Progress Snapshot Trend';
    public static readonly ORG_ADMIN_AUDIT_LOG_NAME = 'Audit Log';
    public static readonly OPPORTUNITY_DATA_INGESTION = 'Submission Opportunity Data Ingestion';
    public static readonly HCC_PER_PATIENT_TREND_NAME = 'HCC Count Per Patient Trend';
}
