import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ProgressAllocationService } from '../../../services/progress/progress-allocation.service';
import { Subscription } from 'rxjs';
import { UserAllocationTotal } from '../../../models/user-allocation-total';
import { SettingsService } from '../../../services/settings/settings.service';

@Component({
  selector: 'app-progress-allocation-totals',
  templateUrl: './progress-allocation-totals.component.html',
  styleUrls: ['./progress-allocation-totals.component.scss']
})
export class ProgressAllocationTotalsComponent implements OnInit, OnDestroy {

  totals: UserAllocationTotal = new UserAllocationTotal();
  serviceYear = 0;

  subs: Subscription[] = [];

  constructor(
    private allocationService: ProgressAllocationService,
    private settingsService: SettingsService) {
    //TODO
  }

  ngOnInit(): void {
    this.subs.push(this.allocationService.userAllocationTotals.subscribe(result => {
      if(!result) {
        return;
      }

      this.totals = result;
    }));

    this.subs.push(this.settingsService.serviceYear.subscribe(result => {
      if(!result) {
        return;
      }

      this.serviceYear = result;
      this.updateData();
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  updateData() {
    this.allocationService.getAllocationTotals(this.serviceYear);
  }

}
