<mat-form-field [appearance]="appearance">
    <mat-label *ngIf="label">{{label}}</mat-label>
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [min]="startDateMin" [max]="endDateMax" [required]="required">
      <input matStartDate formControlName="start" [placeholder]="startPlaceholder">
      <input matEndDate formControlName="end" [placeholder]="endPlaceholder">
    </mat-date-range-input>
    <mat-hint *ngIf="hint">{{hint}}</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  
    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
    <mat-error *ngIf="range.controls.start.hasError('matDatepickerMax')">Date exceeds max range of {{endDateMax | date:'MM/dd/yyyy'}}</mat-error>
    <mat-error *ngIf="range.controls.end.hasError('matDatepickerMax')">Date exceeds max range of {{endDateMax | date:'MM/dd/yyyy'}}</mat-error>
    <mat-error *ngIf="range.controls.start.hasError('matDatepickerMin')">Date before min range of {{startDateMin | date:'MM/dd/yyyy'}}</mat-error>
</mat-form-field>