import { Injectable } from '@angular/core';
import { ServiceFormatter } from '../../helpers/service-formatter';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TemplateConfigItem } from '../../models/template-config-item';
import { TemplateConfigFieldItem } from '../../models/template-config-field-item';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  private endpoint = ServiceFormatter.endpoint + 'exporttemplate/';

  constructor(private http: HttpClient) { 
    //TODO
  }

  getTemplateConfigItems(typeIDs: string, statusValues: string) {
    return this.http.post<TemplateConfigItem[]>(this.endpoint + "templates", {
      TypeIDs: typeIDs,
      EnabledValues: statusValues
    }, ServiceFormatter.getHttpOptions());
  }

  addUpdateTemplate(item: TemplateConfigItem) {
    return this.http.post<TemplateConfigItem>(this.endpoint + "add-update", item, ServiceFormatter.getHttpOptions());
  }

  deleteTemplate(templateID: number) {
    const params = new HttpParams().set("templateID", templateID);
    return this.http.post(this.endpoint + "delete", null, ServiceFormatter.getHttpOptions(params));
  }

  getAvailableFields(categoryIDs: string) {
    const params = new HttpParams().set("categoryIDs", categoryIDs);
    return this.http.get<TemplateConfigFieldItem[]>(this.endpoint + "available-field-items", ServiceFormatter.getHttpOptions(params));
  }

  getTemplateConfigFieldItems(templateID: number) {
    const params = new HttpParams().set("templateID", templateID);
    return this.http.get<TemplateConfigFieldItem[]>(this.endpoint + "get-items", ServiceFormatter.getHttpOptions(params));
  }

  duplicateTemplate(templateID: number) {
    const params = new HttpParams().set("templateID", templateID);
    return this.http.post(this.endpoint + "duplicate", null, ServiceFormatter.getHttpOptions(params));
  }
}
