import { formatNumber } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { CalculationsService } from '../../../../../services/calculations/calculations.service';
import { AgCartesianChartOptions } from 'ag-charts-community';

@Component({
  selector: 'app-hcc-patient-trend-graph',
  templateUrl: './hcc-patient-trend-graph.component.html',
  styleUrls: ['./hcc-patient-trend-graph.component.scss']
})
export class HccPatientTrendGraphComponent implements OnInit {

  @Input() showChartTitle = true;

  public options: AgCartesianChartOptions;

  title = {text: 'HCC Patient Trend', enabled: true};

  parentDiv = document.getElementById('snav');
  primaryColor = '';
  accentColor = '';

  constructor(private calcService: CalculationsService) {

    this.primaryColor = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-primary');
    this.accentColor = window.getComputedStyle(this.parentDiv).getPropertyValue('--color-accent');

    this.options = {
      autoSize: true,
      title: this.title,
      data: [],
      series: [
        {
          type: 'line',
          xKey: 'ServiceYear',
          yKey: 'DocumentedDxEvents',
          yName: 'Documented Dx Events',
          stroke: this.primaryColor,
          marker: {
            fill: this.accentColor,
            stroke: this.primaryColor
          },
          tooltip: {
            renderer: function(params) {
              const yValue = parseFloat(params.yKey);
              const xValue = parseFloat(params.xKey);

              const contentValue = formatNumber(yValue, 'en');
              return {
                content: contentValue,
                title: xValue + ' DOS'
              }
            }
          }
        },
      ],
      axes: [
        {
          type: 'number',
          position: 'bottom',
          title: {
            text: 'Service Year'
          },
          tick: {
            interval: 1
          }
        },
        {
          type: 'number',
          position: 'left',
          title: {
            text: 'Documented Dx Events'
          },
          label: {
            format: '~s',
            formatter: (params) => params.formatter(params.value).replace('0y', '0')
          },
          min: 0
        }
      ],
      legend: {
        enabled: false
      }
    };
  }

  ngOnInit(): void {
    this.calcService.getDocumentedDxEventGraphData().subscribe(result => {

      const options = { ...this.options };

      options.data = result;
      options.title.enabled = this.showChartTitle;
  
      this.options = options;
    });
  }
}
