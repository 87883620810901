
<div class="flex h-full w-full">
    <div class="flex flex-auto justify-end items-center content-center">
        <button mat-flat-button class="primary-button" 
        [disabled]="exportDisabled"
        [matTooltip]="exportTooltip"
        [matTooltipClass]="'app-tooltip'"
        [matTooltipPosition]="'left'"
        (click)="onExportClick()"
        *ngIf="exportActive">Export</button>
    
        <div
        [matTooltip]="'Contact Admin to grant permission'"
        [matTooltipClass]="'app-tooltip'"
        [matTooltipPosition]="'left'"
        *ngIf="!exportActive">
            <button mat-flat-button class="primary-button" 
            [disabled]="true">Export</button>
        </div>
    </div>
</div>
