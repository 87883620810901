<div mat-dialog-title>
    <div class="flex flex-row">
        <div class="flex basis-3/4">
            <h1>User Profile</h1>
        </div>
    
        <div class="flex basis-1/4 justify-end items-center content-center">
            <mat-icon (click)="onCloseClick()">close</mat-icon>
        </div>
    </div>
</div>

<div class="flex flex-row">
    <div class="dialog-side-menu-container flex basis-[20%] flex-col">
        <mat-selection-list [multiple]="false" (selectionChange)="onSelectedSideMenuOption($event)">
            <mat-list-option *ngFor="let option of sideMenuOptions" [value]="option" [selected]="selectedOption.ID == option.ID" [disabled]="option.Disabled">
                <div class="flex flex-row">
                    <div class="flex basis-[20%] justify-start items-center content-center">
                        <mat-icon *ngIf="!option.IconOutlined">{{option.Icon}}</mat-icon>
                        <mat-icon fontSet="material-icons-outlined" *ngIf="option.IconOutlined">{{option.Icon}}</mat-icon>
                    </div>

                    <div class="flex flex-auto justify-start items-center content-center">
                        {{option.Name}}
                    </div>
                </div>
            </mat-list-option>
        </mat-selection-list>
    </div>

    <div mat-dialog-content class="flex flex-auto">
    
        <div class="sm-content-container flex flex-auto flex-col gap-[15px]" *ngIf="selectedOption.ID == 1">
            <div class="flex flex-auto">
                <h2>{{data.title}}</h2>
            </div>
            <div class="flex flex-[2_1_auto]">
                <form [formGroup]="form" class="flex basis-[80%]" autocomplete="off">
                    <div class="flex flex-auto flex-col">
                        <div class="flex flex-row">
                            <div class="flex basis-[40%] justify-start items-center content-center">
                                <h3>First Name *</h3>
                            </div>
                
                            <div class="flex basis-[60%]">
                                <mat-form-field appearance="outline" color="primary" floatLabel="always">
                                    <input type="text" autocomplete="off" matInput placeholder="Enter First Name"
                                        [formControl]="firstname" name="firstname" maxlength="45" required>
                                    <mat-error *ngIf="firstname.invalid">{{getFirstNameErrorMessage()}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="flex flex-row">
                            <div class="flex basis-[40%] justify-start items-center content-center">
                                <h3>Last Name *</h3>
                            </div>
                
                            <div class="flex basis-[60%]">
                                <mat-form-field appearance="outline" color="primary" floatLabel="always"> 
                                    <input type="text" autocomplete="off" matInput placeholder="Enter Last Name"
                                        [formControl]="lastname" name="lastname" maxlength="45" required>
                                    <mat-error *ngIf="lastname.invalid">{{getLastNameErrorMessage()}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="flex flex-row">
                            <div class="flex basis-[40%] justify-start items-center content-center">
                                <h3>Email (Username) *</h3>
                            </div>
                
                            <div class="flex basis-[60%]">
                                <mat-form-field appearance="outline" color="primary" floatLabel="always">
                                    <input type="email" name="email" autocomplete="off" matInput placeholder="Enter Email (username)"
                                        [formControl]="email" maxlength="45" required>
                                    <mat-error *ngIf="email.invalid">{{getEmailErrorMessage()}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
    
            <div class="flex flex-auto justify-end items-end content-end" *ngIf="editEnabled">
                <button [disabled]="form.pristine" mat-flat-button class="primary-button" *ngIf="data.mode == 'edit'" (click)="editUser()">
                    Save
                </button>
                <button [disabled]="form.invalid" mat-flat-button class="primary-button" *ngIf="data.mode != 'edit'" (click)="addUser()">
                    Add User
                </button>
            </div>
        </div>
    
        <div class="sm-content-container flex flex-auto flex-col" *ngIf="selectedOption.ID == 2">
            <div class="flex flex-auto">
                <h2>Permissions</h2>
            </div>
            <div class="flex flex-[2_1_auto] flex-col">
                <div class="permission-item flex flex-row gap-[10px]" *ngFor="let perm of permissions">
                    <mat-checkbox
                    [(ngModel)]="perm.Active"
                    [disabled]="!editEnabled"
                    (change)="permissionSaveEnabled = true">
                        <span>{{perm.PermissionName}}</span>
                    </mat-checkbox>

                    <mat-icon
                    *ngIf="perm.PermissionHelpText"
                    [matTooltip]="perm.PermissionHelpText"
                    [matTooltipClass]="'app-tooltip'">info</mat-icon>
                </div>
            </div>
            <div class="flex flex-auto justify-end item-end content-end" *ngIf="editEnabled">
                <button [disabled]="!permissionSaveEnabled" mat-flat-button class="primary-button" *ngIf="data.mode == 'edit'" (click)="updatePermissions()">
                    Save
                </button>
            </div>
        </div>

        <div class="sm-content-container flex flex-auto flex-col" *ngIf="selectedOption.ID == 3">
            <div class="flex flex-auto">
                <h2>Authentication</h2>
            </div>
            <div class="flex flex-[2_1_auto] flex-col gap-[10px]">
                <h3>Reset Password</h3>

                <div class="flex flex-row">
                    <button class="flex basis-[40%]" mat-flat-button color="primary" (click)="sendPasswordResetLink()">
                        Send Reset Password Link
                    </button>
                </div>

                <div class="pt-5 pb-5">
                    <mat-divider></mat-divider>
                </div>

                <h3>View Export Password</h3>

                <div class="flex flex-row">
                    <mat-form-field appearance="outline" class="flex basis-[50%]">
                        <input matInput [(ngModel)]="exportPassword" [type]="hidePw ? 'password' : 'text'" disabled>
                        <button mat-icon-button matSuffix (click)="hidePw = !hidePw" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePw">
                          <mat-icon>{{hidePw ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
            </div>
        </div>
    
    </div>
</div>