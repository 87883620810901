<div class="flex flex-auto flex-col">
    <div class="ag-grid-outer-container dx-trend-row report-grid-alt" [ngStyle]="{'--row-height': currentRowHeight + 'px', '--row-width': currentGridWidth + 'px'}">
        <ag-grid-angular
        #grid
        class="ag-theme-balham w-full h-full"
        [context]="gridContext"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="rowItems"
        [excelStyles]="excelStyles"
        [suppressContextMenu]="true"
        [statusBar]="statusBar"
        [suppressHorizontalScroll]="true"
        [loadingOverlayComponent]="loadingOverlayComponent"
        (gridReady)="onGridReady($event)"></ag-grid-angular>
    </div>
</div>
