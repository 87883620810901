<mat-form-field [color]="color" [appearance]="appearance" [floatLabel]="floatLabel">
    <mat-label *ngIf="label">{{label}}</mat-label>
    <mat-select 
    [multiple]="multiple" 
    [formControl]="control"
    [placeholder]="placeholder"
    [matTooltip]="getTooltipString()"
    [matTooltipClass]="'field-tooltip'"
    [matTooltipPosition]="toolTipPosition"
    [required]="required"
    (closed)="onSelectionChange()">
        <mat-select-trigger *ngIf="multiple">
            <span *ngIf="(control.value?.length || 0) === 1">
                {{control.value?.[0].Description || ''}}
            </span>
            <span *ngIf="(control.value?.length || 0) > 1 && (control.value?.length) == options.length">
                All
            </span>
            <span *ngIf="(control.value?.length || 0) > 1 && (control.value?.length) < options.length">
                Multiple
            </span>
            <span *ngIf="(control.value?.length || 0) > 1">
                ({{getSelectCount()}})
            </span>
        </mat-select-trigger>

        <mat-option>
            <ngx-mat-select-search
            placeholderLabel="search..."
            noEntriesFoundLabel="No Actions Found"
            [id]="'filter-' + controlName"
            [formControl]="filterControl"
            [showToggleAllCheckbox]="multiple"
            [toggleAllCheckboxChecked]="allToggled"
            [toggleAllCheckboxIndeterminate]="indeterminateToggle"
            (toggleAll)="allOptionClicked($event)"></ngx-mat-select-search>
        </mat-option>

        <mat-option *ngFor="let option of filteredOptions | async"
        #item
        [value]="option"
        (click)="toggleSelection(item, option)">
            {{option.Description}}
        </mat-option>
    </mat-select>
</mat-form-field>
