<div class="">
    <div class="flex flex-row flex-wrap gap-0">
        <div *ngFor="let graph of pieChartOptionsList">
            <div>
                <span 
                class="material-symbols-outlined"
                style="cursor: pointer;"
                [matTooltip]="'Download ' + graph.title.text"
                [matTooltipClass]="'app-tooltip'"
                [matTooltipPosition]="'above'"
                (click)="onDownload(chart?.chart)">download</span>
            </div>
            <div>
                <ag-charts-angular
                #chart
                class="h-full"
                [options]="graph"></ag-charts-angular>
            </div>
        </div>
    </div>

    <mat-divider class="pt-[5px] pb-[5px]"></mat-divider>

    <div class="flex flex-row flex-wrap gap-[3px]">
        <div>
            <div>
                <span 
                class="material-symbols-outlined"
                style="cursor: pointer;"
                [matTooltip]="'Download ' + hccDistChartOptions.title?.text"
                [matTooltipClass]="'app-tooltip'"
                [matTooltipPosition]="'above'"
                (click)="onDownload(chart1?.chart)">download</span>
            </div>
            <div>
                <ag-charts-angular
                #chart1
                class="h-full"
                [options]="hccDistChartOptions"></ag-charts-angular>
            </div>
        </div>
        <div>
            <div>
                <span 
                class="material-symbols-outlined"
                style="cursor: pointer;"
                [matTooltip]="'Download ' + averageChartOptions.title?.text"
                [matTooltipClass]="'app-tooltip'"
                [matTooltipPosition]="'above'"
                (click)="onDownload(chart2?.chart)">download</span>
            </div>
            <div>
                <ag-charts-angular
                #chart2
                class="h-full"
                [options]="averageChartOptions"></ag-charts-angular>
            </div>
        </div>
    </div>
</div>
