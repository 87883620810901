<h1 mat-dialog-title *ngIf="actionType == 'add'">Create Template</h1>
<h1 mat-dialog-title *ngIf="actionType == 'edit'">Edit Template</h1>

<div mat-dialog-content>
    <div class="content-container flex flex-col">
        <div class="grid grid-cols-[0.5fr_0.7fr_0.2fr_0.5fr_1fr] gap-3">
            <div class="col-start-1">
                <h3>Template Name *</h3>
            </div>

            <div class="col-start-2">
                <mat-form-field color="primary" appearance="outline">
                    <input matInput placeholder="Enter Name" [formControl]="nameControl" [required]="true">
                    <mat-error *ngIf="nameControl.hasError('required')">Name is <strong>required</strong></mat-error>
                    <mat-error *ngIf="nameControl.hasError('invalidName')">Name already exists. Please enter a unique name.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-start-4">
                <h3>Active:</h3>
            </div>

            <div class="col-start-5 w-[50%]">
                <app-boolean-select
                #activeSelect
                [form]="form"
                [controlName]="activeControlName"
                [defaultValue]="'Yes'"
                [required]="true"></app-boolean-select>
            </div>

            <div class="row-start-2 col-start-1">
                <h3>Template Type *</h3>
            </div>

            <div class="row-start-2 col-start-2">
                <app-filter-select
                #type
                [form]="form"
                [options]="types"
                [required]="true"
                [multiple]="false"
                [label]="null"
                [placeholder]="'Select Type'"
                [selectAllEnabled]="false"></app-filter-select>
            </div>

            <div class="row-start-2 col-start-4">
                <h3>Notes</h3>
            </div>

            <div class="row-start-2 col-start-5">
                <mat-form-field color="primary" appearance="outline">
                    <textarea matInput placeholder="Enter Notes (Optional)" [rows]="3" [formControl]="notesControl"></textarea>
                </mat-form-field>
            </div>
        </div>

        <mat-divider class="pt-[5px] pb-[5px]"></mat-divider>

        <div class="content-grid-container flex flex-auto flex-row">
            <ag-grid-angular
            #selectedFields
            class="ag-theme-balham ag-grid-container w-[60%]"
            [columnDefs]="selectedGridColDefs"
            [defaultColDef]="selectedGridDefaultColDef"
            [rowData]="selectedItems"
            [tooltipShowDelay]="0"
            [noRowsOverlayComponent]="noRowsOverlayComponent"
            [noRowsOverlayComponentParams]="noRowsOverlayComponentParams"
            [context]="gridContext"
            [components]="frameworkComps"
            [rowClassRules]="rowClassRules"
            [rowDragManaged]="true"
            [rowDragMultiRow]="true"
            [rowSelection]="'multiple'"
            [editType]="'fullRow'"
            [suppressCopyRowsToClipboard]="true"
            [suppressClickEdit]="true"
            [stopEditingWhenCellsLoseFocus]="true"
            [suppressHorizontalScroll]="true"
            [loadingOverlayComponent]="loadingOverlayComponent"
            (gridReady)="onSelectedGridReady($event)"
            (rowDragEnd)="updateSelectedSortOrder($event)"></ag-grid-angular>

            <ag-grid-angular
            #availableFields
            class="ag-theme-balham ag-grid-container w-[40%]"
            [columnDefs]="availableGridColDefs"
            [defaultColDef]="defaultColDef"
            [rowData]="availableItems"
            [tooltipShowDelay]="0"
            [noRowsOverlayComponent]="noRowsOverlayComponent"
            [noRowsOverlayComponentParams]="noRowsOverlayComponentParams"
            [context]="gridContext"
            [components]="frameworkComps"
            [rowSelection]="'multiple'"
            [rowDragManaged]="true"
            [rowDragMultiRow]="true"
            [suppressMoveWhenRowDragging]="true"
            [suppressCopyRowsToClipboard]="true"
            [suppressHorizontalScroll]="true"
            [getRowId]="getAvailableGridRowID"
            [loadingOverlayComponent]="loadingOverlayComponent"
            (gridReady)="onAvailableGridReady($event)"></ag-grid-angular>
        </div>
        
    </div>
</div>

<mat-dialog-actions align="end">
    <button mat-flat-button class="secondary-button" (click)="openCancelConfirm()">Cancel</button>
    <button 
        mat-flat-button 
        class="primary-button" 
        [disabled]="form.invalid || invalidSelectedRows || currentSelectedCount == 0"
        (click)="onSaveClick(false)">
        Save
    </button>

    <button 
        mat-flat-button 
        class="primary-button" 
        [disabled]="form.invalid || invalidSelectedRows || currentSelectedCount == 0"
        (click)="onSaveClick(true)">
        Save & Close
    </button>
</mat-dialog-actions>