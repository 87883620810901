<mat-card>
    <mat-card-header>
        <mat-card-title>
            Enter a new password
        </mat-card-title>
        <mat-card-subtitle>
            Must be 7 or more characters
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <form>
            <div class="example-container">

                <mat-form-field>
                    <input minlength="7" matInput maxlength="45" placeholder="Enter password" [formControl]="password"
                        required>
                    <mat-error *ngIf="password.invalid">{{getPasswordErrorMessage()}}</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input minlength="7" matInput maxlength="45" placeholder="Confirm password"
                        [formControl]="confirmPassword" required>
                    <mat-error *ngIf="confirmPassword.invalid">{{getconfirmPasswordErrorMessage()}}</mat-error>
                </mat-form-field>
            </div>
        </form>
    </mat-card-content>
    <mat-card-actions align="center">
        <button mat-raised-button color="primary" (click)="resetPassword()">SAVE</button>
        <button mat-raised-button color="success" (click)="goToLogin()">BACK TO LOGIN
        </button>
    </mat-card-actions>
</mat-card>