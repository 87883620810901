import { RootCauseSummaryCalculation } from "./root-cause-summary-calculation";

export class HccSummaryCalculations {
    OrgID: number;
    ServiceYear: number;
    DateUpdated: string;

    //HCC View
    HCCDocumentedDiagnosisEvents: number;
    HCCPostedViaClaimOrSuppFeed: number;
    HCCPostedOnClaim: number;
    HCCPostedOnSuppFeed: number;
    HCCOnRapsOrMOR: number;
    HCCOnRAPS: number;
    HCCOnMOR: number;
    HCCPostedAndOnRapsOrMOR: number;
    HCCPostedNotOnRapsOrMOR: number;
    HCCNotPostedButOnRapsOrMOR: number;
    HCCNonActionable: number;
    
    HCCPostedViaClaimOrSuppFeedPercent: number;
    HCCPostedOnClaimPercent: number;
    HCCPostedOnSuppFeedPercent: number;
    HCCOnRapsOrMORPercent: number;
    HCCOnRAPSPercent: number;
    HCCOnMORPercent: number;
    HCCPostedAndOnRapsOrMORPercent: number;
    HCCPostedNotOnRapsOrMORPercent: number;

    HCCSubmissionOpp: number;
    HCCSubmissionOppPercent: number;
    HCCSubmissionOppProgress: number;
    HCCPayerValidation: number;
    HCCPayerValidationPercent: number;
    HCCPatientCount: number;

    //Dx View
    DxDocumentedDiagnosisEvents: number;
    DxPostedViaClaimOrSuppFeed: number;
    DxPostedOnClaim: number;
    DxPostedOnSuppFeed: number;
    DxOnRapsOrMOR: number;
    DxOnRAPS: number;
    DxOnMOR: number;
    DxPostedAndOnRapsOrMOR: number;
    DxPostedNotOnRapsOrMOR: number;
    DxNotPostedButOnRapsOrMOR: number;
    DxNonActionable: number;

    DxPostedViaClaimOrSuppFeedPercent: number;
    DxPostedOnClaimPercent: number;
    DxPostedOnSuppFeedPercent: number;
    DxOnRapsOrMORPercent: number;
    DxOnRAPSPercent: number;
    DxOnMORPercent: number;
    DxPostedAndOnRapsOrMORPercent: number;
    DxPostedNotOnRapsOrMORPercent: number;
    DxNotPostedButOnRapsOrMORPercent: number;
    DxNonActionablePercent: number;

    DXSubmissionOpp: number;
    DXSubmissionOppPercent: number;
    DXSubmissionOppProgress: number;
    DxPayerValidation: number;
    DxPayerValidationPercent: number;

    NonActionables: RootCauseSummaryCalculation[];
}