import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { Subscription } from 'rxjs';
import { SettingsService } from '../../../services/settings/settings.service';
import { DateQuarter } from '../../../models/date-quarter';

@Component({
  selector: 'app-dos-select',
  templateUrl: './dos-select.component.html',
  styleUrls: ['./dos-select.component.scss']
})
export class DosSelectComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() color = 'primary';
  @Input() appearance = 'outline';
  @Input() floatLabel = 'always';
  @Input() multiple = true;
  @Input() controlName = 'dos';
  @Input() toolTipPosition = 'above';
  @Input() selectAllEnabled = true;
  @Input() required = false;

  serviceYear: number;
  options: DateQuarter[] = [];
  allOption = {Name: 'All', Selected: false};
  control = new FormControl<any[]>(null);

  subs: Subscription[] = []

  @Output() ready = new EventEmitter();
  @Output() valueChanged = new EventEmitter();

  @ViewChild(MatSelect) select: MatSelect;

  constructor(private settingsService: SettingsService, private cd: ChangeDetectorRef) { 
    //TODO
  }

  ngOnInit(): void {

    if(this.required) {
      this.control.setValidators([Validators.required]);
    }

    if(this.form) {
      this.form.addControl(this.controlName, this.control);
    }

  }

  ngAfterViewInit(): void {
    this.subs.push(this.settingsService.serviceYear.subscribe(result => {
      if(result > -1) {
        this.serviceYear = result;

        this.options = [
          {Name: 'Q1', StartDate: new Date(`1/1/${this.serviceYear}`), EndDate: new Date(`3/31/${this.serviceYear}`)},
          {Name: 'Q2', StartDate: new Date(`4/1/${this.serviceYear}`), EndDate: new Date(`6/30/${this.serviceYear}`)},
          {Name: 'Q3', StartDate: new Date(`7/1/${this.serviceYear}`), EndDate: new Date(`9/30/${this.serviceYear}`)},
          {Name: 'Q4', StartDate: new Date(`10/1/${this.serviceYear}`), EndDate: new Date(`12/31/${this.serviceYear}`)}
        ];

        this.settingsService.getUserDosQuarters();
      }
    }));

    this.subs.push(this.settingsService.dosQuarters.subscribe(result => {
      if(result) {

        this.select?.options.forEach(o => {
          result.includes(o.value.Name) ? o.select() : o.deselect();
        });

        const checkAll = this.checkOptionAllStatus();

        if(checkAll) {
          const allItem = this.select.options.get(0);
          allItem.select();
          this.onToggleItem(allItem);
        }
        
        this.cd.detectChanges();
      }

      this.ready.emit();
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  setValues(values: string[]) {
    if(!values) {
      return;
    }

    if(values.length == 0) {
      this.control.setValue([this.allOption]);

      setTimeout(() => {
        this.onToggleItem(this.select.options.get(0));
        this.settingsService.updateUserDosQuarters(this.getNameValues());
      }, 300);
      
      return;
    }

    this.control.setValue(values);
    this.settingsService.updateUserDosQuarters(this.getNameValues());
  }

  onToggleItem(item: MatOption) {

    if(item.value === this.allOption) {
      if(item.selected) {
        this.allOption.Selected = true;
        this.select.options.forEach(o => o.select());
        return;
      }

      this.allOption.Selected = false;
      this.select.options.forEach(o => o.deselect());

    } else {
      
      const allItem = this.select.options.get(0);

      if(!item.selected && allItem.selected) {
        this.allOption.Selected = false;
        allItem.deselect();
        return;
      }

      if(item.selected && !allItem.selected) {
        const check = this.checkOptionAllStatus();
        if(check) {
          this.allOption.Selected = true;
          allItem.select();
        }
      }
    }
  }

  onSelectionChange() {
    const currentValues = this.getNameValues();
    this.settingsService.updateUserDosQuarters(currentValues);
    this.valueChanged.emit(currentValues);
  }

  checkOptionAllStatus() {
    let result = true;
    this.select.options.forEach(o => {
      if(o.value != this.allOption && !o.selected) {
        result = false;
      }
    });

    return result;
  }

  getNameValues() {
    const values = this.allOption.Selected ? this.control?.value.slice(1) : this.control?.value;
    return values.map(i => i.Name);
  }

  getValues(): DateQuarter[] {
    return this.allOption.Selected ? this.control?.value.slice(1) : this.control?.value;
  }

  getTooltipString() {
    let result = '';
    const options = this.allOption.Selected ? this.control?.value.slice(1) : this.control?.value;

    options.forEach((o, index) => {
      result += o.Name;

      if(index < options.length - 1) {
        result += ', ';
      }
    })
    return result;
  }

  getSelectCount() {
    return (this.control.value?.length <= this.options.length) 
      ? this.control.value?.length 
      : this.control.value?.length - 1;
  }
}
