export const ACCESS_TOKEN = 'LabelAI_accessToken';
export const EXPIRES_AT = 'LabelAI_expiresAt';
export const CURRENT_DATASET = 'LabelAI_currentDataset';
export const LAST_DATASET = 'LabelAI_lastDataset';
export const CURRENT_USER = 'LabelAI_currentUser';
export const ORGANIZATION = 'LabelAI_organization';
export const ORGANIZATIONADDRESS = 'LabelAI_orgAddress';
export const DATASETS = 'LabelAI_Datasets';
export const REFRESH_TOKEN = 'LabelAI_refresh_token';
export const LOGIN_EVENT = 'LabelAI_login-event'
export const LOGOUT_EVENT = 'LabelAI_logout-event';
