import { AbstractControl, ValidatorFn } from "@angular/forms";

export function markedDuplicateValidator(duplicateValue: any): ValidatorFn {
    return (control: AbstractControl) => {
        if(duplicateValue == control.value) {
            return {markedDuplicate: true}
        }

        return null;
    }
}

export function matchToOtherControlValidator(otherControlName: string): ValidatorFn {
    return (control: AbstractControl) => {
        const otherControl = control.parent.get(otherControlName);
        return control && otherControl && control.value != otherControl.value
        ? {notMatching: true} : null;
    }
}