import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ACCESS_TOKEN } from '../../storage/constants.lib';
import { ProgressAllocationUserListItem } from '../../models/progress-allocation-user-list-item';
import { UserAllocationTotal } from '../../models/user-allocation-total';
import { ProgressAllocationActionItem } from '../../models/progress-allocation-action-item';
import { ProgressAllocationPayerItem } from '../../models/progress-allocation-payer-item';
import { GridQuery, UserAllocationQuery } from '../../models/grid-query';
import { ProgressAllocationSnapshotRequest } from '../../models/progress-allocation-snapshot-request';

@Injectable({
  providedIn: 'root'
})
export class ProgressAllocationService {

  private endpoint = `${environment.baseURL}/api/`;

  userAllocation: ReplaySubject<ProgressAllocationUserListItem[]>;
  userAllocationTotals: ReplaySubject<UserAllocationTotal>;

  preferredActions: ReplaySubject<ProgressAllocationActionItem[]>;
  payerAllocation: ReplaySubject<ProgressAllocationPayerItem[]>;

  constructor(private http: HttpClient) { 
    this.userAllocation = new ReplaySubject<ProgressAllocationUserListItem[]>(1);
    this.userAllocationTotals = new ReplaySubject<UserAllocationTotal>(1);

    this.preferredActions = new ReplaySubject<ProgressAllocationActionItem[]>(1);
    this.payerAllocation = new ReplaySubject<ProgressAllocationPayerItem[]>(1);
  }

  private getHttpOptions(params?: HttpParams) {
    const token = localStorage.getItem(ACCESS_TOKEN);
    let headers = new HttpHeaders();
    headers = headers.append("Authorization", "Bearer " + token);
    const httpOptions = {
      headers: headers,
      params: params
    };

    return httpOptions;
  }

  getUserAllocation(serviceYear: number) {
    const params = new HttpParams().set("serviceYear", serviceYear.toString());
    return this.http.get<ProgressAllocationUserListItem[]>(this.endpoint + "progressallocation/users", this.getHttpOptions(params)).subscribe(result => {
      if(!result) {
        return;
      }

      this.userAllocation.next(result);
    });
  }

  getUserAllocationByQuery(query: UserAllocationQuery) {
    return this.http.post<any>(this.endpoint + "progressallocation/users-query", query, this.getHttpOptions());
  }

  getUsersSnapshotItems(request: ProgressAllocationSnapshotRequest) {
    return this.http.post<ProgressAllocationUserListItem[]>(this.endpoint + "progressallocation/users-snapshot", request, this.getHttpOptions()).subscribe(result => {
      if(!result) {
        return;
      }

      this.userAllocation.next(result);
    });
  }

  getPayerAllocationByQuery(query: GridQuery) {
    return this.http.post<any>(this.endpoint + "progressallocation/payers-query", query, this.getHttpOptions());
  }

  getPayerSnapshotItems(request: ProgressAllocationSnapshotRequest) {
    return this.http.post<ProgressAllocationPayerItem[]>(this.endpoint + "progressallocation/payers-snapshot", request, this.getHttpOptions()).subscribe(result => {
      if(!result) {
        return;
      }

      this.payerAllocation.next(result);
    });
  }

  getPreferredActionsAllocationByQuery(query: GridQuery) {
    return this.http.post<any>(this.endpoint + "progressallocation/preferred-actions-query", query, this.getHttpOptions());
  }

  getAllocationTotals(serviceYear: number) {
    const params = new HttpParams().set("serviceYear", serviceYear.toString());
    return this.http.get<UserAllocationTotal>(this.endpoint + "progressallocation/totals", this.getHttpOptions(params)).subscribe(result => {
      if(!result) {
        return;
      }

      this.userAllocationTotals.next(result);
    });
  }

  getPreferredActionItems(serviceYear: number) {
    const params = new HttpParams().set("serviceYear", serviceYear.toString());
    return this.http.get<ProgressAllocationActionItem[]>(this.endpoint + "progressallocation/preferred-actions", this.getHttpOptions(params)).subscribe(result => {
      if(!result) {
        return;
      }

      this.preferredActions.next(result);
    });
  }

  getPreferredActionSnapshotItems(request: ProgressAllocationSnapshotRequest) {
    return this.http.post<ProgressAllocationActionItem[]>(this.endpoint + "progressallocation/preferred-actions-snapshot", request, this.getHttpOptions()).subscribe(result => {
      if(!result) {
        return;
      }

      this.preferredActions.next(result);
    });
  }

  getPayerItems(serviceYear: number) {
    const params = new HttpParams().set("serviceYear", serviceYear.toString());
    return this.http.get<ProgressAllocationPayerItem[]>(this.endpoint + "progressallocation/payers", this.getHttpOptions(params)).subscribe(result => {
      if(!result) {
        return;
      }

      this.payerAllocation.next(result);
    });
  }
}
