<div class="trend-row w-[650px]" [style]="'--row-height: ' + currentRowHeight + 'px'">
    <ag-grid-angular
    #grid1
    [id]="'grid1'"
    class="ag-theme-balham w-full h-full"
    [context]="gridContext"
    [columnDefs]="gridColDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="data"
    [excelStyles]="excelStyles"
    [suppressNoRowsOverlay]="true"
    [suppressContextMenu]="true"
    [loadingOverlayComponent]="loadingOverlayComponent"
    (gridReady)="gridReady = true; onAllGridsReady();"></ag-grid-angular>
</div>
