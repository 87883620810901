import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router'
import { environment } from "../../../environments/environment";
import { AuthService } from "../authentication/auth.service";
const MINUTES_UNITL_AUTO_LOGOUT = environment.autoLogoutInactivityTimeOut// in mins
const CHECK_INTERVAL = environment.autoLogoutCheckInterval; // in ms
const STORE_KEY = 'lastAction';
@Injectable(
  {
    providedIn: 'root'
  }
)
export class AutoLogoutService {
  lastActionTime: number;


  constructor(private router: Router, private route: ActivatedRoute, private authService: AuthService) {
    this.isItTimeToAutoLogout();
    this.initEventListeners();
    this.initInterval();
    localStorage.setItem(STORE_KEY, Date.now().toString());
  }

  public getLastActionTime() {
    return this.lastActionTime;
  }
  public setLastActionTime(lastAction: number) {
    this.lastActionTime = lastAction;
  }

  initEventListeners() {
    console.log ("initListener");
    document.body.addEventListener('click', () => this.resetIdleTime());
    document.body.addEventListener('mouseover', () => this.resetIdleTime());
    document.body.addEventListener('mouseout', () => this.resetIdleTime());
    document.body.addEventListener('keydown', () => this.resetIdleTime());
    document.body.addEventListener('keyup', () => this.resetIdleTime());
    document.body.addEventListener('keypress', () => this.resetIdleTime());

  }

  resetIdleTime() {
    this.setLastActionTime(Date.now());
  }

  initInterval() {
    setInterval(() => {
      this.isItTimeToAutoLogout();
    }, CHECK_INTERVAL);
  }

  isItTimeToAutoLogout() {
    const now = Date.now();
    const timeleft = this.getLastActionTime() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
   
    const isTimeout = diff < 0;

    if (isTimeout) {
      if (this.router.url !='/login') {
      this.authService.logout();
      }
    }
  }

}

