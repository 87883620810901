<div class="flex flex-row flex-wrap gap-0 pt-3">

    <mat-card class="vc-card vc-card-charts mat-elevation-z5" *ngFor="let graph of graphOptionsList">
        <mat-card-header>
            <div class="title-container flex flex-row">
              <h3 class="flex basis-[80%]">{{graph.title.text}}</h3>
              <div class="flex flex-auto justify-end items-center content-center">
                  <span class="material-symbols-outlined download-icon"
                  [matTooltip]="'Download'"
                  [matTooltipClass]="'app-tooltip'"
                  [matTooltipPosition]="'above'"
                  (click)="onDownload(chart.chart)">download</span>
              </div>
            </div>
          </mat-card-header>
    
          <mat-card-content class="w-[750px] h-[435px]">
    
              <ag-charts-angular
              #chart
              class="h-full"
              [options]="graph"></ag-charts-angular>
            
          </mat-card-content>
    </mat-card>
</div>
