<div class="dx-trend-row" [ngStyle]="{'--row-height': currentRowHeight + 'px', '--row-width': currentGridWidth + 'px'}">
    <ag-grid-angular
    #grid
    class="ag-theme-balham ag-theme-dx-event-summary-report w-full h-full"
    [context]="gridContext"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="rowItems"
    [rowStyle]="{background: 'white', border: 'none', 'margin-bottom': '0px'}"
    [excelStyles]="excelStyles"
    [noRowsOverlayComponent]="noRowsOverlayComponent"
    [noRowsOverlayComponentParams]="noRowsOverlayComponentParams"
    [suppressContextMenu]="true"
    [loadingOverlayComponent]="loadingOverlayComponent"
    (gridReady)="onGridReady($event)"></ag-grid-angular>
</div>
