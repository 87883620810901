import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-boolean-select',
  templateUrl: './boolean-select.component.html',
  styleUrls: ['./boolean-select.component.scss']
})
export class BooleanSelectComponent implements OnInit {

  private _disabled;
  @Input() set disabled(value: boolean) {
    this._disabled = value;
    this._disabled ? this.control?.disable() : this.control?.enable();
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Input() form: FormGroup;
  @Input() controlName = "boolean-select";
  @Input() appearance = "outline";
  @Input() color = "primary";
  @Input() label = "";
  @Input() placeholder = "";
  @Input() floatLabel = 'always';
  @Input() allValue = "All";
  @Input() trueValue = "Yes";
  @Input() falseValue = "No";
  @Input() defaultValue:any = '';
  @Input() multiSelect = false;
  @Input() required = false;
  @Input() selectAllEnabled = false;
  @Input() tooltipPosition = 'above';

  @Output() ready = new EventEmitter();
  @Output() valueChanged = new EventEmitter();

  control: FormControl;

  allToggled = false;

  @ViewChild(MatSelect) select: MatSelect;

  constructor() { 
    //TODO
  }

  ngOnInit(): void {
    this.control = this.required ? new FormControl(this.defaultValue, [Validators.required]) : new FormControl(this.defaultValue);
    this.form.addControl(this.controlName, this.control);

    if(this.defaultValue.includes('All')) {
      this.allToggled = true;
    }
    
    this.ready.emit();
  }

  getSelectedValues() {
    if(this.allToggled) {
      return null;
    }

    return this.control?.value == this.trueValue ? "1" : "0";
  }

  getSelectedValueAsBoolean() {
    if(this.allToggled) {
      return null;
    }

    return this.control?.value == this.trueValue;
  }

  getSelectedValueAsLabel() {
    if(this.allToggled) {
      return "All";
    }

    return this.control?.value.toString();
  }

  setValue(value: boolean) {
    this.control.reset();
    this.allToggled = false;

    const trueVal = this.multiSelect ? [this.trueValue] : this.trueValue;
    const falseVal = this.multiSelect ? [this.falseValue] : this.falseValue;

    this.control.setValue(value ?  trueVal : falseVal);
  }

  setAllValue() {
    this.control.setValue([this.allValue]);
  }

  onToggleItem(item: MatOption) {
    if(!this.multiSelect) {
      return;
    }
    
    if(item.value == this.allValue) {
      if(item.selected) {
        this.allToggled = true;
        this.select.options.forEach(o => o.select());
        return;
      }

      this.allToggled = false;
      this.select.options.forEach(o => o.deselect());

    } else {
      
      const allItem = this.select.options.get(0);

      if(!item.selected && allItem.selected) {
        this.allToggled = false;
        allItem.deselect();
        return;
      }

      if(item.selected && !allItem.selected) {
        const check = this.checkOptionAllStatus();
        if(check) {
          this.allToggled = true;
          allItem.select();
        }
      }
    }
  }

  checkOptionAllStatus() {
    let result = true;
    this.select.options.forEach(o => {
      if(o.value != this.allValue && !o.selected) {
        result = false;
      }
    });

    return result;
  }

  onSelectionChange() {
    this.valueChanged.emit(this.control?.value);
  }

  getTooltipString() {
    if(!this.multiSelect) {
      return;
    }

    let result = '';
    const options = this.allToggled ? this.control?.value.slice(1) : this.control?.value;

    options?.forEach((o, index) => {
      result += o;

      if(index < options.length - 1) {
        result += ', ';
      }
    })
    return result;
  }

}
