export interface User {
    uid: string;
    firstName: string;
    lastName: string;
    email: string;
    picture: string;
    created_at: Date;
    identities: Identity[];
    last_ip: string;
    last_login: Date;
    locale?: any;
    logins_count: string;
    phone_verified: boolean;
    updated_at: Date;
    blocked: boolean;
    multifactor?: any;
    nickname: string;
    name: string;
    app_metadata: AppMetadata;
    email_verified: boolean;
    phone_number?: any;
    user_metadata?: any;
    username?: any;
    user_id: string;
    given_name?: any;
    family_name?: any;
    shortcutSettings?: any;
    mobileOnly: boolean;
    limitedUser: boolean;
    failedLogin: number;
}

export const jsonToUser = (data: object) => ({
    uid: data['user_id'],
    firstName: data['given_name'],
    lastName: data['family_name'],
    email: data['email'],
    picture: data['picture'],
    created_at: data['created_at'],
    identities: data['identities'],
    last_ip: data['last_ip'],
    last_login: data['last_login'],
    locale: data['locale'],
    logins_count: data['logins_count'],
    phone_verified: data['phone_verified'],
    updated_at: data['updated_at'],
    blocked: data['blocked'],
    multifactor: data['multifactor'],
    nickname: data['nickname'],
    name: data['name'],
    app_metadata: data['app_metadata'],
    email_verified: data['email_verified'],
    phone_number: data['phone_number'],
    user_metadata: data['user_metadata'],
    username: data['username'],
    shortcutSettings: data['shortcutSettings'],
    limitedUser: data['limitedUser'],
    mobileOnly: data['mobileOnly'],
failedLogin: data['failedLogin']


}) as User;

export class Identity {
    access_token?: any;
    connection: string;
    isSocial: boolean;
    provider: string;
    user_id: string;
}

export class AppMetadata {
    siteID: string;
    role: string;
    systemAdmin: string;
}

export interface ApplicationUser {
    UserName: string;
    Role: string;
    OrgName: string;
    UserID: number;
    OrgID: string;
}
