<mat-form-field [color]="color" [appearance]="appearance" [floatLabel]="floatLabel">
    <mat-label *ngIf="label">{{label}}</mat-label>
    <mat-select 
    [multiple]="multiple" 
    [formControl]="control"
    [placeholder]="placeholder"
    [matTooltip]="(control.value?.length || 0) > 1 ? getTooltipString() : ''"
    [matTooltipClass]="'field-tooltip'"
    [matTooltipPosition]="toolTipPosition"
    [required]="required"
    (selectionChange)="onSelectionChange()">
    
        <mat-select-trigger *ngIf="multiple">
            <span *ngIf="(control.value?.length || 0) === 1">
                {{control.value?.[0].Description || ''}}
            </span>
            <span *ngIf="allOption.Selected">
                All
            </span>
            <span *ngIf="!allOption.Selected && (control.value?.length || 0) < options.length && (control.value?.length || 0) > 1">
                Multiple
            </span>
            <span *ngIf="(control.value?.length || 0) > 1">
            ({{getSelectCount()}})
            </span>
        </mat-select-trigger>

        <mat-option *ngIf="selectAllEnabled && multiple && filteredOptions?.length > 1"
        #allItem 
        [value]="allOption"
        (click)="onToggleItem(allItem)">All</mat-option>

        <mat-option *ngFor="let option of filteredOptions"
        #item
        [value]="option"
        (click)="onToggleItem(item)">
            {{option.Description}}
        </mat-option>
    </mat-select>

    <button *ngIf="clearEnabled && control.value" matSuffix mat-icon-button aria-label="Clear" (click)="$event.stopImmediatePropagation(); onClear()">
        <mat-icon>close</mat-icon>
    </button>

    <mat-hint *ngIf="control?.disabled">Status Selection Required</mat-hint>

    <mat-error *ngIf="control.hasError('required')">Root Cause is <strong>required</strong></mat-error>
</mat-form-field>
