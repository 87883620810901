<div mat-dialog-title>
    <div class="flex flex-row">
        <div class="flex basis-3/4">
            <h1>HCC Valuation  {{data.mode == 'add' ? 'Add' : 'Edit'}} Entry</h1>
        </div>
    
        <div class="flex basis-1/4 justify-end items-center content-center">
            <mat-icon (click)="onCloseClick()">close</mat-icon>
        </div>
    </div>
</div>

<div mat-dialog-content>
    <div class="content-container flex flex-col">
        <div class="flex basis-[5%] flex-row">
            <div class="flex basis-[35%] justify-start items-center content-center">
                <h3>Service Year *</h3>
            </div>

            <div class="flex basis-[60%]">
                <app-payment-year-select
                #serviceYear
                [form]="form"
                [controlName]="serviceYearControlName"
                [label]="''"
                [placeholder]="'Make a Selection'"
                [required]="true"
                [minYear]="2021"
                [maxYearAddition]="2"
                (ready)="onServiceYearReady()"></app-payment-year-select>
            </div>
        </div>

        <div class="flex basis-[5%] flex-row">
            <div class="flex basis-[35%] justify-start items-center content-center">
                <h3>Valuation Amount *</h3>
            </div>

            <div class="flex basis-[60%]">
                <app-currency-input
                class="w-[60%]"
                [form]="form"
                [label]="''"
                [controlName]="currencyControlName"
                [required]="true"></app-currency-input>
            </div>
        </div>
        
    </div>
</div>

<mat-dialog-actions align="end">
    <button  
        mat-flat-button 
        class="primary-button" 
        [disabled]="form.invalid"
        (click)="onSaveClick()">
        Save
    </button>
</mat-dialog-actions>
