import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-enterprise';

export interface IAgHeaderInfoIconRendererParams {
  iconTooltip: string;
}

@Component({
  selector: 'app-ag-header-info-icon-renderer',
  templateUrl: './ag-header-info-icon-renderer.component.html',
  styleUrls: ['./ag-header-info-icon-renderer.component.scss']
})
export class AgHeaderInfoIconRendererComponent implements IHeaderAngularComp  {
  params: IHeaderParams;
  iconTooltip: string;

  agInit(params: IHeaderParams & IAgHeaderInfoIconRendererParams): void {
    this.params = params;
    this.iconTooltip = params.iconTooltip;
  }

  refresh(params: IHeaderParams & IAgHeaderInfoIconRendererParams): boolean {
    this.params = params;
    this.iconTooltip = params.iconTooltip;
    return true;
  }
}
