<mat-form-field [color]="color" [appearance]="appearance" [floatLabel]="floatLabel">
    <mat-label *ngIf="label">{{label}}</mat-label>
    <mat-select 
    [multiple]="multiSelect" 
    [formControl]="control"
    [placeholder]="placeholder"
    [matTooltip]="(control.value?.length || 0) > 1 ? getTooltipString() : ''"
    [matTooltipClass]="'field-tooltip'"
    [matTooltipPosition]="tooltipPosition"
    [required]="required"
    (closed)="onSelectionChange()">
        <mat-select-trigger *ngIf="multiSelect">
            <span *ngIf="(control.value?.length || 0) === 1">
                {{control.value?.[0] || ''}}
            </span>
            <span *ngIf="selectAllEnabled && allToggled">
                All
            </span>
        </mat-select-trigger>

        <mat-option *ngIf="selectAllEnabled && multiSelect"
        #allItem 
        [value]="allValue"
        (click)="onToggleItem(allItem)">All</mat-option>

        <mat-option #trueItem [value]="trueValue" (click)="onToggleItem(trueItem)">
            {{trueValue}}
        </mat-option>

        <mat-option #falseItem [value]="falseValue" (click)="onToggleItem(falseItem)">
            {{falseValue}}
        </mat-option>
    </mat-select>

    <mat-error *ngIf="control.hasError('required')">Selection is <strong>required</strong></mat-error>
</mat-form-field>
