<html class="auth0-lock-html">

<head>
  <meta charset="utf-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
  <title>Sign In to Value Cycle</title>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <style type="text/css" id="auth0-lock-style">

  </style>
  <script src="https://secure.gravatar.com/45d27db990ade77d755c357829454da8.json?callback=__jp0"></script>
</head>

<body data-new-gr-c-s-check-loaded="14.984.0" data-gr-ext-installed="">

  <!--[if IE 8]>
  <script src="//cdnjs.cloudflare.com/ajax/libs/ie8/0.2.5/ie8.js"></script>
  <![endif]-->

  <!--[if lte IE 9]>
  <script src="https://cdn.auth0.com/js/base64.js"></script>
  <script src="https://cdn.auth0.com/js/es5-shim.min.js"></script>
  <![endif]-->


  <style>

  </style>
  <div id="auth0-lock-container-1" class="auth0-lock-container">



    <div data-reactroot="" class="auth0-lock auth0-lock-opened" lang="en">


      <div class="auth0-lock-overlay"></div>
      <div class="auth0-lock-center">

        <form class="auth0-lock-widget" (ngSubmit)="login()" [overlayLoading]="busy.asObservable()">
          <div class="auth0-lock-widget-container">
            <div class="auth0-lock-cred-pane auth0-lock-quiet">

              <div class="auth0-lock-header">

                <div id="header" class="auth0-lock-header-bg auth0-lock-blur-support">


                </div>

                <div id="loginsplogo" style="background:#003f9c !important; color:white;"
                  class="auth0-lock-header-welcome">
                  <div class="secpro-navbar-brand2 flex flex-auto justify-center items-center content-center" href="https://portal.securitypro.app">

                    <img src="/assets/images/ValueCycleWhite.svg" />
                  </div>

                </div>
              </div>
              <div *ngIf="disabledAccount==true">
                <div>
                  <div class="auth0-global-message auth0-global-message-error"><span
                      class="animated fadeInUp"><span>This account has been disabled. Please contact customer
                        support.</span></span></div>
                </div>
              </div>
              <div *ngIf="mobileOnly==true">
                <div>
                  <div class="auth0-global-message auth0-global-message-error"><span
                      class="animated fadeInUp"><span>This user account is for the mobile access only</span></span>
                  </div>
                </div>
              </div>
              <div *ngIf="loginError==true">
                <div>
                  <div class="auth0-global-message auth0-global-message-error"><span
                      class="animated fadeInUp"><span>Wrong email or
                        password.</span></span></div>
                </div>
              </div>
              <div *ngIf="blocked==true">
                <div>
                  <div class="auth0-global-message auth0-global-message-error"><span
                      class="animated fadeInUp"><span>This account has been blocked.  Contact your administrator!</span></span></div>
                </div>
              </div>
              <div *ngIf="userRequired==true">
                <div>
                  <div class="auth0-global-message auth0-global-message-error"><span
                      class="animated fadeInUp"><span>Please enter your email address.</span></span></div>
                </div>
              </div>
              <div *ngIf="linkSent==true">
                <div>
                  <div class="auth0-global-message auth0-global-message-success"><span
                      class="animated fadeInUp"><span>We've just sent
                        you an email to reset your password.</span></span></div>
                </div>
              </div>
              <div  style="position: relative;"><span>
                  <div>
                    <div style="visibility: inherit;">
                      <div class="auth0-lock-view-content">
                        <div style="position: relative;">
                          <div class="auth0-lock-body-content">
                            <div class="auth0-lock-content">
                              <div class="auth0-lock-form">
                                <div>
                                  <div>
                                    <div class="auth0-lock-input-block auth0-lock-input-email">
                                      <div class="auth0-lock-input-wrap auth0-lock-input-wrap-with-icon"><span
                                          aria-hidden="true"><svg aria-hidden="true" focusable="false" width="14px"
                                            height="13px" viewBox="0 0 32 26" version="1.1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
                                            class="auth0-lock-icon auth0-lock-icon-box">
                                            <g id="Page-1" stroke="none" stroke-width="1" fill="none"
                                              fill-rule="evenodd" sketch:type="MSPage">
                                              <g id="32px" sketch:type="MSLayerGroup"
                                                transform="translate(-2155.000000, -2317.000000)" fill="#373A39">
                                                <g id="Group-856" transform="translate(1.000000, 1.000000)"
                                                  sketch:type="MSShapeGroup">
                                                  <path id="Fill-419"
                                                    d="M2184,2339 C2184,2339.55 2183.55,2340 2183,2340 L2157,2340 C2156.45,2340 2156,2339.55 2156,2339 L2156,2319 C2156,2318.45 2156.45,2318 2157,2318 L2183,2318 C2183.55,2318 2184,2318.45 2184,2319 L2184,2339 L2184,2339 Z M2184,2316 L2156,2316 C2154.89,2316 2154,2316.89 2154,2318 L2154,2340 C2154,2341.1 2154.89,2342 2156,2342 L2184,2342 C2185.1,2342 2186,2341.1 2186,2340 L2186,2318 C2186,2316.89 2185.1,2316 2184,2316 L2184,2316 Z M2176,2322 L2180,2322 L2180,2326 L2176,2326 L2176,2322 Z M2174,2328 L2182,2328 L2182,2320 L2174,2320 L2174,2328 Z M2158,2332 L2172,2332 L2172,2330 L2158,2330 L2158,2332 Z M2158,2336 L2172,2336 L2172,2334 L2158,2334 L2158,2336 Z">
                                                  </path>
                                                </g>
                                              </g>
                                            </g>
                                          </svg></span><input [(ngModel)]="username" type="email" name="email"
                                          class="auth0-lock-input" placeholder="yours@example.com" autocomplete="on"
                                          autocapitalize="off" aria-label="Email" aria-invalid="false" maxlength="45">
                                      </div>
                                    </div>
                                    <div class="auth0-lock-input-block auth0-lock-input-show-password">
                                      <div class="auth0-lock-input-block auth0-lock-input-password">
                                        <div class="auth0-lock-input-wrap auth0-lock-input-wrap-with-icon"><span
                                            aria-hidden="true"><svg aria-hidden="true" focusable="false" width="11px"
                                              height="14px" viewBox="0 0 13 16" version="1.1"
                                              xmlns="http://www.w3.org/2000/svg"
                                              xmlns:xlink="http://www.w3.org/1999/xlink"
                                              class="auth0-lock-icon auth0-lock-icon-box">
                                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g transform="translate(-288.000000, -1508.000000)" fill="#888888">
                                                  <path
                                                    d="M299,1523.998 L290,1523.998 C288.896,1523.998 288,1523.102 288,1521.999 L288,1515.999 C288,1514.895 288.896,1513.998 290,1513.998 L290,1513.998 L290,1512.499 C290,1510.015 292.015,1507.999 294.5,1507.999 C296.985,1507.999 299,1510.015 299,1512.499 L299,1513.999 C300.104,1513.999 301,1514.895 301,1515.999 L301,1521.999 C301,1523.103 300.104,1523.998 299,1523.998 L299,1523.998 Z M298,1512.499 C298,1510.566 296.433,1508.999 294.5,1508.999 C292.567,1508.999 291,1510.566 291,1512.499 L291,1513.998 L298,1513.998 L298,1512.499 L298,1512.499 Z M300,1515.999 C300,1515.446 299.552,1514.998 299,1514.998 L290,1514.998 C289.447,1514.998 289,1515.446 289,1515.999 L289,1521.999 C289,1522.551 289.447,1522.998 290,1522.998 L299,1522.998 C299.552,1522.998 300,1522.551 300,1521.999 L300,1515.999 L300,1515.999 Z M294.5,1520.998 C294.224,1520.998 294,1520.774 294,1520.498 L294,1517.498 C294,1517.223 294.224,1516.999 294.5,1516.999 C294.776,1516.999 295,1517.223 295,1517.498 L295,1520.498 C295,1520.774 294.776,1520.998 294.5,1520.998 L294.5,1520.998 Z">
                                                  </path>
                                                </g>
                                              </g>
                                            </svg></span><input [(ngModel)]="password" type="password" name="password"
                                            class="auth0-lock-input" autocomplete="on" autocapitalize="off" value=""
                                            aria-label="Password" aria-invalid="false" placeholder="your password"
                                            maxlength="45">
                                        </div>
                                      </div>
                                    </div>

                                    <button (click)="sendForgotLink()" [disabled]="username==''" mat-button type="button"
                                      color="primary">Don't remember your password?</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </span></div><button #loginButton class="auth0-lock-submit" name="submit" type="submit" aria-label="Log In"
                style="background-color:#0193fa; display: block;">
                <div class="auth0-loading-container">
                  <div class="auth0-loading"></div>
                </div><span class="auth0-label-submit">
                  <!-- react-text: 31 -->Log In
                  <!-- /react-text --><span><svg aria-hidden="true" focusable="false" class="icon-text" width="8px"
                      height="12px" viewBox="0 0 8 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
                      <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Web/Submit/Active" transform="translate(-148.000000, -32.000000)" fill="#FFFFFF">
                          <polygon id="Shape" points="148 33.4 149.4 32 155.4 38 149.4 44 148 42.6 152.6 38"></polygon>
                        </g>
                      </g>
                    </svg></span>
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>


</body>

</html>