export enum AuditAction {
    Login = 1,
    UserAuth = 2,
    UserEdit = 3,
    ReportExported = 4,
    UserCreated = 5,
    UserDeleted = 6,
    DXEventAssigned = 7,
    DXEventsExported = 8,
    DXEventEdited = 9,
    DXEventCreated = 10,
    PermissionUpdated = 11
}
