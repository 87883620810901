import { DiagnosisEventQuery } from "../models/diagnosis-event-query";

export class Comparitors {
    public static DiagnosisEventQueryEquals(a: DiagnosisEventQuery, b: DiagnosisEventQuery) {
        if(a.PreferredActionIDs != b.PreferredActionIDs) {
            return false;
        }

        if(a.PayerIDs != b.PayerIDs) {
            return false;
        }

        if(a.StateIDs != b.StateIDs) {
            return false;
        }

        if(a.StatusIDs != b.StatusIDs) {
            return false;
        }

        if(a.RootCauseIDs != b.RootCauseIDs) {
            return false;
        }

        if(a.AssignedToIDs != b.AssignedToIDs) {
            return false;
        }

        if(a.EmrPID != b.EmrPID) {
            return false;
        }

        if(a.IcdCodeID != b.IcdCodeID) {
            return false;
        }

        if(a.DateRanges) {
            let drResult = true;
            if(!b.DateRanges) {
                return false;
            }

            a.DateRanges.every(dra => {
                if(!b.DateRanges.find(bra => bra.StartDate == dra.StartDate && bra.EndDate == dra.EndDate)) {
                    drResult = false;
                    return false;
                }
            });

            return drResult;
        }

        return true;
    }
}