import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ColumnConfigItem } from '../../models/column-config-item';
import { ACCESS_TOKEN } from '../../storage/constants.lib';

export enum SettingsKeys {
  OPPTY_LIST_COLUMN_CONFIG = "OpptyListColumnConfig",
  DX_LIST_COLUMN_CONFIG = "DxListColumnConfig",
  SERVICE_YEAR_SELECTED = "ServiceYearSelected",
  DOS_QUARTERS_SELECTED = "DosQuartersSelected",
  OPPTY_LIST_PAGE_SIZE = "OpptyListPageSize",
  DX_LIST_PAGE_SIZE = "DxListPageSize",
  PAYER_CONFIG_PAGE_SIZE = "PayerConfigPageSize",
  CMS_CONFIG_PAGE_SIZE = "CMSConfigPageSize",
  DATA_REFRESH_LOG_PAGE_SIZE = "DataRefreshLogPageSize",
  USERS_CONFIG_PAGE_SIZE = "UsersConfigPageSize",
  ORGS_LIST_PAGE_SIZE = "OrgsListPageSize",
  OPPTY_SUBMISSION_USER_ALLOCATION_PAGE_SIZE = "OpptySubmissionUserAllocationPageSize",
  OPPTY_SUBMISSION_PAYER_ALLOCATION_PAGE_SIZE = "OpptySubmissionPayerAllocationPageSize",
  OPPTY_SUBMISSION_PREFERRED_ACTION_ALLOCATION_PAGE_SIZE = "OpptySubmissionPreferredActionAllocationPageSize",
  HCC_CONFIG_PAGE_SIZE = "HCCConfigPageSize",
  ORG_HCC_MODEL_VERSION_PAGE_SIZE = "OrgHCCModelVersionPageSize",
  ORG_AUDIT_LOG_QUERY_PAGE_SIZE = 'OrgAuditLogQueryPageSize',
  TEMPLATE_CONFIG_PAGE_SIZE = 'TemplateConfigPageSize'
}

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  serviceYear: ReplaySubject<number> = new ReplaySubject<number>(1);
  dosQuarters: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

  private endpoint = `${environment.baseURL}/api/`;

  constructor(private http: HttpClient) { 
    
  }

  getHttpOptions(params?: HttpParams) {
    const token = localStorage.getItem(ACCESS_TOKEN);
    let headers = new HttpHeaders();
    headers = headers.append("Authorization", "Bearer " + token);
    const httpOptions = {
      headers: headers,
      params: params
    };

    return httpOptions;
  }

  getServiceYear() {
    return this.http.get<number>(this.endpoint + "settings/user-service-year", this.getHttpOptions()).subscribe(result => {
      if(result > -1) {
        this.serviceYear.next(result);
      }
    });
  }

  updateServiceYear(year: number) {
    return this.http.post(this.endpoint + "settings/user-service-year", year,
    this.getHttpOptions()).subscribe(() => {
      this.serviceYear.next(year);
    });
  }

  getDxEventColumnConfig(mode: string) {
    const route = mode == 'oppty-list' ? "settings/user-oppty-list-column" : "settings/user-dx-list-column";
    return this.http.get<ColumnConfigItem[]>(this.endpoint + route, this.getHttpOptions());
  }

  updateDxEventColumnConfig(items: ColumnConfigItem[], mode: string) {
    const route = mode == 'oppty-list' ? "settings/user-oppty-list-column" : "settings/user-dx-list-column";
    return this.http.post(this.endpoint + route, items,
    this.getHttpOptions());
  }

  getDxListColumnConfig() {
    return this.http.get<ColumnConfigItem[]>(this.endpoint + "settings/user-dx-list-column", this.getHttpOptions());
  }

  updateDxListColumnConfig(items: ColumnConfigItem[]) {
    return this.http.post(this.endpoint + "settings/user-dx-list-column", items,
    this.getHttpOptions());
  }

  getUserDosQuarters() {
    return this.http.get<string[]>(this.endpoint + "settings/user-dos-quarters", this.getHttpOptions()).subscribe(result => {
      this.dosQuarters.next(result);
    });
  }

  updateUserDosQuarters(quarters: string[]) {
    return this.http.post(this.endpoint + "settings/user-dos-quarters", quarters,
    this.getHttpOptions()).subscribe(() => {
      //TODO
    });
  }

  getPaginatorPageSize(key: string) {
    const params = new HttpParams().set('key', key);

    return this.http.get<number>(this.endpoint + "settings/paginator-page-size", this.getHttpOptions(params));
  }

  updatePaginatorPageSize(pageSize: number, key: string) {
    const params = new HttpParams()
    .set('key', key)
    .set('pageSize', pageSize.toString());

    return this.http.post(this.endpoint + "settings/paginator-page-size", pageSize,
    this.getHttpOptions(params));
  }
}
