import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

@Component({
  selector: 'app-notes-renderer',
  templateUrl: './notes-renderer.component.html',
  styleUrls: ['./notes-renderer.component.scss']
})
export class NotesRendererComponent implements ICellRendererAngularComp {

  params: ICellRendererParams<any, any>;

  constructor() {
    //TODO
  }

  agInit(params: ICellRendererParams<any, any>): void {
    this.params = params;
  }
  
  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }

  onClick(event) {
    event.stopImmediatePropagation();

    if(this.params.value) {
      this.params.context.componentParent.onNoteCellClick(this.params.data);
    }
  }
}
