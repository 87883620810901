<mat-form-field [appearance]="appearance" [color]="color">
    <mat-label *ngIf="label">{{label}}</mat-label>
    <input matInput [formControl]="control" [matDatepicker]="aPicker" [placeholder]="placeholder" [required]="required">
    <mat-datepicker-toggle matSuffix [for]="aPicker"></mat-datepicker-toggle>
    <mat-datepicker #aPicker
        startView="multi-year"
        (monthSelected)="setMonthAndYear($event, aPicker)"
        panelClass="month-picker"></mat-datepicker>
    <mat-error *ngIf="control.hasError('required')">Date is <strong>required</strong></mat-error>
</mat-form-field> 
