import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ACCESS_TOKEN } from '../../storage/constants.lib';
import { CmsScheduleQuery } from '../../models/cms-schedule-query';
import { ReplaySubject } from 'rxjs';
import { CmsScheduleItem } from '../../models/cms-schedule-item';
import { RefreshBatchQuery } from '../../models/refresh-batch-query';
import { ServiceFormatter } from '../../helpers/service-formatter';

@Injectable({
  providedIn: 'root'
})
export class SchedulesService {

  private endpoint = ServiceFormatter.endpoint;

  cmsTimelineItems: ReplaySubject<CmsScheduleItem[]>;
  isCMSTimelineLoaded = false;

  constructor(private http: HttpClient) { 
    this.cmsTimelineItems = new ReplaySubject<CmsScheduleItem[]>(1);
  }

  getCMSConfigItems(query: CmsScheduleQuery) {
    return this.http.post<any>(this.endpoint + "schedules/get-cms", query, ServiceFormatter.getHttpOptions());
  }

  getCMSTimelineItems(serviceYear: number) {
    const params = new HttpParams().set("serviceYear", serviceYear.toString());

    return this.http.get<any>(this.endpoint + "schedules/cms-timeline", ServiceFormatter.getHttpOptions(params)).subscribe(result => {
      this.cmsTimelineItems.next(result);
      this.isCMSTimelineLoaded = true;
    });
  }

  addUpdateCmsSchedule(item: CmsScheduleItem, currentYear: number, currentType: number) {
    return this.http.post<any>(this.endpoint + "schedules/add-update", 
    {
      Item: item, 
      CurrentYear: currentYear, 
      CurrentType: currentType
    }, 
    ServiceFormatter.getHttpOptions());
  }

  getRefreshBatchItems(query: RefreshBatchQuery) {
    return this.http.post<any>(this.endpoint + "schedules/get-refresh-batch-items", query, ServiceFormatter.getHttpOptions());
  }

  getRefreshBatchItemErrorDetails(batchID: number) {
    const params = new HttpParams().set("batchID", batchID.toString());
    return this.http.get<any>(this.endpoint + "schedules/get-refresh-batch-item-error-details", ServiceFormatter.getHttpOptions(params));
  }

  getCMSSubmissionWindow(runYear: number) {
    const params = new HttpParams().set("runYear", runYear.toString());

    return this.http.get<any>(this.endpoint + "schedules/cms-submission-window", ServiceFormatter.getHttpOptions(params));
  }
}
