import { MediaMatcher } from "@angular/cdk/layout";
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Subscription } from "rxjs";
import { AuthService } from "../../services/authentication/auth.service";
import { NavigationEnd, Router } from "@angular/router";
import { PermissionMap, PermissionService } from "../../services/settings/permission.service";
import { Permission } from "../../models/permission";
import { SettingsService } from "../../services/settings/settings.service";
import { SchedulesService } from "../../services/settings/schedules.service";

/** @title Responsive sidenav */
@Component({
  selector: "app-full-layout",
  templateUrl: "full.component.html",
  styleUrls: ["./full.component.scss"],
})
export class FullComponent implements OnDestroy, OnInit {
  mobileQuery: MediaQueryList;
  minisidebar: boolean;
  openSideBar: any;

  private _mobileQueryListener: () => void;

  private subscriptions: Subscription[] = [];

  subscription2: any;
  browser: string;

  navLinks = [
    {
      link: "/insights",
      label: "Insights",
      disabled: false,
      disabledTooltip: 'Contact Admin to grant permission'
    },
    {
      link: "/opportunity-list",
      label: "Opportunity List",
      disabled: false,
      disabledTooltip: 'Contact Admin to grant permission'
    },
    {
      link: "/diagnosis-event",
      label: "Diagnosis Events",
      disabled: false,
      disabledTooltip: 'Contact Admin to grant permission'
    },
    {
      link: "/reporting",
      label: "Reporting",
      disabled: true,
      disabledTooltip: 'Contact Admin to grant permission'
    },
  ];

  activeLink = '';

  userPermissions: Permission[] = [];
  selectedYear = -1;
  cmsSubmissionWindow;

  acceptedReportPermissionIDs = [
    PermissionMap.GenerateReportsAdmin,
    PermissionMap.GenerateReportsDxEvents
  ];

  constructor(
    public auth: AuthService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private router: Router,
    private permissionService: PermissionService,
    private settingsService: SettingsService,
    private scheduleService: SchedulesService
  ) {
    this.mobileQuery = media.matchMedia("(min-width: 768px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener("change", this._mobileQueryListener);

    this.browser = this.getBrowser();

    this.subscriptions.push(router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {
        this.activeLink = this.router.routerState.snapshot.url;
      }
    }));
  }

  getBrowser(): string {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf("edge") > -1:
        return "edge";
      case agent.indexOf("edg/") > -1:
        return "chromium based edge (dev or canary)"; // Match also / to avoid matching for the older Edge
      case agent.indexOf("opr") > -1 && !!(<any>window).opr:
        return "opera";
      case agent.indexOf("chrome") > -1 && !!(<any>window).chrome:
        return "chrome";
      case agent.indexOf("trident") > -1:
        return "ie";
      case agent.indexOf("firefox") > -1:
        return "firefox";
      case agent.indexOf("safari") > -1:
        return "safari";
      default:
        return "other";
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener("change", this._mobileQueryListener);
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  ngOnInit() {
    this.subscriptions.push(this.permissionService.activeUserPermissions.subscribe(result => {
      this.userPermissions = result;
      this.permissionsCheck();
    }));

    this.subscriptions.push(this.settingsService.serviceYear.subscribe(result => {
      this.selectedYear = result;

      this.subscriptions.push(this.scheduleService.getCMSSubmissionWindow(this.selectedYear).subscribe(result => {
        this.cmsSubmissionWindow = result;
      }));
    }));
  }

  permissionsCheck() {
    //reporting
    let reportingDisabled = true;
    this.userPermissions.forEach(p => {
      if(this.acceptedReportPermissionIDs.includes(p.PermissionID)) {
        reportingDisabled = false;
        return;
      }
    });

    this.navLinks.find(l => l.link == '/reporting').disabled = reportingDisabled;
  }
}
