import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PictureService } from '../../../services/libs/picture.service';
import { Router } from '@angular/router';
import { Org } from '../../../models/org';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../../../dialogs/confirm-dialog/confirm-dialog.component';
import { AuthService } from '../../../services/authentication/auth.service';
import { AgGridAngular } from 'ag-grid-angular';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SettingsKeys, SettingsService } from '../../../services/settings/settings.service';
import { ColDef, GetContextMenuItemsParams, GetRowIdFunc, GetRowIdParams, IServerSideDatasource, StatusPanelDef } from 'ag-grid-enterprise';
import { OrgQuery } from '../../../models/org-query';
import { QueryMetadata, QuerySort } from '../../../models/query-metadata';
import { Clipboard } from '@angular/cdk/clipboard';
import { AgPaginatorComponent, IAgPaginator } from '../../ag-grid-components/ag-paginator/ag-paginator.component';
import { Subscription } from 'rxjs';
import { AgNoRowsOverlayComponent } from '../../ag-grid-components/ag-no-rows-overlay/ag-no-rows-overlay.component';
import { OrgListActionIconRendererComponent } from '../../ag-grid-components/org-list-action-icon-renderer/org-list-action-icon-renderer.component';
import { OrganizationLib } from '../../../services/libs/organization-lib.service';
import { AddUpdateOrgDialogComponent, AddUpdateOrgDialogModel } from '../../../dialogs/add-update-org-dialog/add-update-org-dialog.component';
import { AgLoadingOverlayComponent } from '../../ag-grid-components/ag-loading-overlay/ag-loading-overlay.component';

@Component({
  selector: 'app-sites',
  templateUrl: './orgs.component.html',
  styleUrls: ['./orgs.component.css']
})
export class OrgsComponent implements OnInit, OnDestroy {

  frameworkComps = {
    actionRenderer: OrgListActionIconRendererComponent
  }

  statusBar: {
    statusPanels: StatusPanelDef[];
  } = {
    statusPanels: [
      {
        statusPanel: AgPaginatorComponent,
        key: 'ag-paginator'
      }
    ],
  };

  noRowsOverlayComponent: any = AgNoRowsOverlayComponent;
  noRowsOverlayComponentParams: any = {
    noRowsMessageFunc: () => 'No results returned',
  };

  loadingOverlayComponent: any = AgLoadingOverlayComponent;

  columnDefs: ColDef[] = [

    { field: 'OrgID', headerName: 'ID' },
    { field: 'OrgName', headerName: 'Name' },
    { field: 'Timezone', headerName: 'Time Zone', valueGetter: this.getTimezoneValue },
    { field: 'Disabled', headerName: 'Status', valueGetter: this.statusValue, cellStyle: params => params.data.Disabled ? {color: 'red'} : {color: 'black'} },

    { 
      colId: 'action', minWidth: 68, maxWidth: 68, headerName: 'Action', 
      suppressColumnsToolPanel: true, 
      cellRenderer: 'actionRenderer', 
      sortable: false
    },
  ];

  defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    menuTabs: [],
    wrapHeaderText: true,
    autoHeaderHeight: true,
    minWidth: 100
  };

  form: FormGroup;
  nameControl = new FormControl('');

  nameControlName = 'orgNameControl';

  query: OrgQuery = new OrgQuery();
  gridContext: any;
  currentListSize = 0;
  isFirstGenerate = true;

  subs: Subscription[] = [];

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  loadingGrid = false;
  public sites: Org[];

  constructor(
    private picService: PictureService, 
    public router: Router, 
    private dialog: MatDialog, 
    private auth: AuthService,
    private orgService: OrganizationLib,
    private settingsService: SettingsService,
    private fb: FormBuilder,
    private clipboard: Clipboard) {

      this.gridContext = { componentParent: this };
      this.query.Metadata = new QueryMetadata();
      this.query.Metadata.Sort = new QuerySort();
    }

  ngOnInit() {
    this.form = this.fb.group({});
    this.form.addControl(this.nameControlName, this.nameControl);
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  onGridReady() {
    this.LoadOrgs();

    this.settingsService.getPaginatorPageSize(SettingsKeys.ORGS_LIST_PAGE_SIZE).subscribe(result => {
      if(result == 0) {
        return;
      }

      this.agGrid.api.getStatusPanel<IAgPaginator>('ag-paginator').setPageSize(result);
      
    });
  }

  //#region AG Grid Functions

  getServerSideDatasource(): IServerSideDatasource {
    return {
      getRows: (params) => {

        this.query.Metadata.PageSize = params.api.paginationGetPageSize();
        this.query.Metadata.PageNumber = params.api.paginationGetCurrentPage();

        const sortModel = params.request.sortModel;

        if(sortModel.length > 0) {
          this.query.Metadata.Sort.Sort = sortModel.length > 0 ? sortModel[0].sort : null;
          this.query.Metadata.Sort.ColID = sortModel.length > 0 ? sortModel[0].colId : null;
        }

        this.orgService.getOrgsList(this.query).subscribe(result => {
          this.currentListSize = result.ListSize;
          params.success({
            rowData: result.Items,
            rowCount: result.ListSize,
          });

          if(this.currentListSize == 0) {
            params.api.showNoRowsOverlay();
          }

          params.api.sizeColumnsToFit();
        },
        error => {
          params.fail();
        });

      },
    };
  }

  getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.OrgID;
  };

  onGridGetContextMenu(params: GetContextMenuItemsParams) {

    if(params.column.getColId() == 'edit') {
      return [];
    }

    const result = [
      {
        name: 'Copy',
        action: () => {
          const value = params.context.componentParent.getColumnValue(params.column.getColId(), params.value);
          params.context.componentParent.clipboard.copy(value);
        },
        icon: '<span class="ag-icon ag-icon-copy"></span>'
      }
    ];

    return result;
  }

  onCellKeyDown(event) {
    const kbEvent:KeyboardEvent = event.event;
    if (kbEvent.ctrlKey && kbEvent.key === "c") {
      const value = this.getColumnValue(event.column.getColId(), event.value);
      this.clipboard.copy(value);
    }
  }

  onActionItemClick(result: any) {
    if(!result) {
      return;
    }

    switch(result.action) {
      case "Switch Org":
        this.SwitchToSite(result.item.OrgID);
        break;
      
      case "Edit Org":
        this.AddEditOrg(result.item, 'edit');
        break;

      default:
        break;
    }
  }

  onPaginatorPageSizeChange(pageSize: number) {
    this.settingsService.updatePaginatorPageSize(pageSize, SettingsKeys.ORGS_LIST_PAGE_SIZE).subscribe(() => {
      //TODO
    });
  }

  getColumnValue(fieldName: string, value: any) {
    if(!value) {
      return null;
    }

    let result = '';

    switch(fieldName) {

      //object cases here

      default:
        result = value;
        break;
    }

    return result;
  }

  getNameValue(item: any) {
    if(!item.data) {
      return '';
    }

    return item.data.OrgName;
  }

  statusValue(item: any) {
    return item.data.Disabled ? "Inactive" : "Active";
  }

  getTimezoneValue(item: any) {
    if(!item) {
      return;
    }

    return item.data.Timezone.DisplayName;
  }

  //#endregion

  LoadOrgs(): void {
    this.loadingGrid = true;

    this.query.Name = this.nameControl.value ? this.nameControl.value.toLowerCase() : null;

    if(this.isFirstGenerate || this.currentListSize == 0) {
      this.agGrid.api.setGridOption('serverSideDatasource', this.getServerSideDatasource());
      this.isFirstGenerate = false;
      return;
    }
    
    this.agGrid.api.refreshServerSide({purge: true});
    this.agGrid.api.deselectAll();
  }

  getRenewalDateValue(date: Date) {
    if (date != null) {
      return date.getMilliseconds;
    } else {
      return 0;
    }
  }

  SwitchToSite(siteId: string) {
    this.auth
      .switchSite(siteId)
      .subscribe(() => {
        window.location.reload();
      },
        error => console.log(error),
        () => this.loadingGrid = false);
  }

  DeleteSite(siteId: string, name: string) {
    const message = "Are you sure you want to delete the account:  " + name + "  ? All data for this account will be deleted!!";
    const dialogData = new ConfirmDialogModel("Confirm Action", message, "delete");

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "450px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      const result = dialogResult;
      if (result) {


        this.picService
          .deleteSite(siteId)
          .subscribe((data: any) => this.LoadOrgs(),
            error => console.log(error));

      }
    }); //end dialogRef.afterClosed().subscribe
  } //end delete Site

  DisableSite(siteId: string, name: string) {
    const message = "Are you sure you want to disable the account:  " + name + "  ? Users will not be able to log in to HuntPro!!";
    const dialogData = new ConfirmDialogModel("Confirm Action", message, "disable");

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "450px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      const result = dialogResult;
      if (result) {


        this.picService
          .disableSite(siteId)
          .subscribe((data: any) => this.LoadOrgs(),
            error => console.log(error));

      }
    }); //end dialogRef.afterClosed().subscribe
  } //end disable Site

  SetReadOnly(siteId: string, name: string) {
    const message = "Are you sure you want to set the account:  " + name + "  to read only? Users will not be able to make changes!!";
    const dialogData = new ConfirmDialogModel("Confirm Action", message, "readonly");

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "450px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      const result = dialogResult;
      if (result) {


        this.picService
          .setReadonly(siteId, 1)
          .subscribe((data: any) => {
            this.LoadOrgs();
          },
            error => console.log(error));

      }
    }); //end dialogRef.afterClosed().subscribe
  } //end readonly Site

  RemoveReadOnly(siteId: string, name: string) {
    const message = "Are you sure you want to remove readonly status from the account:  " + name + "? Users will be able to make changes!!";
    const dialogData = new ConfirmDialogModel("Confirm Action", message, "readonly");

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "450px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      const result = dialogResult;
      if (result) {


        this.picService
          .setReadonly(siteId, 0)
          .subscribe((data: any) => {
            this.LoadOrgs();
          },
            error => console.log(error));

      }
    }); //end dialogRef.afterClosed().subscribe
  } //end readonly Site

  EnableSite(siteId: string, name: string) {
    const message = "Are you sure you want to enable the account:  " + name + "  ? Users will be able to log in to HuntPro!!";
    const dialogData = new ConfirmDialogModel("Confirm Action", message, "enable");

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "450px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      const result = dialogResult;
      if (result) {


        this.picService
          .enableSite(siteId)
          .subscribe((data: any) => this.LoadOrgs(),
            error => console.log(error));

      }
    }); //end dialogRef.afterClosed().subscribe
  } //end enable Site


  AddEditOrg(org: Org, mode: string) {

    const dialogRef = this.dialog.open(AddUpdateOrgDialogComponent, new AddUpdateOrgDialogModel({orgData: org, mode: mode}));

    dialogRef.afterClosed().subscribe(dialogResult => {
      this.LoadOrgs();
    }); //end dialogRef.afterClosed().subscribe
  }


  EditSite(site: Org) {
    //go back and add stuff
  }

  onKeyPress(event: KeyboardEvent) {
    if(event.key == 'Enter') {
      this.LoadOrgs();
    }
  }

}
