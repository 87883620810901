import { SimpleUserOption } from "./simple-user-option";
import { TemplateConfigFieldItem } from "./template-config-field-item";

export class TemplateConfigItem {
    ID: number;
    OrgID: number;
    Name: string;
    TypeID: number;
    TypeName: string;
    Notes: string;
    CreateDate: string;
    CreatedBy: SimpleUserOption;
    LastModified: string;
    ModifiedBy: SimpleUserOption;
    Enabled: boolean;
    FieldItems: TemplateConfigFieldItem[]
}
