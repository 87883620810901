import { DiagnosisEventNote } from "./diagnosis-event-note";
import { FilterSelectItem } from "./filter-select-item";
import { PayerItem } from "./payer-item";
import { SimpleUserOption } from "./simple-user-option";

export class DiagnosisEventItem {
    Checked: boolean;
    ID: number;
    EventTypeID: number;
    EventTypeName: string;
    DateOfService: string;
    ServiceLocation: string;
    VisitID: string;
    EMRPID: string;
    ICDCODE: string;
    StatusState: FilterSelectItem;
    Status: FilterSelectItem;
    RootCause: FilterSelectItem;
    RootCauseReason: string;
    PreferredAction: FilterSelectItem;
    Payer: PayerItem;
    AssignedTo: SimpleUserOption;
    Notes: DiagnosisEventNote[];
    SuperbillStatus: string;
    HCCCode: string;
    BillingChargePostingDate: string;
    BillingPaymentPostingDate: string;
    EMRDocumentVersion: string;
    EMRDocumentVersionCreateDate: string;
    EMRDocumentSignedDate: string;
    PatientFirstName: string;
    PatientLastName: string;
    PatientDateOfBirth: string;
    PaymentYear: number;
    MemberMonthsPerPaymentYear: number;
    IsActivePatient: boolean;
    IsOnRaps: boolean;
    IsOnMOR: boolean;
    IsOnMAO: boolean;
    IsOnSupplementalFeed: boolean;
    IsDocumentInEMR: boolean;
    LastModifiedDateTime: string;
    LastModifiedBy: SimpleUserOption;
    CreatedDate: string;
    CCN: string;
    PatientMiddleName: string;
    PatientSuffix: string;
    PatientAddress1: string;
    PatientAddress2: string;
    PatientAddressCity: string;
    PatientAddressState: string;
    PatientAddressCode: string;
    PatientGender: string;
    PatientPCPFirstName: string;
    PatientPCPLastName: string;
    RenderingProviderFirstName: string;
    RenderingProviderLastName: string;
    RenderingProviderNPI: string;
    RenderingProviderTaxID: string;
    RenderingProviderTaxonomyCode: string;
    ProcedureCode: string;
    PlaceOfService: string;
    RCMPID: string;
    BillableProviderNPI: string;
    BillableProviderName: string;
    PatientDeceasedDate: string;
    PatientMedicareID: string;
    PatientPolicyID: string;
}
