import { ComponentType } from '@angular/cdk/portal';
import { AfterViewInit, Component, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AgNoRowsOverlayComponent } from '../../components/ag-grid-components/ag-no-rows-overlay/ag-no-rows-overlay.component';
import { AgIconRendererComponent } from '../../components/ag-grid-components/ag-icon-renderer/ag-icon-renderer.component';
import { ColDef, GetRowIdFunc, GetRowIdParams } from 'ag-grid-enterprise';
import { AgGridAngular } from 'ag-grid-angular';
import { ColGroupDef, GridReadyEvent, RowClassRules } from 'ag-grid-community';
import { TemplateService } from '../../services/settings/template.service';
import { TemplateConfigFieldItem } from '../../models/template-config-field-item';
import { FieldFilterService } from '../../services/filters/field-filter.service';
import { Subscription } from 'rxjs';
import { TemplateConfigItem } from '../../models/template-config-item';
import { FilterSelectComponent } from '../../components/field-components/filter-select/filter-select.component';
import { BooleanSelectComponent } from '../../components/field-components/static/boolean-select/boolean-select.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AgHeaderInfoIconRendererComponent } from '../../components/ag-grid-components/ag-header-info-icon-renderer/ag-header-info-icon-renderer.component';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';
import { AgLoadingOverlayComponent } from '../../components/ag-grid-components/ag-loading-overlay/ag-loading-overlay.component';

export class AddUpdateTemplateConfigDialogModel {
  readonly component: ComponentType<any> = AddUpdateTemplateConfigDialogComponent;
  panelClass?: string | string[] = 'dialog-container';
  autoFocus?: boolean = false;
  data?: any;
  disableClose? = true;
  maxWidth? = '95dvw';

  constructor(data: any) {
    this.data = data;
  }
}

@Component({
  selector: 'app-add-update-template-config-dialog',
  templateUrl: './add-update-template-config-dialog.component.html',
  styleUrls: ['./add-update-template-config-dialog.component.scss']
})
export class AddUpdateTemplateConfigDialogComponent implements OnInit, OnDestroy, AfterViewInit {

  noRowsOverlayComponent: any = AgNoRowsOverlayComponent;
  noRowsOverlayComponentParams: any = {
    noRowsMessageFunc: () => {
      return 'No Entries Found'
    },
  };

  loadingOverlayComponent: any = AgLoadingOverlayComponent;

  frameworkComps = {
    iconRenderer: AgIconRendererComponent,
    headerInfoRenderer: AgHeaderInfoIconRendererComponent
  }


  selectedGridColDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'Selected Fields',
      children: [
        { rowDrag: true, maxWidth: 50, 
          tooltipValueGetter(params) {
            return 'Move'
          }, 
        },
        { 
          cellRenderer: 'iconRenderer',
          cellRendererParams: {
            icon: 'edit'
          },
          tooltipValueGetter(params) {
            return 'Edit'
          }, 
          onCellClicked: this.onEditRow,
          maxWidth: 50
        },
        { field: 'SortID', headerName: 'Order', maxWidth: 75},
        { field: 'Label', headerName: 'Label', editable: true},
        { field: 'Field', headerName: 'Field'},
        { field: 'DefaultValue', headerName: 'Prefill', 
          editable: (params) => {
            return params.data.FieldID == 1
          },
          headerComponent: 'headerInfoRenderer',
          headerComponentParams: {
            iconTooltip: `If field is of type "<Blank>", enter the text that should auto populate.`
          }
        },
        { 
          cellRenderer: 'iconRenderer',
          cellRendererParams: {
            icon: 'delete'
          },
          tooltipValueGetter(params) {
            return 'Delete'
          }, 
          onCellClicked: this.onRemoveRow,
          maxWidth: 50
        }
      ]
    },
  ];

  availableGridColDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'Available Fields',
      children: [
        { rowDrag: true, maxWidth: 50, 
          tooltipValueGetter(params) {
            return 'Move'
          }, 
        },
        { field: 'Field', headerName: 'Field' },
        { field: 'Category', headerName: 'Category', maxWidth: 145 },
      ]
    }
  ];

  defaultColDef: ColDef = {
    editable: false,
    sortable: true,
    filter: true,
    menuTabs: [],
    wrapHeaderText: true,
    autoHeaderHeight: true,
    minWidth: 100
  };

  selectedGridDefaultColDef: ColDef = {
    editable: false,
    sortable: false,
    menuTabs: [],
    wrapHeaderText: true,
    autoHeaderHeight: true,
    minWidth: 100
  };

  public rowClassRules: RowClassRules = {
    // row style function
    'danger-cell-2': (params) => {
      let invalid = !params.data.Label;

      //Grid check for create button disable
      params.api.forEachNode(n => {
        if(!n.data.Label) {
          params.context.componentParent.invalidSelectedRows = true;
        }

        /// IF UNIQUE LABEL ///
        if(n.rowIndex != params.rowIndex && n.data.FieldID == params.data.FieldID && n.data.Label == params.data.Label) {
          params.context.componentParent.invalidSelectedRows = true;
          invalid = true;
        }
      });

      params.context.componentParent.checkGridValidation();

      return invalid;
    }
  };

  gridContext: any;

  actionType = '';
  templateID = 0;
  types = [];

  invalidSelectedRows = false;
  currentSelectedCount = 0;

  form: FormGroup;
  nameControl = new FormControl('', [Validators.required]);
  notesControl = new FormControl('');

  nameControlName = 'name';
  notesControlName = 'notes';
  activeControlName = 'active';

  selectedItems: TemplateConfigFieldItem[] = [];
  availableItems: TemplateConfigFieldItem[] = [];

  invalidTemplateNames = [];

  subs: Subscription[] = [];

  @ViewChild('selectedFields') selectedGrid!: AgGridAngular;
  @ViewChild('availableFields') availableGrid!: AgGridAngular;
  @ViewChild('type') typeSelect: FilterSelectComponent;
  @ViewChild('activeSelect') activeSelect: BooleanSelectComponent;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.selectedGrid?.api.sizeColumnsToFit();
    this.availableGrid?.api.sizeColumnsToFit();
  }

  constructor(
    public dialogRef: MatDialogRef<AddUpdateTemplateConfigDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private templateService: TemplateService,
    private filterService: FieldFilterService,
    private fb: FormBuilder,
    private snackbar: MatSnackBar
  ) {
    this.gridContext = { componentParent: this };
    this.actionType = this.data.actionType;
    this.templateID = this.data.item?.ID || 0;
    this.form = this.fb.group({});
  }

  ngOnInit(): void {
    this.form.addControl(this.nameControlName, this.nameControl);
    this.form.addControl(this.notesControlName, this.notesControl);

    this.subs.push(this.filterService.templateConfigTypes.subscribe(result => {
      this.types = result;
    }));

    this.subs.push(this.nameControl.valueChanges.subscribe(input => {
      if(!input) {
        this.nameControl.setErrors({required: true});
        return;
      }

      const invalidName = this.invalidTemplateNames.some(name => input?.toLowerCase().includes(name.toLowerCase()));
      if(invalidName) {
        this.nameControl.setErrors({invalidName: true});
        return;
      }

      this.nameControl.setErrors(null);
    }))
  }

  ngAfterViewInit(): void {
    if(this.actionType == 'edit') {
      const item = this.data.item;
      const value = this.typeSelect?.options.find(i => i.ID == item.TypeID);
      this.typeSelect.setSingleModeValue(value);

      this.nameControl.setValue(item.Name);
      this.notesControl.setValue(item.Notes);
      this.activeSelect.setValue(item.Enabled);
      this.form.markAsTouched();
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  loadData() {
    this.templateService.getTemplateConfigFieldItems(this.templateID).subscribe(result => {
      this.selectedItems = result;
      this.currentSelectedCount = result?.length;
      this.selectedGrid.api.setGridOption('rowData', result);
    });

    this.templateService.getAvailableFields("1").subscribe(result => {
      this.availableItems = result;
      this.availableGrid.api.sizeColumnsToFit();
    });
    
    this.selectedGrid.api.sizeColumnsToFit();
  }

  onSelectedGridReady(params) {
    this.addGridDropZone();
    this.loadData();
  }

  onAvailableGridReady(params) {
    //TODO
  }

  getAvailableGridRowID: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.FieldID;
  };

  addGridDropZone() {
    const dropZoneParams = this.selectedGrid.api.getRowDropZoneParams({
      onDragStop: (params) => {
        params.context.componentParent.updateSelectedSortOrder();
      },
    });

    this.availableGrid.api.addRowDropZone(dropZoneParams);
  }

  updateSelectedSortOrder(params) {
    const items = [];
    this.selectedGrid.api.forEachNode(n => {
      const data = n.data;
      data.SortID = n.rowIndex + 1;

      items.push(data);
    });

    this.currentSelectedCount = items.length;

    this.selectedGrid.api.applyTransaction({update: items});
  }

  onEditRow(params) {
    params.api.startEditingCell({
      rowIndex: params.node.rowIndex,
      colKey: 'Label',
    });
  }

  onRemoveRow(params) {
    params.api.applyTransaction({remove: [params.node.data]});
    params.context.componentParent.updateSelectedSortOrder();
  }

  onSaveClick(closeAfter: boolean) {
    const item = new TemplateConfigItem();
    item.ID = this.templateID;
    item.TypeID = this.typeSelect?.getSingleModeValue().ID;
    item.Name = this.nameControl.value;
    item.Notes = this.notesControl.value;
    item.Enabled = this.activeSelect?.getSelectedValueAsBoolean();
    item.FieldItems = [];
    this.selectedGrid.api.forEachNode(n => item.FieldItems.push(n.data));

    this.templateService.addUpdateTemplate(item).subscribe(() => {
      this.snackbar.open("Template Saved", null, {duration: 3000});
      if(closeAfter) {
        this.dialogRef.close(true);
      }
    },
    error => {
      if(error == "InvalidName") {
        this.invalidTemplateNames.push(this.nameControl.value);
        this.nameControl.setErrors({invalidName: true, required: false});
        this.nameControl.markAsTouched();
      }
    });
  }

  openCancelConfirm() {
    const message = 'Clicking Cancel will not save any changes. Do you want to continue to close and not save?';
    const title = 'Warning';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "450px",
      data: new ConfirmDialogModel(title, message, '')
    });

    this.subs.push(dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.dialogRef.close(false);
      }
    }));
  }

  checkGridValidation() {
    let result = false;
    this.selectedGrid?.api.forEachNode(n => {
      if(!n.data.Label) {
        result = true;
        return;
      }
      result = false;
    })

    this.invalidSelectedRows = result;
  }

}
