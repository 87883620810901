<mat-form-field [color]="color" [appearance]="appearance" [floatLabel]="floatLabel">
    <mat-label *ngIf="label">{{label}}</mat-label>

    <mat-select
    #userSelect
    [id]="'user-search-' + controlName"
    [formControl]="control"
    [placeholder]="placeholder"
    [matTooltip]="(control.value?.length || 0) > 1 ? displayTooltip() : ''"
    [matTooltipClass]="'field-tooltip'"
    [matTooltipPosition]="toolTipPosition"
    [multiple]="multiple"
    [required]="required">

        <mat-select-trigger *ngIf="multiple">
            <span *ngIf="(control.value?.length || 0) === 1 && !unassignedOption.Selected">
                {{control.value?.[0].user.given_name + ' ' + control.value?.[0].user.family_name || ''}}
            </span>
            <span *ngIf="(control.value?.length || 0) === 1 && unassignedOption.Selected">
                {{control.value?.[0].Description || ''}}
            </span>
            <span *ngIf="(control.value?.length || 0) > 1 && (control.value?.length - displayOptionLengthFeatureSubtract) == userOptions.length">
                All
            </span>
            <span *ngIf="(control.value?.length || 0) > 1 && (control.value?.length - displayOptionLengthFeatureSubtract) < userOptions.length">
                Multiple
            </span>
            <span *ngIf="(control.value?.length || 0) > 1">
            ({{getSelectCount()}})
            </span>
        </mat-select-trigger>

        <mat-option>
            <ngx-mat-select-search
            placeholderLabel="search..."
            noEntriesFoundLabel="No Users Found"
            [id]="'filter-' + controlName"
            [formControl]="filterControl"
            [showToggleAllCheckbox]="multiple"
            [toggleAllCheckboxChecked]="allToggled"
            [toggleAllCheckboxIndeterminate]="indeterminateToggle"
            (toggleAll)="allOptionClicked($event)"></ngx-mat-select-search>
        </mat-option>

        <mat-option 
        #unassignedItem
        *ngIf="unassignedEnabled && (viewEditPermActive || assignPermActive)"
        [value]="unassignedOption"
        (click)="unassignedOption.selected = unassignedItem.selected">
            {{unassignedOption.user.given_name}} {{unassignedOption.user.family_name}}
        </mat-option>

        <mat-option 
        *ngFor="let option of filteredOptions | async"
        #selectItem
        [value]="option"
        (click)="toggleSelection(selectItem, option)">
            {{option.user.given_name}} {{option.user.family_name}}
        </mat-option>

    </mat-select>

    <mat-error *ngIf="control.hasError('required')">Selection is <strong>required</strong></mat-error>
</mat-form-field>
