import { Component, OnInit } from '@angular/core';
import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { IStatusPanelParams } from 'ag-grid-enterprise';

export interface IAgAssignButton {
  updateAssignDisabled(value: boolean): void;
  updateAssignToActive(value: boolean): void;
}

@Component({
  selector: 'app-ag-assign-button',
  templateUrl: './ag-assign-button.component.html',
  styleUrls: ['./ag-assign-button.component.scss']
})
export class AgAssignButtonComponent implements IStatusPanelAngularComp {

  params: IStatusPanelParams<any, any>;
  assignDisabled = true;
  assignToActive = false;
  mode = 'oppty-list';

  agInit(params: IStatusPanelParams<any, any>): void {
    this.params = params;
    this.assignToActive = this.params.context.componentParent.assignToActive;
    this.mode = params.context.componentParent.mode;
  }

  updateAssignDisabled(value: boolean) {
    this.assignDisabled = value;
  }

  updateAssignToActive(value: boolean) {
    this.assignToActive = value;
    this.params.context.componentParent.cd.detectChanges();
  }

  onAssignButtonClick() {
    this.params.context.componentParent.onAssignButtonClick();
  }
}
