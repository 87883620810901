<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container vc-theme">
  <!-- ============================================================== -->
  <!-- Topbar - style you can find in header.scss -->
  <!-- ============================================================== -->
  <mat-toolbar style="background-color:#fff" class="topbar telative" *ngIf="auth.isAuthenticated()">
    <!-- ============================================================== -->
    <!-- Logo - style you can find in header.scss -->
    <!-- ============================================================== -->
    <div class="navbar-header">

      <div id="logo" class="navbar-brand" href="https://dev.valuecycle.app">
        <img src="/assets/images/ValueCycleLogo.svg"/>
      </div>
    </div>

    <span class="grow"></span>

    <span class="org-name">{{ this.auth.orgName }}</span>
    <!-- ============================================================== -->
    <!-- app header component - style you can find in header.scss / header.component.ts-->
    <!-- ============================================================== -->
    <app-header></app-header>
  </mat-toolbar>

  <nav mat-tab-nav-bar class="topbar nav-bar-container mat-elevation-z3" [tabPanel]="navTabPanel" backgroundColor="primary" *ngIf="auth.isAuthenticated()">
    <div class="nav-links flex basis-3/4 justify-start align-middle">
      <div
      class="link-container"
      *ngFor="let link of navLinks"
      [matTooltip]="link.disabled ? link.disabledTooltip : ''"
      [matTooltipClass]="'app-tooltip'">
        <a mat-tab-link
        [routerLink]="link.link"
        [disabled]="link.disabled"
        [active]="activeLink.includes(link.link)"> {{link.label}} </a>
      </div>
    </div>

    <div class="service-icon flex gap-2.5 basis-1/4 justify-end self-center align-middle" *ngIf="!activeLink.includes('/reporting') && !router.url.includes('/configuration')">
      <mat-icon
      [ngClass]="
      {
        'cms-icon-base' : cmsSubmissionWindow > 30,
        'cms-icon-approaching' : 0 <= cmsSubmissionWindow && cmsSubmissionWindow <= 30,
        'cms-icon-past-due' : cmsSubmissionWindow < 0
      }"
      [matTooltip]="cmsSubmissionWindow + ' days until final submission deadline'"
      [matTooltipClass]="'app-tooltip'"
      *ngIf="cmsSubmissionWindow">schedule</mat-icon>
      <app-service-year-icon [selectedYear]="selectedYear"></app-service-year-icon>
    </div>
  </nav>

  <!-- ============================================================== -->
  <!-- End Topbar - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <mat-sidenav-container [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
    [ngClass]="{ minisidebar: minisidebar }">
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <mat-sidenav #snav id="snav" class="pl-xs" [mode]="mobileQuery.matches ? 'side' : 'over'" fixedTopGap="0"
      [opened]="false" [disableClose]="mobileQuery.matches">
      <app-sidebar></app-sidebar>
    </mat-sidenav>
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->

    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-content [ngClass]="auth.isAuthenticated() ? 'page-wrapper' : 'login-page-wrapper'">
      <div class="page-content">
        <mat-tab-nav-panel #navTabPanel>
          <router-outlet>
          </router-outlet>
        </mat-tab-nav-panel>
      </div>
    </mat-sidenav-content>
    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
  </mat-sidenav-container>

</div>