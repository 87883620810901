import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';

export interface IAgHeaderCheckbox {
  updateChecked(isChecked: boolean);
  updateLimit(limit: number);
}

@Component({
  selector: 'app-ag-header-checkbox',
  templateUrl: './ag-header-checkbox.component.html',
  styleUrls: ['./ag-header-checkbox.component.scss']
})
export class AgHeaderCheckboxComponent implements IHeaderAngularComp {

  params: IHeaderParams<any, any>;
  limit = 0;
  checked = false;

  agInit(params: IHeaderParams<any, any>): void {
    this.params = params;
    this.limit = params.context.componentParent.batchEditLimit;
    this.params.context.componentParent.subs.push(this.params.context.componentParent.allSelected.subscribe(result => {
      this.checked = result;
    }));
  }

  refresh(params: IHeaderParams<any, any>): boolean {
    return false;
  }

  updateChecked(isChecked: boolean) {
    this.checked = isChecked;
  }

  updateLimit(limit: number) {
    this.limit = limit;
  }

  onChange() {
    this.params.context.componentParent.currentPageRows.forEach(r => r.setSelected(this.checked));
  }

}
