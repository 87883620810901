import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { MediaMatcher } from "@angular/cdk/layout";
import { Subscription } from "rxjs";

import { AuthService } from "../../../services/authentication/auth.service";


import * as _moment from "moment";

import { OrganizationService } from "../../../services/organization/organization.service";

import { UserService } from "../../../services/user/user.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class AppSidebarComponent implements OnDestroy, OnInit {
  mobileQuery: MediaQueryList;
  public isAdmin = false;
  public isSystemAdmin = false;
  public isReadOnly = true;
  public limitedUser = false;
  subscription2: any;
  private _mobileQueryListener: () => void;

  public aiConfig: any;

  private subscriptions: Subscription[] = [];
  subscription: Subscription;
  folderSubscription: Subscription;
  authSubscription: Subscription;
  currentDataset: any;

  constructor(
    private orgService: OrganizationService,
    public auth: AuthService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public router: Router,
    private userService: UserService
  ) {

    this.mobileQuery = media.matchMedia("(min-width: 768px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

  }



  ngOnInit() {
    // this.subscriptions.push(this.auth.onLoggedIn().subscribe(tag => {
    //  this.ngOnInit();
    //}));



    this.subscriptions.push(
      this.userService.currentUser$.subscribe(
        (user) => {
          if (user.app_metadata.role == "readonlyuser") {
            this.isReadOnly = true;
          } else {
            this.isReadOnly = false;
          }

          if (user.app_metadata.role == "admin") {
            this.isAdmin = true;
          } else {
            this.isAdmin = false;
          }

          if (user.app_metadata.systemAdmin == "true") {
            this.isSystemAdmin = true;
          } else {
            this.isSystemAdmin = false;
          }

          this.limitedUser = user.limitedUser;
        },
        (error) => console.log(error)
      )
    );



    this.subscriptions.push(
      this.orgService.organization$.subscribe((response) => {
        if (response) {
          this.aiConfig = response;
          this.auth.setOrgName(response.orgName);

        }
      })
    );

  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  formatLabel(value: number | null) {
    if (!value) {
      return "0%";
    } else {
      return value + "%";
    }

    return value;
  }













}
