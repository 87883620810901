<h1 mat-dialog-title>
  {{ title }}
</h1>

<div mat-dialog-content>
  <p *ngFor="let entry of message">
    {{ entry }}
  </p>
</div>


<div mat-dialog-actions align="end">
  <button mat-flat-button class="primary-button" (click)="onDismiss()">OK</button>
</div>