import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { LicenseManager } from "ag-grid-enterprise";
import { AgCharts } from 'ag-charts-community';

const agGridLicense = "CompanyName=ValueCycle,LicensedApplication=ValueCycle,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-039014,SupportServicesEnd=5_March_2024_[v2]_MTcwOTU5NjgwMDAwMA==f68ad64d238f6330d20b78207dd52fba";
const agChartsLicense = "Using_this_{AG_Charts}_Enterprise_key_{AG-053984}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{ValueCycle}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{ValueCycle}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{ValueCycle}_need_to_be_licensed___{ValueCycle}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts}_Enterprise_versions_released_before_{22_January_2025}____[v3]_[02]_MTczNzUwNDAwMDAwMA==4bcda74ca1bae964980d322c4e5a53f0";
LicenseManager.setLicenseKey(agGridLicense);
AgCharts.setLicenseKey(agChartsLicense);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
