import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { ACCESS_TOKEN } from "../../storage/constants.lib";
import { ReplaySubject } from "rxjs";
import { HccSummaryCalculations } from "../../models/hcc-summary-calculations";
import { DocumentedDxEventGraphItem } from "../../models/documented-dx-event-graph-item";
import { RootCauseTrendItem } from "../../models/root-cause-trend-item";
import { OpptyHccValuationReportItem } from "../../models/oppty-hcc-valuation-report-item";
import { HccPatientDistributionReportItem } from "../../models/hcc-patient-distribution-report-item";
import { UtilityFunctions } from "../../helpers/utility-functions";
import { DiagnosisEventMonthlyTrendItem } from "../../models/diagnosis-event-monthly-trend-item";

@Injectable({
    providedIn: 'root'
})

export class CalculationsService {
  private endpoint = `${environment.baseURL}/api/`;

  hccSummaryCalculations: ReplaySubject<HccSummaryCalculations>;
  hccSummaryCalculationsRange: ReplaySubject<HccSummaryCalculations[]>;
  rootCauseTrendCalculations: ReplaySubject<RootCauseTrendItem[]>;
  hccValuation: ReplaySubject<OpptyHccValuationReportItem[]>;

  lastRefreshDate: ReplaySubject<Date>;

  hccValuationClosedRowName = 'Closed';
  hccValuationOpenRowName = 'Open';
  hccValuationTotalRowName = 'Total';

  constructor(private http: HttpClient) { 
    this.hccSummaryCalculations= new ReplaySubject<HccSummaryCalculations>(1);
    this.hccSummaryCalculationsRange= new ReplaySubject<HccSummaryCalculations[]>(1);
    this.rootCauseTrendCalculations = new ReplaySubject<RootCauseTrendItem[]>(1);
    this.hccValuation = new ReplaySubject<OpptyHccValuationReportItem[]>(1);
    this.lastRefreshDate = new ReplaySubject<Date>(1);
  }

  private getHttpOptions(params?: HttpParams) {
    const token = localStorage.getItem(ACCESS_TOKEN);
    let headers = new HttpHeaders();
    headers = headers.append("Authorization", "Bearer " + token);
    const httpOptions = {
      headers: headers,
      params: params
    };

    return httpOptions;
  }

  getHccSummaryCalculations(serviceYear: number) {
    const params = new HttpParams().set('serviceYear', serviceYear.toString());
    return this.http.get<HccSummaryCalculations>(this.endpoint + "calculations/hcc-summary", this.getHttpOptions(params)).subscribe(result => {
        this.hccSummaryCalculations.next(result);
        const refreshDate = result.DateUpdated ? new Date(result.DateUpdated) : null;
        this.lastRefreshDate.next(refreshDate);
    });
  }

  getHccSummaryCalculationsByRange(startYear: number, endYear: number) {
    const params = new HttpParams()
    .set('startYear', startYear.toString())
    .set('endYear', endYear.toString());

    return this.http.get<HccSummaryCalculations[]>(this.endpoint + "calculations/hcc-summary-range", this.getHttpOptions(params)).subscribe(result => {
        this.hccSummaryCalculationsRange.next(result);
    });
  }

  getDocumentedDxEventGraphData() {
    return this.http.get<DocumentedDxEventGraphItem[]>(this.endpoint + "calculations/dx-documented-event-graph", this.getHttpOptions());
  }

  getRootCauseTrend(startYear: number, endYear: number) {
    const params = new HttpParams()
    .set('startYear', startYear.toString())
    .set('endYear', endYear.toString());

    return this.http.get<RootCauseTrendItem[]>(this.endpoint + "calculations/root-cause-trend", this.getHttpOptions(params)).subscribe(result => {
        this.rootCauseTrendCalculations.next(result);
    });
  }

  getOpptyHccValuation(serviceYear: number) {
    const params = new HttpParams().set('serviceYear', serviceYear.toString());

    return this.http.get<OpptyHccValuationReportItem[]>(this.endpoint + "calculations/status-valuation", this.getHttpOptions(params));
  }

  getOpptyPreferredActionHccValuation(serviceYear: number) {
    const params = new HttpParams().set('serviceYear', serviceYear.toString());

    return this.http.get<OpptyHccValuationReportItem[]>(this.endpoint + "calculations/preferred-action-status-valuation", this.getHttpOptions(params)).subscribe(result => {
      this.hccValuation.next(result);
    });
  }

  getHccPatientDistribution(serviceYear: number) {
    const params = new HttpParams().set('serviceYear', serviceYear.toString());

    return this.http.get<any>(this.endpoint + "calculations/patient-distribution", this.getHttpOptions(params));
  }

  getDxMonthlyBreakdownTreand() {
    return this.http.get<DiagnosisEventMonthlyTrendItem[]>(this.endpoint + "calculations/dx-monthly-trend", this.getHttpOptions());
  }


  //#region Helper Methods
  getHccValuationGrandTotalItems(data: OpptyHccValuationReportItem[]) {
    const rowItems = [];
    let closedTotals = new OpptyHccValuationReportItem();
    let openTotals = new OpptyHccValuationReportItem();
    let totals = new OpptyHccValuationReportItem();

    let closedTotal = 0;
    let closedHccCount = 0;
    let closedValuation = 0;

    let openTotal = 0;
    let openHccCount = 0;
    let openValuation = 0;

    const openItems = data.filter(d => d.StateID == 1);
    const closedItems = data.filter(d => d.StateID == 2);
    const uniqueClosedStatuses = UtilityFunctions.distinct(closedItems, "Status").map(i => i.Status);
    const uniqueOpenStatuses = UtilityFunctions.distinct(openItems, "Status").map(i => i.Status);

    openItems.forEach(k => {
      openTotal += k.TotalCount;
      openHccCount += k.HCCCount;
      openValuation += k.Valuation;
    });

    closedItems.forEach(k => {
      closedTotal += k.TotalCount;
      closedHccCount += k.HCCCount;

      //1 = Non-Actionable
      if(k.StatusID != 1) {
        closedValuation += k.Valuation;
      }
    });

    closedTotals = {PreferredActionID: 0, PreferredAction: '', StateID: 2, StatusID: 0, Status: '', TotalCount: closedTotal, HCCCount: closedHccCount, Valuation: closedValuation};
    openTotals = {PreferredActionID: 0, PreferredAction: '', StateID: 1, StatusID: 0, Status: '', TotalCount: openTotal, HCCCount: openHccCount, Valuation: openValuation};
    totals = {PreferredActionID: 0, PreferredAction: '', StateID: 1, StatusID: 0, Status: '', TotalCount: openTotal + closedTotal, HCCCount: openHccCount + closedHccCount, Valuation: openValuation + closedValuation};

    rowItems.push(this.addNewHccValuationCategory(closedTotals, '', this.hccValuationClosedRowName));
    this.setHccValuationCombinedTotalsByStatus(uniqueClosedStatuses, closedItems, rowItems);

    rowItems.push(this.addNewHccValuationCategory(openTotals, '', this.hccValuationOpenRowName));
    this.setHccValuationCombinedTotalsByStatus(uniqueOpenStatuses, openItems, rowItems);

    rowItems.push(this.addNewHccValuationCategory(totals, '', this.hccValuationTotalRowName));

    return rowItems;
  }

  setHccValuationCombinedTotalsByStatus(statusList: string[], itemList: OpptyHccValuationReportItem[], gridRowList: any[]) {
    statusList.forEach(s => {
      const items = itemList.filter(i => i.Status == s);
      const statusTotals = new OpptyHccValuationReportItem();
      let total = 0;
      let hcc = 0;
      let valuation = 0;

      items.forEach(i => {
        total += i.TotalCount;
        hcc += i.HCCCount;
        valuation += i.Valuation;
      });

      statusTotals.TotalCount = total;
      statusTotals.HCCCount = hcc;
      statusTotals.Valuation = valuation;

      gridRowList.push(this.addNewHccValuationCategory(statusTotals, this.hccValuationClosedRowName, s));
    });
  }

  getHccValuationRowItems(data: OpptyHccValuationReportItem[]) {
    const rowItems = [];
    const openItems = data.filter(d => d.StateID == 1);
    const closedItems = data.filter(d => d.StateID == 2);

    let closedTotals = new OpptyHccValuationReportItem();
    let openTotals = new OpptyHccValuationReportItem();
    let totals = new OpptyHccValuationReportItem();

    let closedTotal = 0;
    let closedHccCount = 0;
    let closedValuation = 0;

    let openTotal = 0;
    let openHccCount = 0;
    let openValuation = 0;

    openItems.forEach(k => {
      openTotal += k.TotalCount;
      openHccCount += k.HCCCount;
      openValuation += k.Valuation;
    });

    closedItems.forEach(k => {
      closedTotal += k.TotalCount;
      closedHccCount += k.HCCCount;

      //1 = Non-Actionable
      if(k.StatusID != 1) {
        closedValuation += k.Valuation;
      }
    });

    closedTotals = {PreferredActionID: 0, PreferredAction: '', StateID: 2, StatusID: 0, Status: '', TotalCount: closedTotal, HCCCount: closedHccCount, Valuation: closedValuation};
    openTotals = {PreferredActionID: 0, PreferredAction: '', StateID: 1, StatusID: 0, Status: '', TotalCount: openTotal, HCCCount: openHccCount, Valuation: openValuation};
    totals = {PreferredActionID: 0, PreferredAction: '', StateID: 1, StatusID: 0, Status: '', TotalCount: openTotal + closedTotal, HCCCount: openHccCount + closedHccCount, Valuation: openValuation + closedValuation};

    if(closedItems?.length > 0) {
      rowItems.push(this.addNewHccValuationCategory(closedTotals, '', this.hccValuationClosedRowName));

      data.filter(d => d.StateID == 2).forEach(k => {
        rowItems.push(this.addNewHccValuationCategory(k, this.hccValuationClosedRowName, k.Status));
      });
    }

    if(openItems?.length > 0) {
      rowItems.push(this.addNewHccValuationCategory(openTotals, '', this.hccValuationOpenRowName));

      data.filter(d => d.StateID == 1).forEach(k => {
        rowItems.push(this.addNewHccValuationCategory(k, this.hccValuationOpenRowName, k.Status));
      });
    }

    rowItems.push(this.addNewHccValuationCategory(totals, '', this.hccValuationTotalRowName));

    return rowItems;
  }

  addNewHccValuationCategory(dataItem: any, parentName: string, displayName: string) {

    if(!dataItem) {
      return;
    }

    const rowItem = {};
    rowItem["DisplayName"] = displayName;
    rowItem["Parent"] = parentName;
    rowItem["TotalCount"] = dataItem.TotalCount;
    rowItem["HCCCount"] = dataItem.HCCCount;
    rowItem["Valuation"] = dataItem.Valuation;

    return rowItem;
  }

  getHccValuationTotal(items: OpptyHccValuationReportItem[]) {
    let total = 0;
    items.forEach(i => total += i.Valuation);
    return total;
  }
  //#endregion
}