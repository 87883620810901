import { ComponentType } from '@angular/cdk/portal';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DiagnosisEventItem } from '../../models/diagnosis-event-item';
import { DiagnosisEventService } from '../../services/diagnosis-event/diagnosis-event.service';
import { UserSearchByPermissionComponent } from '../../components/field-components/user-search-by-permission/user-search-by-permission.component';
import { DiagnosisEventQuery } from '../../models/diagnosis-event-query';

export class AssignOpptyDialogModel {
  readonly component: ComponentType<any> = AssignOpptyDialogComponent;
  panelClass?: string | string[] = 'dialog-container';
  autoFocus?: boolean = false;
  data?: any;
  disableClose? = true;

  constructor(data: any) {
    this.data = data;
  }
}

@Component({
  selector: 'app-assign-oppty-dialog',
  templateUrl: './assign-oppty-dialog.component.html',
  styleUrls: ['./assign-oppty-dialog.component.scss']
})
export class AssignOpptyDialogComponent implements OnInit {

  assignDialogForm: FormGroup;
  query: DiagnosisEventQuery;
  items: DiagnosisEventItem[] = [];
  parent: string;
  allEnabled = false;
  queryResultsCount = 0;

  controlName = 'assign-user';
  modeControlName = 'assign-mode';

  modeControl = new FormControl('default', [Validators.required]);

  @ViewChild('assignSearchDialog') assignedComp: UserSearchByPermissionComponent;

  constructor(
    public dialogRef: MatDialogRef<AssignOpptyDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private opptyService: DiagnosisEventService,
    private fb: FormBuilder
  ) 
  {
    this.assignDialogForm = this.fb.group({});
    this.items = this.data.items;
    this.parent = this.data.parent;
    this.allEnabled = this.data.allEnabled;
    this.queryResultsCount = this.data.queryResultsCount;
    this.query = this.data.query;

    if(this.allEnabled) {
      this.assignDialogForm.addControl(this.modeControlName, this.modeControl);
    }
  }

  ngOnInit(): void {
    //TODO
  }

  onAssignClick() {
    const value = this.assignedComp.getSingleModeValue();
    const query = this.modeControl?.value == 'default' ? null : this.query;
    const batchCount = query ? this.queryResultsCount : this.items.length;

    this.opptyService.updateAssignedOpptyListItems(this.items, value.user, batchCount, query).subscribe(result => {
      this.dialogRef.close(result);
    });
  }

}
