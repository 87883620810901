import { SimpleUserOption } from "./simple-user-option";

export class HccValuationItem {
    ID: number;
    OrgID: number;
    ServiceYear: number;
    ValuationAmount: number;
    LastModifiedDateTime: string;
    CreateDateTime: string;
    LastModifiedBy: SimpleUserOption;
}
