import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ReplaySubject } from 'rxjs';
import { Permission } from '../../models/permission';
import { ServiceFormatter } from '../../helpers/service-formatter';
import { User } from '../../models/users';

export enum PermissionType {
  Org = 1,
  User = 2
}

export enum PermissionMap {
  AssignOpportunities = 1,
  ViewAndEditAllDxEvents = 2,
  AcceptsOpportunityAssignment = 3,
  ExportDiagnosisEvents = 4,
  GenerateReportsAdmin = 5,
  GenerateReportsDxEvents = 6,
  GenerateReportsOpportunities = 7,
  GenerateReportsHCC = 8,
  GenerateReportsFinancial = 9
}

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  private endpoint = ServiceFormatter.endpoint;

  permissions: ReplaySubject<Permission[]>;
  activeUserPermissions: ReplaySubject<Permission[]>;
  activeOrgPermissions: ReplaySubject<Permission[]>;

  constructor(private http: HttpClient) { 
    this.permissions = new ReplaySubject<Permission[]>(1);
    this.activeOrgPermissions = new ReplaySubject<Permission[]>(1);
    this.activeUserPermissions = new ReplaySubject<Permission[]>(1);
  }

  getPermissions() {
    return this.http.get<Permission[]>(this.endpoint + "permission", ServiceFormatter.getHttpOptions()).subscribe(result => {
      if(!result) {
        return;
      }

      const orgActivePerms = result.filter(p => p.TypeID == PermissionType.Org && p.Active);
      const userActivePerms = result.filter(p => p.TypeID == PermissionType.User && p.Active);

      this.permissions.next(result);
      this.activeOrgPermissions.next(orgActivePerms);
      this.activeUserPermissions.next(userActivePerms);
    })
  }

  getPermissionsByUser(userID: string) {
    const params = new HttpParams().set('userID', userID);
    return this.http.get<Permission[]>(this.endpoint + "permission/user", ServiceFormatter.getHttpOptions(params));
  }

  updateUserPermissions(user: User, items: Permission[]) {
    return this.http.put<any>(this.endpoint + "permission/user", {
      UserID: user.user_id,
      UserFirstName: user.given_name,
      UserLastName: user.family_name,
      Permissions: items
    }, ServiceFormatter.getHttpOptions());
  }
}
