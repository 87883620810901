import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatSelectSearchComponent } from 'ngx-mat-select-search';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-filter-field-search-select',
  templateUrl: './filter-field-search-select.component.html',
  styleUrls: ['./filter-field-search-select.component.scss']
})
export class FilterFieldSearchSelectComponent<T> implements OnInit {

  @Input() form: FormGroup;
  @Input() color = 'primary';
  @Input() appearance = 'outline';
  @Input() floatLabel = 'always';
  @Input() multiple = false;
  @Input() controlName = 'field-search-select';
  @Input() label = '';
  @Input() placeholder = 'Make a Selection';
  @Input() toolTipPosition = 'above';
  @Input() selectAllEnabled = false;
  @Input() required = false;
  @Input() noneEnabled = true;
  @Input() idField = 'ID';
  @Input() labelField = 'Description';

  private _options: T[] = [];

  set options(value: T[]){
    if(this._options == value) {
      return;
    }

    this._options = value;
    this.filteredOptions.next(value);
  }

  get options(): T[] {
    return this._options;
  }

  private _disabled;
  @Input() set disabled(value: boolean) {
    this._disabled = value;
    this._disabled ? this.control?.disable() : this.control?.enable();
  }

  get disabled(): boolean {
    return this._disabled;
  }

  filteredOptions: ReplaySubject<T[]> = new ReplaySubject<T[]>(1);
  control = new FormControl(null);
  filterControl = new FormControl<string>('');
  noneOption = {};
  allToggled = false;
  indeterminateToggle = false;

  subs: Subscription[] = [];

  protected _onDestroy = new Subject<void>();

  @Output() ready = new EventEmitter();
  @Output() valueChanged = new EventEmitter();

  @ViewChild(MatSelect) select: MatSelect;
  @ViewChild(MatSelectSearchComponent) searchFilter: MatSelectSearchComponent;

  constructor() { 
    //TODO
  }

  ngOnInit(): void {

    this.noneOption[this.idField] = -1;

    if(this.required) {
      this.control.setValidators([Validators.required]);
    }

    if(this.form) {
      this.form.addControl(this.controlName, this.control);
    }

    this.filterControl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterMulti();

      if(this.multiple) {
        this.setAllToggleState();
      }
    });

    if(this.multiple) {
      this.control.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.setAllToggleState();
      });
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  setSelectedValues(values: T[]) {

    if(!values) {
      this.control?.reset();
      this.control?.setValue([]);
      this.searchFilter._reset();
      return;
    }

    if(values.length == 0) {
      this.allOptionClicked(true);
      return;
    }

    values.forEach(o => {
      const found = this.select.options.find((i, index) => {
        if(index == 0) {
          return;
        }

        return i.value[this.idField] == o[this.idField];
      });

      if(found) {
        found.select();
      }
    });
  }

  setSingleValueByID(id: any) {
    const item = this.select.options.find(i => {
      if(i.value) {
        return i.value[this.idField] === id;
      }
    });

    this.control.setValue(item?.value || null);
  }

  setToNone() {
    this.control?.setValue(this.noneOption);
  }

  getValues() {

    if(this.allToggled && this.multiple) {
      const value = {};
      value[this.idField] = 0;
      return [value];
    }

    const controlValue = this.control?.value;

    //Multi mode null check
    if(this.multiple && !controlValue) {
      return [];
    }

    //Single mode none/null check
    if(!this.multiple && !controlValue) {
      return null;
    }
    
    return this.control?.value.map(i => i);
  }

  allOptionClicked(isChecked: boolean) {

    this.select.options.forEach((o, index) => {
      if(index == 0) {
        return;
      }

      isChecked ? o.select() : o.deselect();
    });

  }

  onSelectionChange() {
    this.valueChanged.emit(this.control?.value);
  }

  getTooltipString() {
    let result = '';
    const options = this.control?.value;

    options.forEach((o, index) => {
      result += o[this.labelField];

      if(index < options.length - 1) {
        result += ', ';
      }
    })
    return result;
  }

  getSelectCount() {
    return (this.control.value?.length <= this.options.length) 
      ? this.control.value?.length 
      : this.control.value?.length - 1;
  }

  filterMulti() {
    if (!this._options) {
      return;
    }

    let search = this.filterControl?.value;

    if (!search) {
      this.filteredOptions.next(this._options.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredOptions.next(
      this._options.filter(o => (o[this.labelField].toLowerCase()).indexOf(search.toLowerCase()) > -1)
    );
  }

  setAllToggleState() {
    let filteredLength = 0;
    if(this.control?.value) {
      this._options.forEach(o => {
        if(this.control?.value.indexOf(o) > -1) {
          filteredLength++;
        }
      });

      this.indeterminateToggle = filteredLength > 0 && filteredLength < this._options.length;
      this.allToggled = filteredLength > 0 && filteredLength === this._options.length;
    }
  }

}
